import { spacing } from 'ams-common';
import styled from 'styled-components';

export const StyledFileName = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
  margin-top: ${spacing.xs};
  margin-bottom: ${spacing.xs};
`;
