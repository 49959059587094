import * as t from 'io-ts';
import { tOptional } from 'src/types';

export const CurrentContactDetailsCodec = t.type({
  clientId: t.number,
  contactId: t.number,
  name: t.string,
  type: tOptional(t.string),
  preferType: tOptional(t.string),
  phone: tOptional(t.string),
  homePhone: tOptional(t.string),
  email: tOptional(t.string),
  mobile: tOptional(t.string),
  complianceType: tOptional(t.string),
});

export type ContactDetails = t.TypeOf<typeof CurrentContactDetailsCodec>;

// Auditor Details

const outOfOfficeDetailsCodec = t.type({
  active: tOptional(t.boolean),
  startDate: tOptional(t.string),
  endDate: tOptional(t.string),
  message: tOptional(t.string),
});

export const AuditorDetailCodec = t.intersection([
  t.type({
    userId: t.string,
    fullName: t.string,
    title: tOptional(t.string),
    position: tOptional(t.string),
    phoneNumber: t.string,
    email: t.string,
  }),
  t.partial({
    outOfOfficeDetails: tOptional(outOfOfficeDetailsCodec),
  }),
]);

export type AuditorDetailsResult = t.TypeOf<typeof AuditorDetailCodec>;

// start - Onboarding details
export const TableContactCodec = t.type({
  id: t.string,
  contactId: t.number,
  registrationStatus: t.string,
  name: tOptional(t.string),
  principalContact: t.boolean,
  phone: tOptional(t.string),
  email: tOptional(t.string),
  type: tOptional(t.string),
});

export const OnboardingCodec = t.type({
  name: t.string,
  id: t.number,
  contacts: t.array(TableContactCodec),
});

export const OnboardingDataCodec = t.array(OnboardingCodec);
export type OnboardingData = t.TypeOf<typeof OnboardingDataCodec>;
export type OnboardingRowData = t.TypeOf<typeof TableContactCodec>;

// end - Onboarding details

export const PortalContactSummaryCodec = t.type({
  id: t.string,
  portalMatterId: t.string,
  clientId: t.number,
  contactId: t.number,
  clientName: t.string,
  contactName: t.string,
  contactEmail: t.string,
  complianceType: t.string,
  registrationStatus: t.string,
});

export type PortalContactSummary = t.TypeOf<typeof PortalContactSummaryCodec>;
