const contents = {
  'matter.tabs.files.upload.error.title': 'Error in uploading',
  'matter.tabs.files.upload.error.multiple.files.description':
    'Following file(s) cannot be uploaded as the format is not supported or the file content is empty. Please rectify and upload. Please click continue to proceed with uploading the supported files.',
  'matter.tabs.files.upload.error.single.file.description':
    'Following file cannot be uploaded as the format is not supported or the file content is empty. Please rectify and upload.',
  'matter.tabs.files.upload.error.virus.file.description':
    'Following file(s) cannot be uploaded as there is a detection of virus or the file is password protected. Please rectify and upload.',
  'matter.tabs.empty.files.upload.error.single.file.description':
    'Following file cannot be uploaded as the format is not supported or the file content is empty. Please rectify and upload.',
  'matter.tabs.empty.files.upload.error.multiple.files.description':
    'Following file(s) cannot be uploaded as the format is not supported or the file content is empty. Please rectify and upload. Please click continue to proceed with uploading the supported files.',
};

export default contents;
