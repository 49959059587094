import { MainStatus, StatusOptions } from './constants';
import { StatusOption } from './types';

export const getStatusOptions = (status: string): StatusOption[] => {
  return StatusOptions[status] || [];
};

export const getMainStatus = (status: string) => {
  return MainStatus[status] || '';
};

export const getStatusLabel = (status: string | null, value: string) => {
  if (status === null) {
    return value;
  }
  return (
    (StatusOptions[status] || []).find(
      (statusOption) => statusOption.value === value,
    )?.text || value
  );
};
