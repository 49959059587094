import { RecipientsData } from './types';

export const getSelectedRecipientData = (
  clientFilters: string,
  recipientsData?: RecipientsData,
) => {
  if (!recipientsData) {
    return null;
  }

  return (
    recipientsData.filter((recipient) =>
      clientFilters.includes(recipient.clientId.toString()),
    ) || null
  );
};
