import React from 'react';
import { InPageAlert } from '@snsw/react-component-library';

import { ERROR_TYPE_MAPPER } from './constants';
import { ErrorKey } from './types';

const ErrorHandler: React.FC<{ keys: ErrorKey[] }> = ({ keys }) => {
  if (keys.length === 0) return null;

  return (
    <>
      {keys.map((key) => (
        <div key={key}>
          <InPageAlert variant="error" title={ERROR_TYPE_MAPPER[key]} compact />
        </div>
      ))}
    </>
  );
};

export default ErrorHandler;
