import { useQuery } from 'react-query';
import { QueryKey } from 'src/api/constants';

import { APIResource, queryApi } from '../../../api';

import { MattersDataCodec } from './types';

const fetch = (matterStatus?: 'open' | 'closed') => {
  let params = { pageSize: 9999, pageIndex: 0 };
  params = matterStatus
    ? { ...params, ...{ isClosed: matterStatus === 'closed' } }
    : params;
  return queryApi(
    {
      resourcePath: APIResource.matters,
      suffix: ['matters'],
      params,
    },
    MattersDataCodec,
  );
};

export const useMattersData = (
  isCustomer: boolean = false,
  matterStatus?: 'open' | 'closed',
) =>
  useQuery([QueryKey.MATTERS], () => fetch(matterStatus), {
    enabled: !isCustomer,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
