import { spacing } from 'ams-common';
import styled from 'styled-components';

export const DocumentLinkRow = styled.div`
  margin-top: ${spacing.sm};
  display: flex;
  align-items: baseline;
`;

export const DocumentLink = styled.a`
  margin-left: ${spacing.sm};
  font-weight: 700;
`;
