import { useNavigate } from 'react-router-dom';
import { SessionObject } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

import { PATHS } from '../../routes/constants';

export * from './AMSSignIn';

export const OktaSignIn = () => {
  const { oktaAuth } = useOktaAuth();
  const history = useNavigate();

  oktaAuth.session.get().then((sessionObject: SessionObject) => {
    if (sessionObject?.status === 'ACTIVE') {
      oktaAuth
        .signInWithRedirect()
        .catch((err) => console.log('Found an error', err));
    } else {
      history(PATHS.home);
    }
  });

  return null;
};
