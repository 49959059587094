import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  MethodTypes,
  queryApi,
  requestApi,
} from 'src/api';
import { QueryKey } from 'src/api/constants';

import { ActionItemsListCodec, InternalDueDatePayload } from './types';

const fetchCustomerActionItems = (matterUId: string | null) => {
  if (!matterUId) {
    return null;
  }

  return queryApi(
    {
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', matterUId, 'actionItems'],
      params: {
        pageNumber: 0,
        pageSize: 99999,
      },
    },
    ActionItemsListCodec,
  );
};

export const useCustomerActionItems = (matterUId: string | null) =>
  useQuery([QueryKey.ACTION_ITEMS], () => fetchCustomerActionItems(matterUId), {
    enabled: !!matterUId,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

const fetchAuditorActionItems = () => {
  return queryApi(
    {
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', 'actionItems'],
      params: {
        pageNumber: 0,
        pageSize: 99999,
      },
    },
    ActionItemsListCodec,
  );
};

export const useAuditorActionItems = () =>
  useQuery([QueryKey.ACTION_ITEMS], () => fetchAuditorActionItems(), {
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

const updateInternalDueDate = (
  matterUId: string | null,
  actionItemId: string | null,
  payload: InternalDueDatePayload,
  requestType = MethodTypes.PATCH,
) => {
  return requestApi(
    {
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', `${matterUId}`, 'actionItems', `${actionItemId}`],
    },
    requestType,
    payload,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useUpdateInternalDueDate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ([matterUId, actionItemId, payload, requestType = MethodTypes.PATCH]: [
      string,
      string,
      InternalDueDatePayload,
      MethodTypes,
    ]) => {
      return updateInternalDueDate(
        matterUId,
        actionItemId,
        payload,
        requestType,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.ACTION_ITEMS]);
        console.log('Due date successfully updated');
      },
      onError: () => {
        console.log('Due Date failed to update');
      },
    },
  );
};
