import dashboardContent from 'src/screens/auditor/dashboard/contents';
import notificationsContent from 'src/screens/auditor/notifications/contents';
import mainAutoLogoutContent from 'src/screens/common/mainAutoLogout/contents';
import mattersContent from 'src/screens/common/matters/contents';
import statusManagerContent from 'src/screens/common/matters/tabs/auditor/statusManager/contents';
import auditorContactsContent from 'src/screens/common/matters/tabs/common/matterContacts/auditor/contents';
import customerContactsContent from 'src/screens/common/matters/tabs/common/matterContacts/customer/contents';
import matterCorrespondenceContent from 'src/screens/common/matters/tabs/common/matterCorrespondence/contents';
import matterDocumentsContent from 'src/screens/common/matters/tabs/common/matterDocuments/contents';
import matterMessagesContent from 'src/screens/common/matters/tabs/common/matterMessages/contents';
import matterNotificationsContent from 'src/screens/common/matters/tabs/common/matterNotifications/contents';
import matterSummaryContent from 'src/screens/common/matters/tabs/common/matterSummary/contents';
import myProfileContent from 'src/screens/common/myProfile/contents';
import customerAuthFormContent from 'src/screens/customer/customerAuthForm/content';
import customerLoginFormContent from 'src/screens/customer/customerLoginForm/content';
import onboardingContent from 'src/screens/customer/onboarding/contents';

const combineFeatureContents = {
  // screens
  ...dashboardContent,
  ...mattersContent,
  ...notificationsContent,
  ...matterCorrespondenceContent,
  ...myProfileContent,
  ...auditorContactsContent,
  ...onboardingContent,
  ...customerAuthFormContent,
  ...customerLoginFormContent,
  ...matterSummaryContent,
  ...matterNotificationsContent,
  ...customerContactsContent,
  ...matterDocumentsContent,
  ...statusManagerContent,
  ...matterMessagesContent,
  ...mainAutoLogoutContent,
};

export default combineFeatureContents;
