export enum MessageFieldLabels {
  lastActionBy = 'Last responded by',
  subject = 'Subject',
  clients = 'Client ID',
  entityName = 'Entity name',
  lastActionDate = 'Last updated',
}

export enum MessageFieldNames {
  lastActionBy = 'lastActionBy',
  subject = 'subject',
  clients = 'clientId',
  entityName = 'entityName',
  lastActionDate = 'lastActionDate',
}

export const SortedFields = ['lastActionBy', 'lastActionDate', 'entityName'];
export const PDF_EXT = '.pdf';
