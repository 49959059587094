export interface FileData {
  documentId: string;
  fileId: string | undefined | null;
  fileName: string;
  fileSize: number;
  description: string;
  entity: string;
  isUploaded: boolean;
}

export interface SubmitResponseToAuditCommencementDocumentsFormValues {
  recipient: string;
  messageSubject: string;
  message: string;
  sections: Record<
    string,
    {
      documentId: string;
      fileId: string | undefined | null;
      fileName: string;
      fileSize: number;
      description: string;
      entity: string;
      isUploaded: boolean;
    }[]
  > | null;
}

export interface SubmitResponseToAuditCommencementDocumentsErrors {
  [key: string]: string | undefined;
}

export interface UploadedDocument {
  id: string | null;
  s3Key: string | null;
  errorMessage: string | null;
}

export interface ResponseToAuditCommencementRequestBody {
  corroType: string;
  dueDate: string;
  subject?: string;
  messageBody?: string;
  documents?: {
    id: string;
  }[];
}

export enum RequestType {
  Add,
  Update,
}

export enum SaveAs {
  Draft,
  Submit,
}

export interface DocumentUploadErrorResponse {
  messages?: string[];
}
