import { useState } from 'react';
import { FormGroup, Modal, Textarea } from '@snsw/react-component-library';

interface DescriptionModalProps {
  description: string;
  setDescription: (description: string) => void;
  closeModal: () => void;
  isDescriptionMandatory: boolean;
}

export const DescriptionModal = ({
  description,
  setDescription,
  closeModal,
  isDescriptionMandatory,
}: DescriptionModalProps) => {
  const [newDescription, setNewDescription] = useState(description);
  const [descriptionError, setDescriptionError] = useState(false);
  const [messageCharCount, setMessageCharCount] = useState(description.length);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setMessageCharCount(event.target.value.length);
    setNewDescription(event.target.value);
  };
  const handleSubmit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (isDescriptionMandatory && !newDescription) {
      setDescriptionError(!newDescription);
    } else {
      setDescription(newDescription);
      closeModal();
    }
  };
  return (
    <Modal
      title="Description"
      buttons={[
        {
          text: 'Submit',
          onClick: handleSubmit,
        },
        {
          text: 'Cancel',
          onClick: closeModal,
        },
      ]}
    >
      <FormGroup
        label="Description"
        errorMessage="Description is required"
        hasError={descriptionError}
        helpMessage={`Maximum 175 characters including spaces. ${(
          175 - messageCharCount
        ).toString()} character(s) remaining`}
      >
        <Textarea
          value={newDescription}
          onChange={handleDescriptionChange}
          placeholder="Document description"
          maxLength={175}
        />
      </FormGroup>
    </Modal>
  );
};
