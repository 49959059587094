import { Input } from '@snsw/react-component-library';
/*
    - These hidden inputs are used for error summary items
    - On click of the items curser should scroll to the section
 */

export const HiddenInputs = ({
  sectionId,
  index,
}: {
  sectionId: string;
  index: string;
}) => (
  <Input
    name={`sections-${sectionId}-${index}`}
    style={{ position: 'absolute', left: '-9999px' }}
  />
);
