import environments from 'src/environments';

export const IDLE_TIME_MIN = Number(environments.logoutIdleTimeInMinutes);
export const IDLE_TIME_MS = IDLE_TIME_MIN * 1000 * 60;
export const SHOW_MODAL_TIME_MS = 1 * 1000 * 60;
export const MODAL_AUTO_LOGOUT_MS = 1 * 1000 * 60;
export const EVENTS = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

export const LAST_ACTIVITY_KEY_PREFIX = 'lastActivity_';
export const LOGOUT_KEY_PREFIX = 'logout_';
export const CONTINUE_KEY_PREFIX = 'continue_';

export const USER_TYPES = {
  CUSTOMER: 'customer',
  STAFF: 'staff',
};
