export const CLASSIFICATION_SECTIONS = [
  {
    apiSectionName: 'Audit Questionnaire',
    sectionName: 'Audit questionnaire',
    sectionId: 'audit-questionnaire',
  },
  {
    apiSectionName: 'Financial Statements',
    sectionName: 'Financial statements',
    sectionId: 'financial-statements',
  },
  {
    apiSectionName: 'Payroll Records',
    sectionName: 'Payroll records',
    sectionId: 'payroll-records',
  },
  {
    apiSectionName: 'Business or Group Structure',
    sectionName: 'Business/group structure',
    sectionId: 'business-group-structure',
  },
  {
    apiSectionName: 'Fringe Benefits Information',
    sectionName: 'Fringe benefits tax',
    sectionId: 'fringe-benefits-tax',
  },
  {
    apiSectionName: 'Exempt Payment or Wages',
    sectionName: 'Exempt payments/wages',
    sectionId: 'exempt-payments-wages',
  },
  {
    apiSectionName: 'Payments Outside the Payroll System',
    sectionName: 'Payments outside the payroll system',
    sectionId: 'payments-outside-payroll-system',
  },
  {
    apiSectionName: 'Taxable Payments Annual Report',
    sectionName: 'Taxable payments annual report',
    sectionId: 'taxable-payments-annual-report',
  },
  {
    apiSectionName: 'Shares and Options Scheme',
    sectionName: 'Shares & options schemes',
    sectionId: 'shares-options-schemes',
  },
  {
    apiSectionName: 'Jobkeeper Payment',
    sectionName: 'Jobkeeper payments',
    sectionId: 'jobkeeper-payments',
  },
  {
    apiSectionName: 'Other',
    sectionName: 'Other documents',
    sectionId: 'other-documents',
  },
];
