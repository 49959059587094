import { formatDate } from 'ams-common';
import {
  AuditorDetailsResult,
  ContactDetails,
  MessageDetail,
  Recipient,
  RecipientsData,
  UserProfile,
} from 'src/hooks/types';
import getContent from 'src/utils/contentUtils';

import {
  MessageReplyInitValues,
  MessagesDocument,
  MessagesInitValues,
} from './types';

export const getRecipientOptions = (
  recipientData: RecipientsData | null | undefined,
) => {
  if (!recipientData) {
    return null;
  }

  return recipientData.map((recipient) => ({
    text: recipient.contactName,
    value: recipient.id,
  }));
};

export const getMessageRecipients = (
  recipients: Recipient[],
  isCustomer: boolean,
  currentContactDetails?: ContactDetails,
  auditorDetails?: AuditorDetailsResult,
  auditorProfileDetails?: UserProfile,
): string => {
  const recipientsWithoutAuditor = recipients.filter(
    (re) => re.clientId && re.clientName,
  );

  const updatedRecipients = [
    {
      recipientName:
        auditorDetails?.fullName ?? auditorProfileDetails?.fullName,
      clientName: null,
      recipientId: auditorDetails?.userId ?? auditorProfileDetails?.userId,
      clientId: null,
    },
    ...recipientsWithoutAuditor,
  ];

  return updatedRecipients
    .sort((_, b) => (b.clientName ? -1 : 1))
    .filter(
      ({ recipientId }) =>
        !currentContactDetails ||
        recipientId !== `${currentContactDetails.contactId}`,
    )
    .map((recipient) => {
      // eslint-disable-next-line no-nested-ternary
      return recipient.clientName
        ? `${recipient.recipientName} from ${recipient.clientName}`
        : isCustomer
        ? `${recipient.recipientName}`
        : '';
    })
    .filter((recipient): recipient is string => Boolean(recipient))
    .join(', ');
};

export const getMessageDetailsRecipients = (
  recipients: Recipient[],
): string => {
  return recipients
    .sort((_, b) => (b.clientName ? -1 : 1)) // always get auditor name first
    .map((recipient) => {
      if (recipient.clientName) {
        return `${recipient.recipientName} from ${recipient.clientName}`;
      }
      return recipient.recipientName;
    })
    .filter(Boolean)
    .join(', ');
};

export const getCustomerRecipients = (recipients: Recipient[]) =>
  recipients
    .map((recipient) =>
      recipient.clientName
        ? `${recipient.recipientName} from ${recipient.clientName}`
        : `${recipient.recipientName}`,
    )
    .join(', ');

export const getMessageDetailList = (data: MessageDetail) => {
  const { sentDate, sentBy, recipients } = data;

  return [
    {
      label: getContent('matters.messages.sentOn.label'),
      description: formatDate(sentDate, 'DD MMM YYYY HH:mm'),
    },
    {
      label: getContent('matters.messages.sentBy.label'),
      description: sentBy,
    },
    {
      label: getContent('matters.messages.recipients.label'),
      description: recipients ? getMessageDetailsRecipients(recipients) : '',
    },
  ];
};

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();

const getClientIds = (clientIds: string): number[] =>
  clientIds.split(',').map((id) => Number(id.trim()));

const getRecipients = (recipients: string): string[] =>
  recipients.split(',').map((id) => id.trim());

const getDocuments = (documents?: MessagesDocument[]): string[] =>
  documents ? documents.map(({ documentId }) => documentId) : [];

export const getCreateMessageRequestBody = (values: MessagesInitValues) => {
  return {
    subject: values.messageSubject,
    clientIds: getClientIds(values.entities),
    recipientIds: getRecipients(values.recipient),
    message: {
      message: values.message,
      documentIds: getDocuments(values.documents),
    },
  };
};

export const getReplyMessageRequestBody = (values: MessageReplyInitValues) => {
  return {
    message: values.message,
    documentIds: getDocuments(values.documents),
  };
};

export const formatDocuments = (documents?: string[] | null) =>
  documents ? documents.map((document) => ({ id: document })) : null;
