import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  Method,
  MethodTypes,
  queryApi,
  requestApi,
} from 'src/api';
import { QueryKey } from 'src/api/constants';

import {
  CreateMessageRequestBody,
  MatterMessageUploadToTrimResponse,
  MessageDetailsDataCodec,
  MessagesCodec,
  ReplyToMessageRequestBody,
  SortDirection,
} from './types';

const fetch = (
  matterUId: string | null,
  isCustomer: boolean = false,
  pageSize?: number,
  pageIndex?: number,
  sortBy?: string | null,
  sortDirection?: SortDirection,
) => {
  if (!matterUId) {
    return null;
  }
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.messages,
      suffix: ['matters', `${matterUId}`, 'threads'],
      params: { pageSize, pageIndex, sortBy, sortDirection },
    },
    MessagesCodec,
  );
};

export const useMessagesData = (
  matterUId: string | null,
  isCustomer: boolean = false,
  pageSize?: number,
  pageIndex?: number,
  sortBy?: string | null,
  sortDirection?: SortDirection,
) =>
  useQuery(
    [QueryKey.MESSAGES, matterUId, sortDirection],
    () =>
      fetch(matterUId, isCustomer, pageSize, pageIndex, sortBy, sortDirection),
    {
      enabled: !!matterUId,
      refetchOnWindowFocus: true,
      staleTime: 0,
    },
  );

const fetchMessageDetails = (
  pathPortalMatterId: string | null,
  threadId: string | null,
  isCustomer: boolean = false,
) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.messages,
      suffix: [
        'matters',
        `${pathPortalMatterId}`,
        'threads',
        `${threadId}`,
        'messages',
      ],
      params: {
        pageNumber: 0,
        pageSize: 99999,
      },
    },
    MessageDetailsDataCodec,
  );
};

export const useMessagesDetails = (
  pathPortalMatterId: string | null,
  threadId: string | null,
  isCustomer: boolean = false,
) =>
  useQuery(
    [QueryKey.MESSAGE_DETAILS, pathPortalMatterId, threadId],
    () => fetchMessageDetails(pathPortalMatterId, threadId, isCustomer),
    {
      enabled: !!pathPortalMatterId && !!threadId,
      refetchOnWindowFocus: true,
    },
  );

const createMessage = (
  matterUId: string | null,
  payload: CreateMessageRequestBody,
  isCustomer: boolean,
) => {
  return requestApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.messages,
      suffix: ['matters', `${matterUId}`, 'threads'],
    },
    MethodTypes.POST,
    payload,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useCreateMessage = (
  matterUId: string | null,
  isCustomer: boolean,
  onSuccess: (statusCode: number) => void,
  onError: (errorMessage: string) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ([payload]: [CreateMessageRequestBody]) => {
      return createMessage(matterUId, payload, isCustomer);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([QueryKey.MATTER_DOCUMENTS, matterUId]);
        onSuccess((data as any).status);
      },
      onError: () => {
        onError('Internal server error. Please try again after sometime.');
      },
    },
  );
};

const replyToMessage = (
  matterUId: string | null,
  messageId: string | null,
  payload: ReplyToMessageRequestBody,
  isCustomer: boolean,
) => {
  return requestApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.messages,
      suffix: [
        'matters',
        `${matterUId}`,
        'threads',
        `${messageId}`,
        'messages',
      ],
    },
    MethodTypes.POST,
    payload,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useReplyToMessage = (
  matterUId: string | null,
  messageId: string,
  isCustomer: boolean,
  onSuccess: (statusCode: number) => void,
  onError: (errorMessage: string) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ([payload]: [ReplyToMessageRequestBody]) => {
      return replyToMessage(matterUId, messageId, payload, isCustomer);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([QueryKey.MATTER_DOCUMENTS, matterUId]);
        queryClient.invalidateQueries([
          QueryKey.MESSAGE_DETAILS,
          matterUId,
          messageId,
        ]);
        onSuccess((data as any).status);
      },
      onError: () => {
        onError('Internal server error. Please try again after sometime.');
      },
    },
  );
};

const uploadMessageToTrim = async ({
  portalMatterId,
  threadId,
  file,
  fileName,
  clientId,
  trimTitle,
  trimDescription,
}: {
  portalMatterId: string;
  threadId: string;
  file: File;
  fileName: string;
  clientId: number;
  trimTitle: string;
  trimDescription: string;
}): Promise<MatterMessageUploadToTrimResponse> => {
  const formData = new FormData();
  const jsonPayload = JSON.stringify({
    clientId,
    trimTitle,
    trimDescription,
    fileName,
  });

  formData.append(
    'fileUploadRequest',
    new Blob([jsonPayload], {
      type: 'application/json',
    }),
  );

  formData.append('multiPartfile', file, file.name);

  return requestApi<MatterMessageUploadToTrimResponse>(
    {
      resourcePath: APIResource.messages,
      suffix: [
        'matters',
        `${portalMatterId}`,
        'threads',
        `${threadId}`,
        'trim',
        'upload',
      ],
    },
    'POST' as Method,
    formData,
    undefined,
    'json',
  );
};

export const useMatterMessageUploadToTrim = (
  portalMatterId: string | null,
  threadId: string | null,
  navigateSuccess: (data: MatterMessageUploadToTrimResponse) => void,
  navigateError: () => void,
): UseMutationResult<
  MatterMessageUploadToTrimResponse,
  Error,
  {
    file: File;
    fileName: string;
    clientId: number;
    trimTitle: string;
    trimDescription: string;
  }
> => {
  const queryClient = useQueryClient();
  return useMutation<
    MatterMessageUploadToTrimResponse,
    Error,
    {
      file: File;
      fileName: string;
      clientId: number;
      trimTitle: string;
      trimDescription: string;
    }
  >(
    ({ file, fileName, clientId, trimTitle, trimDescription }) => {
      if (!portalMatterId) {
        throw new Error('Portal Matter ID is required');
      }
      if (!threadId) {
        throw new Error('Thread ID is required');
      }
      return uploadMessageToTrim({
        portalMatterId,
        threadId,
        file,
        fileName,
        clientId,
        trimTitle,
        trimDescription,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          QueryKey.MESSAGE_DETAILS,
          portalMatterId,
          threadId,
        ]);
        navigateSuccess(data);
      },
      onError: (error) => {
        console.error('Error uploading file:', error);
        navigateError();
      },
    },
  );
};
