import { Route } from 'react-router-dom';
import {
  ContentContainer,
  ErrorSystemPage,
  SectionPage,
} from '@snsw/react-component-library';
import { RootLayout } from 'ams-common';
import { AuthButtons } from 'src/components/users/AuthButtons';
import { Dashboard } from 'src/screens/auditor/dashboard';
import { Notifications } from 'src/screens/auditor/notifications';
import { LogoutSuccess } from 'src/screens/common/logoutSuccess';
import { MainAutoLogout } from 'src/screens/common/mainAutoLogout';
import { StatusManager } from 'src/screens/common/matters/tabs/auditor/statusManager';
import { SubmitAuditCommencementDocuments } from 'src/screens/common/matters/tabs/common/matterCorrespondence/auditor/submitAuditCommencementDocuments';
import { Correspondence } from 'src/screens/common/matters/tabs/common/matterCorrespondence/correspondence';
import { CorrespondenceDetails } from 'src/screens/common/matters/tabs/common/matterCorrespondence/correspondenceDetails';
import { SubmitResponseToAuditCommencementDocuments } from 'src/screens/common/matters/tabs/common/matterCorrespondence/customer/submitResponseToAuditCommencementDocuments';
import { MatterMessages } from 'src/screens/common/matters/tabs/common/matterMessages';
import { MessageDetails } from 'src/screens/common/matters/tabs/common/matterMessages/MessageDetails';
import { MessageReply } from 'src/screens/common/matters/tabs/common/matterMessages/MessageReply';
import { NewMessage } from 'src/screens/common/matters/tabs/common/matterMessages/NewMessage';
import { MatterNotifications } from 'src/screens/common/matters/tabs/common/matterNotifications';
import { MyProfile } from 'src/screens/common/myProfile';
import { CustomerContactDetails } from 'src/screens/common/myProfile/CustomerContactDetails';

import { PATHS } from './constants';
import ProtectedRoute from './ProtectedRoute';
import { lazyload } from './utils';

const MatterContacts = lazyload(
  import('src/screens/common/matters/tabs/common/matterContacts'),
  'MatterContacts',
);

const MatterCorrespondence = lazyload(
  import('src/screens/common/matters/tabs/common/matterCorrespondence'),
  'MatterCorrespondence',
);

const Matters = lazyload(import('src/screens/common/matters'), 'Matters');

const MatterSummary = lazyload(
  import('src/screens/common/matters/tabs/common/matterSummary'),
  'MatterSummary',
);

const MatterDocuments = lazyload(
  import('src/screens/common/matters/tabs/common/matterDocuments'),
  'MatterDocuments',
);
const MatterDetails = lazyload(
  import('src/screens/common/matters/matterDetails'),
  'MatterDetails',
);

export const globalNavigation = (
  isStaffLogin: boolean,
  isCustomerLogin: boolean,
) => (
  <>
    <Route path={PATHS.logoutSuccess} element={<LogoutSuccess />} />
    <Route
      path="/"
      element={
        <RootLayout
          headerAuthButton={
            isStaffLogin || isCustomerLogin ? (
              <AuthButtons
                isCustomerLogin={isCustomerLogin}
                isStaffLogin={isStaffLogin}
              />
            ) : undefined
          }
          isCustomer={!isStaffLogin}
        >
          <MainAutoLogout
            isCustomerLogin={isCustomerLogin}
            isStaffLogin={isStaffLogin}
          />
        </RootLayout>
      }
    >
      <Route
        element={
          <ProtectedRoute
            isRouteAccessible={isStaffLogin}
            redirectRoute={PATHS.login}
          />
        }
      >
        <Route path="/" element={<Dashboard />} />
        <Route path={PATHS.dashboard} element={<Dashboard />} />
        <Route path={PATHS.notifications} element={<Notifications />} />
        <Route path={PATHS.matters} element={<Matters />} />
      </Route>
      <Route path={PATHS.myProfile} element={<MyProfile />} />
      <Route
        path={PATHS.myProfileCustomerDetails}
        element={<CustomerContactDetails />}
      />
      <Route path={`${PATHS.matters}/:matterId`} element={<MatterDetails />}>
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.matterOverview}`}
          element={<MatterSummary />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.matterContacts}`}
          element={<MatterContacts />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}`}
          element={<MatterCorrespondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}${PATHS.submitAuditCommencementDocuments}`}
          element={<SubmitAuditCommencementDocuments />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}${PATHS.requestForInformation}`}
          element={<Correspondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}${PATHS.preliminaryFindings}`}
          element={<Correspondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}${PATHS.finalisationAdvice}`}
          element={<Correspondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}${PATHS.submitResponseToAuditCommencementDocuments}`}
          element={<SubmitResponseToAuditCommencementDocuments />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}${PATHS.respondToAuditCommencementCorrespondence}`}
          element={<Correspondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}/:threadId`}
          element={<CorrespondenceDetails />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}/:threadId${PATHS.correspondence}/:correspondenceId${PATHS.submitAuditCommencementDocuments}`}
          element={<SubmitAuditCommencementDocuments />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}/:threadId${PATHS.correspondence}/:correspondenceId${PATHS.requestForInformation}`}
          element={<Correspondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}/:threadId${PATHS.correspondence}/:correspondenceId${PATHS.preliminaryFindings}`}
          element={<Correspondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}/:threadId${PATHS.correspondence}/:correspondenceId${PATHS.finalisationAdvice}`}
          element={<Correspondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}/:threadId${PATHS.correspondence}/:correspondenceId${PATHS.respondToAuditCommencementCorrespondence}`}
          element={<Correspondence />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.threads}/:threadId${PATHS.correspondence}/:correspondenceId${PATHS.submitResponseToAuditCommencementDocuments}`}
          element={<SubmitResponseToAuditCommencementDocuments />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.documents}`}
          element={<MatterDocuments />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.messages}`}
          element={<MatterMessages />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.messages}${PATHS.newMessage}`}
          element={<NewMessage />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.messages}/:messageId${PATHS.messageReply}`}
          element={<MessageReply />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.messages}/:messageId${PATHS.messageDetails}`}
          element={<MessageDetails />}
        />
        <Route
          path={`${PATHS.matters}/:matterId${PATHS.matterNotifications}`}
          element={<MatterNotifications />}
        />
        <Route
          element={
            <ProtectedRoute
              isRouteAccessible={isStaffLogin}
              redirectRoute={PATHS.login}
            />
          }
        >
          <Route
            path={`${PATHS.matters}/:matterId${PATHS.statusManager}`}
            element={<StatusManager />}
          />
        </Route>
      </Route>
    </Route>
    <Route
      path="/*"
      element={
        <ContentContainer>
          <SectionPage>
            <ErrorSystemPage heading="Sorry, this page is under construction" />
          </SectionPage>
        </ContentContainer>
      }
    />
  </>
);
