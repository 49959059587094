export enum UserProfileFieldsName {
  fullName = 'fullName',
  position = 'position',
  email = 'email',
  phoneNumber = 'phoneNumber',
  mobile = 'mobile',
}

export const UserProfileFieldsLabels = [
  {
    key: `${UserProfileFieldsName.fullName}`,
    label: 'Name',
  },
  {
    key: `${UserProfileFieldsName.position}`,
    label: 'Position',
  },
  {
    key: `${UserProfileFieldsName.phoneNumber}`,
    label: 'Phone',
  },
  {
    key: `${UserProfileFieldsName.email}`,
    label: 'Email',
  },
];

export enum CustomerProfileFieldsName {
  name = 'name',
  type = 'type',
  email = 'email',
  phone = 'phone',
  mobile = 'mobile',
  complianceType = 'complianceType',
  clientName = 'clientName',
  clientId = 'clientId',
  preferType = 'preferType',
  homePhone = 'homePhone',
}

export const CustomerProfileFieldsLabels = [
  {
    key: `${CustomerProfileFieldsName.name}`,
    label: 'Name',
  },
  {
    key: `${CustomerProfileFieldsName.type}`,
    label: 'Contact type',
  },
  {
    key: `${CustomerProfileFieldsName.phone}`,
    label: 'Phone',
  },
  {
    key: `${CustomerProfileFieldsName.mobile}`,
    label: 'Mobile',
  },
  {
    key: `${CustomerProfileFieldsName.email}`,
    label: 'Email',
  },
];

export enum ClientFieldsName {
  name = 'name',

  id = 'id',
}

export const ClientFieldsLabels = [
  {
    key: `${ClientFieldsName.id}`,
    label: 'Client ID',
  },
  {
    key: `${ClientFieldsName.name}`,
    label: 'Entity Name',
  },
];

export const contactTypeList = [
  {
    text: 'Accountant ',
    value: 'Accountant ',
  },
  { text: 'Administrator ', value: 'Administrator ' },
  {
    text: 'Agent ',
    value: 'Agent ',
  },
  {
    text: 'Bookkeeper ',
    value: 'Bookkeeper ',
  },
  {
    text: 'Club Secretary   ',
    value: 'Club Secretary   ',
  },
  {
    text: 'Company secretary   ',
    value: 'Company secretary  ',
  },
  {
    text: 'Director',
    value: 'Director ',
  },
  {
    text: 'Electronic Commerce ',
    value: 'Electronic Commerce ',
  },
  {
    text: 'Financial controller  ',
    value: 'Financial controller  ',
  },
  {
    text: 'General enquiries ',
    value: 'General enquiries   ',
  },
  {
    text: 'Hotel licensee  ',
    value: 'Hotel licensee   ',
  },
  {
    text: 'Manager   ',
    value: 'Manager ',
  },

  {
    text: 'Paymaster  ',
    value: 'Paymaster  ',
  },
  {
    text: 'Solicitor    ',
    value: 'Solicitor   ',
  },

  {
    text: 'Other   ',
    value: 'Other  ',
  },
];
