import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

import { CustomerToAuditorMessageReceivedData } from './types';

type CustomerToAuditorMessageReceivedProps = {
  data: CustomerToAuditorMessageReceivedData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  alertId: string;
};

export const CustomerToAuditorMessageReceived = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
}: CustomerToAuditorMessageReceivedProps) => {
  const { contactName, matterId, threadId } = data;

  const title = getContent('matterAlert.alert.message.received.title');

  const body = getContentWithReplacements(
    'matterAlert.alert.auditor.message.received.body',
    {
      contactName,
      matterId,
    },
  );

  return (
    <MatterAlert
      title={title}
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`${PATHS.matters}/${matterId}${PATHS.messages}/${threadId}${PATHS.messageDetails}`}
      linkText="View message"
      onClick={() => {
        if (!read) updateStatus(alertId);
      }}
    />
  );
};
