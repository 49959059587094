import { useState } from 'react';

import { ActionItemDueDateModal } from './ActionItemDueDateModal';
import { NoStyleButton, StyledContainer, StyledEditIcon } from './styles';

export const ActionItemsDueDateButton = ({
  dueDate,
  actionItemId,
  matterUId,
  handleDueDateChange,
  corroType,
}: {
  dueDate: string;
  actionItemId: string;
  matterUId: string;
  handleDueDateChange: (
    matterUId: string,
    actionItemId: string,
    values: { internalDueDate: string },
    onError: () => void,
    onSuccess: () => void,
  ) => Promise<void>;
  corroType: string;
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <StyledContainer>
      <StyledEditIcon color="secondaryBlue" />
      <NoStyleButton
        type="button"
        onClick={() => {
          setShowEditModal(true);
        }}
      >
        {dueDate ? 'Edit' : 'Add'}
      </NoStyleButton>
      {showEditModal && (
        <ActionItemDueDateModal
          date={dueDate || ''}
          actionItemId={actionItemId}
          matterUId={matterUId}
          onClose={() => setShowEditModal(false)}
          handleDueDateChange={handleDueDateChange}
          corroType={corroType}
        />
      )}
    </StyledContainer>
  );
};
