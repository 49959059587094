import { useQuery } from 'react-query';
import { APIResource, queryApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import { UserProfileResultCodec } from './types';

const fetch = () => {
  return queryApi(
    {
      resourcePath: APIResource.userProfile,
      suffix: ['profiles'],
    },
    UserProfileResultCodec,
  );
};
export const useUserProfile = (isCustomer: boolean = false) =>
  useQuery([QueryKey.USER_PROFILE], () => fetch(), { enabled: !isCustomer });
