import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

import { StyledContainer, StyledLink } from '../styles';

export const PrivacyCollectionModal = ({
  openModal,
  onClose,
}: {
  openModal: boolean;
  onClose: () => void;
}) => {
  return openModal ? (
    <Modal
      title={getContent(
        'onboarding.termsOfUse.heading.second.paragraph.heading',
      )}
      onClose={onClose}
      buttons={[
        {
          text: 'OK',
          onClick: () => {
            onClose();
          },
        },
      ]}
    >
      <StyledContainer>
        {' '}
        <p>
          <strong>
            PRIVACY COLLECTION NOTICE - <i>for Compliance Portal</i>
          </strong>
        </p>
      </StyledContainer>
      <StyledContainer>
        <p>
          Revenue NSW collects your personal information so that you can manage
          your Payroll Tax online.
        </p>
      </StyledContainer>
      <StyledContainer>
        <p>
          Revenue NSW gives priority to protecting the privacy of your personal
          information. We do this by handling personal information in a
          responsible manner and in accordance with the NSW Privacy Laws -
          <StyledLink href="http://www.legislation.nsw.gov.au/maintop/view/inforce/act+133+1998+cd+0+N">
            Privacy and Personal Information Protection Act 1998
          </StyledLink>
          and
          <StyledLink href="http://www.legislation.nsw.gov.au/maintop/view/inforce/act+71+2002+cd+0+N">
            Health Records and Information Privacy Act 2002
          </StyledLink>
          .
        </p>
      </StyledContainer>
      <p>
        <strong>Why are we collecting your personal information?</strong>
      </p>
      <StyledContainer>
        <p>
          Revenue NSW is collecting your personal information for the following
          purposes:
        </p>
        <ul>
          <li>
            administering payroll tax in accordance with the Payroll Tax Act
            2007 and Taxation Administration Act 1996;
          </li>
          <li>providing you with access to notifications about payroll tax;</li>
          <li>
            contacting you to request, verify, or confirm the information you
            provide in support of your payroll tax;
          </li>
          <li>
            responding to complaints and enquiries in relation to payroll tax,
            including disclosure to relevant authorities such as the NSW
            Ombudsman, the NSW Civil and Administrative Tribunal, etc;
          </li>
          <li>
            conducting audit and compliance activities to confirm that
            legislation has been complied with;
          </li>
          <li>and other directly related purposes.</li>
        </ul>
        <p>
          <strong> What personal information do we collect? </strong>
        </p>
      </StyledContainer>
      <p>
        Revenue NSW may collect the following information for the purposes of
        providing you payroll tax services:
      </p>
      <li>name;</li>
      <li>contact details (address, email, phone number), date of birth;</li>
      <li>bank details;</li>
      <li> Trust information, where applicable</li>
      <li>
        organisation information, including ABN, CAN, ARBN organisation
        representative details;
      </li>
      <li>ASIC register details such as shareholders, directors, etc; and</li>
      <li>
        authorised representative details, including full name, email, and
        phone.
      </li>
      <p>
        We may ask you to provide additional supporting documentation, including
        further information about your business and business activities. The
        information you provide may contain personal information. If you provide
        the personal information of another person, you must gain consent from
        that person to disclose their information to Revenue NSW for the
        purposes outlined in this notice.
      </p>
      <StyledContainer>
        <p>
          <strong>How will we use and disclose this information? </strong>
        </p>
      </StyledContainer>
      <p>Revenue NSW may use your personal information to:</p>
      <ul>
        <li>verify the information you provide to Revenue NSW;</li>
        <li>
          and collect, maintain, and use information about you for payroll tax
          administration purposes.
        </li>
      </ul>
      <p>Revenue NSW may disclose the information to:</p>
      <ul>
        <li>
          public/private entities (including the Document Verification Service)
          in order to verify the information, you provide or request additional
          information as permitted by law; NSW Police, if Revenue NSW reasonably
          suspects a case of fraud or other criminal activity or as permitted by
          law;
        </li>
        <li>
          Australian Taxation Office; other third-party service providers
          engaged by Revenue NSW to support the administration of payroll tax;
          authorised representatives, and anyone with implied authority for
          payroll tax administration purposes; and administer other taxation
          laws and Revenue NSW legislation such as Land tax, Unclaimed Money Act
          1995, Fines Act 1996 etc.
        </li>
      </ul>
      <p>Revenue NSW may disclose de-identified information:</p>
      <ul>
        <li>
          to NSW Treasury for the purposes of data analysis and aggregation to
          audit, analyse and report on payroll tax. Any report or publications
          on payroll tax will not identify any individual or entity; and
          <li>
            for research and statistical purposes in accordance with the Data
            Sharing (Government Sector) Act 2015.
          </li>
        </li>
      </ul>
      <StyledContainer>
        <p>
          Revenue NSW will not disclose the personal information you provide to
          third parties for any purposes not already stated in this privacy
          collection notice, or to which you have not otherwise consented,
          unless Revenue NSW is required, or authorised, by law to do so. We
          will retain your information in accordance with the law.
        </p>
      </StyledContainer>
      <p>
        <strong>
          Are you required by law to provide us with this information?
        </strong>
      </p>
      <StyledContainer>
        <p>
          Revenue NSW will only collect personal information that is necessary
          to deal with your payroll tax matter. Providing us with the requested
          information is required under the Payroll Tax Act 2007 and Taxation
          Administration Act 1996.
        </p>
      </StyledContainer>
      <p>
        <strong> We will keep your information secure </strong>
      </p>
      <StyledContainer>
        <p>
          Revenue NSW will take reasonable security measures to protect your
          personal information from loss, unauthorised access, use,
          modification, disclosure, or other misuse. Physical measures, such as
          building and equipment security, are used in conjunction with digital
          technology, such as data encryption and firewalls, to minimise
          unauthorised access to information.
        </p>
      </StyledContainer>
      <StyledContainer>
        <p>
          <strong>Further information </strong>
        </p>
      </StyledContainer>
      <p>
        For further details about how Revenue NSW collects and manages personal
        information, how privacy enquiries or complaints can be made, or how you
        can access and seek correction of the information please visit
        <StyledLink href="http://www.revenue.nsw.gov.au/privacy">
          www.revenue.nsw.gov.au/privacy.
        </StyledLink>
      </p>
    </Modal>
  ) : null;
};
