import { pxToRem } from 'ams-common';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: ${pxToRem(20)};
  margin-bottom: ${pxToRem(20)};
`;
export const StyledLink = styled.a`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
