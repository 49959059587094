import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  MethodTypes,
  queryApi,
  requestApi,
} from 'src/api';
import { QueryKey } from 'src/api/constants';

import { CustomerLoginFormData, CustomerLoginFormDataCodec } from './types';

const fetchCustomerLoginDetails = () => {
  return queryApi(
    {
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      resourcePath: APIResource.customerLogin,
    },
    CustomerLoginFormDataCodec,
  );
};

export const useCustomerLoginDetails = () =>
  useQuery([QueryKey.CUSTOMER_LOGIN], () => fetchCustomerLoginDetails());

const fetchOtp = () => {
  return queryApi({
    resourcePath: APIResource.otp,
  });
};

export const useOtp = () =>
  useQuery([QueryKey.OTP], () => fetchOtp(), { enabled: false });

const validateCustomerDetails = (payload: CustomerLoginFormData) => {
  return requestApi(
    {
      resourcePath: APIResource.customerLogin,
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
    },
    MethodTypes.POST,
    payload,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useValidateCustomerDetails = (
  navigateSuccess: (statusCode: number) => void,
  navigateError: (data: {
    status: number;
    code: string;
    messages?: string[];
  }) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: CustomerLoginFormData) => validateCustomerDetails(payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([QueryKey.MATTERS_CONTACTS]);
        navigateSuccess((data as any).status);
      },
      onError: (data) => {
        queryClient.invalidateQueries([QueryKey.MATTERS_CONTACTS]);
        navigateError(data as any);
      },
    },
  );
};
