const content = {
  'matter.upload.correspondence.to.trim.heading':
    'Upload correspondence to TRIM',
  'matter.upload.message.to.trim.heading': 'Upload message to TRIM',
  'matter.upload.to.trim.description':
    'Please add document details before uploading to TRIM',
  'matter.upload.to.trim.mandatory.fields.description':
    'All fields must be completed, unless marked optional.',
  'matter.upload.to.trim.entityName.errorMessage':
    'Please select a entity name',
  'matter.upload.to.trim.description.helpMessage':
    'Maximum 60 characters including spaces. {remChar} character(s) remaining',
  'matter.upload.to.trim.description.errorMessage':
    'Please enter a description',
  'matter.upload.successfully.to.trim.heading': 'Uploaded successfully',
  'matter.upload.correspondence.successfully.to.trim.description':
    "Correspondence with the TRIM title '{trimTitle}' and TRIM record number '{recordNumber}' has been uploaded successfully",
  'matter.upload.message.successfully.to.trim.description':
    "The message thread with the TRIM title '{trimTitle}' and TRIM record number '{recordNumber}' has been uploaded successfully",
};

export default content;
