import { useMutation, useQueryClient } from 'react-query';
import { APIResource, MethodTypes, requestApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

const closeCorrespondence = (
  matterUId: string | null,
  threadId: string | null,
) => {
  return requestApi(
    {
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', `${matterUId}`, 'threads', `${threadId}`, 'close'],
    },
    MethodTypes.PUT,
    undefined,
    undefined,
    // passing headers
  );
};

export const useCloseCorrespondence = (
  matterUId: string | null,
  navigateError?: () => void,
  navigateSuccess?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (threadId: string | null) => {
      return closeCorrespondence(matterUId, threadId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryKey.MATTERS_CORRESPONDENCES,
          matterUId,
        ]);
        navigateSuccess?.();
      },
      onError: () => {
        navigateError?.();
      },
    },
  );
};
