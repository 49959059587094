import { useQuery } from 'react-query';
import { APIResource, queryApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import { SystemDateTimeCodec } from './types';

const fetch = () => {
  return queryApi(
    {
      resourcePath: APIResource.amsConfig,
      suffix: ['config', 'system-date-time'],
    },
    SystemDateTimeCodec,
  );
};

export const useSystemDateTime = (isCustomer: boolean) =>
  useQuery([QueryKey.SYSTEM_DATE_TIME], () => fetch(), {
    enabled: !isCustomer,
  });
