const content = {
  'matter.multipleDocumentModal.id': 'upload-documents',
  'matter.multipleDocumentModal.title': 'Upload documents',
  'matter.multipleDocumentModal.description':
    'Please add document details before uploading to matter.',
  'matter.multipleDocumentModal.button.primary': 'Done',
  'matter.multipleDocumentModal.button.remove': 'Remove',
  'matter.multipleDocumentModal.button.addDetails': 'Add details',
  'matter.multipleDocumentModal.document.status.text': 'Uploaded',
};

export default content;
