import { Matter } from 'src/screens/common/matters/types';

import { MatterNotifications } from './types';

const getMatterIDMatchingPortalMatterId = (
  mattersData?: Matter[],
  portalMatterId?: string,
) => {
  if (!mattersData || !portalMatterId) {
    return null;
  }

  return mattersData.find((matter) => matter.portalMatterId === portalMatterId)
    ?.matterId;
};

export const updateNotificationContentWithMatterId = (
  notifications: MatterNotifications,
  notificationTypes: string[],
  matterId?: string | null,
  mattersData?: Matter[],
) => {
  const updatedNotificationContent = notifications.content.map((content) => {
    if (notificationTypes.includes(content.alertType)) {
      const updatedMessageData = {
        ...content.messageData,
        matterId:
          matterId ??
          getMatterIDMatchingPortalMatterId(
            mattersData,
            content.portalMatterId,
          ) ??
          '',
      };

      return {
        ...content,
        messageData: updatedMessageData,
      };
    }
    return content;
  });

  return updatedNotificationContent;
};
