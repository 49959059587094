import { ClientOptions, Clients } from 'src/components/clients/types';

export const getClientNames = (
  clients: Clients | undefined,
  selectedClientIds: string,
) => {
  if (!clients) {
    return [];
  }

  const selectedClientIdsArray = selectedClientIds.split(',');

  return clients
    .filter(({ id }) => selectedClientIdsArray.includes(String(id)))
    .map(({ name }) => `${name}`);
};

export const getClientOptions = (customers: ClientOptions) => {
  if (!customers) {
    return [];
  }

  return [
    ...customers.map(({ text, value }) => ({
      text: `${value} ${text}`,
      value,
    })),
    { text: 'Applicable to all customers', value: 'all' },
  ];
};
