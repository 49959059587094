import { useState } from 'react';
import { Heading } from '@snsw/react-component-library';
import CustomerAuthForm from 'src/screens/customer/customerAuthForm';
import CustomerLoginForm from 'src/screens/customer/customerLoginForm';
import { RegistrationState } from 'src/screens/customer/types';
import getContent from 'src/utils/contentUtils';

import { StyledDescription, StyledSectionPage } from './styles';

type CustomerLoginProps = {
  setIsCustomerLoggedIn: React.Dispatch<
    React.SetStateAction<{
      isLoggedInForTheFirstTime: boolean;
      isLoggedIn: boolean;
    }>
  >;
};

const CustomerLogin = ({ setIsCustomerLoggedIn }: CustomerLoginProps) => {
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
  const [registration, setRegistration] = useState<RegistrationState | null>(
    null,
  );
  return (
    <StyledSectionPage>
      <Heading level={3}>
        {getContent('customerLogin.login.heading.main')}
      </Heading>
      <StyledDescription>
        {getContent('customerLogin.login.description')}
      </StyledDescription>
      {!isRegistrationSuccess ? (
        <CustomerAuthForm
          onRegistrationSuccess={setIsRegistrationSuccess}
          setRegistration={setRegistration}
        />
      ) : (
        <CustomerLoginForm
          setIsCustomerLoggedIn={setIsCustomerLoggedIn}
          registration={registration}
        />
      )}
    </StyledSectionPage>
  );
};

export default CustomerLogin;
