import { useEffect, useState } from 'react';
import { ClientOptions } from 'src/components/clients/types';
import {
  DocumentStatus,
  HandleEditDocument,
  OperationType,
} from 'src/types/documentTypes';

import { MatterDocuments } from '../documentTableWithAccordion/types';
import MultipleDocumentModal from '../multipleDocumentModal';
import SingleDocumentModal from '../singleDocumentModal';

type DocumentModalProps = {
  matterUId: string | null;
  matterId: string | null;
  close: () => void;
  handleFileUploadValue?: ({
    fileName,
    docId,
    fileSize,
  }: {
    fileName: string;
    docId: string | null;
    fileSize: number;
  }) => void;
  documents?: File[] | null;
  clientOptions: ClientOptions;
  documentsData?: MatterDocuments;
  operationType?: OperationType;
  editDocumentDetails: HandleEditDocument | null;
  selectedCustomer?: string;
};

const DocumentModal = ({
  matterUId,
  matterId,
  close,
  handleFileUploadValue,
  documents,
  clientOptions,
  documentsData,
  operationType = OperationType.Add,
  editDocumentDetails,
  selectedCustomer,
}: DocumentModalProps) => {
  const [selectedDocuments, setSelectedDocuments] = useState(documents);
  const [document, setDocument] = useState<File | null>(null);
  const [openSingleDocumentModal, setOpenSingleDocumentModal] = useState(false);
  const [documentsStatus, setDocumentsStatus] = useState<
    DocumentStatus[] | null
  >(null);

  useEffect(() => {
    if (documents && documents.length > 0) {
      setDocument(documents[0]);
      setDocumentsStatus(
        documents.map((document) => ({
          name: document.name,
          status: '',
        })),
      );
    }
  }, [documents]);

  const handleAddDetails = (document: File) => {
    setDocument(document);
    setOpenSingleDocumentModal(true);
  };

  const handleCloseSingleDocumentModal = (documentName?: string) => {
    setOpenSingleDocumentModal(false);
    const updatedDocumentStatus = documentsStatus?.map((doc) =>
      doc.name === documentName ? { ...doc, status: 'updated' } : doc,
    );

    if (updatedDocumentStatus) {
      setDocumentsStatus(updatedDocumentStatus);
    }
  };

  const handleRemove = (document: File) => {
    setSelectedDocuments(
      (prevSelectedDocuments) =>
        prevSelectedDocuments?.filter(
          (prevDoc) => prevDoc.name !== document.name,
        ),
    );
  };

  if (
    operationType !== OperationType.Edit &&
    (!documents ||
      (documents && documents.length === 0) ||
      (selectedDocuments && selectedDocuments.length === 0))
  ) {
    close();
    return null;
  }

  return documents?.length === 1 ||
    openSingleDocumentModal ||
    operationType === OperationType.Edit ? (
    <SingleDocumentModal
      matterUId={matterUId}
      close={openSingleDocumentModal ? handleCloseSingleDocumentModal : close}
      handleFileUploadValue={handleFileUploadValue}
      file={document || null}
      clientOptions={clientOptions}
      matterId={matterId}
      documentsData={documentsData}
      operationType={operationType}
      editDocumentDetails={editDocumentDetails}
      selectedCustomer={selectedCustomer}
    />
  ) : (
    <MultipleDocumentModal
      documents={selectedDocuments}
      onAddDetails={handleAddDetails}
      onRemove={handleRemove}
      close={close}
      documentsStatus={documentsStatus}
    />
  );
};

export default DocumentModal;
