import { FormGroup } from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import styled from 'styled-components';

export const StyledFormGroup = styled(FormGroup)`
  flex: 10;
`;

export const StyledMandatoryFieldsDescription = styled.span`
  margin-top: ${spacing.xs};
  display: flex;
  color: ${colours.text.disabled};
`;

export const StyledTrimUploadSuccessfulDescription = styled.span`
  margin-top: ${spacing.xs};
`;
