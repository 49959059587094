import actionItemCardContent from 'src/components/actionItems/contents';
import alertContents from 'src/components/alerts/contents';
import archiveToTrimContent from 'src/components/archiveToTrim/content';
import correspondenceContent from 'src/components/correspondence/content';
import deleteDocumentContent from 'src/components/deleteDocument/contents';
import editDueDateButton from 'src/components/editDueDateButton/contents';
import filesUploadErrorContent from 'src/components/fileUploadErrors/contents';
import multipleDocumentModalContent from 'src/components/multipleDocumentModal/content';
import SingleDocumentModalContents from 'src/components/singleDocumentModal/contents';
import signInPageContent from 'src/components/users/AMSSignIn/content';
import customerLoginContent from 'src/components/users/CustomerLogin/contents';

const combinedComponentsContent = {
  // components
  ...alertContents,
  ...signInPageContent,
  ...SingleDocumentModalContents,
  ...multipleDocumentModalContent,
  ...deleteDocumentContent,
  ...customerLoginContent,
  ...actionItemCardContent,
  ...editDueDateButton,
  ...correspondenceContent,
  ...archiveToTrimContent,
  ...filesUploadErrorContent,
};

export default combinedComponentsContent;
