const content = {
  'correspondence.dueDate.modal.title': 'Edit due date',
  'correspondence.dueDate.modal.text':
    'Update the correspondence response due date',
  'correspondence.dueDate.modal.form.field1.title': 'Due date',
  'correspondence.dueDate.modal.form.field2.title': 'Reason',
  'correspondence.auditCommencementDocuments.dueDate.modal.dueDate.tooltip.text':
    'This is the NOI due date from the AMS system',
  'correspondence.others.dueDate.modal.dueDate.tooltip.text':
    'This is the due date by when customer is expected to respond',
  'correspondence.dueDate.modal.secondary.button': 'Update',
  'correspondence.dueDate.modal.primary.button': 'Cancel',
  'correspondence.dueDate.confirmModal.secondary.button': 'Confirm and submit',
  'correspondence.dueDate.confirmModal.text':
    'Please confirm you want to change the due date from {oldDate} to {newDate}',
  'correspondence.dueDate.reason.helpMessage.text':
    'Maximum 500 characters including spaces. {remChar} character(s) remaining',
};

export default content;
