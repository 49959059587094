import { FormDate, Heading } from '@snsw/react-component-library';
import { formatDate, Tooltip } from 'ams-common';
import { EditDueDateButton } from 'src/components/editDueDateButton';
import getContent from 'src/utils/contentUtils';

import { HiddenInput } from '../../../../../../../components/correspondence/hiddenInput';

import { FormDateGroup, StyledDueDate } from './styles';

interface DueDateProps {
  hasError: boolean;
  errorMessage?: string;
  value: string;
  handleChange: ({ value, field }: { value: string; field: string }) => void;
  isCustomer: boolean;
  isAtLeastOneResponseSubmitted: boolean;
  matterUId: string | null;
  threadId: string | null;
  corroType: string;
}

export const DueDate = ({
  isCustomer,
  value,
  hasError,
  errorMessage,
  handleChange,
  isAtLeastOneResponseSubmitted,
  matterUId,
  threadId,
  corroType,
}: DueDateProps) => {
  if (isCustomer) {
    return null;
  }

  return (
    <>
      {!isAtLeastOneResponseSubmitted ? (
        <FormDateGroup
          id="dueDate"
          hasError={hasError}
          errorMessage={errorMessage}
          label={
            <Tooltip
              label="Due date"
              text={getContent('matters.correspondence.dueDate.tooltip.text')}
            />
          }
        >
          <FormDate
            value={value}
            onChange={({ value }: { value: string }) =>
              handleChange({ value, field: 'dueDate' })
            }
          />
        </FormDateGroup>
      ) : (
        <>
          <Heading level={6}>Due date</Heading>
          <StyledDueDate>
            {formatDate(value)}
            <EditDueDateButton
              matterUId={matterUId || ''}
              dueDate={value || ''}
              threadId={threadId || ''}
              corroType={corroType}
            />
          </StyledDueDate>
        </>
      )}
      <HiddenInput name="dueDate" />
    </>
  );
};
