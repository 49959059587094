import * as Yup from 'yup';

export const auditorValidationSchema = () =>
  Yup.object().shape({
    entities: Yup.string().required('Entity selection is required'),
    recipient: Yup.string().required('Recipient selection is required'),
    messageSubject: Yup.string().required('Message subject is required'),
    message: Yup.string().required('Message is required'),
  });

export const customerValidationSchema = () => {
  return Yup.object().shape({
    messageSubject: Yup.string().required('Message subject is required'),
    message: Yup.string().required('Message is required'),
  });
};

export const messageReplyValidationSchema = () =>
  Yup.object().shape({
    message: Yup.string().required('Message is required'),
  });
