import { useEffect, useState } from 'react';
import {
  Col,
  ComponentLoader,
  ContentContainer,
  FormGroup,
  InPageAlert,
  Row,
  Select,
  Textarea,
} from '@snsw/react-component-library';
import { ErrorSummary, LabelDescriptionList } from 'ams-common';
import { useFormik } from 'formik';
import { MethodTypes } from 'src/api';
import { QueryKey } from 'src/api/constants';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';
import * as Yup from 'yup';

import { ConfirmStatusChangeModal } from './confirmStatusChangeModal';
import { MainStatus } from './constants';
import { getStatusLabel, getStatusOptions } from './helpers';
import { HistoryLog } from './historyLog';
import { useHistoryLog, useUpdateStatus } from './hooks';
import {
  StyledButton,
  StyledHeading,
  StyledMandatoryFieldsMessage,
} from './styles';

export const StatusManager = () => {
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const {
    matterStatus: currentMatterStatus,
    matterUId,
    isMatterClosed,
  } = useMatter();

  const mainStatus = currentMatterStatus ? MainStatus[currentMatterStatus] : '';

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [messageCharCount, setMessageCharCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { data: historyLog, isLoading, isError } = useHistoryLog(matterUId);

  const validationSchema = Yup.object({
    matterStatus: Yup.string().required('Status is required'),
    comment: Yup.string().required('Comment is required'),
  });
  const navigateError = () => {
    setErrorKeys((prevErrorKeys) => [
      ...new Set([
        ...prevErrorKeys,
        `${ScreenNames.STATUS_MANAGER}-${QueryKey.HISTORY_LOGS}-POST` as ErrorKey,
      ]),
    ]);
  };
  const navigateSuccess = () => {
    setShowSuccessMessage(true);
  };

  const { mutate: updateStatus } = useUpdateStatus(
    matterUId,
    navigateError,
    navigateSuccess,
  );

  const { values, resetForm, handleChange, handleSubmit, handleBlur, errors } =
    useFormik({
      initialValues: { matterStatus: '', comment: '' },
      validationSchema,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: () => {
        setShowModal(true);
      },
    });

  const handleStatusChangeSubmit = () => {
    setShowSuccessMessage(false);
    updateStatus([values, MethodTypes.PUT]);
    resetForm();
    setShowModal(false);
    setMessageCharCount(0);
  };

  useEffect(() => {
    if (isError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.STATUS_MANAGER}-${QueryKey.HISTORY_LOGS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isError]);

  return (
    <ContentContainer>
      <ComponentLoader active={isLoading || !currentMatterStatus} fullPage />
      <StyledHeading level={3}>
        {getContent('status.manager.heading.title')}
      </StyledHeading>
      <StyledMandatoryFieldsMessage>
        <span>{getContent('status.manager.mandatory.fields.description')}</span>
      </StyledMandatoryFieldsMessage>

      <ErrorHandler keys={errorKeys} />
      {showSuccessMessage ? (
        <InPageAlert variant="success" title="" compact>
          <p>Status changed successfully</p>
        </InPageAlert>
      ) : null}

      <ErrorSummary errors={errors} />
      <Row>
        <Col span={6}>
          {' '}
          <LabelDescriptionList
            list={[
              {
                label: ` ${getContent('status.manager.subheading.title')}`,
                description: mainStatus
                  ? `${mainStatus} -  ${currentMatterStatus}`
                  : `${currentMatterStatus}`,
              },
            ]}
            hasMarginTop
            hasMarginBottom
          />
        </Col>
      </Row>
      <form>
        {' '}
        <Row>
          <Col span={4}>
            <FormGroup
              label={getContent('status.manager.form.field.label1')}
              id="matterStatus"
              errorMessage={errors.matterStatus}
              hasError={errors.matterStatus}
            >
              <Select
                name="matterStatus"
                options={
                  currentMatterStatus
                    ? getStatusOptions(currentMatterStatus)
                    : []
                }
                onChange={handleChange}
                value={values.matterStatus}
                placeholder="Select status"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            {' '}
            <FormGroup
              label={getContent('status.manager.form.field.label2')}
              id="comment"
              helpMessage={
                <span>
                  {getContentWithReplacements(
                    'matters.status.manager.comment.helpmessage.text',
                    {
                      remChar: (250 - messageCharCount).toString(),
                    },
                  )}
                </span>
              }
              errorMessage={errors.comment}
              hasError={errors.comment}
            >
              <Textarea
                name="comment"
                value={values.comment}
                onChange={(e) => {
                  setMessageCharCount(e.target.value.length);
                  handleChange(e);
                }}
                placeholder="Please enter comment"
                maxLength={250}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Col>
        </Row>
        <StyledButton
          type="submit"
          onClick={handleSubmit}
          disabled={isMatterClosed}
        >
          Submit
        </StyledButton>
        {showModal && (
          <ConfirmStatusChangeModal
            currentStatus={currentMatterStatus || ''}
            updatedStatus={getStatusLabel(
              currentMatterStatus,
              values.matterStatus,
            )}
            handleStatusChangeSubmit={() => handleStatusChangeSubmit()}
            handleClose={() => setShowModal(false)}
          />
        )}
      </form>
      <HistoryLog historyLogValues={historyLog} isLoading={isLoading} />
    </ContentContainer>
  );
};
