import {
  Button,
  Heading,
  Table,
  TableContainer,
} from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  margin-top: 50px;
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 ${spacing.xs};
`;

export const StyledDescriptionPopup = styled.div`
  display: flex;
  width: 250px;
  white-space: normal;
  text-align: left;
  padding: 10px;
`;

export const CenteredText = styled.div`
  text-align: center;
  display: flex;

  align-items: center;
`;

export const StyledTable = styled(Table)`
  td {
    width: 1%;
  }
`;
export const StyledTableContainer = styled(TableContainer)`
  div:first-of-type {
    overflow-x: hidden;
  }
  table {
    table-layout: fixed;
    width: auto;
  }
  > p {
  }

  th {
    white-space: nowrap;
  }
  td {
    width: 15%;
    div {
      text-align: left;
    }
  }
`;

export const StyledMandatoryFieldsMessage = styled.div`
  margin-bottom: ${spacing.sm};
  color: ${colours.text.disabled};
`;
