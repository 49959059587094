import { OktaAuth } from '@okta/okta-auth-js';

import environments from '../environments';

export const getOktaAuth = () => {
  /*
  
  const config = {
    issuer: 'https://dfsi-sandbox.oktapreview.com/oauth2/aus1tb2r2omvgCEED0h8',
    clientId: '0oa1t87iinyuQSks10h8',
    redirectUri: `http://localhost:3000/ams-portal-ui/login/callback`,
  };
  */
  const config = {
    issuer: environments.oktaUrl,
    clientId: environments.oktaAppClientId,
    redirectUri: `${window.location.origin}${environments.oktaRedirectUrl}`,
    postLogoutRedirectUri: `${window.location.origin}${environments.oktaPostLogoutUrl}`,
  };
  return new OktaAuth(config);
};
