import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIResource, MethodTypes, queryApi, requestApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import {
  OutOfOfficeDetails,
  OutOfOfficeDetailsCodec,
  RequestType,
} from './types';

const fetch = () => {
  return queryApi(
    {
      resourcePath: APIResource.userProfile,
      suffix: ['profiles', 'out-of-office'],
    },
    OutOfOfficeDetailsCodec,
  );
};

export const useOutOfOfficeDetails = () => {
  const { data, isError, isLoading } = useQuery([QueryKey.OUT_OF_OFFICE], () =>
    fetch(),
  );

  return {
    isLoading,
    isError,
    OutOfOfficeDetails: data,
  };
};

const upsertOOO = (
  payload: OutOfOfficeDetails,
  requestType = RequestType.Add,
) => {
  const endpointProps =
    requestType === RequestType.Add
      ? { verb: MethodTypes.POST }
      : {
          verb: MethodTypes.PUT,
        };
  const { verb } = endpointProps;
  return requestApi(
    {
      resourcePath: APIResource.userProfile,
      suffix: ['profiles', 'out-of-office'],
    },
    verb,
    payload,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useCreateUpdateOOO = (
  onSuccess: () => void,
  onError: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ([payload, requestType = RequestType.Add]: [
      OutOfOfficeDetails,
      RequestType,
    ]) => {
      return upsertOOO(payload, requestType);
    },
    {
      onSuccess: (_, variables) => {
        const [, requestType] = variables;
        queryClient.invalidateQueries([QueryKey.OUT_OF_OFFICE]);
        console.log(
          `OOO successfully ${
            requestType === RequestType.Add ? 'added' : 'updated'
          }`,
        );
        onSuccess();
      },
      onError: (error, variables) => {
        const [, requestType] = variables;
        console.log(
          `OOO is failed to ${
            requestType === RequestType.Add ? 'add' : 'update'
          }`,
        );
        console.log(error);
        onError();
      },
    },
  );
};
