import { useParams } from 'react-router-dom';
import { TableCell, TableRow } from '@snsw/react-component-library';

import {} from '../styles';
import { ColumnType, HistoryLogValue } from '../types';

export const HistoryLogRow = ({
  tableColumns,
  rowData,
}: {
  tableColumns: ColumnType[];
  rowData: HistoryLogValue;
}) => {
  const { matterId = '' } = useParams();

  return (
    <TableRow key={rowData && rowData.id}>
      {tableColumns?.map(({ render, dataIndex }) => (
        <TableCell key={dataIndex}>
          {render &&
            dataIndex &&
            render({
              columnData: dataIndex ? rowData[dataIndex] : null,
              rowData,
              key: dataIndex,
              matterId,
            })}
          {!render && dataIndex && rowData[dataIndex]}
        </TableCell>
      ))}
    </TableRow>
  );
};
