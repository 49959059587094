import { isDocumentClientsMatchedWithAllClients } from 'src/components/clients/helpers';
import { ClientOptions } from 'src/components/clients/types';
import {
  CLASSIFICATION_OPTIONS_AUDITOR,
  CLASSIFICATION_OPTIONS_CUSTOMER,
} from 'src/constants';

import { getClassificationTextFromApiSectionName } from '../singleDocumentModal/helpers';

import { DocumentFieldNames } from './constants';
import {
  APPLICABLE_TO_ALL_CLIENTS,
  APPLICABLE_TO_ALL_CLIENTS_TEXT,
  DocumentInitialValues,
  MatterDocument,
  MatterDocuments,
} from './types';

export const getDocumentDetails = (
  document: MatterDocument,
  clientId: string,
  clientName: string,
) => ({
  [DocumentFieldNames.DocumentId]: document.id,
  [DocumentFieldNames.DocumentName]: document.name,
  [DocumentFieldNames.DocumentUrl]: document.name,
  [DocumentFieldNames.Description]: document.description,
  [DocumentFieldNames.Status]: document.status as 'Received' | 'Draft' | 'Sent',
  [DocumentFieldNames.DisplayStatus]: document.displayStatus,
  [DocumentFieldNames.UploadedDate]: document.uploadedDate,
  [DocumentFieldNames.UploadedBy]: document.uploadedBy,
  [DocumentFieldNames.Classification]: getClassificationTextFromApiSectionName(
    [...CLASSIFICATION_OPTIONS_AUDITOR, ...CLASSIFICATION_OPTIONS_CUSTOMER],
    document.classification,
  ),
  // eslint-disable-next-line no-nested-ternary
  [DocumentFieldNames.ClientId]: clientId,
  // eslint-disable-next-line no-nested-ternary
  [DocumentFieldNames.ClientName]: clientName,
  [DocumentFieldNames.IsRead]:
    document.clients.find((item) => `${item.id}` === clientId)?.read || false,
  [DocumentFieldNames.IsSelected]: false,
  [DocumentFieldNames.DocumentS3Key]: document.s3Key || null,
});

export const createDocumentInitialValues = (
  data: Record<number | string, Array<{ [key: string]: any }>>,
) =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value.map((document) => ({
        ...document,
        [DocumentFieldNames.IsSelected]: false,
        [DocumentFieldNames.Action]: false,
      })),
    ]),
  );

export const getClientName = (clientsOptions: ClientOptions, id: number) => {
  if (!clientsOptions) {
    return '';
  }
  return clientsOptions.find(({ value }) => value === `${id}`)?.text;
};

export const groupDataByClients = (
  matterDocuments: MatterDocuments | undefined,
  clientsOptions: ClientOptions,
): DocumentInitialValues | undefined => {
  if (
    !clientsOptions ||
    (clientsOptions && clientsOptions.length === 0) ||
    !matterDocuments ||
    (matterDocuments && matterDocuments.length === 0)
  ) {
    return undefined;
  }

  const customerIds = clientsOptions.map(({ value }) => Number(value));
  const result: DocumentInitialValues =
    customerIds.length > 1 ? { [APPLICABLE_TO_ALL_CLIENTS]: [] } : {};

  matterDocuments.forEach((matterDocument) => {
    if (
      isDocumentClientsMatchedWithAllClients(
        matterDocument.clients,
        customerIds,
      ) &&
      customerIds.length > 1
    ) {
      const clientIds = matterDocument.clients.map(({ id }) => id).join(',');
      result[APPLICABLE_TO_ALL_CLIENTS].push(
        getDocumentDetails(
          matterDocument,
          clientIds,
          APPLICABLE_TO_ALL_CLIENTS_TEXT,
        ),
      );
    } else {
      matterDocument.clients.forEach(({ id }) => {
        if (!result[id.toString()]) {
          result[id.toString()] = [];
        }
        const clientName = getClientName(clientsOptions, id) || '';
        result[id.toString()].push(
          getDocumentDetails(matterDocument, `${id}`, clientName),
        );
      });
    }
  });

  return result;
};
