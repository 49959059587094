import axios, { AxiosError, AxiosResponse, ResponseType } from 'axios';
import { isDayjs } from 'dayjs';
import { Type } from 'io-ts';
import { SESSION_STORAGE_CUSTOMER_ACCESS_TOKEN } from 'src/constants';
import environments from 'src/environments';

import { getOktaAuth } from '../config/app.config';

import { withDecoded } from './decoder';

export const BASE_URL = `/amsportal-internal-bff`;
export const AMS_PORTAL_EXTERNAL_BFF = `/amsportal-external-bff`;
export const WARNING = 'WARNING';
const auth = getOktaAuth();

const dateTransformer = (data: any): any => {
  if (isDayjs(data)) {
    return data.format('YYYY-MM-DD[T]HH:mm:ss');
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (data === null || typeof data !== 'object' || data instanceof FormData) {
    return data;
  }
  return Object.fromEntries(
    Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]),
  );
};

export const externalApi = axios.create({
  transformRequest: (data) => {
    const transformedData = dateTransformer(data);
    return data instanceof FormData
      ? transformedData
      : JSON.stringify(transformedData);
  },
  // withCredentials true only for customer apis not internal apis
  withCredentials: true,
});

export const internalApi = axios.create({
  transformRequest: (data) => {
    const transformedData = dateTransformer(data);
    return data instanceof FormData
      ? transformedData
      : JSON.stringify(transformedData);
  },
  // withCredentials true only for customer apis not internal apis
  withCredentials: false,
});

if (environments.bypassAuthorization !== 'true') {
  internalApi.interceptors.request.use(
    async (request) => {
      if (await auth.isAuthenticated()) {
        request.headers.Authorization = `Bearer ${
          window.sessionStorage.getItem(
            SESSION_STORAGE_CUSTOMER_ACCESS_TOKEN,
          ) || auth.getAccessToken()
        }`;
      }

      return request;
    },
    (error) => {
      Promise.reject(error);
    },
  );
}

export enum APIResource {
  common = 'common',
  matters = 'audit-matter/v1',
  customerMatter = 'customer-matter/v1',
  dashboard = 'dashboard',
  notifications = 'amsportal-alerts/v1',
  correspondence = 'correspondence',
  documents = 'document-manager/v1',
  contacts = 'amsportal-customers/v1',
  userProfile = 'ams-user-profile/v1',
  correspondenceManager = 'correspondence-manager/v2',
  captcha = 'onboarding/captcha',
  customerAuth = 'authenticate',
  resendOTP = 'resend-otp',
  customerLogin = 'login',
  otp = 'onboarding/otp',
  amsConfig = 'amsportal-config/v1',
  logout = 'logout',
  messages = 'amsportal-messages/v1',
}

export enum MethodTypes {
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}
export type Method = 'get' | 'post' | 'put' | 'delete' | 'patch';

export interface FetchInstructions {
  resourcePath: APIResource | APIResource[];
  id?: number | string | null;
  suffix?: string | string[];
  params?: any;
  decode404?: boolean;
  baseUrl?: string;
}

const formPath = (path?: string | string[]) =>
  Array.isArray(path) ? path.join('/') : path ?? '';

export const buildFetchURL = (
  resourcePath: APIResource | APIResource[],
  id?: string | number | null,
  suffix?: string | string[],
  baseUrl?: string,
): string => {
  const formPathFromResourcePath = formPath(resourcePath);
  const formPathFromSuffix = formPath(suffix);
  if (id)
    return `${environments.apiUrl}${
      baseUrl || BASE_URL
    }/${formPathFromResourcePath}/${id}${
      formPathFromSuffix ? `/${formPathFromSuffix}` : ''
    }`;
  return `${environments.apiUrl}${
    baseUrl || BASE_URL
  }/${formPathFromResourcePath}${
    formPathFromSuffix ? `/${formPathFromSuffix}` : ''
  }`;
};

export const queryApi = async <T>(
  { baseUrl, resourcePath, id, suffix, params, decode404 }: FetchInstructions,
  codec?: Type<T>,
  transform?: (decoded: T) => T,
): Promise<T> => {
  const url = buildFetchURL(resourcePath, id, suffix, baseUrl);
  const api = baseUrl === AMS_PORTAL_EXTERNAL_BFF ? externalApi : internalApi;
  const config = {
    params,
    ...(environments.apiGateWayId && {
      headers: {
        'x-apigw-api-id': environments.apiGateWayId,
      },
    }),
  };

  const { data } = await api.get(url, config).catch((error) => {
    if (decode404 && error.response.status === 404) {
      return { data: undefined };
    }
    throw error;
  });

  if (!codec || !data) return data;
  return transform
    ? withDecoded(codec, data, transform)
    : withDecoded(codec, data, (tr) => tr);
};

export interface ValidationError {
  field: string;
  message: string;
}

export interface RequestError {
  code: string;
  messages?: string[];
  status: string;
  errors?: ValidationError[];
}

export const requestApi = async <T>(
  { resourcePath, id, suffix, params, baseUrl }: FetchInstructions,
  method: Method,
  body?: any,
  codec?: Type<T>,
  responseType?: ResponseType,
  headers?: { [key: string]: string },
) => {
  const url = buildFetchURL(resourcePath, id, suffix, baseUrl);
  const api = baseUrl === AMS_PORTAL_EXTERNAL_BFF ? externalApi : internalApi;
  const requestHeaders = {
    ...(headers || {}),
    ...(environments.apiGateWayId
      ? { 'x-apigw-api-id': environments.apiGateWayId }
      : {}),
  };

  try {
    const {
      data,
      status,
      headers: responseHeaders,
    } = await api.request({
      url,
      data: body,
      method,
      params,
      responseType,
      headers: requestHeaders,
    });
    if (!codec) return { data, status, headers: responseHeaders };
    return withDecoded(codec, data, (tr) => tr);
  } catch (err: any) {
    const parsedError: RequestError = {
      status: err.response.status,
      messages: err.response.data.messages,
      code: err.response.data.code,
      errors: err.response.data.errors,
    };

    return Promise.reject(parsedError);
  }
};

/* 
  This is been used to call presigned url to upload url
  we shouldn't be sending any headers in this case
*/

export const requestExternalApi = async (
  externalApiUri: string,
  method: Method,
  formData?: FormData | File,
  responseType?: ResponseType,
  fileName?: string,
) => {
  try {
    const response = await axios.request({
      url: externalApiUri,
      method,
      data: formData,
      ...(responseType && { responseType }),
      ...(fileName && { fileName }),
    });
    return response;
  } catch (error) {
    return axios.isAxiosError(error)
      ? (error as AxiosError)
      : (error as AxiosResponse<any>);
  }
};

export const findErrorMessage = (
  error: RequestError | null,
  fieldName: string,
) => error?.errors?.find(({ field }) => field === fieldName)?.message;

export const getErrorMessageFromFetchApi = (apiError: unknown) => {
  if (!apiError) {
    return '';
  }
  if ('messages' in (apiError as any) && (apiError as any)?.messages) {
    return (apiError as any)?.messages[0];
  }
  if ('errors' in (apiError as any) && (apiError as any).errors) {
    return (apiError as any)?.errors[0]?.message;
  }
  return ((apiError as AxiosError)?.response?.data as any).messages[0];
};

export const formatError = (error: any): AxiosError => error;
