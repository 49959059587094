import { Row } from '@snsw/react-component-library';
import { Card } from '@snsw/react-component-library/build/Components';
import { colours, fontWeight, media, spacing } from 'ams-common';
import styled from 'styled-components';

export const ButtonRow = styled(Row)`
  margin-top: ${spacing.lg};

  ${media.desktop} {
    margin-top: ${spacing.xxl};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;
export const FlexContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.xs};
`;
export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid ${colours.grey.geyser};
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

export const StyledCardLink = styled.a`
  display: flex;
  align-items: center;
  margin-top: ${spacing.lg};
  cursor: pointer;
  color: ${colours.primary.light};
  font-weight: ${fontWeight.medium};
  text-decoration: none !important;

  > span {
    padding-right: ${spacing.xs};
  }
  > svg {
    fill: ${colours.primary.light};
  }
`;

export const StyledCard = styled(Card)`
  margin-bottom: 20px;
  border-top: 5px solid ${colours.primary.light};
`;

export const SummaryDetailsContainer = styled.div`
  margin-top: ${spacing.md};
`;

export const IconContainer = styled.div`
  padding: 2px;
`;

export const ItemNumContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
  border-top: 2px solid ${colours.grey.geyser};

  h4 {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

export const StyledStatusLabelContainer = styled.div`
  margin-top: ${spacing.md};
`;
