import { ReactNode } from 'react';

// eslint-disable-next-line import/no-cycle
import getContent, { combineContent } from './contentUtils';

export const getContentHtmlUtil = <T extends keyof typeof combineContent>(
  key: T,
): ReactNode => {
  const contentStr = getContent(key);
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: contentStr }}
    />
  );
};
