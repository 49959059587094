import { MatterAlert } from 'ams-common';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { ContactRegistration } from './types';

type ContactRegistrationAlertProps = {
  data: ContactRegistration;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (matterIdOrThreadId: string) => void;
  alertId: string;
};

export const ContactRegistrationAlert = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: ContactRegistrationAlertProps) => {
  const { contactEmail, matterId } = data;
  const registrationMessage = getContentWithReplacements(
    'matterAlert.alert.successful.registration.message',
    {
      email: contactEmail,
      matterId,
    },
  );

  return (
    <MatterAlert
      title="Customer registration successful"
      timestamp={createdDate}
      body={registrationMessage}
      isRead={read}
      linkUrl={`/matters/${matterId}/contacts`}
      linkText="View contacts"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (matterId) updateMatterContext?.(matterId);
      }}
    />
  );
};
