import * as t from 'io-ts';
import { tOptional } from 'src/types';

import { CustomerAuthFormFieldNames } from '../constants';

export const CustomerAuthFormDataCodec = t.type({
  [CustomerAuthFormFieldNames.clientId]: tOptional(t.number),
  [CustomerAuthFormFieldNames.correspondenceId]: tOptional(t.number),
  [CustomerAuthFormFieldNames.emailAddress]: t.string,
});

export type CustomerAuthFormData = t.TypeOf<typeof CustomerAuthFormDataCodec>;

export const CaptchaResponseCodec = t.type({
  [CustomerAuthFormFieldNames.challengeText]: t.string,
});

export type CaptchaResponse = t.TypeOf<typeof CaptchaResponseCodec>;
