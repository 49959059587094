import { useState } from 'react';

import { DueDateModal } from './dueDateModal';
import { NoStyleButton, StyledContainer, StyledEditIcon } from './styles';

export const EditDueDateButton = ({
  dueDate,
  threadId,
  matterUId,
  corroType,
}: {
  dueDate: string;
  threadId: string;
  matterUId: string;
  corroType: string;
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <StyledContainer>
      <StyledEditIcon color="secondaryBlue" />
      <NoStyleButton
        type="button"
        onClick={() => {
          setShowEditModal(true);
        }}
      >
        Edit
      </NoStyleButton>
      {showEditModal && (
        <DueDateModal
          date={dueDate || ''}
          theadId={threadId}
          matterUId={matterUId}
          onClose={() => setShowEditModal(false)}
          corroType={corroType}
        />
      )}
    </StyledContainer>
  );
};
