import {
  IconNotificationError,
  IconNotificationSuccess,
  Modal,
} from '@snsw/react-component-library';
import { DocumentIcon } from 'ams-common';
import getContent from 'src/utils/contentUtils';

import {
  Container,
  StyledButton,
  StyledCol,
  StyledDocumentNameCol,
  StyledIcons,
  StyledRow,
  StyledStatus,
} from './styles';

type MultipleDocumentModalProps = {
  documents?: File[] | null | undefined;
  onAddDetails: (document: File) => void;
  onRemove: (document: File) => void;
  close: () => void;
  documentsStatus?: { name: string; status: string }[] | null;
};

const MultipleDocumentModal = ({
  documents = [],
  onAddDetails,
  onRemove,
  close,
  documentsStatus = [],
}: MultipleDocumentModalProps) => {
  const isDocumentStatusUpdated = (documentName: string) =>
    documentsStatus?.some(
      ({ name, status }) => name === documentName && !!status,
    );

  const renderFilesList = () =>
    documents?.map((document) => (
      <StyledRow key={document.name}>
        <StyledCol span={1}>
          <StyledIcons>
            {isDocumentStatusUpdated(document.name) ? (
              <IconNotificationSuccess />
            ) : (
              <IconNotificationError />
            )}
            <DocumentIcon />
          </StyledIcons>
        </StyledCol>
        <StyledDocumentNameCol span={6}>{document.name}</StyledDocumentNameCol>
        <StyledCol span={5}>
          {isDocumentStatusUpdated(document.name) ? (
            <StyledStatus>
              {getContent('matter.multipleDocumentModal.document.status.text')}
            </StyledStatus>
          ) : (
            <>
              <StyledButton variant="link" onClick={() => onRemove(document)}>
                {getContent('matter.multipleDocumentModal.button.remove')}
              </StyledButton>
              <StyledButton
                variant="link"
                onClick={() => onAddDetails(document)}
              >
                {getContent('matter.multipleDocumentModal.button.addDetails')}
              </StyledButton>
            </>
          )}
        </StyledCol>
      </StyledRow>
    ));

  return (
    <Modal
      id={getContent('matter.multipleDocumentModal.id')}
      title={getContent('matter.multipleDocumentModal.title')}
      buttons={[
        {
          text: getContent('matter.multipleDocumentModal.button.primary'),
          onClick: close,
        },
      ]}
      description={getContent('matter.multipleDocumentModal.description')}
    >
      <Container>{renderFilesList()}</Container>
    </Modal>
  );
};

export default MultipleDocumentModal;
