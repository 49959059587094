import { useQuery, useQueryClient } from 'react-query';
import { useOktaAuth } from '@okta/okta-react';
import { AMS_PORTAL_EXTERNAL_BFF, APIResource, queryApi } from 'src/api';
import { QueryKey } from 'src/api/constants';
import { SESSION_STORAGE_KEY_USER_INFO } from 'src/constants';
import environments from 'src/environments';

import { UserProfile, UserProfileCodec } from './types';

const fetch = () =>
  queryApi<UserProfile>(
    {
      resourcePath: APIResource.common,
      suffix: 'user-profile',
    },
    UserProfileCodec,
  );

export const useUserProfile = (enabled = true) =>
  useQuery([QueryKey.USER_PROFILE], () => fetch(), { enabled, retry: 3 });

export const useOktaLogout = () => {
  const oktaObject = useOktaAuth();
  const queryClient = useQueryClient();

  if (!oktaObject) {
    // this means this code is called from a place which is not wrapped with Okta Security component
    return null;
  }

  const { oktaAuth } = oktaObject;

  const handleLogout = async () => {
    oktaAuth
      .signOut({
        postLogoutRedirectUri: `${window.location.origin}${environments.oktaPostLogoutUrl}`,
      })
      .then(() => {
        const savedItem = window.sessionStorage.getItem(
          SESSION_STORAGE_KEY_USER_INFO,
        );
        queryClient.invalidateQueries();

        if (savedItem) {
          window.sessionStorage.removeItem(SESSION_STORAGE_KEY_USER_INFO);
        }
      })
      .catch((err) => {
        console.error('Sign out error:', err);
      });
  };

  return handleLogout;
};

export const useCustomerLogout = () => {
  const queryClient = useQueryClient();
  const handleCustomerLogout = () => {
    const savedItem = window.sessionStorage.getItem(
      SESSION_STORAGE_KEY_USER_INFO,
    );
    if (savedItem) {
      window.sessionStorage.removeItem(SESSION_STORAGE_KEY_USER_INFO);
    }
    queryClient.invalidateQueries();

    return queryApi({
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      resourcePath: APIResource.logout,
    });
  };

  return handleCustomerLogout;
};
