import { createGlobalStyle, css } from 'styled-components';

//  https://webaim.org/techniques/css/invisiblecontent/

export const screenReaderOnlyStyle = css`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;

export const GlobalStyleArp = createGlobalStyle`
  html,
  body {
    box-sizing: border-box;
    height: 100%;
  }

  body {
    margin: 0;
  }

  body > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .sr-only {
    ${screenReaderOnlyStyle}
  }

  b,
  strong {
    font-weight: bold;
  }

  h1:focus-visible,
  h1:focus {
    outline: none;
  }
`;
