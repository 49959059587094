import * as t from 'io-ts';
import { tOptional } from 'src/types';

export const ClientsCodec = t.type({
  clientName: t.string,
  clientId: t.number,
});

export const MessageContentCodec = t.type({
  id: t.string,
  lastActionBy: t.string,
  subject: t.string,
  clients: t.array(ClientsCodec),
  lastActionDate: t.string,
  unreadMessageCount: t.number,
});

export const MessagesContentCodec = t.array(MessageContentCodec);
export type MessageContent = t.TypeOf<typeof MessageContentCodec>;

export type MessagesContent = t.TypeOf<typeof MessagesContentCodec>;

export const MessagesCodec = t.type({
  totalElements: t.number,
  content: tOptional(MessagesContentCodec),
});

export type Messages = t.TypeOf<typeof MessagesCodec>;

const RecipientCodec = t.type({
  recipientName: t.string,
  recipientId: t.string,
  clientId: tOptional(t.number),
  clientName: tOptional(t.string),
});

export const MessageDetailCodec = t.intersection([
  t.type({
    id: t.string,
    sentDate: t.string,
    sentBy: t.string,
    messageBody: t.string,
    documentIds: tOptional(t.array(t.string)),
  }),
  t.partial({
    recipients: t.array(RecipientCodec),
  }),
]);

export type Recipient = t.TypeOf<typeof RecipientCodec>;

export const MessageDetailsCodec = t.array(MessageDetailCodec);

export type MessageDetail = t.TypeOf<typeof MessageDetailCodec>;
export type MessageDetails = t.TypeOf<typeof MessageDetailsCodec>;

// Start - Create message request body

export type CreateMessageRequestBody = {
  subject: string;
  clientIds: number[];
  recipientIds: string[];
  message: {
    message: string;
    documentIds: string[];
  };
};

// End - Create message request body

// Start - Reply to message request body

export type ReplyToMessageRequestBody = {
  message: string;
  documentIds: string[];
};

// End - Reply to message request body

export const MessageDetailsDataCodec = t.intersection([
  t.type({
    subject: t.string,
    content: MessageDetailsCodec,
  }),
  t.partial({
    trimRecordNumber: tOptional(t.string),
    trimUploadDate: tOptional(t.string),
  }),
]);

export type MessageDetailsData = t.TypeOf<typeof MessageDetailsDataCodec>;

// Start - Trim upload

export const MatterMessageUploadToTrimDataCodec = t.type({
  recordNumber: t.string,
  clientId: t.number,
  trimTitle: t.string,
  trimDescription: t.string,
});

export type MatterMessageUploadToTrimData = t.TypeOf<
  typeof MatterMessageUploadToTrimDataCodec
>;

export const MatterMessageUploadToTrimResponseCodec = t.type({
  data: MatterMessageUploadToTrimDataCodec,
  status: t.number,
});

export type MatterMessageUploadToTrimResponse = t.TypeOf<
  typeof MatterMessageUploadToTrimResponseCodec
>;

// End - Trim upload

export type SortConfig = {
  key: string | null;
  direction: SortDirection;
};

export enum SortDirection {
  desc = 'DESC',
  asc = 'ASC',
}
