import { utils } from '@snsw/react-component-library';
import styled from 'styled-components';

import { fontWeight } from './fonts';

const { getBreakpoint } = utils;

export const ShowOnMobile = styled.div`
  display: block !important;
  ${getBreakpoint.md`
    display: none !important;
  `}
`;

export const HideOnMobile = styled.div`
  display: none !important;
  ${getBreakpoint.md`
    display: block !important;
  `}
`;

export const WeightBold = styled.span`
  font-weight: ${fontWeight.bold};
`;

export const WeightMedium = styled.span`
  font-weight: ${fontWeight.medium};
`;

export const WeightBook = styled.span`
  font-weight: ${fontWeight.book};
`;
