import { Link } from 'react-router-dom';
import { InPageAlert } from '@snsw/react-component-library';
import { colours } from 'ams-common';
import styled from 'styled-components';

export const StyledInPageAlert = styled(InPageAlert)`
  background-color: ${colours.backgrounds.info};
  border-left: none;
`;

export const StyledMessages = styled.span`
  color: ${colours.text.link};
`;

export const StyledLink = styled(Link)`
  text-decoration: none !important;
`;
