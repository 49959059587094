const content = {
  'onboarding.termsOfUse.heading.main': 'Customer Compliance Portal onboarding',
  'onboarding.termsOfUse.heading.first.paragraph.heading': 'Terms of Use',
  'onboarding.termsOfUse.heading.first.modal.heading':
    'Terms of Use - Payroll Tax Compliance Portal',
  'onboarding.termsOfUse.content.first.paragraph.content':
    'Please view and read Terms of Use.',
  'onboarding.termsOfUse.content.first.paragraph.content1':
    'Please view and read',
  'onboarding.termsOfUse.content.first.paragraph.content2': 'Terms of Use.',
  'onboarding.termsOfUse.heading.second.paragraph.heading':
    'Privacy Collection Notice',
  'onboarding.termsOfUse.content.second.paragraph.content2':
    'Privacy Collection Notice.',
  'onboarding.termsOfUse.agreeToTerms.checkbox.label':
    'I agree to <b>Terms of Use</b> and <b>Privacy Collection Notice</b>.',
  'onboarding.termsOfUse.primary.button.text': 'Accept and continue',
};

export default content;
