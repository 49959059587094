import { useNavigate } from 'react-router-dom';
import getContent, { getContentWithHtml } from 'src/utils/contentUtils';

import { StyledButton, StyledHeading } from './styles';

export const RedirectToRegistration = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledHeading level={4}>
        {getContent('customerAuthForm.redirect.title')}
      </StyledHeading>
      <p>{getContent('customerAuthForm.redirect.text1')}</p>
      <p>{getContentWithHtml('customerAuthForm.redirect.text2')}</p>
      <StyledButton variant="secondary" onClick={() => navigate('/onboarding')}>
        Register
      </StyledButton>
    </>
  );
};
