import { formatDate, ListItemType } from 'ams-common';
import {
  Documents,
  MatterCorrespondenceDetailResponses,
  PortalContacts,
} from 'src/hooks/types';
import getContent from 'src/utils/contentUtils';

import { ReplyFrom } from './types';

const getContactNames = (portalContacts?: PortalContacts) => {
  if (!portalContacts) {
    return [];
  }

  return portalContacts.map(({ contactName }) => contactName);
};

export const getUniqueEntityNames = (portalContacts: PortalContacts) =>
  Array.from(new Set(portalContacts?.map((item) => item.clientName)));

export const getAuditCommencementSections = (
  matterLeadClientName: string | null,
  data?: MatterCorrespondenceDetailResponses,
) => {
  if (!data) {
    return null;
  }
  return data.reduce(
    (acc, correspondence, index) => {
      const {
        id,
        sentDate,
        portalContacts,
        dueDate,
        subject,
        documents,
        messageBody,
        replyFrom,
        sentBy,
        auditor,
      } = correspondence;
      if (sentDate) {
        acc[id] = {
          listItems: [
            {
              label: getContent('correspondence.details.label.sentOn'),
              description: formatDate(sentDate, 'DD MMMM YYYY HH:mm'),
            },
            {
              label: getContent('correspondence.details.label.sentBy'),
              description:
                replyFrom === ReplyFrom.CUSTOMER
                  ? `${sentBy} from ${matterLeadClientName}`
                  : sentBy,
            },
            ...(replyFrom === ReplyFrom.CUSTOMER
              ? [
                  {
                    label: getContent('correspondence.details.label.recipient'),
                    description: auditor,
                  },
                ]
              : [
                  {
                    label: getContent(
                      'correspondence.details.label.entityName',
                    ),
                    description: portalContacts
                      ? getUniqueEntityNames(portalContacts).join(', ')
                      : '',
                  },
                  {
                    label: getContent(
                      'correspondence.details.label.recipients',
                    ),
                    description: getContactNames(portalContacts).join(', '),
                  },
                  ...(index === data.length - 1
                    ? [
                        {
                          label: getContent(
                            'correspondence.details.label.dueDate',
                          ),
                          description: formatDate(dueDate, 'DD MMMM YYYY'),
                        },
                      ]
                    : []),
                ]),
            {
              label: getContent('correspondence.details.label.subject'),
              description: subject,
            },
          ],
          documents,
          messageBody,
        };
      }
      return acc;
    },
    {} as {
      [id: string]: {
        listItems: ListItemType[];
        documents: Documents;
        messageBody: string | undefined | null;
      };
    },
  );
};

export const getDocumentIds = (documents: Documents) => {
  return documents?.map(({ id }) => id) || [];
};

export const getMatterCorrespondenceDetails = (
  data: MatterCorrespondenceDetailResponses,
  id: string,
) => {
  return data.find(({ id: corroId }) => corroId === id);
};

export const getDraftResponses = (
  data?: MatterCorrespondenceDetailResponses,
) => {
  if (!data) {
    return null;
  }

  return data.filter(
    ({ corroStatus, replyFrom }) =>
      corroStatus === 'Draft' && replyFrom === 'CUSTOMER',
  );
};

export const getContactIds = (data?: MatterCorrespondenceDetailResponses) => {
  if (!data) {
    return null;
  }
  // Filter only auditor responses, get the latest response and return contactIds
  const filteredData = data.filter((da) => da.replyFrom !== 'CUSTOMER');
  const correspondenceDetails = filteredData[0];
  if (correspondenceDetails) {
    return (
      correspondenceDetails.portalContacts?.map(({ contactId }) => contactId) ||
      null
    );
  }
  return null;
};
