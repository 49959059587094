import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { CORRESPONDENCE_TYPE_MAPPER } from './constants';
import { AuditorReplyToCorrespondenceData } from './types';

type ExtendedAuditorReplyToCorrespondenceData =
  AuditorReplyToCorrespondenceData & {
    matterId: string;
  };

type AuditorReplyToCorrespondenceProps = {
  data: ExtendedAuditorReplyToCorrespondenceData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (threadId: string) => void;
  alertId: string;
};

export const AuditorReplyToCorrespondence = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: AuditorReplyToCorrespondenceProps) => {
  const { correspondenceThreadId, matterId, corroType } = data;
  const correspondenceTypeText =
    CORRESPONDENCE_TYPE_MAPPER[corroType ?? 'AUDIT COMMENCEMENT DOCUMENTS'];
  const title = getContentWithReplacements(
    'matterAlert.alert.customer.correspondence.new.reply.received',
    {
      correspondenceTypeText,
    },
  );

  return (
    <MatterAlert
      title={title}
      timestamp={createdDate}
      body=""
      isRead={read}
      linkUrl={`${PATHS.matters}/${matterId}${PATHS.threads}/${correspondenceThreadId}`}
      linkText="View correspondence"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (correspondenceThreadId) {
          updateMatterContext?.(correspondenceThreadId);
        }
      }}
    />
  );
};
