const content = {
  'notificationsSection.heading.new': 'New Notifications',
  'notificationsSection.heading.read': 'Read Notifications',
  'notificationsSection.heading.single.noOfNotifications':
    '{noOfNotifications} {type} notification(s)',
  'notificationsSection.heading.multiple.noOfNotifications':
    '{noOfNotifications} {type} notifications',
};

export default content;
