import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ComponentLoader } from '@snsw/react-component-library';
import { PATHS } from 'src/routes/constants';
import { useMatterSummaryData } from 'src/screens/common/matters/tabs/common/matterSummary/hook';

import CustomerLogin from '../CustomerLogin';

const LoginAndRedirectWrapper = () => {
  const navigate = useNavigate();
  const [isCustomerLoggedIn, setIsCustomerLoggedIn] = useState<{
    isLoggedInForTheFirstTime: boolean;
    isLoggedIn: boolean;
  }>({
    isLoggedInForTheFirstTime: false,
    isLoggedIn: false,
  });
  const {
    data,
    refetch,
    isLoading: isMatterSummaryDataLoading,
  } = useMatterSummaryData(null, true, false);
  useEffect(() => {
    const fetch = async () => {
      await refetch();
    };

    if (isCustomerLoggedIn.isLoggedIn) {
      fetch();

      if (data) {
        navigate(`${PATHS.matters}/${data?.matterId}${PATHS.matterOverview}`, {
          state: {
            isFirstTimeLoggedIn: isCustomerLoggedIn.isLoggedInForTheFirstTime,
            isAlreadyRegistered: !isCustomerLoggedIn.isLoggedInForTheFirstTime,
          },
        });
      }
    }
  }, [data, isCustomerLoggedIn, navigate, refetch]);
  return (
    <>
      <ComponentLoader active={isMatterSummaryDataLoading} fullPage />
      <CustomerLogin setIsCustomerLoggedIn={setIsCustomerLoggedIn} />
    </>
  );
};

export default LoginAndRedirectWrapper;
