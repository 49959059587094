const content = {
  'signIn.heading.title': 'Sign in to AMS Portal',
  'signIn.userName.label': 'User name',
  'signIn.userName.placeholder': 'type your user name',
  'signIn.password.label': 'Password',
  'signIn.password.placeholder': 'type your password',
  'signIn.button.text': 'Login',
};

export default content;
