import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  ComponentLoader,
  FormGroup,
  Heading,
  InPageAlert,
  Input,
  Row,
} from '@snsw/react-component-library';
import { ErrorSummary } from 'ams-common';
import { useFormik } from 'formik';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames, SESSION_STORAGE_KEY_USER_INFO } from 'src/constants';
import { UserLoginType } from 'src/types';
import getContent from 'src/utils/contentUtils';
import * as Yup from 'yup';

import { useResendOTP } from '../customerAuthForm/hooks';
import { NoStyleButton } from '../onboarding/styles';
import { PrivacyCollectionModal } from '../onboarding/termsOfUse/privacyCollectionModal';
import { RegistrationState } from '../types';

import OTPModal from './modal/otpModal';
import {
  CustomerLoginFormFieldLabels,
  CustomerLoginFormFieldNames,
} from './constants';
import { useValidateCustomerDetails } from './hooks';
import { ButtonGroup, StyledErrorContainer } from './styles';
import { CustomerLoginFormData } from './types';

type CustomerLoginFormProps = {
  isOnboarding?: boolean;
  setIsCustomerLoggedIn: React.Dispatch<
    React.SetStateAction<{
      isLoggedInForTheFirstTime: boolean;
      isLoggedIn: boolean;
    }>
  >;
  registration?: RegistrationState | null;
  termsAndConditions?: string;
};

const customerLoginFormInitValues: CustomerLoginFormData = {
  [CustomerLoginFormFieldNames.clientId]: null,
  [CustomerLoginFormFieldNames.correspondenceId]: null,
  [CustomerLoginFormFieldNames.emailAddress]: '',
  [CustomerLoginFormFieldNames.otp]: null,
  [CustomerLoginFormFieldNames.termsAndConditions]: '',
};

const customerLoginFormSchema = Yup.object({
  [CustomerLoginFormFieldNames.otp]: Yup.string()
    .required(getContent('customerAuthForm.otp.errorMessage.required'))
    .matches(/^[0-9]{6}$/, 'Invalid OTP. Please enter a 6 digit number'),
});

const CustomerLoginForm = ({
  isOnboarding = false,
  setIsCustomerLoggedIn,
  registration,
  termsAndConditions,
}: CustomerLoginFormProps) => {
  const errorSummaryRef = useRef<HTMLDivElement>(null);
  const [internalServerError, setInternalServerError] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [disableResendOtpButton, setDisableResendOtpButton] = useState(false);
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const [privacyCollectionModalOpen, setprivacyCollectionModalOpen] =
    useState(false);

  const navigateSuccess = (statusCode: number) => {
    if (statusCode === 200 || statusCode === 201) {
      window.sessionStorage.setItem(
        SESSION_STORAGE_KEY_USER_INFO,
        JSON.stringify({
          userType: UserLoginType.Customer,
        }),
      );
      window.dispatchEvent(new Event('storage'));
      setIsCustomerLoggedIn((prevState) => ({
        ...prevState,
        ...{
          isLoggedIn: true,
          isLoggedInForTheFirstTime: statusCode === 201,
        },
      }));
    }
  };

  const navigateError = (data: {
    status: number;
    code: string;
    messages?: string[];
  }) => {
    const { code } = data;
    switch (code) {
      case 'OTP_EXPIRED':
        setOpenOtpModal(true);
        break;
      case 'OTP_INCORRECT':
        setErrorMessage('One time password is incorrect');
        break;
      default:
        setInternalServerError(true);
        break;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resetOtpField();
    setDisableResendOtpButton(false);
  };

  const onError = (data: { status: number; messages?: string[] }) => {
    setErrorMessage(data?.messages?.[0] || '');
    setDisableResendOtpButton(false);
  };

  const { mutate: resendOtp, isLoading: isCustomerAuthLoading } = useResendOTP(
    () => {
      setOtpSuccess(true);
    },
    onError,
  );

  const {
    mutate: validateCustomerDetails,
    isLoading: isCustomerDetailsValidating,
  } = useValidateCustomerDetails(navigateSuccess, navigateError);

  const {
    handleChange,
    errors,
    submitCount,
    values,
    submitForm,
    isValid,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues: {
      ...customerLoginFormInitValues,
      [CustomerLoginFormFieldNames.termsAndConditions]: termsAndConditions,
    },
    validationSchema: customerLoginFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (submittedValues) => {
      validateCustomerDetails(submittedValues);
    },
  });

  useEffect(() => {
    if (!registration) {
      return;
    }

    if (registration.clientId) {
      setFieldValue(
        CustomerLoginFormFieldNames.clientId,
        registration.clientId,
      );
    }
    if (registration.corroId) {
      setFieldValue(
        CustomerLoginFormFieldNames.correspondenceId,
        registration.corroId,
      );
    }
    if (registration.contactEmail) {
      setFieldValue(
        CustomerLoginFormFieldNames.emailAddress,
        registration.contactEmail,
      );
    }
  }, [registration, setFieldValue]);

  useEffect(() => {
    if (internalServerError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.CUSTOMER_LOGIN}-POST` as ErrorKey,
        ]),
      ]);
    else {
      // reset error keys if false
      setErrorKeys([]);
    }
  }, [internalServerError]);

  const handleLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    submitForm();
    if (!isValid) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSendNewPassword = async () => {
    setDisableResendOtpButton(true);
    setTimeout(() => setDisableResendOtpButton(false), 120000);

    if (registration && registration !== null) {
      await resendOtp(registration);
    }
  };

  const resetErrorMessages = () => {
    setInternalServerError(false);
    setErrorMessage('');
    setErrors({});
  };

  const resetSuccessMessages = () => {
    setOtpSuccess(false);
  };

  const resetOtpField = () => {
    setFieldValue(CustomerLoginFormFieldNames.otp, '');
  };

  return (
    <>
      <ComponentLoader
        active={isCustomerDetailsValidating || isCustomerAuthLoading}
        fullPage
      />
      <form>
        {/* <Heading level={3}>
          {getContent('customerLoginForm.onboarding.heading.main')}
        </Heading> */}
        {isOnboarding && (
          <Heading level={3}>
            {getContent('customerLoginForm.onboarding.heading.main')}
          </Heading>
        )}
        <StyledErrorContainer
          ref={errorSummaryRef}
          showError={!isValid && submitCount > 0}
        />
        <ErrorSummary errors={errors} />
        {errorMessage && (
          <InPageAlert variant="error" title="Error." compact>
            <p>{errorMessage}</p>
          </InPageAlert>
        )}
        {otpSuccess ? (
          <InPageAlert
            variant="success"
            title={getContent(
              'customerLoginForm.otp.modal.success.heading.text',
            )}
          >
            <p>{getContent('customerLoginForm.otp.modal.success.body.text')}</p>
          </InPageAlert>
        ) : null}
        <ErrorHandler keys={errorKeys} />
        <Row>
          <Col span={4}>
            <FormGroup
              id={CustomerLoginFormFieldNames.clientId}
              label={CustomerLoginFormFieldLabels.clientId}
            >
              <Input
                name={CustomerLoginFormFieldNames.clientId}
                inputWidth="xl"
                value={values.clientId}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <FormGroup
              id={CustomerLoginFormFieldNames.correspondenceId}
              label={CustomerLoginFormFieldLabels.correspondenceId}
            >
              <Input
                name={CustomerLoginFormFieldNames.correspondenceId}
                inputWidth="xl"
                value={values.corroId}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <FormGroup
              id={CustomerLoginFormFieldNames.emailAddress}
              label={CustomerLoginFormFieldLabels.emailAddress}
            >
              <Input
                name={CustomerLoginFormFieldNames.emailAddress}
                inputWidth="xl"
                value={values.contactEmail}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <FormGroup
              id={CustomerLoginFormFieldNames.otp}
              label={CustomerLoginFormFieldLabels.otp}
              helpMessage={getContent('customerAuthForm.otp.helpMessage')}
              hasError={submitCount > 0 && errors.otp}
              errorMessage={errors.otp}
            >
              <Input
                name={CustomerLoginFormFieldNames.otp}
                inputWidth="xl"
                value={values.otp}
                type="password"
                maxLength={6}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <ButtonGroup>
          <Button
            type="submit"
            onClick={(e) => {
              resetErrorMessages();
              resetSuccessMessages();
              handleLogin(e);
            }}
          >
            Login
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              resetErrorMessages();
              resetSuccessMessages();
              resetOtpField();
              handleSendNewPassword();
            }}
            disabled={disableResendOtpButton}
          >
            Resend One-time password
          </Button>
        </ButtonGroup>
      </form>
      <OTPModal
        open={openOtpModal}
        close={() => setOpenOtpModal(false)}
        onSendNewPassword={handleSendNewPassword}
      />
      {/* <Heading level={4}>
        {getContent('onboarding.privacyCollectionNotice.heading')}
      </Heading>
      {getContentWithHtml('onboarding.privacyCollectionNotice.text')} */}
      <Heading level={3}>
        {getContent('onboarding.termsOfUse.heading.second.paragraph.heading')}
      </Heading>
      <span>
        {getContent('onboarding.termsOfUse.content.first.paragraph.content1')}
      </span>
      <span>
        <NoStyleButton
          onClick={() => {
            setprivacyCollectionModalOpen(true);
          }}
        >
          <p>
            {getContent(
              'onboarding.termsOfUse.content.second.paragraph.content2',
            )}
          </p>
        </NoStyleButton>
      </span>
      {privacyCollectionModalOpen && (
        <PrivacyCollectionModal
          openModal={privacyCollectionModalOpen}
          onClose={() => setprivacyCollectionModalOpen(false)}
        />
      )}
    </>
  );
};

export default CustomerLoginForm;
