import * as t from 'io-ts';
import { tOptional } from 'src/types';

import { MatterSummaryFieldsName } from './constants';

export const caseProject = t.type({
  [MatterSummaryFieldsName.projectId]: t.number,
  [MatterSummaryFieldsName.projectName]: t.string,
});

export const leadClient = t.type({
  [MatterSummaryFieldsName.leadClientId]: t.number,
  [MatterSummaryFieldsName.leadClientName]: t.string,
  [MatterSummaryFieldsName.leadClientMarsCaseId]: t.number,
});

export const associatedClient = t.type({
  [MatterSummaryFieldsName.clientId]: t.number,
  [MatterSummaryFieldsName.clientName]: t.string,
  [MatterSummaryFieldsName.clientMarsCaseId]: t.number,
});

export const amsLink = t.type({
  [MatterSummaryFieldsName.amsLinkName]: t.string,
  [MatterSummaryFieldsName.amsLinkUrl]: t.string,
});

export const associatedClients = t.array(associatedClient);
export const amsLinks = t.array(amsLink);

export const MatterSummaryCodec = t.intersection([
  t.type({
    [MatterSummaryFieldsName.matterId]: t.string,
    [MatterSummaryFieldsName.noiDate]: tOptional(t.string),
    [MatterSummaryFieldsName.lastActionDate]: t.string,
    [MatterSummaryFieldsName.lastActionType]: tOptional(t.string),
    [MatterSummaryFieldsName.lastActionBy]: t.string,
    [MatterSummaryFieldsName.auditTeam]: t.string,
    [MatterSummaryFieldsName.auditType]: tOptional(t.string),
    [MatterSummaryFieldsName.caseProject]: caseProject,
    [MatterSummaryFieldsName.leadClient]: leadClient,
    [MatterSummaryFieldsName.clients]: associatedClients,
    [MatterSummaryFieldsName.amsLinks]: amsLinks,
  }),
  t.partial({
    [MatterSummaryFieldsName.matterStatus]: t.string,
    [MatterSummaryFieldsName.managerId]: tOptional(t.string),
    [MatterSummaryFieldsName.managerName]: tOptional(t.string),
    [MatterSummaryFieldsName.portalMatterId]: t.string,
  }),
]);
export type MatterSummary = t.TypeOf<typeof MatterSummaryCodec>;
export type AssociatedClients = t.TypeOf<typeof associatedClients>;
