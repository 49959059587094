import * as t from 'io-ts';
import { tOptional } from 'src/types';

export const OutOfOfficeDetailsCodec = t.partial({
  id: tOptional(t.string),
  active: tOptional(t.boolean),
  startDate: tOptional(t.string),
  endDate: tOptional(t.string),
  message: tOptional(t.string),
});

export type OutOfOfficeDetailsInitialValues = t.TypeOf<
  typeof OutOfOfficeDetailsCodec
>;
export type OutOfOfficeDetails = t.TypeOf<typeof OutOfOfficeDetailsCodec>;

export enum RequestType {
  Add,
  Update,
}
