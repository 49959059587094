import { FormCheckbox, FormGroup } from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import { Form } from 'formik';
import styled, { css } from 'styled-components';

export const StyledFormCheckbox = styled(FormCheckbox)`
  margin-top: 0;
`;

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${spacing.md};
  gap: ${spacing.md};
`;

export const StyledForm = styled(Form)`
  > div.hidden {
    display: none;
  }
`;

export const StyledErrorContainer = styled.div<{
  showError: boolean;
}>`
  div.error-content {
    ${({ showError }) => !showError && `display: none`}
  }
`;

export const DocumentBody = styled.div`
  margin-top: ${spacing.sm};
  background-color: ${colours.neutral.lightest};
  padding: ${spacing.md};
`;

export const NoBullet = styled.ul`
  color: ${colours.text.link};
`;

export const FormDateGroup = styled(FormGroup)<{
  hasError: boolean;
}>`
  label {
    display: none;
  }

  fieldset {
    label {
      display: block;
    }

    h3 {
      font-size: 110%;
      font-weight: 500;
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      input,
      select {
        border-color: ${colours.borders.error};
      }
    `}
`;

export const StyledMessageSubject = styled.p`
  display: flex;
`;
