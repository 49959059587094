import { useMutation, useQuery } from 'react-query';
import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  MethodTypes,
  queryApi,
  requestApi,
} from 'src/api';
import { QueryKey } from 'src/api/constants';

import { CaptchaResponseCodec, CustomerAuthFormData } from './types';

const fetchCaptcha = () => {
  return queryApi(
    {
      resourcePath: APIResource.captcha,
    },
    CaptchaResponseCodec,
  );
};

export const useCaptcha = () =>
  useQuery([QueryKey.CAPTCHA], () => fetchCaptcha());

const createCustomerAuth = (
  customerAuthFormRequest: CustomerAuthFormData,
  wafToken?: string,
) => {
  return requestApi(
    {
      resourcePath: APIResource.customerAuth,
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
    },
    MethodTypes.POST,
    customerAuthFormRequest,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
      ...(wafToken ? { 'X-Aws-Waf-Token': wafToken } : {}),
    },
  );
};

export const useCustomerAuthForm = (
  navigateSuccess: (statusCode: number) => void,
  onError: (data: {
    status: number;
    code: string;
    messages?: string[];
  }) => void,
) => {
  return useMutation(
    ([payload, wafToken]: [
      payload: CustomerAuthFormData,
      wafToken?: string,
    ]) => {
      return createCustomerAuth(payload, wafToken);
    },
    {
      onSuccess: (data) => {
        navigateSuccess((data as any).status);
      },
      onError: (data) => {
        onError(data as any);
      },
    },
  );
};

const resendOTP = (authData: CustomerAuthFormData) => {
  return requestApi(
    {
      resourcePath: APIResource.resendOTP,
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
    },
    MethodTypes.POST,
    authData,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useResendOTP = (
  navigateSuccess: (statusCode: number) => void,
  onError: (data: {
    status: number;
    code: string;
    messages?: string[];
  }) => void,
) => {
  return useMutation(
    (payload: CustomerAuthFormData) => {
      return resendOTP(payload);
    },
    {
      onSuccess: (data) => {
        navigateSuccess((data as any).status);
      },
      onError: (data) => {
        onError(data as any);
      },
    },
  );
};
