import {
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@snsw/react-component-library';
import { IconDelete, Tooltip } from 'ams-common';
import getContent from 'src/utils/contentUtils';

import { HiddenInput } from './hiddenInput';
import {
  NoStyleButton,
  StyledEditIcon,
  StyledIconContainer,
  StyledTableCell,
  StyledTableContainer,
  StyledTitle,
} from './styles';

interface DocumentsTableProps {
  handleDocumentEdit: (args: { documentId: string }) => void;
  handleDocumentDelete: (args: {
    documentId: string;
    documentName: string;
  }) => void;

  uploadedDocuments?: {
    documentId: string;
    documentName: string;
    documentSize: string;
  }[];
}
export const DocumentsTable = ({
  handleDocumentEdit,
  handleDocumentDelete,
  uploadedDocuments,
}: DocumentsTableProps) => {
  return (
    <>
      {uploadedDocuments && uploadedDocuments.length > 0 ? (
        <>
          <Heading level={4}>
            {getContent('matter.correspondence.fileUpload.sub.heading')}
          </Heading>
          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>
                    {getContent(
                      'matter.correspondence.documents.table.header.fileName',
                    )}
                  </TableHeader>
                  <TableHeader>
                    {getContent(
                      'matter.correspondence.documents.table.header.editProperties',
                    )}
                  </TableHeader>
                  <TableHeader>
                    <StyledTitle>
                      {getContent(
                        'matter.correspondence.documents.table.header.remove',
                      )}
                      <Tooltip
                        text={getContent(
                          'matter.correspondence.documents.table.header.remove.tooltip.text',
                        )}
                        label=""
                        alignLeft
                      />
                    </StyledTitle>
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadedDocuments &&
                  uploadedDocuments.map(
                    ({ documentId, documentName, documentSize }) => {
                      return (
                        <TableRow key={documentId}>
                          <StyledTableCell>
                            {documentName}
                            {` (${(
                              Number(documentSize) /
                              (1024 * 1024)
                            ).toFixed(2)}MB)`}
                          </StyledTableCell>
                          <TableCell>
                            <NoStyleButton
                              type="button"
                              onClick={() => {
                                handleDocumentEdit({
                                  documentId,
                                });
                              }}
                            >
                              <StyledIconContainer>
                                <StyledEditIcon color="secondaryBlue" /> Edit
                                properties
                              </StyledIconContainer>
                            </NoStyleButton>
                          </TableCell>
                          <TableCell>
                            <NoStyleButton
                              type="button"
                              onClick={() => {
                                handleDocumentDelete({
                                  documentId,
                                  documentName,
                                });
                              }}
                            >
                              <StyledIconContainer>
                                <IconDelete color="primaryBlue" /> Remove
                              </StyledIconContainer>
                            </NoStyleButton>
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </>
      ) : null}
      <HiddenInput name="documents" />
    </>
  );
};
