import { StatusOption } from './types';

export const StatusOptions: Record<string, StatusOption[]> = {
  'Information requested': [
    {
      text: 'Commencement - Information provided',
      value: 'Information provided',
    },
  ],
  'Information provided': [{ text: 'Initial review', value: 'Initial review' }],
  'Initial review': [
    {
      text: 'Further information - More information requested',
      value: 'More Information requested',
    },
  ],
  'More Information requested': [
    {
      text: 'Further information - More information provided',
      value: 'More Information provided',
    },
  ],
  'More Information provided': [{ text: 'Review', value: 'Review' }],
  Review: [
    { text: 'Findings - Preliminary Findings', value: 'Preliminary Findings' },
  ],
  'Preliminary Findings': [
    {
      text: 'Findings - Referred for approval',
      value: 'Referred for approval',
    },
    { text: 'Review', value: 'Review' },
  ],
  'Referred for approval': [
    {
      text: 'Finalisation - Finalisation Advice',
      value: 'Finalisation Advice',
    },
    { text: 'Review', value: 'Review' },
  ],
};

// Status values - [ Commencement, Information requested, Information provided, Initial review, Audit meeting,
// More Information requested, More Information provided, Review, Preliminary Findings, Referred for approval,
// Finalisation Meeting, Finalisation Advice, Payments, Audit Completed ]

export const MainStatus = {
  'Information requested': 'Commencement',
  'Information provided': 'Commencement',
  'Initial review': 'Initial review',
  'More Information requested': 'Further information',
  'More Information provided': 'Further information',
  Review: 'Review',
  'Preliminary Findings': 'Finding',
  'Referred for approval': 'Finding',
  'Finalisation Advice': 'Finalisation',
  'Audit Completed': 'Finalisation',
};
