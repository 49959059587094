import React from 'react';
import { MatterNotificationContent } from 'src/components/alerts/types';

import { NotificationGroup } from '../notificationGroup';

type NotificationSectionProps = {
  notifications: MatterNotificationContent[];
  updateStatus: (alertId: string) => void;
  enableMarkAllRead?: boolean;
  updateMatterContext?: (matterIdOrThreadId: string, threadId?: string) => void;
  submitMarkAllAsRead?: () => void;
};

const NotificationSection: React.FC<NotificationSectionProps> = ({
  notifications,
  updateStatus,
  enableMarkAllRead,
  updateMatterContext,
  submitMarkAllAsRead,
}) => {
  const notificationsData: {
    read: MatterNotificationContent[];
    new: MatterNotificationContent[];
  } = notifications.reduce(
    (groups, item) => {
      const type = item.read ? 'read' : 'new';
      groups[type].push(item);
      return groups;
    },
    { read: [], new: [] } as {
      read: typeof notifications;
      new: typeof notifications;
    },
  );

  const reorderNotifications: string[] = ['new', 'read'];

  return (
    <>
      {reorderNotifications.map((key) => (
        <NotificationGroup
          key={key}
          type={key}
          notifications={notificationsData[key]}
          updateMatterContext={updateMatterContext}
          updateStatus={updateStatus}
          submitMarkAllAsRead={submitMarkAllAsRead}
          enableMarkAllRead={enableMarkAllRead}
        />
      ))}
    </>
  );
};

export default NotificationSection;
