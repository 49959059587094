import { FormCheckbox } from '@snsw/react-component-library';
import styled from 'styled-components';

export const StyledFormCheckbox = styled(FormCheckbox)`
  label {
    display: flex;
    align-items: center;
  }
  label::before {
    top: auto;
  }
`;
