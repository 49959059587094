import { useEffect, useState } from 'react';

export const useStickyTableColumns = (elementId: string = 'sticky-table') => {
  const [offset, setOffset] = useState<number>();

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (!element) return;

    const scrollableElement = element?.nextElementSibling;

    if (!scrollableElement) return;

    const handleScroll = () => {
      const secondColumn = scrollableElement.querySelector('th:nth-child(1)');

      if (secondColumn) {
        const rect = secondColumn.getBoundingClientRect();

        const { width } = rect;
        setOffset(width);
      }
    };

    scrollableElement.addEventListener('scroll', handleScroll);

    // eslint-disable-next-line consistent-return
    return () => {
      scrollableElement.removeEventListener('scroll', handleScroll);
    };
  }, [elementId]);

  return {
    offset,
  };
};
