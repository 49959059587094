import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIResource, MethodTypes, queryApi, requestApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import { NotificationsCodec } from './types';

const fetch = () => {
  return queryApi(
    {
      resourcePath: APIResource.notifications,
      suffix: ['internal-alerts'],
      params: {
        pageNumber: 0,
        pageSize: 99999,
      },
    },
    NotificationsCodec,
  );
};

export const useNotifications = () => {
  return useQuery([QueryKey.MATTER_ALERTS], () => fetch(), {
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
};

const updateAlertStatus = (alertId: string) => {
  return requestApi(
    {
      resourcePath: APIResource.notifications,
      suffix: ['internal-alerts', `${alertId}`],
    },
    MethodTypes.PUT,
    alertId,
  );
};

export const useUpdateAlertStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (alertId: string) => {
      return updateAlertStatus(alertId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.MATTER_ALERTS]);
      },
      onError: (error) => {
        console.log('Failed to update alert status');
        console.log(error);
      },
    },
  );
};
