import { FormGroup, Heading } from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import styled, { css } from 'styled-components';

export const FormDateGroup = styled(FormGroup)<{
  hasError: boolean;
}>`
  label {
    display: none;
  }

  fieldset {
    label {
      display: block;
    }

    h3 {
      font-size: 110%;
      font-weight: 500;
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      input,
      select {
        border-color: ${colours.borders.error};
      }
    `}
`;

export const StyledErrorContainer = styled.div<{
  showError: boolean;
}>`
  div.error-content {
    ${({ showError }) => !showError && `display: none`}
  }
`;

export const NoBullet = styled.ul`
  color: ${colours.text.link};
`;

export const StyledDueDate = styled.p`
  display: flex;
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: 0.5rem;
`;

export const StyledMessageContainer = styled.div`
  margin-bottom: ${spacing.md};
  color: ${colours.text.disabled};
`;

export const StyledMessageSubject = styled.p`
  display: flex;
`;
