import notificationGroupContent from './notificationGroup/contents';
import notificationSectionContent from './notificationsSection/contents';

const content = {
  ...notificationGroupContent,
  ...notificationSectionContent,
  'matterAlert.alert.new.matter.received.message':
    'You have received a new matter {matterId} for {clientName}',
  'matterAlert.alert.successful.registration.message':
    'The user with the email account {email} for the matter ID {matterId} has successfully registered in the portal',
  'matterAlert.alert.customer.onboarding.email.sent.message':
    'Customer onboarding email sent to {contactName} ({contactEmail}) for CID {customerId} {clientName}.',
  'matterAlert.alert.customer.correspondence.read.message':
    '{contactName} from {clientName} has accessed the {correspondenceName} correspondence',
  'matterAlert.alert.customer.document.read.message':
    '{contactName} from {clientName} has accessed the document {documentName}',
  'matterAlert.alert.customer.correspondence.response.received':
    'Response for {correspondenceTypeText} received for matter {matterId} | {leadClientName}.',
  'matterAlert.alert.customer.correspondence.new.reply.received':
    'New reply received for {correspondenceTypeText} correspondence',
  'matterAlert.alert.customer.correspondence.close':
    ' {correspondenceTypeText} correspondence has been closed',
  'matterAlert.alert.new.message.received':
    'New message received from {contactName} for the Audit Matter {matterId}',
  'matterAlert.alert.message.received.title': 'New message received',
  'matterAlert.alert.auditor.message.received.body':
    'New message received from {contactName} for the Audit Matter {matterId}',
  'matterAlert.alert.customer.to.customer.message.received.body':
    'New message received from {contactName} from {clientName}',
  'matterAlert.alert.customer.message.received.body':
    'New message received from {auditorName}',
  'matterAlert.alert.audit.matter.transferred.title':
    'Audit matter has been transferred',
  'matterAlert.alert.auditor.audit.matter.transferred.to.body':
    'The audit matter {matterId} for the {leadClientName} has been transferred to you from {auditorName}',
  'matterAlert.alert.auditor.audit.matter.transferred.from.body':
    'The audit matter {matterId} for the {leadClientName} has been transferred from you to {auditorName}',
  'matterAlert.alert.auditor.audit.matter.transferred.from.linkText':
    'View my matters',
  'matterAlert.alert.auditor.audit.matter.transferred.to.linkText':
    'View matter',
  'matterAlert.alert.customer.audit.matter.transferred.from.body':
    "There's a change in the case officer for this audit matter",
  'matterAlert.alert.audit.matter.terminated.title':
    'Audit matter has been terminated',
  'matterAlert.alert.audit.matter.terminated.body':
    'The audit matter {matterId} for the {leadClientName} has been terminated',
};

export default content;
