import { useEffect, useState } from 'react';
import { FormGroup, Textarea } from '@snsw/react-component-library';
import { getContentWithReplacements } from 'src/utils/contentUtils';

interface MessageProps {
  hasError: boolean;
  errorMessage?: string;
  value: string;
  handleChange: ({ value, field }: { value: string; field: string }) => void;
}

export const Message = ({
  value,
  hasError,
  errorMessage,
  handleChange,
}: MessageProps) => {
  const [messageCharCount, setMessageCharCount] = useState(value.length || 0);

  useEffect(() => {
    setMessageCharCount(value.length || 0);
  }, [value]);

  return (
    <FormGroup
      helpMessage={
        <span>
          {getContentWithReplacements(
            'matters.correspondence.message.helpMessage.text',
            {
              remChar: (1000 - messageCharCount).toString(),
            },
          )}
        </span>
      }
      errorMessage={errorMessage}
      id="message"
      label="Message"
      hasError={hasError}
    >
      <Textarea
        name="message"
        value={value}
        inputWidth="xxl"
        onChange={(e) => {
          setMessageCharCount(e.target.value.length);
          handleChange({ value: e.target.value, field: 'message' });
        }}
        placeholder="Please enter a message"
        maxLength={1000}
      />
    </FormGroup>
  );
};
