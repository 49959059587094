import { colours } from 'ams-common';
import styled from 'styled-components';

export const NoStyleButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  color: ${colours.text.link};
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  text-decoration-thickness: 1px;
  display: flex;
  width: 100%;
  justify-content: end;
`;
