import { useQuery } from 'react-query';
import { AMS_PORTAL_EXTERNAL_BFF, APIResource, queryApi } from 'src/api';
import { QueryKey } from 'src/api/constants';
import { ActionItemsListCodec } from 'src/components/actionItems/types';

import { MatterSummaryCodec } from './types';

const fetchAuditorMatterSummary = (matterUId: string | null) => {
  if (!matterUId) {
    return null;
  }

  return queryApi(
    {
      resourcePath: APIResource.matters,
      suffix: ['matters', matterUId],
    },
    MatterSummaryCodec,
  );
};

const fetchCustomerMatterSummary = () => {
  return queryApi(
    {
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      resourcePath: APIResource.matters,
      suffix: ['matters', 'current'],
    },
    MatterSummaryCodec,
  );
};

export const useMatterSummaryData = (
  matterUId: string | null,
  isCustomer: boolean,
  enabled: boolean = true,
) =>
  useQuery(
    [QueryKey.MATTERS_SUMMARY, matterUId],
    () =>
      !isCustomer
        ? fetchAuditorMatterSummary(matterUId)
        : fetchCustomerMatterSummary(),
    {
      enabled,
    },
  );

const fetchActionItems = (matterUId: string | null) => {
  if (!matterUId) {
    return null;
  }

  return queryApi(
    {
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      resourcePath: APIResource.correspondenceManager,
      suffix: ['matters', matterUId, 'actionItems'],
      params: {
        pageNumber: 0,
        pageSize: 99999,
      },
    },
    ActionItemsListCodec,
  );
};

export const useActionItemsData = (matterUId: string | null) =>
  useQuery([QueryKey.ACTION_ITEMS], () => fetchActionItems(matterUId), {
    enabled: !!matterUId,
  });
