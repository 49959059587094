import { createContext, ReactNode, useContext, useState } from 'react';

interface MatterContextType {
  matterId: string | null;
  matterUId: string | null;
  matterStatus: string | null;
  matterCorrespondenceId: string | null;
  matterCorrespondenceThreadId: string | null;
  matterLeadClientName: string | null;
  matterManagerId: string | null;
  matterManagerName: string | null;
  isMatterClosed: boolean;
  isMatterTerminated: boolean;
  matterLeadClientId: number | null;
  matterClientId: number | null;
  updateMatterUId: (id: string) => void;
  updateMatterStatus: (status: string) => void;
  updateMatterCorrespondenceId: (id: string | null) => void;
  updateMatterCorrespondenceThreadId: (id: string | null) => void;
  updateMatterLeadClientName: (leadClientName: string) => void;
  updateMatterId: (id: string) => void;
  updateMatterManagerId: (managerId: string | null) => void;
  updateMatterManagerName: (managerId: string | null) => void;
  updateIsMatterClosed: (closed: boolean) => void;
  updateIsMatterTerminated: (isTerminated: boolean) => void;
  updateMatterLeadClientId: (matterLeadClientId: number | null) => void;
  updateMatterClientId: (matterClientId: number | null) => void;
}

const MatterContext = createContext<MatterContextType | undefined>(undefined);

export const useMatter = (): MatterContextType => {
  const context = useContext(MatterContext);
  if (!context) {
    throw new Error('useMatter must be used within a MatterContextProvider');
  }
  return context;
};

interface MatterContextProviderProps {
  children: ReactNode;
}

export const MatterContextProvider = ({
  children,
}: MatterContextProviderProps): JSX.Element => {
  const [matterUId, setMatterUId] = useState<string | null>(null);
  const [matterId, setMatterId] = useState<string | null>(null);
  const [matterStatus, setMatterStatus] = useState<string | null>(null);
  const [matterCorrespondenceId, setMatterCorrespondenceId] = useState<
    string | null
  >(null);
  const [matterCorrespondenceThreadId, setMatterCorrespondenceThreadId] =
    useState<string | null>(null);
  const [matterLeadClientName, setMatterLeadClientName] = useState<
    string | null
  >(null);
  const [matterManagerId, setMatterManagerId] = useState<string | null>(null);
  const [matterManagerName, setMatterManagerName] = useState<string | null>(
    null,
  );
  const [isMatterClosed, setIsMatterClosed] = useState<boolean>(false);
  const [matterLeadClientId, setMatterLeadClientId] = useState<number | null>(
    null,
  );
  const [matterClientId, setMatterClientId] = useState<number | null>(null);
  const [isMatterTerminated, setIsMatterTerminated] = useState<boolean>(false);

  const updateMatterUId = (id: string) => {
    setMatterUId(id);
  };

  const updateMatterId = (id: string) => {
    setMatterId(id);
  };

  const updateMatterStatus = (status: string) => {
    setMatterStatus(status);
  };

  const updateMatterCorrespondenceId = (id: string | null) => {
    setMatterCorrespondenceId(id);
  };
  const updateMatterCorrespondenceThreadId = (id: string | null) => {
    setMatterCorrespondenceThreadId(id);
  };

  const updateMatterLeadClientName = (leadClientName: string) => {
    setMatterLeadClientName(leadClientName);
  };

  const updateMatterManagerId = (managerId: string | null) => {
    setMatterManagerId(managerId);
  };
  const updateMatterManagerName = (managerId: string | null) => {
    setMatterManagerName(managerId);
  };

  const updateIsMatterClosed = (closed: boolean) => {
    setIsMatterClosed(closed);
  };

  const updateIsMatterTerminated = (isTerminated: boolean) => {
    setIsMatterTerminated(isTerminated);
  };

  const updateMatterLeadClientId = (matterLeadClientId: number | null) => {
    setMatterLeadClientId(matterLeadClientId);
  };

  const updateMatterClientId = (matterClientId: number | null) => {
    setMatterClientId(matterClientId);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: MatterContextType = {
    matterId,
    matterUId,
    updateMatterUId,
    matterStatus,
    updateMatterStatus,
    matterCorrespondenceId,
    updateMatterCorrespondenceId,
    matterLeadClientName,
    updateMatterLeadClientName,
    updateMatterId,
    matterManagerId,
    updateMatterManagerId,
    matterManagerName,
    updateMatterManagerName,
    isMatterClosed,
    updateIsMatterClosed,
    matterLeadClientId,
    updateMatterLeadClientId,
    isMatterTerminated,
    updateIsMatterTerminated,
    matterClientId,
    updateMatterClientId,
    matterCorrespondenceThreadId,
    updateMatterCorrespondenceThreadId,
  };

  return (
    <MatterContext.Provider value={contextValue}>
      {children}
    </MatterContext.Provider>
  );
};
