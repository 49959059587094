import { MatterAlert } from 'ams-common';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { NewMatterMessageData } from './types';

type NewMatterAlertProps = {
  data: NewMatterMessageData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (matterIdOrThreadId: string) => void;
  alertId: string;
};

export const NewMatterAlert = ({
  data,
  createdDate,
  read,
  updateStatus,
  updateMatterContext,
  alertId,
}: NewMatterAlertProps) => {
  const { leadClientName, matterId } = data;
  const matterMessage = getContentWithReplacements(
    'matterAlert.alert.new.matter.received.message',
    {
      matterId,
      clientName: leadClientName,
    },
  );

  return (
    <MatterAlert
      title="New matter received"
      timestamp={createdDate}
      body={matterMessage}
      isRead={read}
      linkUrl={`/matters/${matterId}/overview`}
      linkText="View matter details"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (matterId) updateMatterContext?.(matterId);
      }}
    />
  );
};
