import { useEffect, useState } from 'react';
import { ComponentLoader } from '@snsw/react-component-library';
import { QueryKey } from 'src/api/constants';
import { updateNotificationContentWithMatterId } from 'src/components/alerts/helpers';
import NotificationSection from 'src/components/alerts/notificationsSection';
import { MatterNotificationContent } from 'src/components/alerts/types';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';

import {
  useExternalMatterNotifications,
  useUpdateExternalMatterNotificationAlertStatus,
  useUpdateMarkAllAsRead,
} from '../hooks';

export const ExternalMatterNotifications = () => {
  const { updateMatterCorrespondenceThreadId, matterUId, matterId } =
    useMatter();
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const [notificationContent, setNotificationContent] = useState<
    MatterNotificationContent[] | null
  >(null);
  const {
    isLoading,
    isError: notificationsError,
    data: notifications,
  } = useExternalMatterNotifications();
  const { mutate: updateAlertStatus, isError: updateAlertStatusError } =
    useUpdateExternalMatterNotificationAlertStatus();

  const { mutate: updateMarkRead, isError: errorUpdateMarkRead } =
    useUpdateMarkAllAsRead(matterUId, true);

  const updateMarkAllAsRead = async () => {
    await updateMarkRead();
  };

  const updateStatus = async (id: string) => {
    await updateAlertStatus(id);
  };

  const updateMatterContext = (matterIdOrThreadId: string) =>
    updateMatterCorrespondenceThreadId(matterIdOrThreadId);

  useEffect(() => {
    if (notifications && matterId) {
      setNotificationContent(
        updateNotificationContentWithMatterId(
          notifications,
          [
            'NEW_CORRESPONDENCE',
            'AUDITOR_REPLY_TO_CORRESPONDENCE',
            'CLOSE_CORRESPONDENCE',
            'AUDITOR_TO_CUSTOMER_MESSAGE_RECEIVED',
            'CUSTOMER_TO_CUSTOMER_MESSAGE_RECEIVED',
            'CUSTOMER_MATTER_TRANSFERRED',
          ],
          matterId,
        ),
      );
    }
  }, [notifications, matterId]);

  useEffect(() => {
    if (errorUpdateMarkRead)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.EXTERNAL_MATTER_NOTIFICATIONS}-POST` as ErrorKey,
        ]),
      ]);
  }, [errorUpdateMarkRead]);

  useEffect(() => {
    if (updateAlertStatusError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.EXTERNAL_MATTER_NOTIFICATIONS}-POST` as ErrorKey,
        ]),
      ]);
  }, [updateAlertStatusError]);

  useEffect(() => {
    if (notificationsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.EXTERNAL_MATTER_NOTIFICATIONS}-GET` as ErrorKey,
        ]),
      ]);
  }, [notificationsError]);

  return (
    <>
      <ComponentLoader active={isLoading} fullPage />

      <ErrorHandler keys={errorKeys} />
      {notifications &&
        notifications.content.length > 0 &&
        notificationContent && (
          <NotificationSection
            notifications={notificationContent}
            updateStatus={updateStatus}
            updateMatterContext={updateMatterContext}
            enableMarkAllRead
            submitMarkAllAsRead={() => updateMarkAllAsRead()}
          />
        )}
    </>
  );
};
