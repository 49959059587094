const contents = {
  'matterSummary.customer.title.text': 'Audit details',
  'matterSummary.customer.success.text':
    'You have successfully logged into the Compliance portal',
  'matterSummary.customer.firstTime.loggedIn.success.text':
    'You have successfully registered and logged into the compliance portal',
  'matterSummary.auditor.existing.correspondence.available.modal.title':
    'Existing draft available',
  'matterSummary.auditor.existing.correspondence.available.modal.description':
    'A {corroTypeText} correspondence draft already exists. Are you sure you want to create a new correspondence?',
};

export default contents;
