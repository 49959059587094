/* eslint-disable jsx-a11y/anchor-is-valid */
import { Heading, Modal } from '@snsw/react-component-library';
import { LabelDescriptionList } from 'ams-common';
import getContent, { combineContent } from 'src/utils/contentUtils';

import { CorrespondenceContentKeys } from '../../constants';
import { getConfirmModalDescriptionList } from '../helpers';
import { NoBullet, StyledMessageSubject } from '../styles';
import { CorrespondenceDocument } from '../types';

interface ConfirmModalProps {
  open: boolean;
  close: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isCustomer: boolean;
  entityNames: string[];
  recipientNames: string[];
  dueDate: string;
  messageSubject: string;
  message: string;
  initiatedBy?: string;
  documents?: CorrespondenceDocument[];
  handleDownloadDocument: (documentId: string, documentName: string) => void;
  hasAuditorOrCustomerResponse: boolean;
  contentKey: CorrespondenceContentKeys | undefined;
}

export const Confirm = ({
  open,
  close,
  handleSubmit,
  isCustomer = false,
  entityNames,
  recipientNames,
  dueDate,
  messageSubject,
  message,
  documents,
  initiatedBy,
  handleDownloadDocument,
  hasAuditorOrCustomerResponse,
  contentKey,
}: ConfirmModalProps) => {
  if (!open) {
    return null;
  }

  const userType = isCustomer ? 'customer' : 'auditor';
  const modalTitle = getContent(
    `correspondence.correspondence.${userType}.${contentKey}.${
      hasAuditorOrCustomerResponse ? 'reply' : 'response'
    }.confirm.modal.title` as keyof typeof combineContent,
  );

  return (
    <Modal
      title={modalTitle}
      buttons={[
        {
          text: 'Confirm and send',
          onClick: handleSubmit,
        },
        {
          text: 'Cancel',
          onClick: close,
        },
      ]}
      description={getContent(
        `correspondence.correspondence.${userType}.confirm.modal.description`,
      )}
    >
      {!isCustomer ? (
        <LabelDescriptionList
          hasMarginTop
          list={getConfirmModalDescriptionList({
            entityNames: entityNames.join(', '),
            recipientNames: recipientNames.join(', '),
            dueDate,
          })}
        />
      ) : null}

      {!isCustomer ? (
        <>
          <Heading level={6}>
            {getContent(
              'correspondence.correspondence.auditor.confirm.modal.messageSubject.label',
            )}
          </Heading>
          <StyledMessageSubject>{messageSubject}</StyledMessageSubject>
        </>
      ) : null}
      {isCustomer ? (
        <>
          <Heading level={6}>
            {getContent(
              'correspondence.correspondence.customer.confirm.modal.recipients.label',
            )}
          </Heading>
          <p>{initiatedBy}</p>
        </>
      ) : null}

      {isCustomer ? (
        <>
          <Heading level={6}>
            {getContent(
              'correspondence.correspondence.auditor.confirm.modal.messageSubject.label',
            )}
          </Heading>
          <StyledMessageSubject>{messageSubject}</StyledMessageSubject>
        </>
      ) : null}

      <Heading level={6}>
        {getContent(
          'correspondence.correspondence.auditor.confirm.modal.message.label',
        )}
      </Heading>
      <p>{message}</p>
      {documents && documents.length ? (
        <>
          <Heading level={6}>
            {getContent(
              `correspondence.correspondence.${userType}.confirm.modal.documents.label`,
            )}
          </Heading>
          <NoBullet>
            {documents.map(({ documentName, documentId }) => (
              <li key={documentId}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownloadDocument(documentId, documentName);
                  }}
                >
                  {documentName}
                </a>
              </li>
            ))}
          </NoBullet>
        </>
      ) : null}
    </Modal>
  );
};
