import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  ComponentLoader,
  FormCheckbox,
  FormDate,
  FormGroup,
  Heading,
  InPageAlert,
  Row,
  Textarea,
} from '@snsw/react-component-library';
import { ErrorSummary, formatDate } from 'ams-common';
import { useFormik } from 'formik';
import _ from 'lodash';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';
import * as Yup from 'yup';

import { filterNonNullProps } from './hepers';
import { useCreateUpdateOOO, useOutOfOfficeDetails } from './hooks';
import { ButtonGroup, FormDateGroup } from './styles';
import { OutOfOfficeDetailsInitialValues, RequestType } from './types';

export const OutOfOfficeDetailsForm = () => {
  const startDateRef = useRef<any>(null);
  const endDateRef = useRef<any>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [messageCharCount, setMessageCharCount] = useState(0);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const initialValues: OutOfOfficeDetailsInitialValues = {
    id: '',
    active: false,
    startDate: '',
    endDate: '',
    message: '',
  };
  const { isLoading: isOutOfOfficeDetailsLoading, OutOfOfficeDetails } =
    useOutOfOfficeDetails();
  const onSuccess = () => {
    setShowSuccessAlert(true);
  };
  const onError = () => {
    setShowErrorAlert(true);
  };
  const { mutate: createUpdateOOO, isLoading: isCreateUpdateOOOLoading } =
    useCreateUpdateOOO(onSuccess, onError);

  const OOOSchema = {
    startDate: Yup.date()
      .typeError('Invalid due date')
      .required('Start date is required')
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'Start date cannot be past date',
      ),
    endDate: Yup.date()
      .typeError('Invalid due date')
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'End date cannot be past date',
      )
      .min(Yup.ref('startDate'), 'End date cannot be before start date')
      .required('End date is required'),
    message: Yup.string()
      .required('Out of office message cannot be left blank')
      .max(300, 'Out of office message cannot exceed 300 characters'),
  };

  const generateValidationSchema = (isActive: boolean) => {
    const schema: any = isActive ? OOOSchema : null;
    return Yup.object().shape(schema);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    errors,
    submitCount,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () => generateValidationSchema(Boolean(values.active)),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async () => {
      const condition = values.active ? isValid && values.active : true;
      const isformDirty = !_.isEqual(values, {
        ...OutOfOfficeDetails,
        startDate: formatDate(OutOfOfficeDetails?.startDate, 'YYYY-MM-DD'),
        endDate: formatDate(OutOfOfficeDetails?.endDate, 'YYYY-MM-DD'),
      });
      if (isformDirty) {
        setIsSubmitted(true);

        if (condition) {
          const payloadValues = filterNonNullProps({
            ...values,
            startDate: formatDate(values.startDate, 'YYYY-MM-DDTHH:mm:ss'),
            endDate: formatDate(values.endDate, 'YYYY-MM-DDTHH:mm:ss'),
          });

          if (
            OutOfOfficeDetails &&
            Object.keys(filterNonNullProps(OutOfOfficeDetails)).length > 0
          ) {
            if (payloadValues.active) {
              await createUpdateOOO([payloadValues, RequestType.Update]);
            } else {
              await createUpdateOOO([
                { ...OutOfOfficeDetails, ...{ active: payloadValues.active } },
                RequestType.Update,
              ]);
            }
          } else {
            await createUpdateOOO([payloadValues, RequestType.Add]);
          }
        }
      }
    },
  });

  useEffect(() => {
    if (!OutOfOfficeDetails || isOutOfOfficeDetailsLoading) {
      return;
    }

    setValues({
      ...OutOfOfficeDetails,
      ...{
        startDate: formatDate(OutOfOfficeDetails.startDate, 'YYYY-MM-DD'),
        endDate: formatDate(OutOfOfficeDetails.endDate, 'YYYY-MM-DD'),
      },
    });

    const length = OutOfOfficeDetails.message
      ? OutOfOfficeDetails.message.length
      : 0;
    setMessageCharCount(length);
  }, [OutOfOfficeDetails, isOutOfOfficeDetailsLoading, setValues]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    setIsSubmitted(false);
    handleSubmit();
  };

  const handleFormCheckboxChange = (value: boolean) => {
    setValues({
      ...values,
      active: value === false,
    });
    if (value !== false) {
      const { id } = values;
      setValues({
        ...initialValues,
        id,
      });
      startDateRef.current.clear();
      endDateRef.current.clear();
    }
  };

  const handleStartDateChange = ({ value }: { value: string }) => {
    setValues({
      ...values,
      ...{ startDate: value },
    });
  };

  const handleEndDateChange = ({ value }: { value: string }) => {
    setValues({
      ...values,
      ...{ endDate: value },
    });
  };

  const handleMessageCountChange = (e) => {
    setMessageCharCount(e.target.value.length);
  };

  return (
    <>
      <ComponentLoader
        active={isOutOfOfficeDetailsLoading || isCreateUpdateOOOLoading}
        fullPage
      />

      <Row>
        <Col span={8}>
          <Heading level={3}>
            {getContent('myprofile.second.subheading')}
          </Heading>
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          {!isSubmitting && isSubmitted && showSuccessAlert ? (
            <InPageAlert
              variant="success"
              title={getContent('myprofile.onsubmit.alert.success')}
            />
          ) : (
            <div />
          )}
          {showErrorAlert ? (
            <InPageAlert
              variant="error"
              title={getContent('myprofile.onsubmit.alert.error')}
            />
          ) : (
            <div />
          )}
        </Col>
      </Row>
      <form id="outOfOfficeForm">
        <ErrorSummary errors={errors} />
        <Row>
          <Col span={8}>
            <FormCheckbox
              id="active"
              name="active"
              label={
                values.active
                  ? 'Uncheck and save to turn off out of office'
                  : 'Check to turn on out of office'
              }
              value={values.active || false}
              onChange={handleFormCheckboxChange}
              checked={values.active}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <FormDateGroup
              id="startDate"
              label="Start date"
              hasError={!!(submitCount > 0 && errors.startDate)}
              errorMessage={errors.startDate}
            >
              <FormDate
                name="startDate"
                value={values.startDate}
                onChange={handleStartDateChange}
                disabled={!values.active}
                ref={startDateRef}
              />
            </FormDateGroup>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <FormDateGroup
              label="End date"
              id="endDate"
              hasError={!!(submitCount > 0 && errors.endDate)}
              errorMessage={errors.endDate}
            >
              <FormDate
                name="endDate"
                id="endDate"
                label="End date"
                value={values.endDate}
                onChange={handleEndDateChange}
                disabled={!values.active}
                ref={endDateRef}
              />
            </FormDateGroup>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <FormGroup
              id="message"
              label="Message"
              hasError={submitCount > 0 && errors.message}
              errorMessage={errors.message}
              helpMessage={
                values.active && (
                  <span>
                    {getContentWithReplacements(
                      'myprofile.outOfOffice.message.helpMessage',
                      {
                        remMessageCharCount: (
                          300 - messageCharCount
                        ).toString(),
                      },
                    )}
                  </span>
                )
              }
            >
              <Textarea
                name="message"
                value={values.message}
                onChange={(e) => {
                  handleMessageCountChange(e);
                  handleChange(e);
                }}
                disabled={!values.active}
                placeholder={values.active && ' Please enter a message'}
                maxLength={300}
              />
            </FormGroup>
          </Col>
        </Row>

        <ButtonGroup>
          <Button
            type="submit"
            onClick={handleFormSubmit}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </ButtonGroup>
      </form>
    </>
  );
};
