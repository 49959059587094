import { colours, fontSizes, spacing } from 'ams-common';
import styled from 'styled-components';

export const NoStyledButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  cursor: pointer;
`;

export const NoStyledButtonWithTitle = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 100%;
`;

export const Popup = styled.div<{
  left: number;
  top: number;
}>`

  position: absolute;
  left: ${({ left }) => `${left}px;`}
  top: ${({ top }) => `${top}px;`}
  background-color: #fff;
  border: 1px solid ${colours.borders.button};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-flow: column;

  > button{
    text-align: right;
    margin: ${spacing.xs}
  }

`;
export const PopupWithTitle = styled.div<{
  left: number;
  top: number;
}>`

  position: absolute;
  left: ${({ left }) => `${left}px;`}
  top: ${({ top }) => `${top}px;`}
  background-color: ${colours.backgrounds.info};
  border: 1px solid ${colours.borders.button};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  flex-flow: column wrap;
  text-align: right;
  padding: 12px;    

  > button{
    
    margin: ${spacing.xs};
     background-color: ${colours.backgrounds.info};
  }  
`;

export const StyledActionPopup = styled.div`
  position: absolute;
  bottom: left;
  display: flex;
  flex-flow: column;
  background-color: ${colours.backgrounds.default};
`;

export const Actions = styled.div`
  display: flex;
  flex-flow: column;

  > a {
    text-decoration: none;
    font-weight: normal;
  }

  > *:hover,
  > *:active {
    background-color: ${colours.backgrounds.info};
  }
`;

export const ActionRowLink = styled.a`
  padding: ${spacing.xs};
  border-bottom: 1px solid ${colours.borders.button};
  font-size: ${fontSizes.xs};
  cursor: pointer;
  color: ${colours.text.body};
  text-decoration: none;
`;

export const ActionRowButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  cursor: pointer;
  text-align: left;
  padding: ${spacing.xs};
  border-bottom: 1px solid ${colours.borders.button};
  font-size: ${fontSizes.xs};
`;
