import { ReactNode } from 'react';
import * as t from 'io-ts';

import { HistoryLogFieldNames } from './historyLog/constants';

export const UpdateStatusCodec = t.type({
  matterStatus: t.string,
  comment: t.string,
});

export type UpdateStatusValues = t.TypeOf<typeof UpdateStatusCodec>;

export interface StatusOption {
  text: string;
  value: string;
}

const HistoryLogCodec = t.type({
  [HistoryLogFieldNames.id]: t.number,
  [HistoryLogFieldNames.originalStatus]: t.string,
  [HistoryLogFieldNames.revisedStatus]: t.string,
  [HistoryLogFieldNames.comment]: t.string,
  [HistoryLogFieldNames.modifiedDate]: t.string,
  [HistoryLogFieldNames.modifiedBy]: t.string,
});
export const HistoryLogsCodec = t.array(HistoryLogCodec);

export type HistoryLogValues = t.TypeOf<typeof HistoryLogsCodec>;
export type HistoryLogValue = t.TypeOf<typeof HistoryLogCodec>;

export type ColumnType = {
  header?: string;
  dataIndex?: string;
  render?: ({
    columnData,
    rowData,
    key,
    matterId,
  }: {
    columnData?: string | number | null;
    rowData: { [key: string]: any };
    key: string;
    matterId?: string;
  }) => ReactNode | string | number;
};
