import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from '@snsw/react-component-library';
import { LabelDescriptionList } from 'ams-common';
import { DocumentsSection } from 'src/components/DocumentsSection';
import { useMatter } from 'src/context/MatterContext';
import { MessageDetail, MessageDetails } from 'src/hooks/types';
import { PATHS } from 'src/routes/constants';

import { formatDocuments, getMessageDetailList } from './helpers';
import {
  ButtonContainer,
  DocumentLinksCard,
  StyledListContainer,
} from './styles';

export const MessageList = ({
  data,
  isReadOnly,
  matterId,
  messageId,
  isCustomer,
  setShowArchiveToTrimModal,
}: {
  data: MessageDetails;
  isReadOnly?: boolean;
  matterId?: string | null;
  messageId?: string;
  isCustomer?: boolean;
  setShowArchiveToTrimModal?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { isMatterClosed } = useMatter();
  return (
    <Row>
      <Col span={12}>
        {data?.map((data: MessageDetail, index: number) => {
          const formattedDocuments = formatDocuments(data.documentIds);
          return (
            <StyledListContainer key={data.id}>
              <LabelDescriptionList
                list={getMessageDetailList(data)}
                hasMarginTop
                hasMarginBottom
              />
              <h4>Message</h4>
              <p>{data.messageBody}</p>
              <br />
              {formattedDocuments?.length ? (
                <DocumentLinksCard>
                  <h4>Documents</h4>
                  <DocumentsSection documents={formattedDocuments} />
                </DocumentLinksCard>
              ) : null}
              {index === 0 && !isReadOnly ? (
                <ButtonContainer className="hide-in-pdf">
                  <Button variant="secondary" onClick={() => navigate(-1)}>
                    Back
                  </Button>
                  {!isCustomer ? (
                    <Button
                      variant="secondary"
                      onClick={() => setShowArchiveToTrimModal?.(true)}
                    >
                      Upload to TRIM
                    </Button>
                  ) : null}
                  <Button
                    variant="primary"
                    disabled={isMatterClosed}
                    onClick={() =>
                      navigate(
                        `${PATHS.matters}/${matterId}${PATHS.messages}/${messageId}${PATHS.messageReply}`,
                      )
                    }
                  >
                    Reply
                  </Button>
                </ButtonContainer>
              ) : null}
            </StyledListContainer>
          );
        })}
      </Col>
    </Row>
  );
};
