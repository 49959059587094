export enum CorrespondenceFieldNames {
  CorrespondenceId = 'id',
  ContactEmail = 'contactEmail',
  ThreadId = 'threadId',
  ThreadStatus = 'threadStatus',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Id = 'id',
  CorrespondenceType = 'corroType',
  CorroStatus = 'corroStatus',
  DueDate = 'dueDate',
  InitiatedDate = 'initiatedDate',
  InitiatedBy = 'initiatedBy',
  Subject = 'subject',
  PortalContacts = 'portalContacts',
  LastActionMessage = 'lastActionMessage',
  ClientId = 'clientId',
  ClientName = 'clientName',
  ContactName = 'contactName',
  ResponseIndicator = 'response',
  MessageBody = 'messageBody',
  Documents = 'documents',
  ReplyFrom = 'replyFrom',
  SentBy = 'sentBy',
  DraftCorrespondenceId = 'draftCorrespondenceId',
  ContactId = 'contactId',
  IsDraftExists = 'isDraftExists',
  TrimRecordNumber = 'trimRecordNumber',
  Auditor = 'auditor',
  portalContactIds = 'portalContactIds',
  PortalContactId = 'portalContactId',
  LastSubject = 'lastSubject',
  SentDate = 'sentDate',
}
