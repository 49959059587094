import * as t from 'io-ts';
import { tOptional } from 'src/types';

export const NewMatterMessageDataCodec = t.type({
  matterId: t.string,
  leadClientName: t.string,
});

export const NewCorrespondenceCodec = t.type({
  correspondenceId: t.string,
  correspondenceThreadId: tOptional(t.string),
  corroType: t.string,
});

export const ContactRegistrationCodec = t.type({
  matterId: t.string,
  contactEmail: t.string,
});

export const CustomerOnboardingEmailSentCodec = t.type({
  matterId: t.string,
  contactEmail: t.string,
  contactName: t.string,
  clientId: t.number,
  clientName: t.string,
});

export const CustomerCorrespondenceReadReceiptCodec = t.type({
  contactName: t.string,
  clientName: t.string,
  corroType: t.string,
  correspondenceId: t.string,
  portalMatterId: t.string,
  correspondenceThreadId: tOptional(t.string),
});

export const CustomerDocumentReadReceiptCodec = t.type({
  contactName: t.string,
  documentName: t.string,
  correspondenceId: t.string,
  clientName: t.string,
  correspondenceThreadId: tOptional(t.string),
});

export const CustomerCorrespondenceResponseReceivedCodec = t.type({
  matterId: t.string,
  leadClientName: t.string,
  portalMatterId: t.string,
  correspondenceId: t.string,
  corroType: t.string,
  correspondenceThreadId: t.string,
});

export const AuditorReplyToCorrespondenceCodec = t.type({
  correspondenceId: t.string,
  corroType: t.string,
  correspondenceThreadId: t.string,
});

export const CloseCorrespondenceCodec = t.type({
  correspondenceId: t.string,
  corroType: t.string,
  portalMatterId: t.string,
  correspondenceThreadId: tOptional(t.string),
});

export const NewMessageCodec = t.type({
  messageId: t.string,
  contactName: t.string,
  matterId: t.string,
});

export const CustomerToAuditorMessageReceivedCodec = t.type({
  contactName: t.string,
  threadId: t.string,
  matterId: t.string,
});

export const AuditorToCustomerMessageReceivedCodec = t.type({
  auditorName: t.string,
  threadId: t.string,
});

export const CustomerToCustomerMessageReceivedCodec = t.type({
  contactName: t.string,
  clientName: t.string,
  threadId: t.string,
});

export const AuditorAuditMatterTransferredCodec = t.type({
  matterId: t.string,
  leadClientName: t.string,
  auditorName: t.string,
});

export const AuditMatterTerminatedCodec = t.type({
  matterId: t.string,
  leadClientName: t.string,
  portalMatterId: t.string,
});

export const CustomerAuditMatterTransferredCodec = t.type({
  portalMatterId: t.string,
});

export const AlertCodec = t.type({
  id: t.string,
  messageData: t.union([
    NewMatterMessageDataCodec,
    ContactRegistrationCodec,
    CustomerOnboardingEmailSentCodec,
    CustomerCorrespondenceReadReceiptCodec,
    CustomerDocumentReadReceiptCodec,
    NewCorrespondenceCodec,
    CustomerCorrespondenceResponseReceivedCodec,
    AuditorReplyToCorrespondenceCodec,
    NewMessageCodec,
    CustomerToAuditorMessageReceivedCodec,
    AuditorToCustomerMessageReceivedCodec,
    CustomerToCustomerMessageReceivedCodec,
    AuditorAuditMatterTransferredCodec,
    AuditMatterTerminatedCodec,
    CustomerAuditMatterTransferredCodec,
  ]),
  createdDate: t.string,
  portalMatterId: t.string,
  createdBy: t.string,
  read: t.boolean,
  alertType: t.string,
});

export type NewMatterMessageData = t.TypeOf<typeof NewMatterMessageDataCodec>;
export type NewCorrespondenceData = t.TypeOf<typeof NewCorrespondenceCodec>;
export type ContactRegistration = t.TypeOf<typeof ContactRegistrationCodec>;
export type CustomerOnboardingEmailSentData = t.TypeOf<
  typeof CustomerOnboardingEmailSentCodec
>;
export type CustomerCorrespondenceReadReceiptData = t.TypeOf<
  typeof CustomerCorrespondenceReadReceiptCodec
>;
export type CustomerDocumentReadReceiptData = t.TypeOf<
  typeof CustomerDocumentReadReceiptCodec
>;
export type CustomerCorrespondenceResponseReceivedData = t.TypeOf<
  typeof CustomerCorrespondenceResponseReceivedCodec
>;
export type AuditorReplyToCorrespondenceData = t.TypeOf<
  typeof AuditorReplyToCorrespondenceCodec
>;
export type CloseCorrespondenceData = t.TypeOf<
  typeof AuditorReplyToCorrespondenceCodec
>;
export type NewMessageData = t.TypeOf<typeof NewMessageCodec>;
export type CustomerToAuditorMessageReceivedData = t.TypeOf<
  typeof CustomerToAuditorMessageReceivedCodec
>;
export type AuditorToCustomerMessageReceivedData = t.TypeOf<
  typeof AuditorToCustomerMessageReceivedCodec
>;
export type CustomerToCustomerMessageReceivedData = t.TypeOf<
  typeof CustomerToCustomerMessageReceivedCodec
>;
export type AuditorAuditMatterTransferredData = t.TypeOf<
  typeof AuditorAuditMatterTransferredCodec
>;
export type AuditMatterTerminatedData = t.TypeOf<
  typeof AuditMatterTerminatedCodec
>;
export type CustomerAuditMatterTransferredData = t.TypeOf<
  typeof CustomerAuditMatterTransferredCodec
>;

export type MatterNotificationContent = t.TypeOf<typeof AlertCodec>;
export const MatterNotificationsCodec = t.type({
  content: t.array(AlertCodec),
});

export type MatterNotifications = t.TypeOf<typeof MatterNotificationsCodec>;
