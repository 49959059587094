const content = {
  'contact.heading.text': 'Initiate customer onboarding',
  'contact.button.text': 'Initiate customer onboarding',
  'contact.table.empty.text':
    'Please initiate customer onboarding to add a new contact',
  'contact.onboarding.status.tooltip.pending.text':
    'An email with onboarding information has been sent to the customer and is awaiting their completed onboarding',
  'contact.onboarding.status.tooltip.completed.text':
    'Customer contact has completed the registration',
  'contact.onboarding.status.completed.text': 'Completed',
  'contact.notice.success.title': 'Initiated customer onboarding successfully',
  'contact.notice.success.text':
    'Onboarding has been initiated successfully for {email} under {customerName}',
  'contact.auditor.noActiveCorrespondenceModal.title':
    'No active correspondence ID',
  'contact.auditor.noActiveCorrespondenceModal.body':
    'There is no active correspondence ID for {entityName}. Please generate a new correspondence ID in MARS before initiating the onboarding.',
  'contact.confirm.modal.title': 'Confirm initiation of customer onboarding',
  'contact.confirm.modal.text':
    'Confirm you want to initiate onboarding for the following customer contacts:',
  'contact.confirm.modal.text.footer':
    'Onboarding request email will be sent to the above contact(s).',
  'contact.manager.tooltip.text':
    'Select the manager of the team that this audit matter belongs to. This will be displayed as an escalation contact for the customer.',
  'contact.auditor.list.label.name': 'Name',
  'contact.auditor.list.label.position': 'Position',
  'contact.auditor.list.label.phone': 'Phone',
  'contact.auditor.list.label.email': 'Email',
  'contact.customer.form.heading.text': 'Add new contact',
  'contact.customer.form.help.message.phone':
    'Enter the number in +ISD Code followed by your contact number. E.g +61444412345',
  'contact.customer.notice.add.contact': 'New contact added',
  'contact.customer.notice.remove.contact': 'Contact removed',
  'contact.customer.manager.error.modal.text':
    ' Please select the manager for this audit matter before initiating the customer onboarding',
  'contact.customer.manager.error.modal.title': 'Manager selection required',
  'contact.customer.duplicateEmailModal.body':
    'Duplicate e-mail addresses found for the contacts {contactName1} and {contactName2}. Please review and correct the e-mail addresses in MARS before initiating the onboarding.',
  'contact.auditor.remove.contact.title': 'Confirm remove contact',
  'contact.auditor.remove.contact.description':
    'Once {contactName} from {customerName}, the user will not be able to access the compliance portal. Are you sure you want to remove this contact?',
};

export default content;
