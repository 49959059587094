import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

type CustomerAuthSuccessModalTypes = {
  open: boolean;
  close: () => void;
  onContinue: (success: boolean) => void;
};
const CustomerAuthSuccessModal = ({
  open,
  close,
  onContinue,
}: CustomerAuthSuccessModalTypes) => {
  if (!open) {
    return null;
  }

  const handleContinue = () => {
    onContinue(true);
    close();
  };

  return (
    <Modal
      id="customer-auth-success"
      title={getContent('customerAuthForm.modal.success.title.text')}
      buttons={[
        {
          text: 'Continue',
          onClick: handleContinue,
        },
        {
          text: 'Cancel',
          onClick: close,
        },
      ]}
      description={getContent(
        'customerAuthForm.modal.success.description.text',
      )}
    />
  );
};

export default CustomerAuthSuccessModal;
