import { Heading, Input } from '@snsw/react-component-library';
import { AccordionItem } from '@snsw/react-component-library/build/Components';
import { useClients } from 'src/components/clients/hooks';
import { useMatter } from 'src/context/MatterContext';
import { useDownloadDocument } from 'src/hooks';
import { HandleEditDocument } from 'src/types/documentTypes';
import { downloadSelectedDocument } from 'src/utils/documentHelper';

import { DocumentTable } from './documentTable';
import { groupDataByClients } from './helpers';
import { StyledAccordion } from './styles';
import {
  APPLICABLE_TO_ALL_CLIENTS,
  APPLICABLE_TO_ALL_CLIENTS_TEXT,
  DocumentInitialValues,
  MatterDocuments,
} from './types';
import { totalAccordionDocuments } from './utils';

export const DocumentTableWithAccordion = ({
  handleDocumentEdit,
  matterDocuments,
  handleDownloadError,
  handleDeleteError,
}: {
  handleDocumentEdit: (args: HandleEditDocument) => void;
  matterDocuments?: MatterDocuments;
  handleDownloadError?: () => void;
  handleDeleteError?: () => void;
}) => {
  const { matterUId } = useMatter();
  const { clientOptions } = useClients(matterUId);
  const { downloadDocumentUrl, downloadDocument } = useDownloadDocument();

  const documents: DocumentInitialValues | undefined = groupDataByClients(
    matterDocuments,
    clientOptions,
  );

  const updatedClientOptions = clientOptions?.reduce(
    (acc, clientOption) => {
      return {
        ...acc,
        [clientOption.value]: clientOption.text,
      };
    },
    {} as Record<string, string>,
  );

  const handleClick = async (documentId: string, documentName: string) => {
    try {
      if (matterUId) {
        const { url: downloadUrl } = await downloadDocumentUrl(
          false,
          matterUId,
          documentId,
        );
        const response = await downloadDocument(downloadUrl, documentName);
        downloadSelectedDocument(response, documentName);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      } else if (typeof error === 'string') {
        console.error('Custom error message:', error);
      } else {
        console.error('Unknown error type');
      }
      handleDownloadError?.();
    }
  };

  const renderAccordionItems = (
    accordions: DocumentInitialValues | undefined,
  ) => {
    if (!accordions) {
      return null;
    }
    return Object.keys(accordions).map((key) => {
      const documentsList = { [key]: accordions[key] };
      return (
        <AccordionItem
          key={key}
          title={
            // eslint-disable-next-line no-nested-ternary
            key !== APPLICABLE_TO_ALL_CLIENTS
              ? updatedClientOptions
                ? updatedClientOptions[key]
                : key
              : APPLICABLE_TO_ALL_CLIENTS_TEXT
          }
          expanded
        >
          <DocumentTable
            key={key}
            dataField={key}
            documents={documentsList}
            clientOptions={updatedClientOptions}
            handleDownloadDocument={handleClick}
            handleDocumentEdit={handleDocumentEdit}
            handleDeleteError={handleDeleteError}
          />
        </AccordionItem>
      );
    });
  };

  const { [APPLICABLE_TO_ALL_CLIENTS]: applicableToAllClients, ...rest } =
    documents || {};

  const accordionItems =
    applicableToAllClients && applicableToAllClients.length > 0
      ? [{ [APPLICABLE_TO_ALL_CLIENTS]: applicableToAllClients }, rest]
      : [rest];

  return documents ? (
    <StyledAccordion id="document-accordions" name="documents">
      <p>{totalAccordionDocuments(documents)} documents</p>
      {accordionItems.map((item) => renderAccordionItems(item))}
      <Input
        name="selectedDocuments"
        style={{ position: 'absolute', left: '-9999px' }}
      />
    </StyledAccordion>
  ) : (
    <Heading level={5}>No data available</Heading>
  );
};
