import { formatDate, ListItemType } from 'ams-common';
import { Clients } from 'src/components/clients/types';
import {
  CorrespondenceDetails,
  DocumentDetails,
  PortalContact,
  PortalContactSummary,
  RecipientData,
  RecipientsData,
} from 'src/hooks/types';
import getContent from 'src/utils/contentUtils';

import {
  CorrespondenceDocument,
  CorrespondenceInitValues,
  ModalType,
} from './types';

export const getRecipientOptions = (
  recipientData: RecipientsData | null | undefined,
) => {
  if (!recipientData) {
    return null;
  }

  return recipientData.map((recipient) => ({
    text: recipient.contactName,
    value: recipient.id,
  }));
};

export const buildPortalContacts = (recipient: string) => {
  if (!recipient) {
    return [];
  }
  return recipient.split(',').map((id) => ({ id }));
};

export const buildDocuments = (documents?: CorrespondenceDocument[]) => {
  if (!documents) {
    return [];
  }
  return documents.map(({ documentId }) => {
    return { id: documentId };
  });
};

export const getRequestBody = (
  correspondenceValues: CorrespondenceInitValues,
  corroType: string,
  modalType: ModalType,
  isCustomer: boolean,
) => {
  const { dueDate, messageSubject, message, recipient, documents } =
    correspondenceValues;

  const portalContacts = buildPortalContacts(recipient);
  const selectedDocuments = buildDocuments(documents);

  // TODO: Discuss with BE
  /*
  return {
    ...(dueDate && { dueDate }),
    ...(messageSubject && { subject: messageSubject }),
    ...(message && { messageBody: message }),
    status: modalType,
    corroType:
      (CORRESPONDENCE_TYPES[corroType] as string) || 'REQUEST FOR INFORMATION',
    ...(portalContacts.length > 0 && { portalContacts }),
    ...(selectedDocuments.length > 0 && { documents: selectedDocuments }),
  };
  */

  return {
    dueDate,
    subject: messageSubject,
    messageBody: message,
    corroType: corroType || 'REQUEST FOR INFORMATION',
    ...(!isCustomer && { portalContacts }),
    documents: selectedDocuments,
    status: modalType,
  };
};

const getEntitiesFromResponse = (portalContacts?: PortalContact[] | null) => {
  return [...new Set(portalContacts?.map(({ clientId }) => clientId))];
};

const getRecipientsFromResponse = (portalContacts?: PortalContact[] | null) => {
  if (!portalContacts) {
    return [];
  }
  return [...new Set(portalContacts?.map(({ id }) => id))];
};

export const getUploadedDocumentsDetailsFromResponse = (
  documents: DocumentDetails[],
) => {
  if (!documents) {
    return [];
  }
  return documents.map(({ id, name, contentLength }) => ({
    documentId: id,
    documentName: name,
    documentSize: `${contentLength}`,
  }));
};

export const getInitValuesFromResponse = (
  data: CorrespondenceDetails,
  isCustomer: boolean,
) => {
  const { portalContacts, dueDate, subject, messageBody, initiatedBy } = data;
  const entitiesFromResponse =
    getEntitiesFromResponse(portalContacts).join(',');
  const recipientsFromResponse =
    getRecipientsFromResponse(portalContacts).join(',');

  return {
    entities: entitiesFromResponse,
    recipient: !isCustomer ? recipientsFromResponse : initiatedBy,
    dueDate: dueDate || '',
    messageSubject: subject || '',
    message: messageBody || '',
  };
};

export const getConfirmModalDescriptionList = ({
  entityNames,
  recipientNames,
  dueDate,
}: {
  entityNames: string;
  recipientNames: string;
  dueDate: string;
}): ListItemType[] => [
  {
    label: getContent(
      'correspondence.correspondence.customer.confirm.modal.entityNames.label',
    ),
    description: entityNames,
  },
  {
    label: getContent(
      'correspondence.correspondence.customer.confirm.modal.recipientNames.label',
    ),
    description: recipientNames,
  },
  {
    label: getContent(
      'correspondence.correspondence.customer.confirm.modal.dueDate.label',
    ),
    description: formatDate(dueDate),
  },
];

export const getEntityNames = (
  values: CorrespondenceInitValues,
  clients?: Clients | null,
) => {
  if (!clients) {
    return [];
  }

  const { entities } = values;
  const entityIds = entities.replace(/^,/, '').split(',');
  return clients
    .filter((client) => entityIds.includes(`${client.id}`))
    .map(({ name }) => name);
};

export const getRecipientNames = (
  values: CorrespondenceInitValues,
  recipientData?: RecipientData[] | null,
) => {
  if (!recipientData) {
    return [];
  }
  const { recipient } = values;
  const recipients = recipient.replace(/^,/, '').split(',');
  return recipientData
    .filter(({ id }) => recipients.includes(id))
    .map(({ contactName }) => contactName);
};

export const getRecipientsIdsFromResponse = (
  portalContacts?: string[] | null,
) => {
  if (!portalContacts) {
    return null;
  }
  return Array.from(new Set(portalContacts.map((id) => id))).join(',');
};

export const getClientIdsFromResponse = (
  portalContacts?: PortalContactSummary[] | null,
) => {
  if (!portalContacts) {
    return '';
  }
  return Array.from(
    new Set(portalContacts.map(({ clientId }) => clientId)),
  ).join(',');
};
