import { useField, useFormikContext } from 'formik';
import { SubmitAuditCommencementDocumentsInitValues } from 'src/screens/common/matters/tabs/common/matterCorrespondence/auditor/submitAuditCommencementDocuments/types';

import { StyledFormCheckbox } from './styles';
import { DocumentCheckboxProps } from './type';

export const DocumentCheckbox = ({
  label,
  name,
  type,
  id,
  isDisabled,
}: DocumentCheckboxProps) => {
  const [field] = useField(name);
  const { setFieldValue, values } =
    useFormikContext<SubmitAuditCommencementDocumentsInitValues>();

  const documents = values.selectedDocuments;

  const handleChange = (value: boolean) => {
    let selectedDocuments: string[] = [];
    if (value === false) {
      if (!documents.includes(id)) {
        selectedDocuments = [...documents, id];
      }
    } else {
      selectedDocuments = documents.filter((document) => document !== id);
    }

    setFieldValue('selectedDocuments', selectedDocuments);
  };

  return (
    <StyledFormCheckbox
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...field}
      type={type}
      label={label}
      value={values.selectedDocuments.includes(id)}
      onChange={handleChange}
      checked={values.selectedDocuments.includes(id) || false}
      disabled={isDisabled}
    />
  );
};
