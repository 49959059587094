import { ActionItemsList } from '../types';

export const totalActionItems = (actionItems: {
  [key: string]: ActionItemsList;
}) =>
  Object.values(actionItems).reduce(
    (sum, actionItemsList) => sum + actionItemsList.length,
    0,
  );

export const getAllActionItems = (actionItems: {
  [key: string]: ActionItemsList;
}) =>
  Object.values(actionItems).reduce(
    (allActionItems, actionItemsList) => allActionItems.concat(actionItemsList),
    [],
  );
