import submitAuditCommencementDocumentsContents from './auditor/submitAuditCommencementDocuments/contents';
import correspondenceContents from './correspondence/contents';
import submitResponseToAuditCommencementDocumentsContents from './customer/submitResponseToAuditCommencementDocuments/contents';

const contents = {
  'correspondence.label.type': 'Correspondence type',
  'correspondence.label.subject': 'Subject',
  'correspondence.label.entityName': 'Entity name(s)',
  'correspondence.label.initiatedBy': 'Initiated by',
  'correspondence.label.initiatedDate': 'Initiated date',
  'correspondence.label.status': 'Status',
  'correspondence.label.dueDate': 'Due date',
  'correspondence.label.lastAction': 'Last action',
  'correspondence.auditCommencement.title': 'Audit commencement documents',
  'correspondence.details.label.sentOn': 'Sent on',
  'correspondence.details.label.sentBy': 'Sent by',
  'correspondence.details.label.entityName': 'Entity name(s)',
  'correspondence.details.label.recipients': 'Recipient(s)',
  'correspondence.details.label.recipient': 'Recipient',
  'correspondence.details.label.dueDate': 'Due date',
  'correspondence.details.label.subject': 'Subject',
  'correspondence.details.label.message': 'Message',
  'correspondence.details.button.respond': 'Respond to correspondence',
  'correspondence.details.button.reply': 'Reply',
  'correspondence.details.button.resumeDraftResponse': 'Resume draft response',
  'correspondence.details.button.back': 'Back',
  'correspondence.details.button.cancel': 'Cancel',
  'correspondence.close.modal.title': 'Close correspondence',
  'correspondence.close.modal.description':
    'Please confirm that all actions associated with this correspondence are completed as no further responses can be made once it is closed.',
  'correspondence.close.success.alert':
    'Correspondence {corroType} has been closed.',
  'correspondence.close.status.label.closed': 'Correspondence closed',
  'matter.correspondence.auditCommencementDocuments.heading':
    'Audit commencement documents',
  'matter.correspondence.requestForInformation.heading':
    'Request for information',
  'matter.correspondence.preliminaryFindings.heading': 'Preliminary findings',
  'matter.correspondence.finalisationAdvice.heading': 'Finalisation advice',
  ...submitResponseToAuditCommencementDocumentsContents,
  ...submitAuditCommencementDocumentsContents,
  ...correspondenceContents,
};

export default contents;
