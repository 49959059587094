import { useState } from 'react';
import { FormDate, Modal } from '@snsw/react-component-library';
import { ErrorSummary, formatDate, Tooltip } from 'ams-common';
import { useFormik } from 'formik';
import getContent, {
  combineContent,
  getContentWithReplacements,
} from 'src/utils/contentUtils';
import * as Yup from 'yup';

import {
  AUDIT_COMMENCEMENT_DOCUMENTS,
  DUE_DATE_EXTENSION_LIMIT,
} from './constants';
import { FormDateGroup } from './styles';

export const ActionItemDueDateModal = ({
  date,
  actionItemId,
  matterUId,
  onClose,
  handleDueDateChange,
  corroType,
}: {
  date: string;
  actionItemId: string;
  matterUId: string;
  onClose: () => void;
  handleDueDateChange: (
    matterUId: string,
    actionItemId: string,
    values: { internalDueDate: string },
    onError: () => void,
    onSuccess: () => void,
  ) => Promise<void>;
  corroType: string;
}) => {
  const [showModal, setShowModal] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const corroTypeKey =
    corroType === AUDIT_COMMENCEMENT_DOCUMENTS
      ? 'auditCommencementDocuments'
      : 'others';

  const validationSchema = Yup.object({
    internalDueDate: Yup.date()
      .typeError('Invalid due date')
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'Due date cannot be past date',
      )
      .test(
        'due date must not exceed the extension limit',
        ` Due date cannot exceed ${DUE_DATE_EXTENSION_LIMIT}  months`,
        (dateToCheck) => {
          let dateVal;
          if (date === '') dateVal = new Date().toDateString();
          else dateVal = date;
          if (dateToCheck) {
            return (
              new Date(dateVal).setMonth(
                new Date(dateVal).getMonth() + DUE_DATE_EXTENSION_LIMIT,
              ) > dateToCheck?.valueOf()
            );
          }
          return false;
        },
      )
      .required('Due date is required'),
  });
  const { values, handleSubmit, errors, setValues } = useFormik({
    initialValues: { internalDueDate: formatDate(date, 'YYYY-MM-DD') },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,

    onSubmit: () => {
      setShowConfirmModal(true);

      setShowModal(false);
    },
  });

  const handleDueDateValChange = ({ value }: { value: string }) => {
    setValues({
      ...values,
      ...{ internalDueDate: value },
    });
  };

  const onError = () => {
    onClose();
  };

  const onSuccess = () => {
    onClose();
  };

  const handleDueDateChangeSubmit = async () => {
    setShowConfirmModal(false);
    handleDueDateChange(matterUId, actionItemId, values, onError, onSuccess);
  };

  const dueDateTooltipText = getContent(
    `actionItems.${corroTypeKey}.dueDate.modal.dueDate.tooltip.text` as keyof typeof combineContent,
  );

  return (
    <form>
      {showModal && (
        <Modal
          title={getContentWithReplacements(`actionItems.dueDate.modal.title`, {
            action: date ? 'Edit' : 'Add',
          })}
          buttons={[
            {
              text: getContentWithReplacements(
                `actionItems.dueDate.modal.secondary.button`,
                { action: date ? 'Update' : 'Add' },
              ),
              onClick: handleSubmit,
            },
            {
              text: getContent('actionItems.dueDate.modal.primary.button'),
              onClick: () => {
                setShowModal(false);
                onClose();
              },
            },
          ]}
          description={getContentWithReplacements(
            `actionItems.dueDate.modal.text`,
            { action: date ? 'Update' : 'Add' },
          )}
        >
          <>
            <ErrorSummary errors={errors} />
            <FormDateGroup
              id="dueDate"
              label={
                <Tooltip
                  label="Due date"
                  text={dueDateTooltipText}
                  noTranslateLeft
                />
              }
              hasError={!!errors.internalDueDate}
            >
              <FormDate
                name="dueDate"
                value={values.internalDueDate}
                onChange={handleDueDateValChange}
              />
            </FormDateGroup>
          </>
        </Modal>
      )}
      {showConfirmModal && (
        <Modal
          title="Confirm due date"
          buttons={[
            {
              text: getContent(
                `actionItems.dueDate.confirmModal.secondary.button`,
              ),
              onClick: handleDueDateChangeSubmit,
            },
            {
              text: getContent('actionItems.dueDate.modal.primary.button'),
              onClick: () => {
                setShowModal(true);
              },
            },
          ]}
          description={getContentWithReplacements(
            `actionItems.dueDate.confirmModal.text`,
            {
              oldDate: date ? `from ${formatDate(date, 'DD MMM YYYY')}` : '',
              newDate: formatDate(values.internalDueDate, 'DD MMM YYYY'),
            },
          )}
        />
      )}
    </form>
  );
};
