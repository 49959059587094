import { QueryKey } from 'src/api/constants';
import { ScreenNames } from 'src/constants';

export const INTERNAL_SERVER_ERROR = 'INTERNAL-SERVER-ERROR';
export const DASHBOARD_ACTION_ITEMS_GET = `${ScreenNames.DASHBOARD}-${QueryKey.ACTION_ITEMS}-GET`;
export const DASHBOARD_ACTION_ITEMS_PATCH = `${ScreenNames.DASHBOARD}-${QueryKey.ACTION_ITEMS}-PATCH`;
export const DASHBOARD_MATTER_ALERTS_GET = `${ScreenNames.DASHBOARD}-${QueryKey.MATTER_ALERTS}-GET`;
export const DASHBOARD_MATTERS_GET = `${ScreenNames.DASHBOARD}-${QueryKey.MATTERS}-GET`;
export const MATTERS_MATTERS_GET = `${ScreenNames.MATTERS}-${QueryKey.MATTERS}-GET`;
export const CUSTOMER_ONBOARDING_GET = `${ScreenNames.CUSTOMER_ONBOARDING}-GET`;
export const CUSTOMER_LOGIN_POST = `${ScreenNames.CUSTOMER_LOGIN}-POST`;
export const AUDITOR_OVERVIEW_MATTERS_SUMMARY_GET = `${ScreenNames.AUDITOR_OVERVIEW}-${QueryKey.MATTERS_SUMMARY}-GET`;
export const CUSTOMER_OVERVIEW_MATTERS_SUMMARY_GET = `${ScreenNames.CUSTOMER_OVERVIEW}-${QueryKey.MATTERS_SUMMARY}-GET`;
export const CUSTOMER_OVERVIEW_ACTION_ITEMS_GET = `${ScreenNames.CUSTOMER_OVERVIEW}-${QueryKey.ACTION_ITEMS}-GET`;
export const CUSTOMER_OVERVIEW_EXTERNAL_MATTER_NOTIFICATIONS_GET = `${ScreenNames.CUSTOMER_OVERVIEW}-${QueryKey.EXTERNAL_MATTER_NOTIFICATIONS}-GET`;
export const CORRESPONDENCE_MATTERS_CORRESPONDENCES_GET = `${ScreenNames.CORRESPONDENCE}-${QueryKey.MATTERS_CORRESPONDENCES}-GET`;
export const CORRESPONDENCE_DETAILS_MATTERS_THREAD_GET = `${ScreenNames.CORRESPONDENCE_DETAILS}-${QueryKey.MATTER_THREAD_DETAILS}-GET`;
export const CORRESPONDENCE_DETAILS_MATTERS_THREAD_POST = `${ScreenNames.CORRESPONDENCE_DETAILS}-${QueryKey.MATTER_THREAD_DETAILS}-POST`;
export const AUDITOR_CONTACTS_ONBOARDING_DATA_GET = `${ScreenNames.AUDITOR_CONTACTS}-${QueryKey.ONBOARDING_DATA}-GET`;
export const AUDITOR_CONTACTS_ONBOARDING_DATA_POST = `${ScreenNames.AUDITOR_CONTACTS}-${QueryKey.ONBOARDING_DATA}-POST`;
export const AUDITOR_CONTACTS_MATTERS_CONTACTS_MANAGER_GET = `${ScreenNames.AUDITOR_CONTACTS}-${QueryKey.MATTERS_CONTACTS_MANAGER}-GET`;
export const AUDITOR_CONTACTS_MATTERS_CONTACTS_MANAGER_POST = `${ScreenNames.AUDITOR_CONTACTS}-${QueryKey.MATTERS_CONTACTS_MANAGER}-POST`;
export const AUDITOR_CONTACTS_MATTERS_CLIENTS_CONTACTS_GET = `${ScreenNames.AUDITOR_CONTACTS}-${QueryKey.MATTERS_CLIENTS_CONTACTS}-GET`;
export const AUDITOR_CONTACTS_ONBOARDING_DATA_DELETE = `${ScreenNames.AUDITOR_CONTACTS}-${QueryKey.ONBOARDING_DATA}-DELETE`;
export const CUSTOMER_CONTACTS_CUSTOMER_CONTACTS_DATA_GET = `${ScreenNames.CUSTOMER_CONTACTS}-${QueryKey.ONBOARDING_DATA}-GET`;
export const CUSTOMER_CONTACTS_MATTER_CONTACTS_MANAGER_DETAILS_GET = `${ScreenNames.CUSTOMER_CONTACTS}-${QueryKey.MATTER_CONTACTS_MANAGER_DETAILS}-GET`;
export const CUSTOMER_CONTACTS_MATTER_CONTACTS_AUDITOR_DETAILS_GET = `${ScreenNames.CUSTOMER_CONTACTS}-${QueryKey.MATTER_CONTACTS_AUDITOR_DETAILS}-GET`;
export const AUDITOR_CONTACTS_CLIENTS_GET = `${ScreenNames.AUDITOR_CONTACTS}-${QueryKey.CLIENTS}-GET`;
export const STATUS_MANAGER_HISTORY_LOGS_GET = `${ScreenNames.STATUS_MANAGER}-${QueryKey.HISTORY_LOGS}-GET`;
export const STATUS_MANAGER_HISTORY_LOGS_POST = `${ScreenNames.STATUS_MANAGER}-${QueryKey.HISTORY_LOGS}-POST`;
export const MESSAGES_MESSAGES_GET = `${ScreenNames.MESSAGES}-${QueryKey.MESSAGES}-GET`;
export const MESSAGE_DETAILS_MESSAGE_DETAILS_GET = `${ScreenNames.MESSAGE_DETAILS}-${QueryKey.MESSAGE_DETAILS}-GET`;
export const MESSAGE_DETAILS_MESSAGE_DETAILS_POST = `${ScreenNames.MESSAGE_DETAILS}-${QueryKey.MESSAGE_DETAILS}-POST`;
export const MESSAGE_SEND_MESSAGE_DETAILS_POST = `${ScreenNames.MESSAGE_SEND}-${QueryKey.MESSAGE_DETAILS}-POST`;
export const MATTER_NOTIFICATIONS_INTERNAL_MATTER_NOTIFICATIONS_GET = `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.INTERNAL_MATTER_NOTIFICATIONS}-GET`;
export const MATTER_NOTIFICATIONS_INTERNAL_MATTER_NOTIFICATIONS_POST = `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.INTERNAL_MATTER_NOTIFICATIONS}-POST`;
export const MATTER_NOTIFICATIONS_EXTERNAL_MATTER_NOTIFICATIONS_GET = `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.EXTERNAL_MATTER_NOTIFICATIONS}-GET`;
export const MATTER_NOTIFICATIONS_EXTERNAL_MATTER_NOTIFICATIONS_POST = `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.EXTERNAL_MATTER_NOTIFICATIONS}-POST`;
export const CUSTOMER_MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET = `${ScreenNames.CUSTOMER_MATTER_DOCUMENTS}-${QueryKey.MATTER_DOCUMENTS}-GET`;
export const CUSTOMER_MATTER_DOCUMENTS_MATTERS_SUMMARY_GET = `${ScreenNames.CUSTOMER_MATTER_DOCUMENTS}-${QueryKey.MATTERS_SUMMARY}-GET`;
export const AUDITOR_MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET = `${ScreenNames.AUDITOR_MATTER_DOCUMENTS}-${QueryKey.MATTER_DOCUMENTS}-GET`;
export const AUDITOR_MATTER_DOCUMENTS_MATTERS_SUMMARY_GET = `${ScreenNames.AUDITOR_MATTER_DOCUMENTS}-${QueryKey.MATTERS_SUMMARY}-GET`;
export const AUDITOR_MATTER_DOCUMENTS_CLIENTS_GET = `${ScreenNames.AUDITOR_MATTER_DOCUMENTS}-${QueryKey.CLIENTS}-GET`;
export const MATTER_DOCUMENTS_TRIM_MATTER_TRIM_DOCUMENTS_POST = `${ScreenNames.MATTER_DOCUMENTS_TRIM}-${QueryKey.MATTER_TRIM_DOCUMENTS}-POST`;
export const MATTER_DOCUMENTS_TRIM_MATTER_TRIM_DOCUMENTS_GET = `${ScreenNames.MATTER_DOCUMENTS_TRIM}-${QueryKey.MATTER_TRIM_DOCUMENTS}-GET`;
export const MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET = `${ScreenNames.MATTER_DOCUMENTS}-${QueryKey.MATTER_DOCUMENTS}-GET`;
export const MATTER_DOCUMENTS_TRIM_MATTER_DOCUMENTS_GET = `${ScreenNames.MATTER_DOCUMENTS_TRIM}-${QueryKey.MATTER_DOCUMENTS}-GET`;
export const MATTER_DOCUMENTS_TRIM_MATTER_DOCUMENTS_DELETE = `${ScreenNames.MATTER_DOCUMENTS_TRIM}-${QueryKey.MATTER_DOCUMENTS}-DELETE`;
export const MATTER_DOCUMENTS_MATTER_DOCUMENTS_DELETE = `${ScreenNames.MATTER_DOCUMENTS}-${QueryKey.MATTER_DOCUMENTS}-DELETE`;
export const SUBMIT_AUDIT_CORRESPONDENCE_MATTER_DOCUMENTS_GET = `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.MATTER_DOCUMENTS}-GET`;
export const SUBMIT_AUDIT_CORRESPONDENCE_MATTERS_CORRESPONDENCES_POST = `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.MATTERS_CORRESPONDENCES}-POST`;
export const SUBMIT_AUDIT_CORRESPONDENCE_CORRESPONDENCE_DETAILS_GET = `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.CORRESPONDENCE_DETAILS}-GET`;
export const SUBMIT_AUDIT_CORRESPONDENCE_CLIENTS_GET = `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.CLIENTS}-GET`;
export const SUBMIT_AUDIT_CORRESPONDENCE_RECIPIENT_CONTACTS_GET = `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-${QueryKey.RECIPIENT_CONTACTS}-GET`;
export const SUBMIT_AUDIT_CORRESPONDENCE_DUE_DATE_POST = `${ScreenNames.SUBMIT_AUDIT_CORRESPONDENCE}-DUE_DATE-POST`;
export const CORRESPONDENCE_FORM_MATTERS_CORRESPONDENCES_POST = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTERS_CORRESPONDENCES}-POST`;
export const CORRESPONDENCE_FORM_CORRESPONDENCE_DETAILS_GET = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.CORRESPONDENCE_DETAILS}-GET`;
export const CORRESPONDENCE_FORM_MATTERS_CORRESPONDENCES_GET = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTERS_CORRESPONDENCES}-GET`;
export const CORRESPONDENCE_FORM_MATTERS_DOCUMENTS_GET = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTERS_DOCUMENTS}-GET`;
export const CORRESPONDENCE_FORM_MATTERS_DOCUMENT_GET = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTERS_DOCUMENT}-GET`;
export const CORRESPONDENCE_FORM_CLIENTS_GET = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.CLIENTS}-GET`;
export const CORRESPONDENCE_FORM_MATTER_DOCUMENTS_GET = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTER_DOCUMENTS}-GET`;
export const CORRESPONDENCE_FORM_MATTER_CONTACTS_AUDITOR_DETAILS_GET = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.MATTER_CONTACTS_AUDITOR_DETAILS}-GET`;
export const CORRESPONDENCE_FORM_RECIPIENT_CONTACTS_GET = `${ScreenNames.CORRESPONDENCE_FORM}-${QueryKey.RECIPIENT_CONTACTS}-GET`;
export const CORRESPONDENCE_FORM_DUE_DATE_POST = `${ScreenNames.CORRESPONDENCE_FORM}-DUE_DATE-POST`;
