import * as t from 'io-ts';
import { tOptional } from 'src/types';

export const MatterClientCodec = t.intersection([
  t.type({
    id: t.number,
  }),
  t.partial({
    read: tOptional(t.boolean),
  }),
]);

export const ClientCodec = t.type({
  id: t.number,
  name: t.string,
  marsCaseId: t.number,
  leadClient: t.boolean,
});

export const ClientsCodec = t.array(ClientCodec);

export type Client = t.TypeOf<typeof ClientCodec>;
export type Clients = t.TypeOf<typeof ClientsCodec>;
export type MatterClient = t.TypeOf<typeof MatterClientCodec>;

export type ClientOptions = { text: string; value: string }[] | null;
