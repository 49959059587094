import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { CORRESPONDENCE_TYPE_MAPPER } from './constants';
import { CustomerCorrespondenceResponseReceivedData } from './types';

type CustomerCorrespondenceResponseReceivedProps = {
  data: CustomerCorrespondenceResponseReceivedData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (matterIdOrThreadId: string, threadId?: string) => void;
  alertId: string;
};

export const CustomerCorrespondenceResponseReceived = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: CustomerCorrespondenceResponseReceivedProps) => {
  const { correspondenceThreadId, matterId, leadClientName, corroType } = data;
  const correspondenceTypeText =
    CORRESPONDENCE_TYPE_MAPPER[corroType ?? 'AUDIT COMMENCEMENT DOCUMENTS'];

  const body = getContentWithReplacements(
    'matterAlert.alert.customer.correspondence.response.received',
    {
      leadClientName,
      matterId,
      correspondenceTypeText,
    },
  );

  return (
    <MatterAlert
      title="Correspondence response received"
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`${PATHS.matters}/${matterId}${PATHS.threads}/${correspondenceThreadId}`}
      linkText="View response"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (matterId) updateMatterContext?.(matterId, correspondenceThreadId);
      }}
    />
  );
};
