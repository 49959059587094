import { useQuery } from 'react-query';
import { AMS_PORTAL_EXTERNAL_BFF, APIResource, queryApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import { getClientOptions } from './helpers';
import { ClientsCodec } from './types';

const fetch = (matterUId: string | null, isCustomer?: boolean) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.contacts,
      suffix: ['matters', `${matterUId}`, 'clients'],
    },
    ClientsCodec,
  );
};

export const useMatterClients = (
  matterUId: string | null,
  isCustomer?: boolean,
) => {
  return useQuery(
    [QueryKey.CLIENTS, matterUId],
    () => fetch(matterUId, isCustomer),
    {
      enabled: !!matterUId,
    },
  );
};

export const useClients = (matterUId: string | null, isCustomer?: boolean) => {
  const { data, isError, isLoading } = useMatterClients(matterUId, isCustomer);

  return {
    isLoading,
    isError,
    clients: data,
    clientOptions: getClientOptions(data),
  };
};
