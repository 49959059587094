import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { CORRESPONDENCE_TYPE_MAPPER } from './constants';
import { CustomerCorrespondenceReadReceiptData } from './types';

type ExtendedCustomerCorrespondenceReadReceiptData =
  CustomerCorrespondenceReadReceiptData & {
    matterId: string;
  };

type CustomerCorrespondenceReadReceiptProps = {
  data: ExtendedCustomerCorrespondenceReadReceiptData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (matterIdOrThreadId: string, threadId?: string) => void;
  alertId: string;
};

export const CustomerCorrespondenceReadReceipt = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: CustomerCorrespondenceReadReceiptProps) => {
  const {
    contactName,
    clientName,
    matterId,
    corroType,
    correspondenceThreadId,
  } = data;

  const body = getContentWithReplacements(
    'matterAlert.alert.customer.correspondence.read.message',
    {
      contactName,
      clientName,
      correspondenceName:
        CORRESPONDENCE_TYPE_MAPPER[corroType ?? 'AUDIT COMMENCEMENT DOCUMENTS'],
    },
  );

  return (
    <MatterAlert
      title="Correspondence has been read"
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`/matters/${matterId}${PATHS.threads}/${correspondenceThreadId}`}
      linkText="View correspondence"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (matterId)
          updateMatterContext?.(matterId, correspondenceThreadId ?? '');
      }}
    />
  );
};
