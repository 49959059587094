import { Heading, InPageAlert } from '@snsw/react-component-library';
import { FileUpload } from 'ams-common';
import getContent from 'src/utils/contentUtils';

import { StyledFileUploadContainer } from './styles';

interface DocumentUploadContainerProps {
  isCustomer: boolean;
  handleFileUpload: (files: File[]) => Promise<void>;
}
export const DocumentUploadContainer = ({
  handleFileUpload,
  isCustomer,
}: DocumentUploadContainerProps) => {
  return (
    <>
      <Heading level={3}>
        {getContent('matter.correspondence.fileUpload.heading')}
      </Heading>

      {isCustomer ? (
        <InPageAlert
          title={getContent('matter.correspondence.document.alert.message')}
          variant="info"
        />
      ) : null}

      <StyledFileUploadContainer>
        <FileUpload onFileUpload={handleFileUpload} />
      </StyledFileUploadContainer>
    </>
  );
};
