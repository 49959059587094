import React, { FC, useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ContentContainer } from '@snsw/react-component-library';
import { RootLayout } from 'ams-common';
import { SESSION_STORAGE_KEY_USER_INFO } from 'src/constants';
import environments from 'src/environments';
import { PATHS } from 'src/routes/constants';
import { LogoutSuccess } from 'src/screens/common/logoutSuccess';
import Onboarding from 'src/screens/customer/onboarding';
import { UserLoginType } from 'src/types';

import LoginAndRedirectWrapper from './LoginAndRedirectWrapper';

const RequireAuth: FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => {
  const bypassAuth = environments.bypassAuthorization === 'true';

  useEffect(() => {
    if (bypassAuth && !isAuthenticated) {
      // local machine only;
      window.sessionStorage.setItem(
        SESSION_STORAGE_KEY_USER_INFO,
        JSON.stringify({
          userType: UserLoginType.Customer,
        }),
      );
      window.dispatchEvent(new Event('storage'));
    }
  }, [bypassAuth, isAuthenticated]);

  if (bypassAuth || isAuthenticated) {
    return <Outlet />;
  }
  return null;
};

export const AMSAuth: FC<{
  isAuthenticated: boolean;
  children: React.ReactNode;
}> = ({ isAuthenticated, children }) => (
  <Routes>
    <Route path={PATHS.onboarding} element={<Onboarding />} />
    <Route path={PATHS.logoutSuccess} element={<LogoutSuccess />} />
    <Route
      path={PATHS.login}
      element={
        <RootLayout showNavigation={false}>
          <ContentContainer>
            <LoginAndRedirectWrapper />
          </ContentContainer>
        </RootLayout>
      }
    />
    <Route element={<RequireAuth isAuthenticated={isAuthenticated} />}>
      {children}
    </Route>
  </Routes>
);
