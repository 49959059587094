import { Button } from '@snsw/react-component-library';
import { ButtonGroup } from 'src/screens/common/matters/tabs/common/matterCorrespondence/styles';

interface ActionButtonsProps {
  handleSaveAsDraft: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleBack: () => void;
  disable: boolean;
  isAtLeastOneResponseFromCustomerSubmitted: boolean;
  isAtLeastOneResponseSubmitted: boolean;
  isCustomer: boolean;
  draftCorrespondenceId: string;
}

export const ActionButtons = ({
  handleSaveAsDraft,
  handleSubmit,
  handleBack,
  disable,
  isAtLeastOneResponseFromCustomerSubmitted,
  isCustomer,
  isAtLeastOneResponseSubmitted,
  draftCorrespondenceId,
}: ActionButtonsProps) => {
  return draftCorrespondenceId ||
    (!isCustomer && !isAtLeastOneResponseSubmitted) ||
    (isCustomer && !isAtLeastOneResponseFromCustomerSubmitted) ? (
    <ButtonGroup>
      <Button variant="secondary" onClick={handleSaveAsDraft}>
        Save as draft
      </Button>
      <Button onClick={handleSubmit} disabled={disable}>
        Submit
      </Button>
    </ButtonGroup>
  ) : (
    <ButtonGroup>
      <Button variant="secondary" onClick={handleBack}>
        Back
      </Button>
      <Button onClick={handleSubmit} disabled={disable}>
        Send
      </Button>
    </ButtonGroup>
  );
};
