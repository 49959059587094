const content = {
  'matters.correspondence.customer.entityNames.tooltip.text':
    'This is the customer for which this correspondence relates to',
  'matters.correspondence.message.helpMessage.text':
    'Maximum 1000 characters including spaces. {remChar} character(s) remaining',
  'matter.correspondence.fileUpload.heading': 'Documents',
  'matter.correspondence.document.alert.message':
    'Please be aware that all authorised contacts in this audit matter can view ALL portal content and documents',
};

export default content;
