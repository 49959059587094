import { ReactNode, useEffect, useRef, useState } from 'react';
import { IconClose } from '@snsw/react-component-library';
import { EllipsisText } from 'ams-common';

import {
  Actions,
  NoStyledButton,
  NoStyledButtonWithTitle,
  Popup,
  PopupWithTitle,
} from './styles';

interface RenderProps {
  handleClosePopup: () => void;
}
interface Props {
  renderActionItems: ({ handleClosePopup }: RenderProps) => ReactNode;
  title?: any;
  width?: number;
}

export const ButtonWithPopup = ({ renderActionItems, title, width }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });

  const triggerButtonRef = useRef<HTMLButtonElement>(null);

  const handleOpenPopup = () => {
    if (triggerButtonRef.current) {
      // Get the position of the trigger button
      const buttonRect = triggerButtonRef.current.getBoundingClientRect();

      // Calculate the position for the popup
      const newPosition = {
        left: buttonRect.left + window.scrollX,
        top: buttonRect.bottom + window.scrollY,
      };

      setPopupPosition(newPosition);
      setIsPopupVisible(true);
    }
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsPopupVisible(false);
      }
    };

    if (isPopupVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisible]);

  return (
    <>
      {title ? (
        <NoStyledButtonWithTitle
          type="button"
          ref={triggerButtonRef}
          onClick={handleOpenPopup}
        >
          <EllipsisText width={width || 200}>{title}</EllipsisText>
        </NoStyledButtonWithTitle>
      ) : (
        <NoStyledButton
          type="button"
          ref={triggerButtonRef}
          onClick={handleOpenPopup}
        >
          ...
        </NoStyledButton>
      )}
      {isPopupVisible &&
        (title ? (
          <PopupWithTitle
            left={popupPosition.left}
            top={popupPosition.top}
            ref={modalRef}
          >
            <NoStyledButton type="button" onClick={handleClosePopup}>
              <IconClose title="Close Icon" />
            </NoStyledButton>
            <Actions>{renderActionItems({ handleClosePopup })}</Actions>
          </PopupWithTitle>
        ) : (
          <Popup
            left={popupPosition.left}
            top={popupPosition.top}
            ref={modalRef}
          >
            <NoStyledButton type="button" onClick={handleClosePopup}>
              <IconClose title="Close Icon" />
            </NoStyledButton>
            <Actions>{renderActionItems({ handleClosePopup })}</Actions>
          </Popup>
        ))}
    </>
  );
};
