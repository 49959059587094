import { DocumentInitialValues } from './types';

export const totalAccordionDocuments = (
  documentsObj?: DocumentInitialValues,
): number => {
  let total = 0;

  if (!documentsObj) {
    return total;
  }

  const entryLengths = Object.values(documentsObj).map(
    (entry) => entry?.length,
  );

  total += entryLengths.reduce((acc, curr) => acc + curr, 0);

  return total;
};

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
