export enum CustomerLoginFormFieldNames {
  clientId = 'clientId',
  correspondenceId = 'corroId',
  emailAddress = 'contactEmail',
  otp = 'otp',
  termsAndConditions = 'termsAndConditions',
}

export enum CustomerLoginFormFieldLabels {
  clientId = 'Client ID',
  correspondenceId = 'Correspondence ID',
  emailAddress = 'Email address',
  otp = 'One time password',
}
