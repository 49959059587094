import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { CustomerDocumentReadReceiptData } from './types';

type ExtendedCustomerDocumentReadReceiptData =
  CustomerDocumentReadReceiptData & {
    matterId: string;
  };

type CustomerDocumentReadReceiptProps = {
  data: ExtendedCustomerDocumentReadReceiptData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (matterIdOrThreadId: string, threadId?: string) => void;
  alertId: string;
};

export const CustomerDocumentReadReceipt = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: CustomerDocumentReadReceiptProps) => {
  const {
    contactName,
    clientName,
    matterId,
    documentName,
    correspondenceThreadId,
  } = data;

  const body = getContentWithReplacements(
    'matterAlert.alert.customer.document.read.message',
    {
      contactName,
      clientName,
      documentName,
    },
  );

  return (
    <MatterAlert
      title="Correspondence document has been read"
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`/matters/${matterId}${PATHS.threads}/${correspondenceThreadId}`}
      linkText="View correspondence"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (matterId)
          updateMatterContext?.(matterId, correspondenceThreadId ?? '');
      }}
    />
  );
};
