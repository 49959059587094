import { SectionPage } from '@snsw/react-component-library';
import { colours, pxToRem } from 'ams-common';
import styled from 'styled-components';

export const StyledDescription = styled.p`
  color: ${colours.grey.shuttle};
`;

export const StyledSectionPage = styled(SectionPage)`
  p {
    line-height: 2;
    margin-bottom: ${pxToRem(24)};
  }
`;
