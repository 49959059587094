import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';

import { CORRESPONDENCE_TYPE_MAPPER } from './constants';
import { NewCorrespondenceData } from './types';

type ExtendedNewCorrespondenceData = NewCorrespondenceData & {
  matterId: string;
};

type NewCorrespondenceProps = {
  data: ExtendedNewCorrespondenceData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (threadId: string) => void;
  alertId: string;
};

export const NewCorrespondence = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: NewCorrespondenceProps) => {
  const { correspondenceThreadId, matterId, corroType } = data;
  const correspondenceTypeText =
    CORRESPONDENCE_TYPE_MAPPER[corroType ?? 'AUDIT COMMENCEMENT DOCUMENTS'];
  return (
    <MatterAlert
      title={`New correspondence received for ${correspondenceTypeText}`}
      timestamp={createdDate}
      body=""
      isRead={read}
      linkUrl={`${PATHS.matters}/${matterId}${PATHS.threads}/${correspondenceThreadId}`}
      linkText="View correspondence"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (correspondenceThreadId) {
          updateMatterContext?.(correspondenceThreadId);
        }
      }}
    />
  );
};
