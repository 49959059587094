import { MatterDocuments } from 'src/hooks/types';
import * as Yup from 'yup';

export const validationSchema = (
  isAtLeastOneResponseFromCustomerSubmitted: boolean,
  uploadedDocuments: {
    [key: string]: MatterDocuments;
  },
) => {
  return Yup.object().shape({
    messageSubject: Yup.string().required('Message subject is required'),
    message: Yup.string().required('Message is required'),
    sections: Yup.lazy((sectionsObj) => {
      if (
        (isAtLeastOneResponseFromCustomerSubmitted && !sectionsObj) ||
        (isAtLeastOneResponseFromCustomerSubmitted &&
          Object.keys(sectionsObj).length === 0)
      ) {
        return Yup.mixed().notRequired();
      }

      if (
        isAtLeastOneResponseFromCustomerSubmitted &&
        Object.keys(sectionsObj).length === 0
      ) {
        return Yup.mixed().notRequired();
      }

      if (!sectionsObj) {
        return Yup.mixed().required('At least one document must be selected');
      }

      return Yup.object()
        .test(
          'Empty object test',
          'At least one document must be selected',
          (sectionsObj) => {
            if (isAtLeastOneResponseFromCustomerSubmitted)
              return isAtLeastOneResponseFromCustomerSubmitted;
            return Object.keys(sectionsObj).length !== 0;
          },
        )
        .shape(
          Object.keys(sectionsObj).reduce((acc, key) => {
            return {
              ...acc,
              [key]: Yup.array()
                .of(
                  Yup.object().shape({
                    entity: Yup.string().required('Select an entity'),
                    description: Yup.string().when('classification', {
                      is: () => key === 'other-documents',
                      then: (schema) => schema.required('Description required'),
                      otherwise: (schema) => schema.notRequired(),
                    }),
                    fileName: Yup.string()
                      .test(
                        'unique',
                        `Duplicate file under ${key}`,
                        (value, context) => {
                          const sections = context?.from?.[1];
                          const classificationObject = sections?.value[key];
                          const fileNameOccurrences =
                            classificationObject.filter(
                              ({ fileName }) => fileName === value,
                            ).length;

                          return fileNameOccurrences <= 1;
                        },
                      )
                      .test(
                        'unique-file',
                        `File already uploaded under ${key}`,
                        (value) => {
                          const documentsByClassification =
                            uploadedDocuments[key] || [];
                          const fileNameOccurrences =
                            documentsByClassification.filter(
                              ({ name }) => name === value,
                            ).length;

                          return fileNameOccurrences === 0;
                        },
                      ),
                  }),
                )
                .required(`At least one item is required in ${key}`),
            };
          }, {}),
        );
    }),
  });
};
