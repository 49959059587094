import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ComponentLoader,
  ContentContainer,
  InPageAlert,
  Row,
} from '@snsw/react-component-library';
import { formatDate } from 'ams-common';
import html2canvas from 'html2canvas';
import { QueryKey } from 'src/api/constants';
import { ArchiveToTrim } from 'src/components/archiveToTrim';
import { TrimUploadSuccessful } from 'src/components/archiveToTrim/TrimUploadSuccessful';
import { useClients } from 'src/components/clients/hooks';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import { useMessage } from 'src/context/MessageContext';
import {
  useMatterMessageUploadToTrim,
  useMessagesDetails,
  useUserContext,
} from 'src/hooks';
import {
  MatterMessageUploadToTrimData,
  MatterMessageUploadToTrimResponse,
} from 'src/hooks/types';
import { UserLoginType } from 'src/types';
import getContent from 'src/utils/contentUtils';
import { createPDF } from 'src/utils/trimPdfUtils';

import { PDF_EXT } from './constants';
import { MessageList } from './MessageList';
import {
  StyledMessageCol,
  StyledMessageLabel,
  StyledMessageSubject,
  StyledTrimUploadMessageCol,
} from './styles';

export const MessageDetails = () => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const [showArchiveToTrimModal, setShowArchiveToTrimModal] = useState(false);
  const [trimRecord, setTrimRecord] =
    useState<MatterMessageUploadToTrimData | null>(null);
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const { messageSubject } = useMessage();
  const { matterUId, matterId, matterLeadClientId } = useMatter();
  const { messageId = '' } = useParams();
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const { clientOptions } = useClients(matterUId, isCustomer);

  const {
    data,
    isLoading: isMessageDetailsLoading,
    isError: isMessageDetailsError,
  } = useMessagesDetails(matterUId, messageId, isCustomer);

  const navigateMessageUploadToTrimSuccess = (
    response: MatterMessageUploadToTrimResponse,
  ) => {
    setTrimRecord(response.data);
  };

  const navigateMessageUploadToTrimError = () => {
    setErrorKeys((prevErrorKeys) => [
      ...new Set([
        ...prevErrorKeys,
        `${ScreenNames.MESSAGE_DETAILS}-${QueryKey.MESSAGE_DETAILS}-POST` as ErrorKey,
      ]),
    ]);
  };

  const {
    mutate: mutateMessageUploadToTrim,
    isLoading: isMessageUploadingToTrim,
  } = useMatterMessageUploadToTrim(
    matterUId,
    messageId,
    navigateMessageUploadToTrimSuccess,
    navigateMessageUploadToTrimError,
  );

  const toggleButtonsVisibility = (isVisible: boolean): void => {
    const buttons = document.querySelectorAll('.hide-in-pdf');
    buttons.forEach((button) => {
      (button as HTMLElement).style.visibility = isVisible
        ? 'visible'
        : 'hidden';
    });
  };

  useEffect(() => {
    if (isMessageDetailsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.MESSAGE_DETAILS}-${QueryKey.MESSAGE_DETAILS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isMessageDetailsError]);

  const generatePDF = async () => {
    const input = pdfRef.current;
    if (!input) return;

    toggleButtonsVisibility(false);

    try {
      const canvas = await html2canvas(input, {
        useCORS: true,
        allowTaint: true,
        scrollY: 0,
      });

      const pdf = await createPDF(canvas);
      const pdfBlob = pdf.output('blob');
      const pdfFile = new File([pdfBlob], 'document.pdf', {
        type: 'application/pdf',
      });

      // eslint-disable-next-line consistent-return
      return pdfFile;
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      toggleButtonsVisibility(true);
    }
  };

  const handleTrimUpload = async ({
    entity,
    trimTitle,
    description,
  }: {
    entity: string;
    description: string;
    trimTitle: string;
  }) => {
    setShowArchiveToTrimModal(false);
    const pdf = await generatePDF();
    if (pdf) {
      mutateMessageUploadToTrim({
        file: pdf,
        fileName: `${trimTitle}${PDF_EXT}`,
        clientId: Number(entity),
        trimTitle,
        trimDescription: description,
      });
    }
  };

  return (
    <ContentContainer>
      <>
        <ComponentLoader
          fullPage
          active={isMessageDetailsLoading || isMessageUploadingToTrim}
        />
        <ErrorHandler keys={errorKeys} />
        {data?.trimUploadDate && data?.trimRecordNumber && !isCustomer ? (
          <Row>
            <StyledTrimUploadMessageCol>
              <InPageAlert compact variant="info">
                <p>
                  TRIM upload is done for this message thread as of{' '}
                  <strong>
                    {formatDate(data.trimUploadDate, 'DD MMM YYYY HH:mm')}
                  </strong>
                  . TRIM Record number <strong>{data.trimRecordNumber}</strong>.
                </p>
              </InPageAlert>
            </StyledTrimUploadMessageCol>
          </Row>
        ) : null}
        <div ref={pdfRef}>
          <Row>
            <StyledMessageCol>
              <StyledMessageLabel>
                {getContent('matters.messages.details.message.label')}
              </StyledMessageLabel>
              <StyledMessageSubject>
                {messageSubject ?? data?.subject}
              </StyledMessageSubject>
            </StyledMessageCol>
          </Row>
          {data && (
            <MessageList
              data={data.content}
              matterId={matterId}
              messageId={messageId}
              setShowArchiveToTrimModal={setShowArchiveToTrimModal}
              isCustomer={isCustomer}
            />
          )}
        </div>
      </>
      <ArchiveToTrim
        open={showArchiveToTrimModal}
        close={() => setShowArchiveToTrimModal(false)}
        clientOptions={clientOptions}
        matterLeadClientId={matterLeadClientId}
        messageTrimTitleSegment="Messages thread"
        modalType="message"
        onUpload={handleTrimUpload}
      />
      {trimRecord ? (
        <TrimUploadSuccessful
          open={!!trimRecord}
          close={() => {
            setTrimRecord(null);
          }}
          record={trimRecord}
          modalType="message"
        />
      ) : null}
    </ContentContainer>
  );
};
