import { ComponentLoader, IconDownload } from '@snsw/react-component-library';
import { useMatter } from 'src/context/MatterContext';
import { useUserContext } from 'src/hooks';
import {
  useDocumentsDetails,
  useDownloadDocument,
} from 'src/hooks/document/hooks';
import { Documents } from 'src/hooks/types';
import { UserLoginType } from 'src/types';
import { downloadSelectedDocument } from 'src/utils/documentHelper';

import { getDocumentIds } from './helper';
import { DocumentLink, DocumentLinkRow } from './styles';

interface DocumentsSectionProps {
  documents: Documents;
}
export const DocumentsSection = ({ documents }: DocumentsSectionProps) => {
  const { matterUId } = useMatter();
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const documentIds = getDocumentIds(documents);
  const { downloadDocumentUrl, downloadDocument } = useDownloadDocument();

  const { data, isLoading: isDocumentsDetailsLoading } = useDocumentsDetails(
    isCustomer,
    matterUId,
    documentIds,
  );

  const handleDocumentDownload = async (document: {
    documentId: string;
    documentName: string;
  }) => {
    const { documentId, documentName } = document;
    try {
      if (matterUId) {
        const { url: downloadUrl } = await downloadDocumentUrl(
          isCustomer,
          matterUId,
          documentId,
        );
        const response = await downloadDocument(downloadUrl, documentName);
        downloadSelectedDocument(response, documentName);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      } else if (typeof error === 'string') {
        console.error('Custom error message:', error);
      } else {
        console.error('Unknown error type');
      }
    }
  };

  return (
    <>
      <ComponentLoader active={isDocumentsDetailsLoading} fullPage />
      {data &&
        data.map(({ id, name, contentLength }) => {
          return (
            <DocumentLinkRow key={id}>
              <IconDownload />
              <DocumentLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleDocumentDownload({
                    documentId: id,
                    documentName: name,
                  });
                }}
              >
                {name} {`(${(contentLength / (1024 * 1024)).toFixed(2)}MB)`}
              </DocumentLink>
            </DocumentLinkRow>
          );
        })}
    </>
  );
};
