import { Modal } from '@snsw/react-component-library';
import getContent, { combineContent } from 'src/utils/contentUtils';

interface SystemErrorUploadFailureProps {
  open: boolean;
  close: () => void;
  type: 'partial' | 'complete';
}

export const SystemErrorUploadFailure = ({
  open,
  close,
  type,
}: SystemErrorUploadFailureProps) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={getContent('matter.tabs.files.upload.error.title')}
      buttons={[
        {
          text: 'Ok',
          onClick: close,
        },
      ]}
      description={getContent(
        `matter.tabs.files.upload.system.error.${type}.file.description` as keyof typeof combineContent,
      )}
    />
  );
};
