import { useQuery } from 'react-query';
import { APIResource, queryApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import { RecipientsCodec } from './types';

const fetchRecipients = (matterUId: string | null) => {
  return queryApi(
    {
      resourcePath: APIResource.contacts,
      suffix: ['matters', `${matterUId}`, 'contacts'],
      params: { registrationStatus: ['REGISTERED', 'PENDING'].join(',') },
    },
    RecipientsCodec,
  );
};

export const useRecipients = (
  matterUId: string | null,
  isCustomer: boolean = false,
) => {
  const queryKey = [QueryKey.RECIPIENT_CONTACTS, matterUId];
  return useQuery(queryKey, () => fetchRecipients(matterUId), {
    enabled: !!matterUId && !isCustomer,
  });
};
