import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

interface SaveAsDraftModalProps {
  open: boolean;
  close: () => void;
  handleSaveAsDraft: () => void;
}

export const SaveAsDraftModal = ({
  open,
  close,
  handleSaveAsDraft,
}: SaveAsDraftModalProps) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={getContent(
        'correspondence.customer.submitResponseToAuditCommencementsDocuments.saveAsDraft.modal.title',
      )}
      buttons={[
        {
          text: 'Save draft',
          onClick: handleSaveAsDraft,
        },
        {
          text: 'Cancel',
          onClick: close,
        },
      ]}
      description={getContent(
        'correspondence.customer.submitResponseToAuditCommencementsDocuments.saveAsDraft.modal.description',
      )}
    />
  );
};
