import { Button, Col, Row } from '@snsw/react-component-library';
import { fontWeight, pxToRem } from 'ams-common';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${pxToRem(32)} 0;
`;

export const StyledCol = styled(Col)`
  padding: 0;
`;

export const StyledRow = styled(Row)`
  padding: ${pxToRem(8)};
  align-items: center;
`;

export const StyledButton = styled(Button)`
  padding: 0 ${pxToRem(16)};
  float: right;
`;

export const StyledStatus = styled.span`
  float: right;
  padding: 0 ${pxToRem(16)};
  font-weight: ${fontWeight.medium};
`;

export const StyledIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${pxToRem(8)};
`;

export const StyledDocumentNameCol = styled(Col)`
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
