export enum CustomerAuthFormFieldNames {
  clientId = 'clientId',
  correspondenceId = 'corroId',
  emailAddress = 'contactEmail',
  challengeText = 'challengeText',
}

export enum CustomerAuthFormFieldLabels {
  clientId = 'Client ID',
  correspondenceId = 'Correspondence ID',
  emailAddress = 'Email address',
  challengeText = 'Challenge text',
}
