const content = {
  'customerAuthForm.onboarding.heading.main': 'Authenticate your details',
  'customerAuthForm.login.heading.main': 'Login to Compliance Portal',
  'customerAuthForm.clientId.errorMessage.required': 'Client ID is required',
  'customerAuthForm.correspondenceId.errorMessage.required':
    'Correspondence ID is required',
  'customerAuthForm.correspondenceId.helpMessage':
    'The correspondence ID can be found in the top right hand corner of any of your correspondences. Please note Correspondence IDs are only valid for a period of 16 months.',
  'customerAuthForm.emailAddress.errorMessage.invalid':
    'Enter a valid email address',
  'customerAuthForm.emailAddress.errorMessage.required': 'Email ID is required',
  'customerAuthForm.emailAddress.helpMessage':
    'This is the email address that you have requested to be used for the Compliance audit.',
  'customerAuthForm.challengeText.button.text': 'Click to get a new challenge',
  'customerAuthForm.challengeText.errorMessage.required':
    'Click to get a new challenge',
  'customerAuthForm.challengeText.helpMessage':
    'Please enter the text you see in the image below.',
  'customerAuthForm.challengeText.apiError': 'Captcha failed to load',

  'customerAuthForm.modal.success.title.text':
    'You have successfully authenticated your details',
  'customerAuthForm.modal.success.description.text':
    'Please check your email for your one time password.',
  'customerAuthForm.redirect.title': 'Register for Compliance Portal',
  'customerAuthForm.redirect.text1':
    ' First time users will need to register to access the Compliance Portal',
  'customerAuthForm.redirect.text2':
    '<p>If you do not have a Client ID or Correspondence ID, please <a href="https://www.revenue.nsw.gov.au/contact#accordion__link-428832-8" rel="noreferrer" target="_blank">contact</a> us during business hours</p>',
};

export default content;
