import { CorrespondenceTemplatesResponse } from '../correspondenceTemplate/types';

import {
  ClientContacts,
  ModalType,
  SubmitAuditCommencementDocuments,
  SubmitAuditCommencementDocumentsInitValues,
  UploadedDocuments,
} from './types';

export const getClientName = (
  clientOptions: {
    text: string;
    value: string;
  }[],
  selectedClientId: string,
) => {
  return (
    clientOptions.find((option) => String(option.value) === selectedClientId)
      ?.text || ''
  );
};

export const buildPortalContacts = (recipient: string) =>
  recipient.split(',').map((id) => ({ id }));

export const buildDocuments = (selectedDocuments: string[]) => {
  return selectedDocuments.map((documentId) => {
    return { id: documentId };
  });
};

export const getRequestBody = (
  auditCommencementValues: SubmitAuditCommencementDocumentsInitValues,
  modalType: ModalType,
) => {
  const { dueDate, subject, message, recipient, selectedDocuments } =
    auditCommencementValues;

  return {
    dueDate,
    subject,
    messageBody: message,
    corroType: 'AUDIT COMMENCEMENT DOCUMENTS',
    status: modalType,
    ...(recipient && { portalContacts: buildPortalContacts(recipient) }),
    documents: buildDocuments(selectedDocuments),
  };
};

const getCustomers = (clientContacts?: ClientContacts[] | null) => {
  return [...new Set(clientContacts?.map(({ clientId }) => clientId))];
};

const getRecipientsAsString = (clientContacts?: ClientContacts[] | null) => {
  const recipients = clientContacts?.map(({ id }) => id);
  const uniqueRecipients = [...new Set(recipients)];

  return uniqueRecipients.join(',');
};

const getSelectedDocumentIds = (documents: UploadedDocuments[]) =>
  documents.map(({ id }) => id);

export const getInitValuesFromResponse = (
  data: SubmitAuditCommencementDocuments,
): SubmitAuditCommencementDocumentsInitValues => {
  const {
    dueDate,
    subject,
    messageBody: message,
    portalContacts,
    documents,
  } = data;
  const customers = getCustomers(portalContacts);
  const recipients = getRecipientsAsString(portalContacts);
  const selectedDocuments =
    (documents && getSelectedDocumentIds(documents)) ?? [];
  return {
    dueDate: dueDate || '',
    subject: subject || '',
    message: message || '',
    customer: customers?.join() || '',
    recipient: recipients,
    selectedDocuments,
  };
};

export const getMessageTemplateOptions = (
  correspondenceTemplatesResponse?: CorrespondenceTemplatesResponse,
) => {
  const options = [];
  if (!correspondenceTemplatesResponse) {
    return options;
  }

  return correspondenceTemplatesResponse.map(({ name }) => ({
    text: name,
    value: name.toLocaleLowerCase().replace(/ /g, '_'),
  }));
};

export const getMessagePlaceholder = (
  messageTemplate: string,
  correspondenceTemplatesResponse?: CorrespondenceTemplatesResponse,
) => {
  if (!correspondenceTemplatesResponse) {
    return undefined;
  }

  return (
    correspondenceTemplatesResponse.find(({ name }) => {
      return name.toLocaleLowerCase().replace(/ /g, '_') === messageTemplate;
    })?.data || undefined
  );
};
