import { Modal } from '@snsw/react-component-library';
import { DocumentIcon } from 'ams-common';
import getContent from 'src/utils/contentUtils';

import { StyledFileName } from './styles';

interface InvalidFormatUploadFailureProps {
  open: boolean;
  close: () => void;
  onContinue: () => void;
  invalidFiles: File[];
  validFiles: File[];
}

export const InvalidFormatUploadFailure = ({
  open,
  close,
  onContinue,
  invalidFiles,
  validFiles,
}: InvalidFormatUploadFailureProps) => {
  if (!open) {
    return null;
  }

  const isOnlyInvalidFiles = invalidFiles.length > 0 && validFiles.length === 0;

  const buttons = isOnlyInvalidFiles
    ? [
        {
          text: 'Ok',
          onClick: close,
        },
      ]
    : [
        {
          text: 'Continue',
          onClick: onContinue,
        },
        {
          text: 'Cancel',
          onClick: close,
        },
      ];
  const description = isOnlyInvalidFiles
    ? getContent('matter.tabs.files.upload.error.single.file.description')
    : getContent('matter.tabs.files.upload.error.multiple.files.description');

  return (
    <Modal
      title={getContent('matter.tabs.files.upload.error.title')}
      buttons={buttons}
      description={description}
    >
      {invalidFiles.map((file) => {
        return (
          <StyledFileName key={file.name}>
            <DocumentIcon />
            {file.name}
          </StyledFileName>
        );
      })}
    </Modal>
  );
};
