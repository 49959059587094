export enum QueryKey {
  CLIENTS = 'CLIENTS',
  USER_PROFILE = 'USER_PROFILE',
  DASHBOARD = 'DASHBOARD',
  MATTERS = 'MATTERS',
  MATTER_ALERTS = 'MATTER_ALERTS',
  MATTER_TRIM_DOCUMENTS = 'MATTER_TRIM_DOCUMENTS',
  MATTER_TRIM_DOCUMENTS_FOLDER = 'MATTER_TRIM_DOCUMENTS_FOLDER',
  INTERNAL_MATTER_NOTIFICATIONS = 'INTERNAL_MATTER_NOTIFICATIONS',
  EXTERNAL_MATTER_NOTIFICATIONS = 'EXTERNAL_MATTER_NOTIFICATIONS',
  MATTERS_SUMMARY = 'MATTERS_SUMMARY',
  MATTERS_CORRESPONDENCES = 'MATTERS_CORRESPONDENCES',
  MATTERS_THREAD = 'MATTERS_THREAD',
  MATTERS_CORRESPONDENCE_RESPONSES = 'MATTERS_CORRESPONDENCE_RESPONSES',
  MATTERS_DOCUMENT = 'MATTERS_DOCUMENT',
  MATTERS_DOCUMENTS = 'MATTERS_DOCUMENTS',
  MATTERS_CONTACTS = 'MATTERS_CONTACTS',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  OUT_OF_OFFICE = 'OUT_OF_OFFICE',
  SUBMIT_AUDIT_COMMENCEMENT_DOCUMENTS = 'SUBMIT_AUDIT_COMMENCEMENT_DOCUMENTS',
  MATTERS_CONTACTS_CUSTOMER_NAMES = 'MATTERS_CONTACTS_CUSTOMER_NAMES',
  MATTERS_CONTACTS_CUSTOMER_CONTACT = 'MATTERS_CONTACTS_CUSTOMER_CONTACT',
  MATTERS_CONTACTS_MANAGER = 'MATTERS_CONTACTS_MANAGER',
  CAPTCHA = 'CAPTCHA',
  CUSTOMER_LOGIN = 'CUSTOMER_LOGIN',
  OTP = 'OTP',
  CURRENT_CONTACT_DETAILS = 'CURRENT_CONTACT_DETAILS',
  CUSTOMER_MATTER = 'CUSTOMER_MATTER',
  MATTERS_CONTACT_TYPES = 'MATTERS_CONTACT_TYPES',
  MATTER_CONTACTS_AUDITOR_DETAILS = 'MATTER_CONTACTS_AUDITOR_DETAILS',
  MATTER_CONTACTS_MANAGER_DETAILS = 'MATTER_CONTACTS_MANAGER_DETAILS',
  MATTERS_CONTACTS_CLIENTS = 'MATTERS_CONTACTS_CLIENTS',
  MATTERS_CLIENTS_CONTACTS = 'MATTERS_CLIENTS_CONTACTS',
  SUBMIT_AUDIT_COMMENCEMENT_DOCUMENTS_CONTACTS = 'SUBMIT_AUDIT_COMMENCEMENT_DOCUMENTS_CONTACTS',
  RECIPIENT_CONTACTS = 'RECIPIENT_CONTACTS',
  ONBOARDING_DATA = 'ONBOARDING_DATA',
  ACTION_ITEMS = 'ACTION_ITEMS',
  MATTER_DOCUMENTS = 'MATTER_DOCUMENTS',
  CORRESPONDENCE_TEMPLATE = 'CORRESPONDENCE_TEMPLATE',
  HISTORY_LOGS = 'HISTORY_LOGS',
  CORRESPONDENCE_DETAILS = 'CORRESPONDENCE_DETAILS',
  SYSTEM_DATE_TIME = 'SYSTEM_DATE_TIME',
  MESSAGES = 'MESSAGES',
  MESSAGE_DETAILS = 'MESSAGE_DETAILS',
  PORTAL_CONTACT_SUMMARY = 'PORTAL_CONTACT_SUMMARY',
  MATTER_THREAD_DETAILS = 'MATTER_THREAD_DETAILS',
}
