import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  isRouteAccessible?: boolean;
  redirectRoute?: string;
}

const ProtectedRoute = ({
  isRouteAccessible = false,
  redirectRoute = '/',
}: ProtectedRouteProps): JSX.Element => {
  return isRouteAccessible ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  );
};

export default ProtectedRoute;
