import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

import { AuditMatterTerminatedData } from './types';

type AuditMatterTerminatedProps = {
  data: AuditMatterTerminatedData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  alertId: string;
};

export const AuditMatterTerminated = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
}: AuditMatterTerminatedProps) => {
  const { leadClientName, matterId } = data;

  const title = getContent('matterAlert.alert.audit.matter.terminated.title');
  const body = getContentWithReplacements(
    'matterAlert.alert.audit.matter.terminated.body',
    {
      matterId,
      leadClientName,
    },
  );

  return (
    <MatterAlert
      title={title}
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`${PATHS.matters}`}
      linkText="View my matters"
      onClick={() => {
        if (!read) updateStatus(alertId);
      }}
    />
  );
};
