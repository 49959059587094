import {
  ContentContainer,
  Table,
  TableContainer,
  TableHeader,
  tokens,
  utils,
} from '@snsw/react-component-library';
import styled, { css } from 'styled-components';

import { pxToRem, spacing } from './styles/index';

export const StyledContentContainer = styled(ContentContainer)`
  padding: ${tokens.spacing.xs}px 0;

  ${utils.getBreakpoint.md`
  padding: ${tokens.spacing.sm}px 0;
`}

  ${utils.getBreakpoint.lg`
padding: ${tokens.spacing.sm}px 0;
`}
`;

export const StyledHeaderContainer = styled(ContentContainer)`
  padding: ${tokens.spacing.sm}px 0;

  ${utils.getBreakpoint.md`
  padding: ${tokens.spacing.md}px 0;
`}

  ${utils.getBreakpoint.lg`
padding: ${tokens.spacing.lg}px 0;
`}
`;

export const StyledTableContainer = styled(TableContainer)`
  overflow: hidden;

  > div {
    overflow-x: hidden;
  }
  table {
    table-layout: fixed;
    width: auto;
  }
  > p {
    text-align: right;
  }

  td,
  th {
    white-space: nowrap;
  }
`;

export const StickyTable = styled(Table)<{
  offset?: number;
  stickyColumns?: number;
}>`
  td:nth-child(1),
  td:nth-child(2) {
    background-color: white;
  }

  th:nth-child(1),
  th:nth-child(2) {
    background-color: ${`${tokens.colors.grey.geyser}`};
  }
  ${({ stickyColumns, offset }) =>
    stickyColumns && stickyColumns === 1
      ? css`
          td:nth-child(1),
          th:nth-child(1) {
            position: sticky;
            left: 0;
            z-index: 1;
            border-right: 2px solid ${`${tokens.colors.grey.geyser}`};
          }
        `
      : css`
          td:nth-child(1),
          th:nth-child(1) {
            position: sticky;
            left: 0;
            z-index: 1;
          }

          td:nth-child(2),
          th:nth-child(2) {
            position: sticky;
            z-index: 1;
            left: ${offset || 0}px;
            border-right: 2px solid ${`${tokens.colors.grey.geyser}`};
          }
        `}
`;

export const StyledTableHeader = styled(TableHeader)`
  padding: ${spacing.sm};
`;

export const EllipsisText = styled.div<{ width?: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  width: ${({ width }) => (width ? `${pxToRem(width)}` : '200px')};
`;
