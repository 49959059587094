import { useEffect, useState } from 'react';
import { ComponentLoader } from '@snsw/react-component-library';
import { QueryKey } from 'src/api/constants';
import { updateNotificationContentWithMatterId } from 'src/components/alerts/helpers';
import NotificationSection from 'src/components/alerts/notificationsSection';
import { MatterNotificationContent } from 'src/components/alerts/types';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import { useMattersData } from 'src/screens/common/matters/hooks';

import {
  useInternalMatterNotifications,
  useUpdateInternalMatterNotificationAlertStatus,
  useUpdateMarkAllAsRead,
} from '../hooks';

export const InternalMatterNotifications = () => {
  const { matterUId } = useMatter();
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const [notificationContent, setNotificationContent] = useState<
    MatterNotificationContent[] | null
  >(null);
  const {
    isLoading,
    isError: notificationsError,
    data: notifications,
  } = useInternalMatterNotifications(matterUId);
  const { mutate: updateAlertStatus, isError: updateAlertStatusError } =
    useUpdateInternalMatterNotificationAlertStatus(matterUId);

  const { mutate: updateMarkRead, isError: errorUpdateMarkRead } =
    useUpdateMarkAllAsRead(matterUId, false);

  const updateMarkAllAsRead = async () => {
    await updateMarkRead();
  };
  const { data: mattersData, isError: mattersDataError } = useMattersData();
  const {
    updateMatterUId,
    updateMatterStatus,
    updateMatterCorrespondenceId,
    updateIsMatterClosed,
    updateIsMatterTerminated,
  } = useMatter();

  const updateStatus = async (id: string) => {
    await updateAlertStatus(id);
  };

  const updateMatterContext = (matterId: string, correspondenceId?: string) => {
    if (mattersData && mattersData.content) {
      const {
        portalMatterId,
        status,
        closed = false,
        terminated = false,
      } = mattersData.content.find(
        ({ matterId: matterIdFromResponse }) =>
          matterIdFromResponse === matterId,
      ) || {};

      if (portalMatterId) {
        updateMatterUId(portalMatterId);
      }

      if (status) {
        updateMatterStatus(status);
      }

      updateIsMatterClosed(closed);
      updateIsMatterTerminated(terminated);

      if (correspondenceId) {
        updateMatterCorrespondenceId(correspondenceId);
      }
    }
  };

  useEffect(() => {
    if (notifications && mattersData?.content) {
      setNotificationContent(
        updateNotificationContentWithMatterId(
          notifications,
          ['CUSTOMER_CORRESPONDENCE_READ', 'CUSTOMER_DOCUMENT_READ'],
          undefined,
          mattersData?.content,
        ),
      );
    }
  }, [notifications, mattersData]);

  useEffect(() => {
    if (errorUpdateMarkRead)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.INTERNAL_MATTER_NOTIFICATIONS}-POST` as ErrorKey,
        ]),
      ]);
  }, [errorUpdateMarkRead]);

  useEffect(() => {
    if (updateAlertStatusError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.INTERNAL_MATTER_NOTIFICATIONS}-POST` as ErrorKey,
        ]),
      ]);
  }, [updateAlertStatusError]);

  useEffect(() => {
    if (notificationsError || mattersDataError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.MATTER_NOTIFICATIONS}-${QueryKey.INTERNAL_MATTER_NOTIFICATIONS}-GET` as ErrorKey,
        ]),
      ]);
  }, [notificationsError, mattersDataError]);

  return (
    <>
      <ComponentLoader active={isLoading} fullPage />

      <ErrorHandler keys={errorKeys} />
      {notifications &&
        notifications.content.length > 0 &&
        notificationContent && (
          <NotificationSection
            notifications={notificationContent}
            updateStatus={updateStatus}
            updateMatterContext={updateMatterContext}
            enableMarkAllRead
            submitMarkAllAsRead={() => updateMarkAllAsRead()}
          />
        )}
    </>
  );
};
