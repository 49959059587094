import { formatDate, ListItemType } from 'ams-common';
import { RecipientsData } from 'src/hooks/types';
import getContent from 'src/utils/contentUtils';

export const getConfirmModalDescriptionList = ({
  customer,
  recipient,
  dueDate,
}: {
  customer: string;
  recipient: string;
  dueDate: string;
}): ListItemType[] => [
  {
    label: getContent(
      'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.customer.label',
    ),
    description: customer,
  },
  {
    label: getContent(
      'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.recipient.label',
    ),
    description: recipient,
  },
  {
    label: getContent(
      'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.dueDate.label',
    ),
    description: formatDate(dueDate),
  },
];

export const getSelectedCustomerName = (
  clientOptions:
    | {
        text: string;
        value: string;
      }[]
    | null,
  selectedClient: string,
) => {
  return (
    clientOptions?.find((option) => String(option.value) === selectedClient)
      ?.text || ''
  );
};

export const getSelectedRecipientName = (
  recipientOptions:
    | {
        text: string;
        value: string;
      }[]
    | null,
  selectedRecipient: string,
) => {
  if (!recipientOptions) {
    return [];
  }
  return (
    recipientOptions.find(
      (option) => String(option.value) === selectedRecipient,
    )?.text || ''
  );
};

export const getRecipientOptions = (
  recipientData: RecipientsData | null | undefined,
) => {
  if (!recipientData) {
    return null;
  }

  return recipientData.map((recipient) => ({
    text: recipient.contactName,
    value: recipient.id,
  }));
};
