export type CorrespondenceDocument = {
  documentId: string;
  documentName: string;
  documentSize: string;
};

export type CorrespondenceInitValues = {
  entities: string;
  recipient: string;
  dueDate: string;
  messageSubject: string;
  message: string;
  documents?: CorrespondenceDocument[];
};

export type RecipientOption = {
  text: string;
  value: string;
};

export type EditDocument = {
  documentName: string;
  documentId: string;
  clientId: string;
  classification: string;
  description: string;
};

export enum ModalType {
  Draft = 'Draft',
  Open = 'Open',
}

export enum ActionValues {
  AuditCommencement = 'audit_commencement',
  RequestInfo = 'request_info',
  PreliminaryFindings = 'preliminary_findings',
  FinalisationAdvice = 'finalisation_advice',
}
