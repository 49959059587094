const content = {
  'notificationGroup.modal.heading':
    'Mark all the unread notifications as read',
  'notificationGroup.modal.primary.button.title': 'Confirm',
  'notificationGroup.modal.secondary.button.title': 'Cancel',
  'notificationGroup.modal.text':
    'This action will mark all the unread notifications as read that cannot be marked as unread again. Please confirm to proceed.',
};

export default content;
