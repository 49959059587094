import * as t from 'io-ts';

export const UserProfileCodec = t.type({
  userId: t.string,
  userName: t.string,
  userRole: t.string,
  emailAddress: t.string,
  phoneNumber: t.string,
});

export type UserProfile = t.TypeOf<typeof UserProfileCodec>;
