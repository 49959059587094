import { useState } from 'react';
import { Heading, SectionPage } from '@snsw/react-component-library';
import Alert from 'src/components/alerts/alert';
import { MatterNotificationContent } from 'src/components/alerts/types';
import getContent, {
  combineContent,
  getContentWithReplacements,
} from 'src/utils/contentUtils';

import { ConfirmMarkReadModal } from './ConfirmMarkReadModal';
import { NoStyleButton } from './styles';

type NotificationGroupProps = {
  type: string;
  notifications: MatterNotificationContent[];
  updateStatus: (alertId: string) => void;
  enableMarkAllRead?: boolean;
  updateMatterContext?: (matterIdOrThreadId: string, threadId?: string) => void;
  submitMarkAllAsRead?: () => void;
};

export const NotificationGroup = ({
  type,
  notifications,
  updateStatus,
  enableMarkAllRead,
  updateMatterContext,
  submitMarkAllAsRead,
}: NotificationGroupProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return (
    <SectionPage>
      <Heading level={3}>
        {getContent(
          `notificationsSection.heading.${type}` as keyof typeof combineContent,
        )}
      </Heading>
      <p>
        {notifications.length <= 1
          ? getContentWithReplacements(
              'notificationsSection.heading.single.noOfNotifications',
              {
                type,
                noOfNotifications:
                  notifications.length === 0
                    ? 'No'
                    : String(notifications.length),
              },
            )
          : getContentWithReplacements(
              'notificationsSection.heading.multiple.noOfNotifications',
              {
                type,
                noOfNotifications: String(notifications.length),
              },
            )}
      </p>
      {enableMarkAllRead && type === 'new' && notifications.length > 0 && (
        <NoStyleButton onClick={() => setShowConfirmModal(true)}>
          Mark all as read
        </NoStyleButton>
      )}
      {showConfirmModal && (
        <ConfirmMarkReadModal
          close={() => setShowConfirmModal(false)}
          onConfirmMarkAsRead={submitMarkAllAsRead}
        />
      )}
      {notifications.map((notification) => (
        <Alert
          key={notification.id}
          notification={notification}
          updateStatus={updateStatus}
          updateMatterContext={updateMatterContext}
        />
      ))}
    </SectionPage>
  );
};
