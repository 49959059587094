import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

export const AutoLogoutModal = ({
  showAutoLogoutModal,
  onContinue,
}: {
  showAutoLogoutModal: boolean;
  onContinue: () => void;
}) => {
  return showAutoLogoutModal ? (
    <Modal
      id="autoLogoutModal"
      title={getContent('autoLogout.modal.title')}
      buttons={[
        {
          text: 'Continue',
          onClick: onContinue,
        },
      ]}
      description={getContent('autoLogout.modal.description')}
    />
  ) : null;
};
