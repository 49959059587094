import { FormikErrors } from 'formik';
import {
  CLASSIFICATION_OPTIONS_AUDITOR,
  CLASSIFICATION_OPTIONS_CUSTOMER,
} from 'src/constants';
import {
  DocumentDetails,
  MatterDocument,
  MatterDocuments,
  UploadDocument,
} from 'src/hooks/document/types';
import { MatterCorrespondenceDetailResponse } from 'src/hooks/types';

import { CLASSIFICATION_SECTIONS } from './constants';
import {
  FileData,
  SubmitResponseToAuditCommencementDocumentsFormValues,
  UploadedDocument,
} from './types';

export const getApiClassificationText = (sectionId: string) => {
  return (
    CLASSIFICATION_SECTIONS.find(
      ({ sectionId: classificationSectionId }) =>
        classificationSectionId === sectionId,
    )?.apiSectionName || ''
  );
};

export const getClassificationText = (sectionId: string) => {
  return (
    CLASSIFICATION_SECTIONS.find(
      ({ sectionId: classificationSectionId }) =>
        classificationSectionId === sectionId,
    )?.sectionName || ''
  );
};

export const getDocumentsPayload = (
  sections: Record<string, FileData[]>,
  options: {
    isUploaded: boolean;
  },
) => {
  const { isUploaded } = options;

  return Object.entries(sections).flatMap(([classification, records]) =>
    records
      .map(
        ({
          fileId,
          description,
          entity,
          fileName,
          isUploaded: uploaded,
          documentId,
        }) =>
          uploaded === isUploaded && {
            s3Key: fileId,
            ...(entity && { clients: [{ id: entity }] }),
            classification: getApiClassificationText(classification),
            description,
            name: fileName,
            ...(documentId && { documentId }),
          },
      )
      .filter((item) => !!item)
      .map((item) => {
        const { documentId, ...rest } = item as UploadDocument;
        return {
          ...(documentId && { id: documentId }),
          ...rest, // Keep other properties
        };
      }),
  ) as UploadDocument[];
};

export const getNewDocumentIds = (
  documents: {
    data: UploadedDocument[];
  } | null,
) => {
  if (!documents) {
    return null;
  }
  return documents.data.map((document) => ({
    id: document.id ?? '',
  }));
};

export const getAlreadyUploadedDocumentIds = (
  documents: UploadDocument[] | null,
) => {
  if (!documents) {
    return null;
  }
  return documents.map((document) => ({
    id: document.id ?? '',
  }));
};

export const getAuditCommencementDocumentsPayload = (
  values: SubmitResponseToAuditCommencementDocumentsFormValues,
  dueDate: string,
  documents: {
    data: UploadedDocument[];
  } | null,
  alreadyUploadedDocuments: UploadDocument[] | null,
) => {
  const { messageSubject, message } = values;
  const uploadedDocumentsIds = getAlreadyUploadedDocumentIds(
    alreadyUploadedDocuments,
  );
  const newDocumentsIds = getNewDocumentIds(documents);
  const allDocuments = [
    ...(uploadedDocumentsIds || []),
    ...(newDocumentsIds || []),
  ];

  const uniqueDocuments = allDocuments.filter(
    (doc, index, self) => index === self.findIndex((t) => t.id === doc.id),
  );

  return {
    corroType: 'AUDIT COMMENCEMENT DOCUMENTS',
    dueDate,
    ...(messageSubject && { subject: messageSubject }),
    ...(message && { messageBody: message }),
    documents: uniqueDocuments,
  };
};

export const getUploadedDocumentIds = (
  response: MatterCorrespondenceDetailResponse,
): string[] => {
  const { documents } = response || {};

  if (!documents) {
    return [];
  }

  return documents.map(({ id }) => id);
};

export const getResponseToAuditCommencementInitValues = (
  response: MatterCorrespondenceDetailResponse,
) => {
  const { subject, messageBody, initiatedBy } = response;
  return {
    messageSubject: subject ?? '',
    message: messageBody ?? '',
    recipient: initiatedBy ?? '',
    sections: null,
  };
};

export const getClassificationId = (sectionName: string) => {
  return (
    CLASSIFICATION_SECTIONS.find(
      ({ apiSectionName: classificationSectionName }) =>
        classificationSectionName === sectionName,
    )?.sectionId || ''
  );
};

export const constructSectionDetailsFromUploadedDocuments = (
  uploadedDocumentsDetails: DocumentDetails[],
) => {
  if (uploadedDocumentsDetails.length === 0) {
    return null;
  }

  return uploadedDocumentsDetails.reduce(
    (
      acc,
      { clients, s3Key, name, description, classification, contentLength, id },
    ) => {
      const sectionId = getClassificationId(classification);
      if (clients.length > 0) {
        clients.forEach(({ id: entity }) => {
          if (!acc[sectionId]) {
            acc[sectionId] = [];
          }
          acc[sectionId].push({
            documentId: id,
            fileId: s3Key,
            fileName: name,
            fileSize: contentLength,
            entity: `${entity}`,
            description,
            isUploaded: true,
          });
        });
      } else {
        acc[sectionId] = [];
        acc[sectionId].push({
          documentId: id,
          fileId: s3Key,
          fileName: name,
          fileSize: contentLength,
          entity: ``,
          description,
          isUploaded: true,
        });
      }

      return acc;
    },
    {} as {
      [key: string]: FileData[];
    },
  );
};

export const updateSectionValuesWithUpdatedDocumentIds = (
  sections: Record<string, FileData[]> | null,
  documents: UploadedDocument[],
) => {
  if (!sections) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.fromEntries(
    Object.entries(sections).map(([classification, records]) => [
      classification,
      records.map((record) => {
        const documentId = documents.find(
          (document) => document.s3Key === record.fileId,
        )?.id;
        return {
          ...record,
          ...(documentId && { documentId }),
        };
      }),
    ]),
  );
};

export const updateSummaryErrorMessages = (
  errors: FormikErrors<SubmitResponseToAuditCommencementDocumentsFormValues>,
) => {
  const selectEntityText = 'Select an entity';
  const selectDescriptionText = 'Description required';
  const updatedErrors = JSON.parse(
    JSON.stringify(errors),
  ) as FormikErrors<SubmitResponseToAuditCommencementDocumentsFormValues>;

  if (updatedErrors.sections && typeof updatedErrors.sections === 'object') {
    Object.keys(updatedErrors.sections || []).forEach((section) => {
      updatedErrors.sections![section] = updatedErrors.sections![
        section
      ].reduce((acc, item) => {
        if (!item) return acc;
        const sectionName = getClassificationText(section);

        const duplicateFileName = `Duplicate file under ${section}`;
        const fileAlreadyUploaded = `File already uploaded under ${section}`;
        if (item.entity === selectEntityText && !acc.entity) {
          acc.entity = `Select an entity or entities for the document(s) under ${sectionName}`;
        }
        if (item.description === selectDescriptionText && !acc.description) {
          acc.description = `Description(s) required for document(s) under ${sectionName}`;
        }
        if (item.fileName === duplicateFileName && !acc.fileName) {
          acc.fileName = `Duplicate file under ${sectionName}`;
        }
        if (item.fileName === fileAlreadyUploaded && !acc.fileName) {
          acc.fileName = `Please reupload the file with a different file name, as this file already exists under ${sectionName}`;
        }

        return acc;
      }, {});
    });
  }

  return updatedErrors;
};

export const flattenObject = (
  errors: FormikErrors<SubmitResponseToAuditCommencementDocumentsFormValues>,
  prefix = '',
) => {
  return Object.keys(errors).reduce((acc, key) => {
    const errorKey = `${prefix}${prefix ? '-' : ''}${key}`;
    if (Array.isArray(errors[key])) {
      errors[key].forEach((item, index) => {
        Object.assign(acc, flattenObject(item, `${errorKey}-${index}`));
      });
    } else if (typeof errors[key] === 'object' && errors[key] !== null) {
      Object.assign(acc, flattenObject(errors[key], errorKey));
    } else {
      acc[errorKey] = errors[key];
    }
    return acc;
  }, {});
};

export const groupDataByClassification = (data: MatterDocuments) =>
  data
    .filter(({ status }) => status === 'SENT')
    .reduce(
      (
        acc: {
          [key: string]: MatterDocuments;
        },
        document: MatterDocument,
      ) => {
        const { classification } = document;
        const classificationKey =
          [
            ...CLASSIFICATION_OPTIONS_AUDITOR,
            ...CLASSIFICATION_OPTIONS_CUSTOMER,
          ].find((classificationSection) => {
            return classificationSection.apiSectionName === classification;
          })?.sectionId || classification;
        if (!acc[classificationKey]) {
          acc[classificationKey] = [];
        }
        acc[classificationKey].push(document);
        return acc;
      },
      {},
    );
