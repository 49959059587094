import { StatusLabel } from '@snsw/react-component-library';
import { formatDate, ListItemType } from 'ams-common';
import { EditDueDateButton } from 'src/components/editDueDateButton';
import {
  MatterCorrespondenceResult,
  PortalContactSummary,
} from 'src/hooks/types';
import getContent from 'src/utils/contentUtils';

import { CLOSE_CORRESPONDENCE_STATUS, CORRESPONDENCE_TYPES } from './constants';
import {
  ButtonContainer,
  CorrespondenceType,
  RowWithButton,
  StyledEditIcon,
  StyledLink,
} from './styles';

export const getCorrespondenceTitleFromCorrespondenceKey = (key: string) =>
  CORRESPONDENCE_TYPES.find((item) => item.value === key)?.title;

export const getContentKeyFromCorrespondenceKey = (key: string) =>
  CORRESPONDENCE_TYPES.find((item) => item.value === key)?.contentKey;

export const getPathFromCorrespondenceKey = (key: string) =>
  CORRESPONDENCE_TYPES.find((item) => item.value === key)?.path;

export const getUniqueEntityNames = (portalContacts: PortalContactSummary[]) =>
  Array.from(new Set(portalContacts?.map((item) => item.clientName)));

export const getCorrespondenceDescriptionList = (
  record: MatterCorrespondenceResult,
  matterUId: string | null,
  updateMatterCorrespondenceThreadId: (id: string | null) => void,
  isCustomer: boolean,
  isDraft: boolean,
  link: string,
  isMatterClosed: boolean,
  portalContactsSummary?: PortalContactSummary[] | null,
): ListItemType[] => {
  if (!record) {
    return [];
  }

  const {
    threadId,
    corroType,
    threadStatus,
    lastSubject,
    dueDate,
    initiatedBy,
    initiatedDate,
    lastActionMessage,
  } = record;

  return [
    {
      label: getContent('correspondence.label.type'),
      description: (
        <b>
          <StyledLink
            to={link}
            onClick={() => {
              updateMatterCorrespondenceThreadId(threadId);
            }}
            style={{ textDecoration: 'none' }}
          >
            {isDraft ? <StatusLabel text="Draft" /> : null}
            {isDraft ? <StyledEditIcon color="secondaryBlue" /> : null}
            {corroType && (
              <CorrespondenceType>
                {getCorrespondenceTitleFromCorrespondenceKey(corroType)}
              </CorrespondenceType>
            )}
          </StyledLink>
        </b>
      ),
    },
    {
      label: getContent('correspondence.label.subject'),
      description: <b>{lastSubject}</b>,
    },
    {
      label: getContent('correspondence.label.entityName'),
      description:
        (portalContactsSummary &&
          getUniqueEntityNames(portalContactsSummary).join(', ')) ||
        '',
    },
    {
      label: getContent('correspondence.label.initiatedBy'),
      description: initiatedBy,
    },
    {
      label: getContent('correspondence.label.initiatedDate'),
      description: formatDate(initiatedDate, 'DD MMMM YYYY'),
    },
    {
      label: getContent('correspondence.label.status'),
      description: threadStatus,
    },
    {
      label: getContent('correspondence.label.dueDate'),
      description: (
        <RowWithButton>
          <ButtonContainer>
            {formatDate(dueDate, 'DD MMMM YYYY')}
            {!isCustomer &&
              !isDraft &&
              !isMatterClosed &&
              record.threadStatus !== CLOSE_CORRESPONDENCE_STATUS && (
                <EditDueDateButton
                  matterUId={matterUId || ''}
                  dueDate={dueDate || ''}
                  threadId={threadId}
                  corroType={corroType || ''}
                />
              )}
          </ButtonContainer>
        </RowWithButton>
      ),
    },
    {
      label: getContent('correspondence.label.lastAction'),
      description: (
        <RowWithButton>
          <span>{lastActionMessage}</span>
        </RowWithButton>
      ),
    },
  ];
};

export const handleCloseCorrespondence = async (
  threadId: string,
  setShowCloseCorroModal: (val: boolean) => void,
  closeCorrespondence: (threadId: string) => void,
): Promise<void> => {
  await closeCorrespondence(threadId);
  setShowCloseCorroModal(false);
};
