/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import {
  ComponentLoader,
  SectionPage,
  TextLink,
} from '@snsw/react-component-library';
import { PageTitle, StyledAMSContentContainer } from 'ams-common';
import { QueryKey } from 'src/api/constants';
import { ActionItemsTable } from 'src/components/actionItems/auditor/ActionItemsTable';
import Alert from 'src/components/alerts/alert';
import { updateNotificationContentWithMatterId } from 'src/components/alerts/helpers';
import { MatterNotificationContent } from 'src/components/alerts/types';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import { useMattersData } from 'src/screens/common/matters/hooks';
import getContent from 'src/utils/contentUtils';

import { useNotifications, useUpdateAlertStatus } from '../notifications/hooks';

export const Dashboard = () => {
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const {
    isLoading: isNotificationsLoading,
    isError: isNotificationsError,
    data: notifications,
  } = useNotifications();
  const { mutate: updateAlertStatus } = useUpdateAlertStatus();
  const { data: mattersData, isLoading: isMattersDataLoading } =
    useMattersData();
  const { updateMatterUId, updateMatterStatus, updateMatterCorrespondenceId } =
    useMatter();
  const [notificationContent, setNotificationContent] = useState<
    MatterNotificationContent[] | null
  >(null);

  const updateStatus = async (id: string) => {
    await updateAlertStatus(id);
  };

  const handleErrors = (errors: ErrorKey[]) => {
    errors.forEach((error) => {
      if (!errorKeys.includes(error)) setErrorKeys([...errorKeys, error]);
    });
  };
  useEffect(() => {
    if (notifications && mattersData?.content) {
      const updatedNotificationsContent = updateNotificationContentWithMatterId(
        notifications,
        ['CUSTOMER_CORRESPONDENCE_READ', 'CUSTOMER_DOCUMENT_READ'],
        undefined,
        mattersData?.content,
      );

      setNotificationContent(updatedNotificationsContent);
    }
  }, [notifications, mattersData]);

  useEffect(() => {
    if (isNotificationsError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.DASHBOARD}-${QueryKey.MATTER_ALERTS}-GET` as ErrorKey,
        ]),
      ]);
  }, [isNotificationsError]);

  const updateMatterContext = (matterId: string, correspondenceId?: string) => {
    if (mattersData && mattersData.content) {
      const { portalMatterId: matterUId, status } =
        mattersData.content.find(
          ({ matterId: matterIdFromResponse }) =>
            matterIdFromResponse === matterId,
        ) || {};

      if (matterUId) {
        updateMatterUId(matterUId);
      }

      if (status) {
        updateMatterStatus(status);
      }

      if (correspondenceId) {
        updateMatterCorrespondenceId(correspondenceId);
      }
    }
  };

  const dashboardIndex = 0;
  const unreadNotifications = notificationContent
    ? notificationContent.filter(({ read }) => !read)
    : [];
  const firstDashboardNotification = unreadNotifications[dashboardIndex];
  const remainingDashboardNotifications = [
    ...unreadNotifications.slice(0, dashboardIndex),
    ...unreadNotifications.slice(dashboardIndex + 1),
  ];

  return (
    <>
      <ComponentLoader
        active={isNotificationsLoading || isMattersDataLoading}
        fullPage
      />
      <PageTitle title={getContent('dashboard.heading.title')} />
      <StyledAMSContentContainer>
        <ErrorHandler keys={errorKeys} />
        <SectionPage>
          {firstDashboardNotification && (
            <Alert
              key={firstDashboardNotification.id}
              notification={firstDashboardNotification}
              updateStatus={updateStatus}
              updateMatterContext={updateMatterContext}
            />
          )}
          {remainingDashboardNotifications.length > 0 && (
            <p>
              You have{' '}
              <TextLink href="/ams-portal-ui/notifications">
                {remainingDashboardNotifications.length} more unread
                notifications
              </TextLink>
            </p>
          )}
        </SectionPage>
        <ActionItemsTable handleErrors={handleErrors} />
      </StyledAMSContentContainer>
    </>
  );
};
