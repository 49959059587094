import * as t from 'io-ts';

export function tOptional<T extends t.Mixed>(someType: T) {
  return t.union([t.null, t.undefined, someType]);
}

export enum UserLoginType {
  Staff = 'staff',
  Customer = 'customer',
}

export type ClassificationOptions = {
  text: string;
  value: string;
  apiSectionName: string;
  sectionId: string;
};
