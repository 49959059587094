const contents = {
  'matter.messages.new.message.heading': 'New Message',
  'matter.messages.new.message.description':
    'All fields must be completed, unless marked optional.',
  'matters.messages.sentOn.label': 'Sent On',
  'matters.messages.sentBy.label': 'Sent By',
  'matters.messages.recipients.label': 'Recipients',
  'matters.messages.details.message.label': 'Message:',
  'matters.messages.reply.message.reply.label': 'Message reply:',
};

export default contents;
