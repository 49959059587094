import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Heading,
  Notice,
  SectionPage,
} from '@snsw/react-component-library';
import { RootLayout } from 'ams-common';
import { StyledContentContainer } from 'ams-common/src/styles';
import environments from 'src/environments';
import { PATHS } from 'src/routes/constants';

export const LogoutSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isCustomerLoggedOut =
    location?.state?.[`${environments.appEnvironment}_logout_customer`] ||
    false;
  const link = isCustomerLoggedOut ? PATHS.login : `/${PATHS.staffLogin}`;
  const handleClick = (link: string) => {
    navigate(link, {
      replace: true,
    });
    window.location.reload();
  };

  return (
    <RootLayout showNavigation={false}>
      <StyledContentContainer>
        <SectionPage>
          <Notice success>
            <Notice.Title success>{`You're now logged out`}</Notice.Title>
          </Notice>
          <Heading level={3}>Login to Compliance Portal</Heading>
          <Button as="button" onClick={() => handleClick(link)}>
            Login
          </Button>
        </SectionPage>
      </StyledContentContainer>
    </RootLayout>
  );
};
