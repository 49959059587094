import { ListItemType } from 'ams-common';
import _ from 'lodash';
import { ContactDetails } from 'src/hooks/contact/types';
import { UserProfile } from 'src/hooks/types';
import { PATHS } from 'src/routes/constants';
import getContent, { combineContent } from 'src/utils/contentUtils';

import { CustomerContactClientProfile } from './CustomerContactDetails/types';
import {
  ClientFieldsLabels,
  CustomerProfileFieldsLabels,
  UserProfileFieldsLabels,
} from './constants';

export const getLabelDescriptionList = (data: UserProfile): ListItemType[] =>
  UserProfileFieldsLabels.map(({ key, label }) => {
    return {
      label,
      description: _.get(data, key),
    };
  }) as ListItemType[];

export const getCustomerProfileLabelDescriptionList = (
  data: ContactDetails,
): ListItemType[] =>
  CustomerProfileFieldsLabels.map(({ key, label }) => {
    return {
      label,
      description: _.get(data, key),
    };
  }) as ListItemType[];

export const getClientLabelDescriptionList = (
  data: CustomerContactClientProfile,
): ListItemType[] =>
  ClientFieldsLabels.map(({ key, label }) => {
    return {
      label,
      description: _.get(data, key),
    };
  }) as ListItemType[];

export const getMyProfileBreadcrumbs = (
  navigationTitle: 'contactDetails',
  subNavigationTitle?: 'updateContactDetails',
) => [
  {
    display: getContent('myprofile.breadcrumbs.text'),
    path: PATHS.myProfileCustomer,
  },
  {
    display: getContent(
      `myprofile.${navigationTitle}.breadcrumbs.text` as keyof typeof combineContent,
    ),
    path: PATHS.myProfileCustomerDetails,
  },
  ...(subNavigationTitle
    ? [
        {
          display: getContent(
            `myprofile.${subNavigationTitle}.breadcrumbs.text` as keyof typeof combineContent,
          ),
        },
      ]
    : []),
];
