import environments from 'src/environments';

import { FEEDBACK_ASSIST_HEADER_SCRIPT_ID } from './constants';
import useScript from './useScript';

const FeedbackAssist = () => {
  useScript(
    FEEDBACK_ASSIST_HEADER_SCRIPT_ID,
    environments.feedbackAssistSourceUrl,
    true,
    () => {
      window?.caBoootstrap?.init(environments.feedbackAssistFooterSourceUrl);
    },
    () => console.error('Script failed to load'),
  );

  return null;
};

export default FeedbackAssist;
