import { createContext } from 'react';
import { UserLoginType } from 'src/types';

export type AMSUserContextType = {
  userType: UserLoginType;
  clientId: string;
};

export const AMSUserContext = createContext<
  AMSUserContextType | null | undefined
>(null);
