const content = {
  'customerLoginForm.onboarding.heading.main': 'Log in to Compliance Portal',
  'customerAuthForm.otp.helpMessage':
    'Check your email for your one time password',
  'customerAuthForm.otp.errorMessage.required': 'One time password is required',
  'customerLoginForm.apiError': 'Failed to load login details',
  'otp.apiError': 'Failed to send otp',
  'customerLoginDetails.apiError': 'Failed to validate customer details',

  'customerLoginForm.otp.modal.title.text':
    'Your one time password has expired',
  'customerLoginForm.otp.modal.description.text':
    'Please request a new one time password,',
  'customerLoginForm.otp.modal.success.heading.text':
    'New one time password sent',
  'customerLoginForm.otp.modal.success.body.text':
    'Please check your e-mail for the new one time password to enter here',
};

export default content;
