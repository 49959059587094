export enum SubmitAuditCommencementDocumentsFieldLabels {
  DocumentName = 'DocumentName',
  Client = 'Client',
  Status = 'Status',
  UploadedDate = 'Uploaded Date',
  UploadedBy = 'Uploaded By',
  Classification = 'Classification',
  Description = 'Description',
  Recipient = 'Recipient',
  DueDate = 'Due Date',
  Message = 'Message',
}

export enum SubmitAuditCommencementDocumentsFieldNames {
  DocumentName = 'documentName',
  ClientId = 'clientId',
  ClientName = 'clientName',
  Status = 'status',
  UploadedDate = 'uploadedDate',
  UploadedBy = 'uploadedBy',
  Classification = 'classification',
  Description = 'description',
  Recipient = 'recipient',
  DueDate = 'dueDate',
  Message = 'message',
}

export type CorrespondenceFieldLabelKeys =
  keyof typeof SubmitAuditCommencementDocumentsFieldLabels;

export const RecipientOptions = [
  {
    text: 'Narayanan Durairajalu',
    value: 'Narayanan.Durairajalu@revenue.nsw.gov.au',
  },
  {
    text: 'Alia Palomata',
    value: 'Alia.Palomata@revenue.nsw.gov.au',
  },
  {
    text: 'Siva Gurelli',
    value: 'Siva.Gurelli@revenue.nsw.gov.au',
  },
  {
    text: 'Siv Chieng',
    value: 'Siv.Chieng@revenue.nsw.gov.au',
  },
  {
    text: 'Jenny Huynh',
    value: 'Jenny.Huynh@revenue.nsw.gov.au',
  },
  {
    text: 'Lisa Trench',
    value: 'Lisa.Trench@revenue.nsw.gov.au',
  },
  {
    text: 'Suraj Adhikari',
    value: 'Suraj.Adhikari@revenue.nsw.gov.au',
  },
  {
    text: 'Usha Padmanabhan',
    value: 'Usha.Padmanabhan@revenue.nsw.gov.au',
  },
  {
    text: 'Reginald Chand',
    value: 'Reginald.Chand@revenue.nsw.gov.au',
  },
  {
    text: 'Kaoser Ahmed',
    value: 'Kaoser.Ahmed@revenue.nsw.gov.au',
  },
  {
    text: 'Jessie Huynh',
    value: 'Jessie.Huynh@revenue.nsw.gov.au',
  },
  {
    text: 'Vivek Sundararaju',
    value: 'vivek.sundararaju@revenue.nsw.gov.au',
  },
  {
    text: 'Bhumika Dureja',
    value: 'Bhumika.Dureja@revenue.nsw.gov.au',
  },
  {
    text: 'Raghwendra Sonu',
    value: 'Raghwendra.Sonu@revenue.nsw.gov.au',
  },
];

export const DUE_DATE_EXTENSION_LIMIT_ACD = 28;
