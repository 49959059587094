import * as t from 'io-ts';

export const RecipientCodec = t.type({
  id: t.string,
  portalMatterId: t.string,
  clientId: t.number,
  contactId: t.number,
  clientName: t.string,
  contactName: t.string,
  contactEmail: t.string,
  complianceType: t.string,
  registrationStatus: t.string,
});

export const RecipientsCodec = t.array(RecipientCodec);
export type RecipientsData = t.TypeOf<typeof RecipientsCodec>;
export type RecipientData = t.TypeOf<typeof RecipientCodec>;
