import { useQuery } from 'react-query';
import { AMS_PORTAL_EXTERNAL_BFF, APIResource, queryApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import { MatterDocumentsCodec } from './types';

const fetch = (matterUId: string | null, isCustomer: boolean = false) => {
  return queryApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.documents,
      suffix: ['matters', `${matterUId}`, 'documents', 'basic'],
    },
    MatterDocumentsCodec,
  );
};

export const useMatterDocuments = (
  matterUId: string | null,
  isCustomer: boolean = false,
) =>
  useQuery(
    [QueryKey.MATTER_DOCUMENTS, matterUId],
    () => fetch(matterUId, isCustomer),
    {
      enabled: !!matterUId,
    },
  );
