const contents = {
  'matter.correspondence.deleteDocument.modal.id': 'delete-document',
  'matter.correspondence.deleteDocument.modal.title': 'Remove document',
  'matter.correspondence.deleteDocument.modal.help.text':
    'This document {documentName} will be removed permanently and cannot be recovered again. Are you sure to remove the document?',
  'matter.correspondence.deleteDocument.modal.primary.button': 'Cancel',
  'matter.correspondence.deleteDocument.modal.secondary.button': 'Remove',
};

export default contents;
