const contents = {
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.add.title':
    'Upload document',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.add.subtitle':
    'Please add document details before uploading to matter.',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.required.text':
    'All fields are required.',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.add.id':
    'upload-document',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.client.label':
    'Entity name',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.client.errorMessage':
    'Please select a customer',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.classification.label':
    'Classification',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.classification.id':
    'classification',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.classification.errorMessage':
    'Please select a classification',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.description.label':
    'Description',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.description.id':
    'description',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.description.errorMessage':
    'Please enter description',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.primary.button.add.label':
    'Upload',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.secondary.button.label':
    'Cancel',

  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.edit.id':
    'edit-document',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.edit.title':
    'Edit document details',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.edit.subtitle':
    'Please edit document details.',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.primary.button.edit.label':
    'Update',
  'matter.correspondence.submitAuditCommencementDocuments.uploadSingleDocument.modal.description.helpMessage':
    'Maximum 175 characters including spaces. {remChar} character(s) remaining',
};

export default contents;
