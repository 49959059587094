import { Button, Heading } from '@snsw/react-component-library';
import { spacing } from 'ams-common';
import styled from 'styled-components';

export const StyledHeading = styled(Heading)`
  margin-top: ${spacing.xxl};
`;

export const StyledButton = styled(Button)`
  margin-bottom: ${spacing.xxl};
`;
