import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  ComponentLoader,
  ContentContainer,
  Row,
} from '@snsw/react-component-library';
import { ErrorSummary } from 'ams-common';
import { useFormik } from 'formik';
import { QueryKey } from 'src/api/constants';
import { useClients } from 'src/components/clients/hooks';
import { DocumentsTable } from 'src/components/correspondence/documentsTable';
import { DocumentUploadContainer } from 'src/components/correspondence/documentUploadContainer';
import { Message } from 'src/components/correspondence/message';
import DeleteDocument from 'src/components/deleteDocument';
import DocumentModal from 'src/components/documentModal';
import { useMatterDocuments } from 'src/components/documentTableWithAccordion/hooks';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { InvalidFormatUploadFailure } from 'src/components/fileUploadErrors';
import { ScreenNames, VALID_FILE_FORMATS } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import { useMessage } from 'src/context/MessageContext';
import {
  useAuditorDetails,
  useCurrentContactDetails,
  useDocumentDetails,
  useMessagesDetails,
  useReplyToMessage,
  useUserContext,
  useUserProfile,
} from 'src/hooks';
import { useOnboarding } from 'src/hooks/contact/hooks';
import { Recipient } from 'src/hooks/types';
import { PATHS } from 'src/routes/constants';
import { UserLoginType } from 'src/types';
import { OperationType } from 'src/types/documentTypes';
import getContent from 'src/utils/contentUtils';

import { getMessageRecipients, getReplyMessageRequestBody } from './helpers';
import { MessageList } from './MessageList';
import {
  ButtonGroup,
  StyledErrorContainer,
  StyledMessageDetails,
  StyledMessageLabel,
  StyledMessageReply,
  StyledMessageReplyCol,
  StyledMessageReplyText,
  StyledMessageSubject,
  StyledRecipients,
} from './styles';
import { EditDocument, MessageReplyInitValues } from './types';
import { messageReplyValidationSchema } from './validation';

const initialValues: MessageReplyInitValues = {
  message: '',
  documents: [],
};

export const MessageReply = () => {
  const { messageSubject } = useMessage();
  const userContext = useUserContext();
  const { messageId = '' } = useParams();
  const navigate = useNavigate();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const [files, setFiles] = useState<File[] | null>();
  const [invalidFiles, setInvalidFiles] = useState<File[] | null>(null);
  const [selectedDocumentIdToRemove, setSelectedDocumentIdToRemove] =
    useState<string>('');
  const [selectedDocumentNameToRemove, setSelectedDocumentNameToRemove] =
    useState<string>('');
  const [openFilesUploadErrorModal, setOpenFilesUploadErrorModal] =
    useState<boolean>(false);
  const [selectedDocumentToEdit, setSelectedDocumentToEdit] =
    useState<string>('');
  const [editDocumentDetails, setEditDocumentDetails] =
    useState<EditDocument | null>(null);
  const [operationType, setOperationType] = useState<OperationType>(
    OperationType.Add,
  );

  const [refetchDocumentDetailsToggle, setRefetchDocumentDetailsToggle] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const errorSummaryRef = useRef<HTMLDivElement>(null);

  const { matterUId, matterId } = useMatter();
  const { clientOptions } = useClients(matterUId, isCustomer);

  const { data: messageDetailsData, isLoading: isMessageDetailsLoading } =
    useMessagesDetails(matterUId, messageId, isCustomer);

  const { data: documentsData, isLoading: matterDocumentsLoading } =
    useMatterDocuments(matterUId, isCustomer);
  const {
    data: documentDetailsToEdit,
    refetch: documentsDetailToEditRefetch,
    isLoading: isDocumentDetailsToEditLoading,
  } = useDocumentDetails(isCustomer, matterUId, selectedDocumentToEdit);

  const { data: auditorDetails, isLoading: isAuditorDetailsLoading } =
    useAuditorDetails(isCustomer);
  const {
    data: currentContactDetails,
    isLoading: isCurrentContactDetailsLoading,
  } = useCurrentContactDetails(isCustomer);

  const {
    data: auditorProfileDetails,
    isLoading: isAuditorProfileDetailsLoading,
  } = useUserProfile(isCustomer);

  const { data: onboardingData, isLoading: isOnboardingDataLoading } =
    useOnboarding(matterUId, isCustomer);

  const onReplyToMessageSuccess = (statusCode: number) => {
    if (statusCode === 201) {
      navigate(`${PATHS.matters}/${matterId}${PATHS.messages}`, {
        state: {
          sentMessageSuccess: 'Message sent successfully',
        },
      });
    }
  };

  const onReplyToMessageError = () => {
    setErrorKeys((prevErrorKeys) => [
      ...new Set([
        ...prevErrorKeys,
        `${ScreenNames.MESSAGE_SEND}-${QueryKey.MESSAGE_DETAILS}-POST` as ErrorKey,
      ]),
    ]);
  };

  const { mutate: replyToMessage, isLoading: isReplyToMessageLoading } =
    useReplyToMessage(
      matterUId,
      messageId,
      isCustomer,
      onReplyToMessageSuccess,
      onReplyToMessageError,
    );

  const {
    values,
    setValues,
    submitCount,
    errors,
    handleSubmit,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema: messageReplyValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      const replyMessageRequestBody = getReplyMessageRequestBody(values);
      await replyToMessage([replyMessageRequestBody]);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedDocumentToEdit) {
        return;
      }
      await documentsDetailToEditRefetch();
    };
    fetchData();
  }, [
    documentsDetailToEditRefetch,
    selectedDocumentToEdit,
    refetchDocumentDetailsToggle,
  ]);

  useEffect(() => {
    if (!documentDetailsToEdit) {
      return;
    }

    const { name, id, clients, classification, description } =
      documentDetailsToEdit;

    setEditDocumentDetails({
      documentName: name,
      documentId: id,
      clientId: clients.map(({ id }) => id).join(','),
      classification,
      description,
    });
  }, [documentDetailsToEdit, refetchDocumentDetailsToggle]);

  useEffect(() => {
    if (!isValid && submitCount > 0) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isValid, submitCount]);

  useEffect(() => {
    if (!messageDetailsData) {
      return;
    }

    if (!onboardingData) {
      return;
    }

    const allUniqueRecipients = Array.from(
      new Map(
        messageDetailsData.content
          .flatMap((item) => item.recipients)
          .filter(
            (recipient): recipient is NonNullable<typeof recipient> =>
              !!recipient,
          )
          .map((recipient) => [recipient.recipientId, recipient]),
      ).values(),
    );

    const onboardingContacts = onboardingData.flatMap((client) =>
      client.contacts.map((contact) => ({
        recipientName: contact.name || '',
        recipientId: `${contact.contactId}`,
        clientId: client.id,
        clientName: client.name,
      })),
    );

    const filteredOnboardedRecipients = onboardingContacts.filter(
      (contact) =>
        contact.recipientName === messageDetailsData.content[0].sentBy,
    );

    const mergedRecipients = [
      ...allUniqueRecipients,
      ...filteredOnboardedRecipients,
    ];

    const uniqueRecipients = mergedRecipients.filter(
      (recipient, index, self) =>
        index ===
        self.findIndex((r) => r.recipientId === recipient.recipientId),
    );

    setRecipients(uniqueRecipients);
  }, [messageDetailsData, onboardingData]);

  const handleFieldChange = ({
    value,
    field,
  }: {
    value: string;
    field: string;
  }) => {
    setValues({
      ...values,
      ...{ [field]: value },
    });
  };

  const handleFileUpload = async (uploadedFiles: File[]) => {
    const { validFiles, invalidFiles } = uploadedFiles.reduce(
      (acc, file) => {
        if (VALID_FILE_FORMATS.includes(file.type) && file.size !== 0) {
          acc.validFiles.push(file);
        } else {
          acc.invalidFiles.push(file);
        }
        return acc;
      },
      { validFiles: [] as File[], invalidFiles: [] as File[] },
    );

    if (invalidFiles.length > 0) {
      setOpenFilesUploadErrorModal(true);
    } else if (invalidFiles.length === 0) {
      setOpenDocumentModal(true);
    }

    setInvalidFiles(invalidFiles);
    setFiles(validFiles);
  };

  const handleDocumentEdit = ({ documentId }: { documentId: string }) => {
    setSelectedDocumentToEdit(documentId);
    setOperationType(OperationType.Edit);
    setOpenDocumentModal(true);
    setRefetchDocumentDetailsToggle((prev) => !prev);
  };

  const handleRemoveDocument = ({
    documentId,
    documentName,
  }: {
    documentId: string;
    documentName: string;
  }) => {
    setIsDeleteModalOpen(true);
    setSelectedDocumentIdToRemove(documentId);
    setSelectedDocumentNameToRemove(documentName);
  };

  const handleAfterDocumentDelete = (documentId?: string) =>
    setFieldValue('documents', [
      ...(values.documents ?? []).filter(
        ({ documentId: docId }) => docId !== documentId,
      ),
    ]);
  const handleCloseDocumentModal = () => {
    setOpenDocumentModal(false);
    setEditDocumentDetails(null);
    setOperationType(OperationType.Add);
    setOpenFilesUploadErrorModal(false);
    setInvalidFiles(null);
  };

  const handleCloseDeleteDocumentModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFileUploadValue = ({
    fileName,
    docId,
    fileSize,
  }: {
    fileName: string;
    docId: string | null;
    fileSize: number;
  }) => {
    if (!docId) {
      return;
    }
    setFieldValue('documents', [
      ...(values.documents ?? []),
      ...[
        {
          documentId: docId,
          documentName: fileName,
          documentSize: fileSize,
        },
      ],
    ]);
  };

  return (
    <ContentContainer>
      <ComponentLoader
        active={
          matterDocumentsLoading ||
          isDocumentDetailsToEditLoading ||
          isMessageDetailsLoading ||
          isAuditorDetailsLoading ||
          isReplyToMessageLoading ||
          isCurrentContactDetailsLoading ||
          isAuditorProfileDetailsLoading ||
          isOnboardingDataLoading
        }
        fullPage
      />
      <ErrorHandler keys={errorKeys} />
      <Row>
        <Col>
          <Row>
            <StyledMessageReply>
              <StyledMessageLabel>
                {getContent('matters.messages.reply.message.reply.label')}
              </StyledMessageLabel>
              <StyledMessageSubject>{messageSubject}</StyledMessageSubject>
            </StyledMessageReply>
          </Row>
          <Row>
            <StyledMessageReplyText>
              <span>
                {getContent('matter.messages.new.message.description')}
              </span>
            </StyledMessageReplyText>
          </Row>
        </Col>
      </Row>
      <Row>
        <StyledMessageReplyCol>
          <StyledRecipients>
            {getContent('matters.messages.recipients.label')}
          </StyledRecipients>
          <span>
            {getMessageRecipients(
              recipients,
              isCustomer,
              currentContactDetails,
              auditorDetails,
              auditorProfileDetails,
            )}
          </span>
        </StyledMessageReplyCol>
      </Row>
      <StyledErrorContainer
        ref={errorSummaryRef}
        showError={!isValid && submitCount > 0}
      />
      <ErrorSummary errors={errors} />
      <form>
        <Row>
          <Col span={8}>
            <Message
              value={values.message}
              errorMessage={errors.message}
              hasError={!!(submitCount > 0 && errors.message)}
              handleChange={handleFieldChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DocumentUploadContainer
              handleFileUpload={handleFileUpload}
              isCustomer={isCustomer}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DocumentsTable
              handleDocumentDelete={handleRemoveDocument}
              uploadedDocuments={values.documents}
              handleDocumentEdit={handleDocumentEdit}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <ButtonGroup>
              <Button variant="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>Send</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </form>
      <StyledMessageDetails>
        {messageDetailsData && (
          <MessageList data={messageDetailsData.content} isReadOnly />
        )}
      </StyledMessageDetails>
      {isDeleteModalOpen && (
        <DeleteDocument
          matterUId={matterUId}
          documentId={selectedDocumentIdToRemove}
          documentName={selectedDocumentNameToRemove}
          close={handleCloseDeleteDocumentModal}
          isOpen={isDeleteModalOpen}
          handleAfterDocumentDelete={handleAfterDocumentDelete}
          isCustomer={isCustomer}
        />
      )}
      {openDocumentModal && !isDocumentDetailsToEditLoading && (
        <DocumentModal
          matterUId={matterUId}
          close={handleCloseDocumentModal}
          handleFileUploadValue={handleFileUploadValue}
          documents={files || null}
          clientOptions={clientOptions}
          matterId={matterId}
          documentsData={documentsData}
          operationType={operationType}
          editDocumentDetails={editDocumentDetails}
        />
      )}
      <InvalidFormatUploadFailure
        open={openFilesUploadErrorModal}
        close={() => setOpenFilesUploadErrorModal(false)}
        onContinue={() => {
          if (files && files?.length > 0) {
            setOpenDocumentModal(true);
          } else {
            setOpenFilesUploadErrorModal(false);
          }
        }}
        invalidFiles={invalidFiles || []}
        validFiles={files || []}
      />
    </ContentContainer>
  );
};
