import { Modal } from '@snsw/react-component-library';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

type StatusChangeProps = {
  currentStatus: string;
  updatedStatus: string;
  handleStatusChangeSubmit: () => void;
  handleClose: () => void;
};

export const ConfirmStatusChangeModal = ({
  currentStatus,
  updatedStatus,
  handleStatusChangeSubmit,
  handleClose,
}: StatusChangeProps) => {
  return (
    <Modal
      title={getContent(`status.manager.modal.title`)}
      buttons={[
        {
          text: getContent(`status.manager.modal.button.primary`),
          onClick: handleStatusChangeSubmit,
        },
        {
          text: getContent('status.manager.modal.button.secondary'),
          onClick: handleClose,
        },
      ]}
      description={getContentWithReplacements(
        `status.manager.modal.description`,
        {
          currentStatus,
          updatedStatus,
        },
      )}
    />
  );
};
