import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ComponentLoader } from '@snsw/react-component-library';

import { CustomerAutoLogout } from './CustomerAutoLogout';
import { StaffAutoLogout } from './StaffAutoLogout';

interface MainAutoLogoutProps {
  isCustomerLogin: boolean;
  isStaffLogin: boolean;
}

export const MainAutoLogout = ({
  isCustomerLogin,
  isStaffLogin,
}: MainAutoLogoutProps) => {
  if (isCustomerLogin) {
    return (
      <CustomerAutoLogout>
        <Suspense fallback={<ComponentLoader />}>
          <Outlet />
        </Suspense>
      </CustomerAutoLogout>
    );
  }

  if (isStaffLogin) {
    return (
      <StaffAutoLogout>
        <Suspense fallback={<ComponentLoader />}>
          <Outlet />
        </Suspense>
      </StaffAutoLogout>
    );
  }

  return null; // or handle cases where neither is logged in
};
