import { FormGroup, Input } from '@snsw/react-component-library';

interface MessageSubjectProps {
  hasError: boolean;
  errorMessage?: string;
  value: string;
  placeholder: string;
  handleChange: ({ value, field }: { value: string; field: string }) => void;
}

export const MessageSubject = ({
  value,
  hasError,
  errorMessage,
  handleChange,
  placeholder,
}: MessageSubjectProps) => {
  return (
    <FormGroup
      errorMessage={errorMessage}
      id="subject"
      label="Message subject"
      hasError={hasError}
    >
      <Input
        name="messageSubject"
        value={value}
        inputWidth="xl"
        placeholder={placeholder}
        onChange={(e) =>
          handleChange({ field: 'messageSubject', value: e.target.value })
        }
        maxLength={100}
      />
    </FormGroup>
  );
};
