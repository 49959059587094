import * as t from 'io-ts';
import { tOptional } from 'src/types';

import { CustomerLoginFormFieldNames } from './constants';

export const CustomerLoginFormDataCodec = t.type({
  [CustomerLoginFormFieldNames.clientId]: tOptional(t.number),
  [CustomerLoginFormFieldNames.correspondenceId]: tOptional(t.number),
  [CustomerLoginFormFieldNames.emailAddress]: t.string,
  [CustomerLoginFormFieldNames.otp]: tOptional(t.number),
  [CustomerLoginFormFieldNames.termsAndConditions]: tOptional(t.string),
});

export type CustomerLoginFormData = t.TypeOf<typeof CustomerLoginFormDataCodec>;
