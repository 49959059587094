import {
  IconEdit,
  TableCell,
  TableContainer,
} from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import styled from 'styled-components';

export const StyledFileUploadContainer = styled.div`
  margin-top: ${spacing.lg};
`;

export const NoStyleButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  color: ${colours.text.link};
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  text-decoration-thickness: 1px;
  margin-left: 5px;

  svg {
    fill: ${colours.text.link};
    margin-right: 5px;
    margin-left: 0px;
  }

  p {
    margin: 0px;
    align-items: center;
  }
`;

export const StyledEditIcon = styled(IconEdit)`
  margin-left: ${spacing.sm};
  margin-right: 5px;
`;

export const StyledIconContainer = styled.p`
  display: flex;
  align-items: center;
`;

export const StyledTableContainer = styled(TableContainer)`
  div:first-of-type {
    overflow-x: hidden;
  }
`;

export const StyledTableCell = styled(TableCell)`
  width: 60%;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
`;
