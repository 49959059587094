export const PATHS = {
  home: '/home',
  dashboard: '/dashboard',
  matters: '/matters',
  matterOverview: '/overview',
  matterContacts: '/contacts',
  correspondence: '/correspondence',
  submitAuditCommencementDocuments: '/submit-audit-commencement-documents',
  requestForInformation: '/request-information',
  preliminaryFindings: '/preliminary-findings',
  finalisationAdvice: '/finalisation-advice',
  respondToAuditCommencementCorrespondence:
    '/respond-audit-commencement-correspondence',
  submitResponseToAuditCommencementDocuments:
    '/submit-response-to-audit-commencement-documents',
  documents: '/documents',
  messages: '/messages',
  messageDetails: '/message',
  messageReply: '/reply',
  activityLog: '/activityLog',
  notifications: '/notifications',
  login: '/login',
  loginCallback: '/login/callback',
  myProfile: '/my-profile',
  myProfileCustomer: '/my-profile/customer',
  myProfileCustomerDetails: '/my-profile/contact-details',
  myProfileCustomerDetailsForm:
    '/my-profile/contact-details/update-contact-form',
  matterNotifications: '/matter-notifications',
  statusManager: '/status-manager',
  staffLogin: '/?loginType=staff',
  logoutSuccess: '/logout/success',
  onboarding: '/onboarding',
  auditCommencement: '/audit-commencement',
  newMessage: '/new-message',
  threads: '/threads',
};
