import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import getContent from 'src/utils/contentUtils';

import { CustomerAuditMatterTransferredData } from './types';

type ExtendedCustomerAuditMatterTransferredData =
  CustomerAuditMatterTransferredData & {
    matterId: string;
  };

type CustomerAuditMatterTransferredProps = {
  data: ExtendedCustomerAuditMatterTransferredData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  alertId: string;
};

export const CustomerAuditMatterTransferred = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
}: CustomerAuditMatterTransferredProps) => {
  const { matterId } = data;

  const title = getContent('matterAlert.alert.audit.matter.transferred.title');
  const body = getContent(
    'matterAlert.alert.customer.audit.matter.transferred.from.body',
  );

  return (
    <MatterAlert
      title={title}
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`${PATHS.matters}/${matterId}${PATHS.matterContacts}`}
      linkText="View update"
      onClick={() => {
        if (!read) updateStatus(alertId);
      }}
    />
  );
};
