import { FormGroup, IconEdit } from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledEditIcon = styled(IconEdit)`
  margin-left: ${spacing.sm};
  margin-right: 5px;
`;

export const NoStyleButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  color: ${colours.text.link};
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  text-decoration-thickness: 1px;
`;

export const FormDateGroup = styled(FormGroup)<{
  hasError: boolean;
}>`
  label {
    display: none;
  }

  fieldset {
    label {
      display: block;
    }

    h3 {
      font-size: 110%;
      font-weight: 500;
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      input,
      select {
        border-color: ${colours.borders.error};
      }
    `}
`;
