import { Button, Row } from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import styled from 'styled-components';

export const DocumentLinksCard = styled.div`
  background-color: ${colours.backgrounds.selected};
  padding: ${spacing.sm};
  width: 60%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.xl};
  margin-top: ${spacing.lg};
`;

export const BackButton = styled(Button)``;

export const CloseCorroButton = styled(Button)`
  margin-top: ${spacing.md};
`;

export const MessageWrapper = styled.div`
  margin: ${spacing.md} 0;
  width: 60%;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
`;

export const StyledRow = styled(Row)`
  border-bottom: ${spacing.xs} solid ${colours.borders.focus};
  padding-bottom: 36px;
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledStatusLabelContainer = styled.div`
  margin-top: ${spacing.md};
`;
