import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  MethodTypes,
  queryApi,
  requestApi,
} from 'src/api';
import { QueryKey } from 'src/api/constants';
import { MatterCorrespondenceDetailResponseCodec } from 'src/hooks/types';

import { ResponseToAuditCommencementRequestBody, SaveAs } from './types';

const fetchResponseToAuditCommencementDocuments = (
  matterUId?: string | null,
  threadId?: string | null,
  correspondenceId?: string | null,
) => {
  return queryApi(
    {
      resourcePath: APIResource.correspondenceManager,
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      suffix: [
        'matters',
        `${matterUId}`,
        'threads',
        `${threadId}`,
        'correspondences',
        `${correspondenceId}`,
      ],
    },
    MatterCorrespondenceDetailResponseCodec,
  );
};

export const useResponseToAuditCommencementDocuments = (
  matterUId?: string | null,
  threadId?: string | null,
  correspondenceId?: string | null,
) => {
  const queryKey = [
    QueryKey.SUBMIT_AUDIT_COMMENCEMENT_DOCUMENTS,
    matterUId,
    threadId,
    correspondenceId,
  ];
  return useQuery(
    queryKey,
    () =>
      fetchResponseToAuditCommencementDocuments(
        matterUId,
        threadId,
        correspondenceId,
      ),
    {
      cacheTime: 0,
      enabled: !!matterUId && !!threadId && !!correspondenceId,
    },
  );
};

const upsertResponseToAuditCommencement = (
  matterUId: string,
  threadId: string,
  correspondenceId: string,
  payload: ResponseToAuditCommencementRequestBody,
  saveAs = SaveAs.Submit,
  isReply: boolean = false,
) => {
  const suffix = ['matters', matterUId, 'threads'];

  if (threadId) {
    suffix.push(threadId, 'correspondences');
    if (correspondenceId) {
      suffix.push(correspondenceId);
    } else if (!correspondenceId && !isReply) {
      suffix.push('response');
    }
    if (isReply) {
      suffix.push('reply');
    }
  }

  if (saveAs === SaveAs.Draft) {
    suffix.push(`draft`);
  }

  const endpointProps = !correspondenceId
    ? { verb: MethodTypes.POST }
    : {
        verb: MethodTypes.PUT,
      };
  const { verb } = endpointProps;
  return requestApi(
    {
      resourcePath: APIResource.correspondenceManager,
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      suffix,
    },
    verb,
    payload,
    undefined,
    undefined,
    {
      'content-type': 'application/json',
    },
  );
};

export const useCreateUpdateResponseToAuditCommencement = (
  navigateSuccess: (statusCode: number, saveAs: SaveAs) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ([
      matterUId,
      threadId,
      correspondenceId,
      payload,
      saveAs = SaveAs.Submit,
      isReply,
    ]: [
      string,
      string,
      string,
      ResponseToAuditCommencementRequestBody,
      SaveAs,
      boolean | undefined,
    ]) => {
      return upsertResponseToAuditCommencement(
        matterUId,
        threadId,
        correspondenceId,
        payload,
        saveAs,
        isReply,
      );
    },
    {
      onSuccess: (data, variables) => {
        const [matterUId, threadId, , , saveAs] = variables;
        queryClient.invalidateQueries([QueryKey.MATTER_DOCUMENTS, matterUId]);
        queryClient.invalidateQueries([
          QueryKey.MATTERS_CORRESPONDENCES,
          matterUId,
        ]);
        queryClient.invalidateQueries([
          QueryKey.MATTERS_CORRESPONDENCE_RESPONSES,
          matterUId,
          threadId,
        ]);
        navigateSuccess((data as any).status, saveAs);
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );
};
