import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

export const CloseCorrespondenceModal = ({
  open,
  handleCloseCorrespondence,
  close,
}: {
  open: boolean;
  handleCloseCorrespondence: () => Promise<void>;
  close: () => void;
}) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={getContent('correspondence.close.modal.title')}
      buttons={[
        {
          text: 'Confirm and close',
          onClick: handleCloseCorrespondence,
        },
        {
          text: 'Cancel',
          onClick: close,
        },
      ]}
      description={getContent('correspondence.close.modal.description')}
    />
  );
};
