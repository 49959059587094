import * as t from 'io-ts';
import { tOptional } from 'src/types';

export const UserProfileResultCodec = t.type({
  userId: t.string,
  fullName: t.string,
  role: tOptional(t.string),
  title: tOptional(t.string),
  position: tOptional(t.string),
  phoneNumber: tOptional(t.string),
  email: tOptional(t.string),
});

export type UserProfile = t.TypeOf<typeof UserProfileResultCodec>;
