import { SectionPage } from '@snsw/react-component-library';
import { colours, pxToRem, spacing } from 'ams-common';
import styled from 'styled-components';

export const NoStyleButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  color: ${colours.text.link};
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  padding: 5px;
`;

export const StyledSectionPage = styled(SectionPage)`
  p {
    line-height: 2;
    margin-bottom: ${pxToRem(24)};
  }
`;

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${spacing.xxl};
  gap: ${spacing.md};
`;

export const StyledErrorContainer = styled.div<{
  showError: boolean;
}>`
  div.error-content {
    ${({ showError }) => !showError && `display: none`}
  }
`;
