import { Modal } from '@snsw/react-component-library';
import { DocumentIcon } from 'ams-common';
import getContent from 'src/utils/contentUtils';

import { StyledFileName } from './styles';

interface VirusDetectedUploadFailureProps {
  open: boolean;
  close: () => void;
  documents: { documentName: string; documentS3Key: string }[];
}

export const VirusDetectedUploadFailure = ({
  open,
  close,
  documents,
}: VirusDetectedUploadFailureProps) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={getContent('matter.tabs.files.upload.error.title')}
      buttons={[
        {
          text: 'Ok',
          onClick: close,
        },
      ]}
      description={getContent(
        'matter.tabs.files.upload.error.virus.file.description',
      )}
    >
      {documents.map((document) => {
        return (
          <StyledFileName key={document.documentS3Key}>
            <DocumentIcon />
            {document.documentName}
          </StyledFileName>
        );
      })}
    </Modal>
  );
};
