import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIResource, MethodTypes, queryApi, requestApi } from 'src/api';
import { QueryKey } from 'src/api/constants';

import { HistoryLogsCodec, UpdateStatusValues } from './types';

const fetchHistoryLog = (matterUId: string | null) => {
  return queryApi(
    {
      resourcePath: APIResource.matters,
      suffix: ['matters', `${matterUId}`, 'statusHistory'],
    },
    HistoryLogsCodec,
  );
};

export const useHistoryLog = (matterUId: string | null) =>
  useQuery(
    [QueryKey.HISTORY_LOGS, matterUId],
    () => fetchHistoryLog(matterUId),
    {
      enabled: Boolean(matterUId),
    },
  );

const updateStatus = (
  matterUId: string | null,
  payload: UpdateStatusValues,
  requestType = MethodTypes.PUT,
) => {
  return requestApi(
    {
      resourcePath: APIResource.matters,
      suffix: ['matters', `${matterUId}`, 'status'],
    },
    requestType,
    payload,
    undefined,
    undefined,
    // passing headers

    {
      'content-type': 'application/json',
    },
  );
};

export const useUpdateStatus = (
  matterUId: string | null,
  navigateError: () => void,
  navigateSuccess: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ([payload, requestType = MethodTypes.PUT]: [
      UpdateStatusValues,
      MethodTypes,
    ]) => {
      return updateStatus(matterUId, payload, requestType);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.MATTERS]);
        queryClient.invalidateQueries([QueryKey.MATTERS_SUMMARY, matterUId]);
        queryClient.invalidateQueries([QueryKey.HISTORY_LOGS, matterUId]);
        navigateSuccess();
        console.log('Status successfully updated');
      },
      onError: () => {
        console.log('Status failed to update');
        navigateError();
      },
    },
  );
};
