import {
  AUDITOR_CONTACTS_CLIENTS_GET,
  AUDITOR_CONTACTS_MATTERS_CLIENTS_CONTACTS_GET,
  AUDITOR_CONTACTS_MATTERS_CONTACTS_MANAGER_GET,
  AUDITOR_CONTACTS_MATTERS_CONTACTS_MANAGER_POST,
  AUDITOR_CONTACTS_ONBOARDING_DATA_DELETE,
  AUDITOR_CONTACTS_ONBOARDING_DATA_GET,
  AUDITOR_CONTACTS_ONBOARDING_DATA_POST,
  AUDITOR_MATTER_DOCUMENTS_CLIENTS_GET,
  AUDITOR_MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET,
  AUDITOR_MATTER_DOCUMENTS_MATTERS_SUMMARY_GET,
  AUDITOR_OVERVIEW_MATTERS_SUMMARY_GET,
  CORRESPONDENCE_DETAILS_MATTERS_THREAD_GET,
  CORRESPONDENCE_DETAILS_MATTERS_THREAD_POST,
  CORRESPONDENCE_FORM_CLIENTS_GET,
  CORRESPONDENCE_FORM_CORRESPONDENCE_DETAILS_GET,
  CORRESPONDENCE_FORM_DUE_DATE_POST,
  CORRESPONDENCE_FORM_MATTER_CONTACTS_AUDITOR_DETAILS_GET,
  CORRESPONDENCE_FORM_MATTER_DOCUMENTS_GET,
  CORRESPONDENCE_FORM_MATTERS_CORRESPONDENCES_GET,
  CORRESPONDENCE_FORM_MATTERS_CORRESPONDENCES_POST,
  CORRESPONDENCE_FORM_MATTERS_DOCUMENT_GET,
  CORRESPONDENCE_FORM_MATTERS_DOCUMENTS_GET,
  CORRESPONDENCE_FORM_RECIPIENT_CONTACTS_GET,
  CORRESPONDENCE_MATTERS_CORRESPONDENCES_GET,
  CUSTOMER_CONTACTS_CUSTOMER_CONTACTS_DATA_GET,
  CUSTOMER_CONTACTS_MATTER_CONTACTS_AUDITOR_DETAILS_GET,
  CUSTOMER_CONTACTS_MATTER_CONTACTS_MANAGER_DETAILS_GET,
  CUSTOMER_LOGIN_POST,
  CUSTOMER_MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET,
  CUSTOMER_MATTER_DOCUMENTS_MATTERS_SUMMARY_GET,
  CUSTOMER_ONBOARDING_GET,
  CUSTOMER_OVERVIEW_ACTION_ITEMS_GET,
  CUSTOMER_OVERVIEW_EXTERNAL_MATTER_NOTIFICATIONS_GET,
  CUSTOMER_OVERVIEW_MATTERS_SUMMARY_GET,
  DASHBOARD_ACTION_ITEMS_GET,
  DASHBOARD_ACTION_ITEMS_PATCH,
  DASHBOARD_MATTER_ALERTS_GET,
  DASHBOARD_MATTERS_GET,
  INTERNAL_SERVER_ERROR,
  MATTER_DOCUMENTS_MATTER_DOCUMENTS_DELETE,
  MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET,
  MATTER_DOCUMENTS_TRIM_MATTER_DOCUMENTS_DELETE,
  MATTER_DOCUMENTS_TRIM_MATTER_DOCUMENTS_GET,
  MATTER_DOCUMENTS_TRIM_MATTER_TRIM_DOCUMENTS_GET,
  MATTER_DOCUMENTS_TRIM_MATTER_TRIM_DOCUMENTS_POST,
  MATTER_NOTIFICATIONS_EXTERNAL_MATTER_NOTIFICATIONS_GET,
  MATTER_NOTIFICATIONS_EXTERNAL_MATTER_NOTIFICATIONS_POST,
  MATTER_NOTIFICATIONS_INTERNAL_MATTER_NOTIFICATIONS_GET,
  MATTER_NOTIFICATIONS_INTERNAL_MATTER_NOTIFICATIONS_POST,
  MATTERS_MATTERS_GET,
  MESSAGE_DETAILS_MESSAGE_DETAILS_GET,
  MESSAGE_DETAILS_MESSAGE_DETAILS_POST,
  MESSAGE_SEND_MESSAGE_DETAILS_POST,
  MESSAGES_MESSAGES_GET,
  STATUS_MANAGER_HISTORY_LOGS_GET,
  STATUS_MANAGER_HISTORY_LOGS_POST,
  SUBMIT_AUDIT_CORRESPONDENCE_CLIENTS_GET,
  SUBMIT_AUDIT_CORRESPONDENCE_CORRESPONDENCE_DETAILS_GET,
  SUBMIT_AUDIT_CORRESPONDENCE_DUE_DATE_POST,
  SUBMIT_AUDIT_CORRESPONDENCE_MATTER_DOCUMENTS_GET,
  SUBMIT_AUDIT_CORRESPONDENCE_MATTERS_CORRESPONDENCES_POST,
  SUBMIT_AUDIT_CORRESPONDENCE_RECIPIENT_CONTACTS_GET,
} from './ErrorKeys';
import { ErrorKey } from './types';

export const ERROR_TYPE_MAPPER: Record<ErrorKey, string> = {
  [INTERNAL_SERVER_ERROR]:
    'Internal Server Error. Please try again after some time.',
  [DASHBOARD_ACTION_ITEMS_GET]:
    'Unable to display action items. Please try again after a few minutes.',
  [DASHBOARD_ACTION_ITEMS_PATCH]:
    'Unable to update internal due date. Please try again after a few minutes.',
  [DASHBOARD_MATTER_ALERTS_GET]:
    'Unable to display matter alerts. Please try again after a few minutes.',
  [DASHBOARD_MATTERS_GET]:
    'Unable to display matters. Please try again after a few minutes.',
  [MATTERS_MATTERS_GET]:
    'Unable to display matters list. Please try again after a few minutes.',
  [CUSTOMER_ONBOARDING_GET]:
    'Unable to process your request temporarily. Please try again after a few minutes.',
  [CUSTOMER_LOGIN_POST]:
    'Unable to process your request temporarily. Please try again after a few minutes.',
  [AUDITOR_OVERVIEW_MATTERS_SUMMARY_GET]:
    'Unable to display matter overview details. Please try again after a few minutes.',
  [CUSTOMER_OVERVIEW_MATTERS_SUMMARY_GET]:
    'Unable to display matter overview details. Please try again after a few minutes.',
  [CUSTOMER_OVERVIEW_ACTION_ITEMS_GET]:
    'Unable to display action items. Please try again after a few minutes.',
  [CUSTOMER_OVERVIEW_EXTERNAL_MATTER_NOTIFICATIONS_GET]:
    'Unable to display notifications. Please try again after a few minutes.',
  [CORRESPONDENCE_MATTERS_CORRESPONDENCES_GET]:
    'Unable to display matter correspondences. Please try again after a few minutes.',
  [CORRESPONDENCE_DETAILS_MATTERS_THREAD_GET]:
    'Unable to display matter correspondence details. Please try again after a few minutes.',
  [CORRESPONDENCE_DETAILS_MATTERS_THREAD_POST]:
    'Unable to upload correspondence to TRIM. Please try again after a few minutes.',
  [AUDITOR_CONTACTS_ONBOARDING_DATA_GET]:
    'Unable to display contact details. Please try again after a few minutes.',
  [AUDITOR_CONTACTS_ONBOARDING_DATA_POST]:
    'Unable to onboard contact. Please try again after a few minutes.',
  [AUDITOR_CONTACTS_MATTERS_CONTACTS_MANAGER_GET]:
    'Unable to display manager options. Please try again after a few minutes.',
  [AUDITOR_CONTACTS_MATTERS_CONTACTS_MANAGER_POST]:
    'Unable to update manager details. Please try again after a few minutes.',
  [AUDITOR_CONTACTS_MATTERS_CLIENTS_CONTACTS_GET]:
    'Unable to display client contact options. Please try again after a few minutes.',
  [AUDITOR_CONTACTS_CLIENTS_GET]:
    'Unable to display client options. Please try again after a few minutes.',
  [AUDITOR_CONTACTS_ONBOARDING_DATA_DELETE]:
    'Unable to delete contact. Please try again after a few minutes.',
  [CUSTOMER_CONTACTS_CUSTOMER_CONTACTS_DATA_GET]:
    'Unable to display contact details. Please try again after a few minutes.',
  [CUSTOMER_CONTACTS_MATTER_CONTACTS_MANAGER_DETAILS_GET]:
    'Unable to display manager details. Please try again after a few minutes.',
  [CUSTOMER_CONTACTS_MATTER_CONTACTS_AUDITOR_DETAILS_GET]:
    'Unable to display auditor details. Please try again after a few minutes.',
  [STATUS_MANAGER_HISTORY_LOGS_POST]:
    'Unable to update matter status. Please try again after a few minutes.',
  [STATUS_MANAGER_HISTORY_LOGS_GET]:
    'Unable to display history log. Please try again after a few minutes.',
  [MESSAGES_MESSAGES_GET]:
    'Unable to display matter messages. Please try again after a few minutes.',
  [MESSAGE_DETAILS_MESSAGE_DETAILS_GET]:
    'Unable to display message details. Please try again after a few minutes.',
  [MESSAGE_DETAILS_MESSAGE_DETAILS_POST]:
    'Unable to upload matter messages. Please try again after a few minutes.',
  [MESSAGE_SEND_MESSAGE_DETAILS_POST]:
    'Unable to send message. Please try again after a few minutes.',
  [MATTER_NOTIFICATIONS_INTERNAL_MATTER_NOTIFICATIONS_GET]:
    'Unable to display notifications. Please try again after a few minutes.',
  [MATTER_NOTIFICATIONS_INTERNAL_MATTER_NOTIFICATIONS_POST]:
    'Unable to update notifications. Please try again after a few minutes.',
  [MATTER_NOTIFICATIONS_EXTERNAL_MATTER_NOTIFICATIONS_GET]:
    'Unable to display notifications. Please try again after a few minutes.',
  [MATTER_NOTIFICATIONS_EXTERNAL_MATTER_NOTIFICATIONS_POST]:
    'Unable to update notifications. Please try again after a few minutes.',
  [CUSTOMER_MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET]:
    'Unable to display matter documents. Please try again after a few minutes.',
  [CUSTOMER_MATTER_DOCUMENTS_MATTERS_SUMMARY_GET]:
    'Unable to display matter documents. Please try again after a few minutes.',
  [AUDITOR_MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET]:
    'Unable to display matter documents. Please try again after a few minutes.',
  [AUDITOR_MATTER_DOCUMENTS_MATTERS_SUMMARY_GET]:
    'Unable to display matter documents. Please try again after a few minutes.',
  [AUDITOR_MATTER_DOCUMENTS_CLIENTS_GET]:
    'Unable to display matter documents. Please try again after a few minutes.',
  [MATTER_DOCUMENTS_TRIM_MATTER_TRIM_DOCUMENTS_POST]:
    'Unable to upload documents to trim. Please try again after a few minutes.',
  [MATTER_DOCUMENTS_TRIM_MATTER_TRIM_DOCUMENTS_GET]:
    'Unable to display trim documents. Please try again after a few minutes.',
  [MATTER_DOCUMENTS_MATTER_DOCUMENTS_GET]:
    'Unable to download documents. Please try again after a few minutes.',
  [MATTER_DOCUMENTS_TRIM_MATTER_DOCUMENTS_GET]:
    'Unable to download documents. Please try again after a few minutes.',
  [MATTER_DOCUMENTS_TRIM_MATTER_DOCUMENTS_DELETE]:
    'Unable to delete document. Please try again after a few minutes.',
  [MATTER_DOCUMENTS_MATTER_DOCUMENTS_DELETE]:
    'Unable to delete document. Please try again after a few minutes.',
  [SUBMIT_AUDIT_CORRESPONDENCE_MATTER_DOCUMENTS_GET]:
    'Unable to display matter documents. Please try again after a few minutes.',
  [SUBMIT_AUDIT_CORRESPONDENCE_MATTERS_CORRESPONDENCES_POST]:
    'Unable to process your request temporarily.  Please try again after a few minutes.',
  [SUBMIT_AUDIT_CORRESPONDENCE_CORRESPONDENCE_DETAILS_GET]:
    'Unable to load draft.  Please try again after a few minutes.',
  [SUBMIT_AUDIT_CORRESPONDENCE_CLIENTS_GET]:
    'Unable to display entity options.  Please try again after a few minutes.',
  [SUBMIT_AUDIT_CORRESPONDENCE_RECIPIENT_CONTACTS_GET]:
    'Unable to display recipient options.  Please try again after a few minutes.',
  [SUBMIT_AUDIT_CORRESPONDENCE_DUE_DATE_POST]:
    'Unable to process your request. Please correct due date and try again.',
  [CORRESPONDENCE_FORM_MATTERS_CORRESPONDENCES_POST]:
    'Unable to process your request temporarily.  Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_CORRESPONDENCE_DETAILS_GET]:
    'Unable to display draft.  Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_MATTERS_CORRESPONDENCES_GET]:
    'Unable to display form data.  Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_MATTERS_DOCUMENTS_GET]:
    'Unable to display matter documents. Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_MATTERS_DOCUMENT_GET]:
    'Unable to display document details. Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_CLIENTS_GET]:
    'Unable to display clients.  Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_MATTER_DOCUMENTS_GET]:
    'Unable to display matter documents. Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_MATTER_CONTACTS_AUDITOR_DETAILS_GET]:
    'Unable to display auditor contact details. Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_RECIPIENT_CONTACTS_GET]:
    'Unable to display recepient contacts. Please try again after a few minutes.',
  [CORRESPONDENCE_FORM_DUE_DATE_POST]:
    'Unable to process your request. Please correct due date and try again.',
};
