import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  MethodTypes,
  queryApi,
  requestApi,
} from 'src/api';
import { QueryKey } from 'src/api/constants';

import { MatterNotificationsCodec } from './types';

const fetchInternalMatterNotifications = (portalMatterId: string | null) => {
  return queryApi(
    {
      resourcePath: APIResource.notifications,
      suffix: ['matters', `${portalMatterId}`, 'internal-alerts'],
      params: {
        pageNumber: 0,
        pageSize: 99999,
      },
    },
    MatterNotificationsCodec,
  );
};

export const useInternalMatterNotifications = (
  portalMatterId: string | null,
) => {
  return useQuery(
    [QueryKey.INTERNAL_MATTER_NOTIFICATIONS, portalMatterId],
    () => fetchInternalMatterNotifications(portalMatterId),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      enabled: portalMatterId !== null,
    },
  );
};

const updateInternalMatterNotificationAlertStatus = (alertId: string) => {
  return requestApi(
    {
      resourcePath: APIResource.notifications,
      suffix: ['internal-alerts', `${alertId}`],
    },
    MethodTypes.PUT,
    alertId,
  );
};

export const useUpdateInternalMatterNotificationAlertStatus = (
  portalMatterId: string | null,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (alertId: string) => {
      return updateInternalMatterNotificationAlertStatus(alertId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryKey.INTERNAL_MATTER_NOTIFICATIONS,
          portalMatterId,
        ]);
      },
      onError: (error) => {
        console.log('Failed to update alert status');
        console.log(error);
      },
    },
  );
};

const fetchExternalMatterNotifications = () => {
  return queryApi(
    {
      resourcePath: APIResource.notifications,
      suffix: ['external-alerts'],
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
      params: {
        pageNumber: 0,
        pageSize: 99999,
      },
    },
    MatterNotificationsCodec,
  );
};

export const useExternalMatterNotifications = () => {
  return useQuery(
    [QueryKey.EXTERNAL_MATTER_NOTIFICATIONS],
    () => fetchExternalMatterNotifications(),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
    },
  );
};

const updateExternalMatterNotificationAlertStatus = (alertId: string) => {
  return requestApi(
    {
      resourcePath: APIResource.notifications,
      suffix: ['external-alerts', `${alertId}`],
      baseUrl: AMS_PORTAL_EXTERNAL_BFF,
    },
    MethodTypes.PUT,
    alertId,
  );
};

export const useUpdateExternalMatterNotificationAlertStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (alertId: string) => {
      return updateExternalMatterNotificationAlertStatus(alertId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.EXTERNAL_MATTER_NOTIFICATIONS]);
      },
      onError: (error) => {
        console.log('Failed to update alert status');
        console.log(error);
      },
    },
  );
};

const updateMarkAllAsRead = (
  portalMatterId: string | null,
  isCustomer: boolean,
) => {
  return requestApi(
    {
      resourcePath: APIResource.notifications,
      suffix: [
        'matters',
        `${portalMatterId}`,
        isCustomer ? 'external-alerts' : 'internal-alerts',
        `unread`,
      ],
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
    },
    MethodTypes.PUT,
  );
};

export const useUpdateMarkAllAsRead = (
  portalMatterId: string | null,
  isCustomer: boolean,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return updateMarkAllAsRead(portalMatterId, isCustomer);
    },
    {
      onSuccess: () => {
        if (isCustomer)
          queryClient.invalidateQueries([
            QueryKey.EXTERNAL_MATTER_NOTIFICATIONS,
          ]);
        if (!isCustomer)
          queryClient.invalidateQueries([
            QueryKey.INTERNAL_MATTER_NOTIFICATIONS,
          ]);
      },
      onError: (error) => {
        console.log('Failed to mark all as read');
        console.log(error);
      },
    },
  );
};
