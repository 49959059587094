import { useNavigate } from 'react-router-dom';
import {
  IconSort,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@snsw/react-component-library';
import { formatDate } from 'ams-common';
import { useMessage } from 'src/context/MessageContext';
import { MessagesContent } from 'src/hooks/types';
import { PATHS } from 'src/routes/constants';

import {
  MessageFieldLabels,
  MessageFieldNames,
  SortedFields,
} from './constants';
import {
  StyledHoverText,
  StyledIcon,
  StyledIconWithNumContainer,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHeader,
  StyledTooltipText,
} from './styles';

export const MessagesTable = ({
  data,
  sortData,
  matterId,
}: {
  data: MessagesContent;
  sortData: (fieldName: string) => void;
  matterId: string | null;
}) => {
  const { updateMessageSubject } = useMessage();
  const navigate = useNavigate();
  const renderTableHeader = () => (
    <TableRow>
      {Object.keys(MessageFieldLabels).map((key) => (
        <TableHeader key={key}>
          <StyledTableHeader>
            {SortedFields.includes(key) && (
              <IconSort
                onClick={() => sortData(MessageFieldNames[key])}
                key={key}
              />
            )}
            <span>{MessageFieldLabels[key]}</span>
          </StyledTableHeader>
        </TableHeader>
      ))}
    </TableRow>
  );

  const renderTableBody = () =>
    data?.map((item) => (
      <TableRow key={item.id}>
        {Object.keys(MessageFieldLabels).map((key) => {
          let clientsList: string = '';
          let entityNameList: string = '';

          if (key === 'clients') {
            clientsList = item[key]
              .reduce<number[]>((acc, { clientName, clientId }) => {
                if (clientName) {
                  acc.push(clientId);
                }
                return acc;
              }, [])
              .join(', ');
          }
          if (key === 'entityName') {
            entityNameList = item.clients
              .reduce<string[]>((acc, { clientName }) => {
                if (clientName) {
                  acc.push(clientName);
                }
                return acc;
              }, [])
              .join(', ');
          }
          return (
            <StyledTableCell
              key={key}
              isRead={item.unreadMessageCount === 0}
              isSubject={key === 'subject'}
            >
              <StyledIconWithNumContainer
                onClick={() => {
                  updateMessageSubject(item.subject);
                  navigate(
                    `${PATHS.matters}/${matterId}${PATHS.messages}/${item.id}/${PATHS.messageDetails}`,
                  );
                }}
              >
                {key === 'lastActionBy' && item.unreadMessageCount !== 0 ? (
                  <StyledIcon>{item.unreadMessageCount}</StyledIcon>
                ) : null}
                <div>
                  {((key !== 'subject' && item[key]?.length > 18) ||
                    (key === 'subject' && item[key]?.length > 43) ||
                    clientsList.length > 18 ||
                    entityNameList.length > 15) &&
                    key !== 'lastActionDate' && (
                      <StyledHoverText>
                        {(key === 'subject' || key === 'lastActionBy') &&
                          item[key]}
                        {key === 'clients' && `Client ID ${clientsList}`}
                        {key === 'entityName' && entityNameList}
                      </StyledHoverText>
                    )}

                  <StyledTooltipText width={key === 'subject' ? 350 : 150}>
                    {key === 'entityName' && entityNameList}
                    {key === 'lastActionDate' &&
                      formatDate(item[key], 'DD MMM YYYY')}
                    {key === 'clients' && clientsList}
                    {(key === 'subject' || key === 'lastActionBy') && item[key]}
                  </StyledTooltipText>
                </div>
              </StyledIconWithNumContainer>
            </StyledTableCell>
          );
        })}
      </TableRow>
    ));

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>{renderTableHeader()}</TableHead>
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </StyledTableContainer>
  );
};
