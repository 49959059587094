/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum MatterSummaryFieldsName {
  portalMatterId = 'id',
  matterId = 'matterId',
  noiDate = 'noiDate',
  lastActionDate = 'lastActionDate',
  lastActionBy = 'lastActionBy',
  lastActionType = 'lastActionType',
  auditTeam = 'team',
  auditType = 'auditType',
  projectId = 'projectID',
  projectName = 'name',
  caseProject = 'caseProject',
  leadClient = 'leadClient',
  leadClientId = 'id',
  leadClientName = 'name',
  leadClientMarsCaseId = 'marsCaseId',
  clients = 'clients',
  clientId = 'id',
  clientName = 'name',
  clientMarsCaseId = 'marsCaseId',
  amsLinks = 'links',
  amsLinkName = 'name',
  amsLinkUrl = 'url',
  matterStatus = 'status',
  managerId = 'managerId',
  managerName = 'managerName',
}

export const MatterSummaryFieldsLabels = [
  {
    key: `${MatterSummaryFieldsName.leadClient}.${MatterSummaryFieldsName.leadClientName}`,
    label: 'Lead customer name',
  },
  {
    key: `${MatterSummaryFieldsName.leadClient}.${MatterSummaryFieldsName.leadClientId}`,
    label: 'Lead client ID',
  },
  {
    key: `${MatterSummaryFieldsName.clients}`,
    label: 'Associated customers',
  },
  {
    key: `${MatterSummaryFieldsName.noiDate}`,
    label: 'NOI/UFC date',
    dateFormat: 'DD MMMM YYYY',
  },
  {
    key: `${MatterSummaryFieldsName.lastActionDate}`,
    label: 'Last action date',
    dateFormat: 'DD MMMM YYYY hh:mm A',
  },
  {
    key: `${MatterSummaryFieldsName.lastActionType}`,
    label: 'Last action type',
  },
  {
    key: `${MatterSummaryFieldsName.lastActionBy}`,
    label: 'Last action by',
  },
  {
    key: `${MatterSummaryFieldsName.auditTeam}`,
    label: 'Team',
  },
  {
    key: `${MatterSummaryFieldsName.auditType}`,
    label: 'Audit type',
  },
  {
    key: `${MatterSummaryFieldsName.caseProject}.${MatterSummaryFieldsName.projectName}`,
    label: 'Project',
  },
];

export const AuditSummaryFieldsLabels = [
  {
    key: `${MatterSummaryFieldsName.leadClient}.${MatterSummaryFieldsName.leadClientName}`,
    label: 'Lead entity name',
  },
  {
    key: `${MatterSummaryFieldsName.leadClient}.${MatterSummaryFieldsName.leadClientId}`,
    label: 'Lead client ID',
  },
  {
    key: `${MatterSummaryFieldsName.clients}`,
    label: 'Associated entity name(s)',
  },
];

export const AMS_LINK_NAME = 'AMS Link';
