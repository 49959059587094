import { createContext, ReactNode, useContext, useState } from 'react';

interface MessageContextType {
  messageSubject: string | null;
  updateMessageSubject: (status: string) => void;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

export const useMessage = (): MessageContextType => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessage must be used within a MessageContextProvider');
  }
  return context;
};

interface MessageContextProviderProps {
  children: ReactNode;
}

export const MessageContextProvider = ({
  children,
}: MessageContextProviderProps): JSX.Element => {
  const [messageSubject, setMessageSubject] = useState<string | null>(null);

  const updateMessageSubject = (messageSubject: string) => {
    setMessageSubject(messageSubject);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: MessageContextType = {
    messageSubject,
    updateMessageSubject,
  };

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  );
};
