import jsPDF from 'jspdf';

const createPageCanvas = (
  canvas: HTMLCanvasElement,
  page: number,
  pxPageHeight: number,
  pxFullHeight: number,
): HTMLCanvasElement => {
  const pageCanvas = document.createElement('canvas');
  const pageCtx = pageCanvas.getContext('2d');
  pageCanvas.width = canvas.width;

  if (
    page === Math.ceil(pxFullHeight / pxPageHeight) - 1 &&
    pxFullHeight % pxPageHeight !== 0
  ) {
    pageCanvas.height = pxFullHeight % pxPageHeight;
  } else {
    pageCanvas.height = pxPageHeight;
  }

  const w = pageCanvas.width;
  const h = pageCanvas.height;

  if (pageCtx) {
    pageCtx.fillStyle = 'white';
    pageCtx.fillRect(0, 0, w, h);
    pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
  }

  return pageCanvas;
};

export const createPDF = async (canvas: HTMLCanvasElement): Promise<jsPDF> => {
  const imageConfig = { type: 'jpeg', quality: 0.98 };
  const margins: [number, number] = [0.5, 0.5];
  const pdfDimensions = { width: 8.5, height: 11 };
  const innerWidth = pdfDimensions.width - margins[0] * 2;

  const canvasHeight = canvas.height;
  const canvasPageHeight = Math.floor(
    canvas.width * (pdfDimensions.height / pdfDimensions.width),
  );
  const totalPages = Math.ceil(canvasHeight / canvasPageHeight);

  // eslint-disable-next-line new-cap
  const pdf = new jsPDF('p', 'in', [pdfDimensions.width, pdfDimensions.height]);

  // eslint-disable-next-line no-plusplus
  for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
    const pageCanvas = createPageCanvas(
      canvas,
      pageIndex,
      canvasPageHeight,
      canvasHeight,
    );

    const adjustedPageHeight =
      (pageCanvas.height * innerWidth) / pageCanvas.width;

    if (pageIndex > 0) pdf.addPage();

    const imgData = pageCanvas.toDataURL(
      `image/${imageConfig.type}`,
      imageConfig.quality,
    );
    pdf.addImage(
      imgData,
      imageConfig.type,
      margins[1],
      margins[0],
      innerWidth,
      adjustedPageHeight,
    );
  }

  return pdf;
};
