import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { NewMessageData } from './types';

type NewMessageProps = {
  data: NewMessageData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  alertId: string;
};

export const NewMessage = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
}: NewMessageProps) => {
  const { messageId, contactName, matterId } = data;

  const body = getContentWithReplacements(
    'matterAlert.alert.new.message.received',
    {
      contactName,
      matterId,
    },
  );

  return (
    <MatterAlert
      title="New message received"
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`${PATHS.matters}/${matterId}${PATHS.messages}/${messageId}`}
      linkText="View message"
      onClick={() => {
        if (!read) updateStatus(alertId);
      }}
    />
  );
};
