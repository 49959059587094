import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import environments from 'src/environments';

import { AutoLogoutModal } from './AutoLogoutModal';
import {
  CONTINUE_KEY_PREFIX,
  EVENTS,
  IDLE_TIME_MS,
  LAST_ACTIVITY_KEY_PREFIX,
  LOGOUT_KEY_PREFIX,
  MODAL_AUTO_LOGOUT_MS,
  SHOW_MODAL_TIME_MS,
  USER_TYPES,
} from './constants';

interface BaseAutoLogoutProps {
  onLogout: () => Promise<void>;
  children: ReactNode;
  userType: (typeof USER_TYPES)[keyof typeof USER_TYPES];
}

export const BaseAutoLogout = ({
  onLogout,
  children,
  userType,
}: BaseAutoLogoutProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLogout, setLogout] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const autoLogoutTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const resetAutoLogoutTimer = useCallback(() => {
    if (autoLogoutTimerRef.current) {
      clearTimeout(autoLogoutTimerRef.current);
      autoLogoutTimerRef.current = null;
    }
  }, []);
  const startAutoLogoutTimer = useCallback(() => {
    resetAutoLogoutTimer();
    autoLogoutTimerRef.current = setTimeout(() => {
      setLogout(true);
    }, MODAL_AUTO_LOGOUT_MS);
  }, [resetAutoLogoutTimer]);

  const startIdleTimer = useCallback(() => {
    resetTimer();
    timerRef.current = setTimeout(() => {
      setModalOpen(true);
      startAutoLogoutTimer();
    }, IDLE_TIME_MS);
  }, [resetTimer, startAutoLogoutTimer]);

  useEffect(() => {
    if (isLogout) {
      onLogout();
    }
  }, [isLogout, onLogout]);

  const lastActivityKey = `${environments.appEnvironment}_${LAST_ACTIVITY_KEY_PREFIX}${userType}`;
  const logoutKey = `${environments.appEnvironment}_${LOGOUT_KEY_PREFIX}${userType}`;
  const continueKey = `${environments.appEnvironment}_${CONTINUE_KEY_PREFIX}${userType}`;

  useEffect(() => {
    const eventHandler = () => {
      resetTimer();
      startIdleTimer();
      localStorage.setItem(lastActivityKey, Date.now().toString());
    };

    const storageEventHandler = (event: StorageEvent) => {
      if (event.key === logoutKey && event.newValue === 'true') {
        setLogout(true);
      } else if (event.key === continueKey && event.newValue === 'true') {
        setModalOpen(false);
        resetAutoLogoutTimer();
      }
    };

    Object.values(EVENTS).forEach((event) => {
      window.addEventListener(event, eventHandler);
    });

    window.addEventListener('storage', storageEventHandler);

    startIdleTimer();

    return () => {
      Object.values(EVENTS).forEach((event) => {
        window.removeEventListener(event, eventHandler);
      });
      window.removeEventListener('storage', storageEventHandler);
      resetTimer();
    };
  }, [
    resetTimer,
    startIdleTimer,
    lastActivityKey,
    logoutKey,
    continueKey,
    resetAutoLogoutTimer,
  ]);

  useEffect(() => {
    let modalTimer: ReturnType<typeof setTimeout> | undefined;

    if (isModalOpen) {
      modalTimer = setTimeout(() => {
        setLogout(true);
      }, SHOW_MODAL_TIME_MS);
    }

    return () => {
      if (modalTimer) {
        clearTimeout(modalTimer);
      }
      resetAutoLogoutTimer();
    };
  }, [isModalOpen, resetAutoLogoutTimer]);

  return (
    <div>
      {children}
      {isModalOpen && (
        <AutoLogoutModal
          showAutoLogoutModal={isModalOpen}
          onContinue={() => {
            localStorage.setItem(continueKey, 'true');
            setModalOpen(false);
            setLogout(false);
            startIdleTimer();
            setTimeout(() => {
              localStorage.removeItem(continueKey);
            }, 500);
          }}
        />
      )}
    </div>
  );
};
