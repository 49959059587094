import { ContentContainer } from '@snsw/react-component-library';
import { PageTitle } from 'ams-common';
import { useUserContext } from 'src/hooks';
import { UserLoginType } from 'src/types';
import getContent from 'src/utils/contentUtils';

import { CustomerContactDetails } from './CustomerContactDetails';
import { OutOfOfficeDetailsForm } from './OutOfOfficeDetails';
import { UserDetails } from './UserDetails';

export const MyProfile = () => {
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  return (
    <>
      <PageTitle title={getContent('myprofile.heading')} />
      <ContentContainer>
        {!isCustomer ? (
          <>
            <UserDetails />
            <OutOfOfficeDetailsForm />
          </>
        ) : (
          <CustomerContactDetails />
        )}
      </ContentContainer>
    </>
  );
};
