// Features
import { ReactNode } from 'react';
import combineComponentsContent from 'src/components/content';
import combineScreensContent from 'src/screens/content';

// eslint-disable-next-line import/no-cycle
import { getContentHtmlUtil } from './html.contentUtils';

export const combineContent = {
  // Features
  ...combineScreensContent,
  ...combineComponentsContent,
};

const getContent = <T extends keyof typeof combineContent>(key: T) => {
  const { [key]: value } = combineContent;
  return value;
};

type ReplacementValues = {
  [key: string]: string;
};

export const getContentWithReplacements = <
  T extends keyof typeof combineContent,
>(
  key: T,
  replacements: ReplacementValues,
) => {
  const content = getContent(key);
  return Object.keys(replacements).reduce(
    (result, replacementKey) =>
      result.replace(`{${replacementKey}}`, replacements[replacementKey]),
    content,
  );
};

export const getContentWithHtml = <T extends keyof typeof combineContent>(
  key: T,
): ReactNode => getContentHtmlUtil(key);

export default getContent;
