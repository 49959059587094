import { Modal } from '@snsw/react-component-library';
import {
  MatterCorrespondenceUploadToTrimData,
  MatterMessageUploadToTrimData,
} from 'src/hooks/types';
import getContent from 'src/utils/contentUtils';

import { StyledTrimUploadSuccessfulDescription } from './styles';

export const TrimUploadSuccessful = ({
  open,
  close,
  record,
  modalType,
}: {
  open: boolean;
  close: () => void;
  record: MatterCorrespondenceUploadToTrimData | MatterMessageUploadToTrimData;
  modalType: 'message' | 'correspondence';
}) => {
  if (!open) {
    return null;
  }

  const descriptionSegment =
    modalType === 'correspondence' ? 'Correspondence' : 'The message thread';

  const trimTitle =
    'trimRecordTitle' in record ? record.trimRecordTitle : record.trimTitle;

  return (
    <Modal
      title={getContent('matter.upload.successfully.to.trim.heading')}
      buttons={[
        {
          text: 'Ok',
          onClick: close,
        },
      ]}
    >
      <StyledTrimUploadSuccessfulDescription>
        {descriptionSegment} with the TRIM title <strong>{trimTitle}</strong>{' '}
        and TRIM record number <strong>{record.recordNumber}</strong> has been
        uploaded successfully
      </StyledTrimUploadSuccessfulDescription>
    </Modal>
  );
};
