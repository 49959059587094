import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  MethodTypes,
  requestApi,
} from 'src/api';

const deleteDocumentRequest = (
  matterUId: string,
  documentId: string,
  isCustomer: boolean = false,
) => {
  const suffix = ['matters', `${matterUId}`, 'documents', `${documentId}`];
  const ifCustomerSuffix = isCustomer ? [...suffix, 'external'] : suffix;
  return requestApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.documents,
      suffix: ifCustomerSuffix,
    },
    MethodTypes.DELETE,
    undefined,
    undefined,
    undefined,
  );
};

const deleteDocument = (
  matterUId: string,
  documentId: string,
  isCustomer: boolean = false,
) => deleteDocumentRequest(matterUId, documentId, isCustomer);

export const useDeleteDocument = () => {
  return {
    deleteDocument,
  };
};
