import {
  Col,
  ComponentLoader,
  Heading,
  Row,
} from '@snsw/react-component-library';
import { LabelDescriptionList, ListItemType, Tooltip } from 'ams-common';
import { useUserProfile } from 'src/hooks';
import getContent from 'src/utils/contentUtils';

import { getLabelDescriptionList } from '../utils';

export const UserDetails = () => {
  const { data, isLoading } = useUserProfile(false);

  return (
    <Row>
      <ComponentLoader active={isLoading} fullPage />
      <Col span={8}>
        <Tooltip
          text={getContent('myprofile.details.tooltip.text')}
          label={
            <Heading level={3}>
              {getContent('myprofile.first.subheading')}
            </Heading>
          }
        />

        {/* <UserProfileContainer> */}
        {data && (
          <LabelDescriptionList
            list={getLabelDescriptionList(data) as ListItemType[]}
          />
        )}
        {/* </UserProfileContainer> */}
      </Col>
    </Row>
  );
};
