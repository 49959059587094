import { FormGroup, Input } from '@snsw/react-component-library';
import { MultiSelect } from 'ams-common';

import { RecipientOption } from './types';

interface RecipientProps {
  recipientOptions: RecipientOption[];
  hasError: boolean;
  errorMessage?: string;
  value: string;
  handleChange: ({ value, field }: { value: string; field: string }) => void;
  isCustomer: boolean;
}

export const Recipient = ({
  recipientOptions,
  value,
  hasError,
  errorMessage,
  handleChange,
  isCustomer,
}: RecipientProps) => {
  return (
    <FormGroup
      errorMessage={errorMessage}
      id="recipient"
      label="Recipient"
      hasError={hasError}
    >
      {!isCustomer ? (
        <MultiSelect
          name="recipient"
          inputWidth="xl"
          showSelectAll
          selectAllLabel="Select all recipients"
          value={value}
          options={recipientOptions || []}
          onChange={(val) => handleChange({ value: val, field: 'recipient' })}
          singleSelectionLabel="recipient"
          multipleSelectionLabel="recipients"
        />
      ) : (
        <Input name="recipient" value={value} inputWidth="xl" disabled />
      )}
    </FormGroup>
  );
};
