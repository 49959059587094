export const DUE_DATE_STATUS_MAPPER = {
  OVERDUE: 'Overdue',
  UPCOMING: 'Upcoming',
  TODAY: 'Due today',
};

export const AUDITOR_ACTION_ITEM_TYPE_MAPPER = {
  'REQUEST FOR INFORMATION': 'Request for information',
  'AUDIT COMMENCEMENT DOCUMENTS': 'NOI/UFC questionnaire',
  'PRELIMINARY FINDINGS': 'Preliminary findings',
  'FINALISATION ADVICE': 'Finalisation advice',
};

export const CUSTOMER_ACTION_ITEM_TYPE_MAPPER = {
  'REQUEST FOR INFORMATION': 'request for information',
  'AUDIT COMMENCEMENT DOCUMENTS': 'audit commencement documents',
  'PRELIMINARY FINDINGS': 'preliminary findings',
  'FINALISATION ADVICE': 'finalisation advice',
};

export const ACTION_ITEMS_MAPPER_TO_REVIEW_OR_RESPOND = {
  'REQUEST FOR INFORMATION': 'Respond to',
  'AUDIT COMMENCEMENT DOCUMENTS': 'Respond to',
  'PRELIMINARY FINDINGS': 'Review',
  'FINALISATION ADVICE': 'Review',
};
