import { useEffect } from 'react';

const useScript = (
  id: string,
  src: string,
  async = true,
  onLoad?: () => void,
  onError?: () => void,
) => {
  useEffect(() => {
    if (!src) return;

    const existingScript = document.getElementById(id);
    if (existingScript) {
      onLoad?.();
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = async;

    script.onload = () => {
      onLoad?.();
    };

    script.onerror = () => {
      onError?.();
    };

    document.body.appendChild(script);

    // eslint-disable-next-line consistent-return
    return () => {
      if (document.getElementById(id)) {
        script.remove();
      }
    };
  }, [id, src, async, onLoad, onError]);
};

export default useScript;
