import { PATHS } from 'src/routes/constants';
import getContent, { combineContent } from 'src/utils/contentUtils';

import { NavigationTitle, SubNavigationTitle } from './types';

export const categorizeDurationInMonths = (days: number): string => {
  if (days < 0) return '';

  let age: string;

  switch (true) {
    case days <= 90:
      age = '<= 3 months';
      break;
    case days > 90 && days <= 182:
      age = '3-6 months';
      break;
    case days > 182 && days <= 365:
      age = '6-12 months';
      break;
    default:
      age = '> 12 months';
      break;
  }

  return age;
};

export const getMattersBreadcrumbs = (
  navigationTitle: NavigationTitle,
  subNavigationTitle?: SubNavigationTitle,
  matterId?: string | null,
) => [
  {
    display: getContent('matters.breadcrumbs.text'),
    path: PATHS.matters,
  },
  {
    display: getContent(
      `matters.${navigationTitle}.breadcrumbs.text` as keyof typeof combineContent,
    ),
    path: matterId ? `${PATHS.matters}/${matterId}/${navigationTitle}` : '',
  },
  ...(subNavigationTitle
    ? [
        {
          display: getContent(
            `matters.${subNavigationTitle}.breadcrumbs.text` as keyof typeof combineContent,
          ),
        },
      ]
    : []),
];
