import { Input } from '@snsw/react-component-library';

interface HiddenInputProps {
  name: string;
}
export const HiddenInput = ({ name }: HiddenInputProps) => {
  return (
    <Input name={name} style={{ position: 'absolute', left: '-9999px' }} />
  );
};
