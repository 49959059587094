import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

type OTPModalTypes = {
  open: boolean;
  close: () => void;
  onSendNewPassword: () => void;
};
const OTPModal = ({ open, close, onSendNewPassword }: OTPModalTypes) => {
  if (!open) {
    return null;
  }

  const handleSendNewPassword = () => {
    onSendNewPassword();
    close();
  };

  return (
    <Modal
      id="customer-auth-success"
      title={getContent('customerLoginForm.otp.modal.title.text')}
      buttons={[
        {
          text: 'Send new password',
          onClick: handleSendNewPassword,
        },
        {
          text: 'Cancel',
          onClick: close,
        },
      ]}
      description={getContent('customerLoginForm.otp.modal.description.text')}
    />
  );
};

export default OTPModal;
