/* eslint-disable jsx-a11y/anchor-is-valid */
import { Heading, Modal } from '@snsw/react-component-library';
import { LabelDescriptionList } from 'ams-common';
import { useDownloadDocument } from 'src/hooks';
import getContent from 'src/utils/contentUtils';
import { downloadSelectedDocument } from 'src/utils/documentHelper';

import { getRequestBody } from '../helpers';
import { NoBullet, StyledMessageSubject } from '../styles';
import {
  DocumentItem,
  ModalType,
  OnConfirmType,
  RequestType,
  SubmitAuditCommencementDocumentsInitValues,
} from '../types';
import {
  getConfirmModalDescriptionList,
  getSelectedCustomerName,
  getSelectedRecipientName,
} from '../utils';

export const ConfirmModal = ({
  onClose,
  auditCommencementValues,
  clientOptions,
  recipientOptions,
  matterUId,
  correspondenceId,
  threadId,
  selectedMatterDocuments,
  onConfirm,
}: {
  onClose: () => void;
  auditCommencementValues: SubmitAuditCommencementDocumentsInitValues;
  clientOptions: { text: string; value: string }[] | null;
  recipientOptions:
    | {
        text: string;
        value: string;
      }[]
    | null;
  matterUId?: string | null;
  correspondenceId: string | null;
  threadId: string | null;
  selectedMatterDocuments: DocumentItem[];
  onConfirm: OnConfirmType;
}) => {
  const { downloadDocumentUrl, downloadDocument } = useDownloadDocument();
  const {
    customer: selectedCustomers,
    recipient: selectedRecipients,
    dueDate,
    message,
    subject,
  } = auditCommencementValues;

  const customer = selectedCustomers
    .replace(/^,/, '')
    .split(',')
    .map((customerId) => getSelectedCustomerName(clientOptions, customerId))
    .join(', ');

  const recipient = selectedRecipients
    .replace(/^,/, '')
    .split(',')
    .map((recipientId) => {
      const name = getSelectedRecipientName(recipientOptions, recipientId);
      if (typeof name === 'string' && name.trim()) {
        return name.trim();
      }
      if (Array.isArray(name) && name.length > 0) {
        return name.join(', ');
      }
      return '';
    })
    .filter(Boolean)
    .join(', ');

  const handleConfirm = async () => {
    try {
      const requestBody = getRequestBody(
        auditCommencementValues,
        ModalType.Open,
      );

      if (matterUId) {
        if (correspondenceId && threadId) {
          await onConfirm({
            matterUId,
            payload: requestBody,
            requestType: RequestType.Update,
            threadId,
            correspondenceId,
            saveAs: undefined,
            isCustomer: undefined,
            isReply: undefined,
          });
        } else {
          await onConfirm({
            matterUId,
            payload: requestBody,
            requestType: RequestType.Add,
            threadId: undefined,
            correspondenceId: undefined,
            saveAs: undefined,
            isCustomer: undefined,
            isReply: undefined,
          });
        }
      } else {
        throw new Error('Missing required identifiers');
      }
    } catch (e) {
      console.error('Error during confirmation:', e);
    } finally {
      onClose();
    }
  };

  const handleClick = async (documentId: string, documentName: string) => {
    try {
      if (matterUId) {
        const { url: DownloadUrl } = await downloadDocumentUrl(
          false,
          matterUId,
          documentId,
        );
        const response = await downloadDocument(DownloadUrl, documentName);
        downloadSelectedDocument(response, documentName);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      } else if (typeof error === 'string') {
        // This is a custom error message
        console.error('Custom error message:', error);
      } else {
        // Handle other error types
        console.error('Unknown error type');
      }
    }
  };

  return (
    <Modal
      buttons={[
        { text: 'Confirm and send', onClick: handleConfirm },
        { text: 'Cancel', onClick: onClose },
      ]}
      title={getContent(
        'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.title',
      )}
    >
      <p>
        {getContent(
          'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.text',
        )}
      </p>
      <LabelDescriptionList
        hasMarginTop
        list={getConfirmModalDescriptionList({
          customer,
          recipient,
          dueDate,
        })}
      />
      <Heading level={6}>
        {getContent(
          'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.document.header',
        )}
      </Heading>
      {selectedMatterDocuments && (
        <NoBullet>
          {selectedMatterDocuments.map(({ name, id: documentId }) => (
            // eslint-disable-next-line react/jsx-no-comment-textnodes
            <li key={documentId}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(documentId, name);
                }}
              >
                {name}
              </a>
            </li>
          ))}
        </NoBullet>
      )}
      <Heading level={6}>
        {getContent(
          'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.messageSubject.header',
        )}
      </Heading>
      <StyledMessageSubject>{subject}</StyledMessageSubject>
      <Heading level={6}>
        {getContent(
          'matters.correspondence.submitAuditCommencementDocuments.modal.confirm.message.header',
        )}
      </Heading>
      <p>{message}</p>
    </Modal>
  );
};
