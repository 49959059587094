import { AxiosError, AxiosResponse } from 'axios';
import { MatterDocuments } from 'src/components/documentTableWithAccordion/types';
import { DocumentItem } from 'src/screens/common/matters/tabs/common/matterCorrespondence/auditor/submitAuditCommencementDocuments/types';

export const getSelectedDocuments = (documents: DocumentItem[]) =>
  Array.from(new Set(documents.map(({ id }) => id))).map((newId) => {
    return documents.find(({ id }) => id === newId);
  }) as DocumentItem[];

export const downloadSelectedDocument = (
  response: AxiosResponse<any, any> | AxiosError<any, any> | any,
  documentName: string,
) => {
  if ('headers' in response) {
    const { headers, data: downloadResponse } = response;
    let fileName = documentName;
    if (response.headers['content-disposition']) {
      const contentDisposition = headers['content-disposition'];
      const [, extractedFileName] =
        contentDisposition.match(/filename="(.+)"/) || [];
      if (extractedFileName) {
        fileName = extractedFileName;
      }
    }

    const url = window.URL.createObjectURL(new Blob([downloadResponse]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
};

export const getSelectedMatterDocuments = (
  selectedDocuments: string[],
  matterDocumentsData?: MatterDocuments,
) => {
  if (!matterDocumentsData) {
    return [];
  }

  return selectedDocuments
    .map((documentId) => {
      const matchedDocument = matterDocumentsData.find(
        ({ id }) => id === documentId,
      );
      if (matchedDocument) {
        const { s3Key, id, name } = matchedDocument;
        return { s3Key, id, name };
      }
      return null;
    })
    .filter((document): document is DocumentItem => document !== null);
};
