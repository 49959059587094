import * as t from 'io-ts';
import { tOptional } from 'src/api/utils';

import { CorrespondenceFieldNames } from './constants';

export const DueDateValuesCodec = t.intersection([
  t.type({
    dueDate: t.string,
  }),
  t.partial({
    reason: t.string,
  }),
]);

export type DueDateValues = t.TypeOf<typeof DueDateValuesCodec>;

export enum ModalType {
  Draft = 'Draft',
  Open = 'Open',
}

export type CorrespondenceRequestBody = {
  documents: {
    id: string;
  }[];
  corroType: string;
  status: ModalType;
  dueDate: string;
  subject: string;
  messageBody: string;
  portalContacts?: {
    id: string;
  }[];
};

export const DocumentsCodec = t.type({
  id: t.string,
});

export const portalContactCodec = t.type({
  id: t.string,
  clientId: t.number,
  contactId: t.number,
  contactEmail: t.string,
});

export const CorrespondenceDetailsCodec = t.type({
  id: t.string,
  sentDate: tOptional(t.string),
  initiatedBy: t.string,
  dueDate: tOptional(t.string),
  subject: tOptional(t.string),
  messageBody: tOptional(t.string),
  documents: tOptional(t.array(DocumentsCodec)),
  portalContacts: tOptional(t.array(portalContactCodec)),
  corroType: t.string,
});

export type CorrespondenceDetails = t.TypeOf<typeof CorrespondenceDetailsCodec>;

export type PortalContact = t.TypeOf<typeof portalContactCodec>;
export type UploadedDocuments = t.TypeOf<typeof DocumentsCodec>;

const Document = t.type({
  id: t.string,
});

export const portalContact = t.type({
  [CorrespondenceFieldNames.Id]: t.string,
  [CorrespondenceFieldNames.ClientId]: t.number,
  [CorrespondenceFieldNames.ClientName]: t.string,
  [CorrespondenceFieldNames.ContactName]: t.string,
  [CorrespondenceFieldNames.ContactId]: t.number,
  [CorrespondenceFieldNames.ContactEmail]: t.string,
});

const NullableDocumentArray = t.union([t.null, t.array(Document)]);
const NullablePortalContactArray = t.union([t.null, t.array(portalContact)]);

export const MatterCorrespondenceDetailResponseCodec = t.intersection([
  t.type({
    [CorrespondenceFieldNames.ThreadId]: t.string,
    [CorrespondenceFieldNames.CorrespondenceId]: t.string,
    [CorrespondenceFieldNames.SentDate]: tOptional(t.string),
    [CorrespondenceFieldNames.InitiatedBy]: t.string,
    [CorrespondenceFieldNames.Documents]: NullableDocumentArray,
    [CorrespondenceFieldNames.CorrespondenceType]: t.string,
    [CorrespondenceFieldNames.Subject]: t.string,
    [CorrespondenceFieldNames.MessageBody]: tOptional(t.string),
    [CorrespondenceFieldNames.SentBy]: tOptional(t.string),
    [CorrespondenceFieldNames.CorroStatus]: tOptional(t.string),
    [CorrespondenceFieldNames.Auditor]: t.string,
  }),
  t.partial({
    [CorrespondenceFieldNames.PortalContacts]: NullablePortalContactArray,
    [CorrespondenceFieldNames.DueDate]: t.string,
    [CorrespondenceFieldNames.ResponseIndicator]: t.boolean,
    [CorrespondenceFieldNames.ContactName]: t.string,
    [CorrespondenceFieldNames.ClientName]: t.string,
    [CorrespondenceFieldNames.ReplyFrom]: tOptional(t.string),
  }),
]);

export const MatterCorrespondenceDetailResponsesCodec = t.array(
  MatterCorrespondenceDetailResponseCodec,
);

export type MatterCorrespondenceDetailResponse = t.TypeOf<
  typeof MatterCorrespondenceDetailResponseCodec
>;

export type MatterCorrespondenceDetailResponses = t.TypeOf<
  typeof MatterCorrespondenceDetailResponsesCodec
>;

export type PortalContacts = t.TypeOf<typeof NullablePortalContactArray>;
export type Documents = t.TypeOf<typeof NullableDocumentArray>;

export const portalContacts = t.array(portalContact);

export const MatterCorrespondenceResultCodec = t.intersection([
  t.type({
    [CorrespondenceFieldNames.IsDraftExists]: t.boolean,
    [CorrespondenceFieldNames.ThreadId]: t.string,
    [CorrespondenceFieldNames.ThreadStatus]: t.string,
    [CorrespondenceFieldNames.CorrespondenceType]: tOptional(t.string),
    [CorrespondenceFieldNames.DueDate]: tOptional(t.string),
    [CorrespondenceFieldNames.InitiatedDate]: tOptional(t.string),
    [CorrespondenceFieldNames.InitiatedBy]: tOptional(t.string),
    [CorrespondenceFieldNames.LastSubject]: tOptional(t.string),
    [CorrespondenceFieldNames.portalContactIds]: tOptional(t.array(t.string)),
    [CorrespondenceFieldNames.LastActionMessage]: tOptional(t.string),
    [CorrespondenceFieldNames.TrimRecordNumber]: tOptional(t.string),
  }),
  t.partial({
    [CorrespondenceFieldNames.DraftCorrespondenceId]: tOptional(t.string),
  }),
]);

export const MatterCorrespondenceResultsCodec = t.array(
  MatterCorrespondenceResultCodec,
);

export type MatterCorrespondenceResult = t.TypeOf<
  typeof MatterCorrespondenceResultCodec
>;

export type MatterCorrespondenceResults = t.TypeOf<
  typeof MatterCorrespondenceResultsCodec
>;
export const MatterCorrespondenceUploadToTrimDataCodec = t.type({
  recordNumber: t.string,
  clientId: t.number,
  trimRecordTitle: t.string,
  trimDescription: t.string,
});

export type MatterCorrespondenceUploadToTrimData = t.TypeOf<
  typeof MatterCorrespondenceUploadToTrimDataCodec
>;

export const MatterCorrespondenceUploadToTrimResponseCodec = t.type({
  data: MatterCorrespondenceUploadToTrimDataCodec,
  status: t.number,
});

export type MatterCorrespondenceUploadToTrimResponse = t.TypeOf<
  typeof MatterCorrespondenceUploadToTrimResponseCodec
>;

// Start - Thread
export const MatterThreadDetailsCodec = t.type({
  [CorrespondenceFieldNames.ThreadId]: t.string,
  [CorrespondenceFieldNames.DueDate]: tOptional(t.string),
  [CorrespondenceFieldNames.CorrespondenceType]: tOptional(t.string),
  [CorrespondenceFieldNames.ThreadStatus]: t.string,
  [CorrespondenceFieldNames.TrimRecordNumber]: tOptional(t.string),
  [CorrespondenceFieldNames.portalContactIds]: tOptional(t.array(t.string)),
  [CorrespondenceFieldNames.LastSubject]: tOptional(t.string),
});
export type MatterThreadDetails = t.TypeOf<typeof MatterThreadDetailsCodec>;
// End - Thread
