import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentContainer, Heading } from '@snsw/react-component-library';
import { RootLayout } from 'ams-common';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { PATHS } from 'src/routes/constants';
import { useMatterSummaryData } from 'src/screens/common/matters/tabs/common/matterSummary/hook';
import getContent from 'src/utils/contentUtils';

import CustomerAuthForm from '../customerAuthForm';
import CustomerLoginForm from '../customerLoginForm';
import { RegistrationState } from '../types';

import { StyledSectionPage } from './styles';
import TermsOfUse from './termsOfUse';

const Onboarding = () => {
  const navigate = useNavigate();
  const { data, refetch } = useMatterSummaryData(null, true, false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const [isAcceptAndContinue, setIsAcceptAndContinue] = useState(false);
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
  const [isCustomerLoggedIn, setIsCustomerLoggedIn] = useState<{
    isLoggedInForTheFirstTime: boolean;
    isLoggedIn: boolean;
  }>({
    isLoggedInForTheFirstTime: false,
    isLoggedIn: false,
  });
  const [registration, setRegistration] = useState<RegistrationState | null>(
    null,
  );
  const handleAcceptAndContinue = () => setIsAcceptAndContinue(true);

  const termsAndConditions = `
  <p>
        ${getContent('onboarding.termsOfUse.content.first.paragraph.content')}
      </p>
      <Heading level={3}>
        ${getContent('onboarding.termsOfUse.heading.second.paragraph.heading')}
      </Heading>
      ${getContent('onboarding.privacyCollectionNotice.text')}
  `;

  let contentToRender;

  useEffect(() => {
    const fetch = async () => {
      await refetch();
    };

    if (isCustomerLoggedIn.isLoggedIn) {
      fetch();

      if (data) {
        navigate(`${PATHS.matters}/${data?.matterId}${PATHS.matterOverview}`, {
          state: {
            isFirstTimeLoggedIn: isCustomerLoggedIn.isLoggedInForTheFirstTime,
            isAlreadyRegistered: !isCustomerLoggedIn.isLoggedInForTheFirstTime,
          },
        });
      } else
        setErrorKeys((prevErrorKeys) => [
          ...new Set([
            ...prevErrorKeys,
            `${ScreenNames.CUSTOMER_ONBOARDING}-GET` as ErrorKey,
          ]),
        ]);
    }
  }, [data, isCustomerLoggedIn, navigate, refetch]);

  if (!isRegistrationSuccess) {
    // Render terms of use and registration form
    if (!isAcceptAndContinue) {
      contentToRender = (
        <TermsOfUse
          isAgreed={isAgreed}
          setIsAgreed={setIsAgreed}
          handleAcceptAndContinue={handleAcceptAndContinue}
        />
      );
    } else {
      contentToRender = (
        <CustomerAuthForm
          onRegistrationSuccess={setIsRegistrationSuccess}
          isOnboarding
          setRegistration={setRegistration}
          registration={registration}
        />
      );
    }
  } else {
    // Render customer login form
    contentToRender = (
      <CustomerLoginForm
        isOnboarding
        setIsCustomerLoggedIn={setIsCustomerLoggedIn}
        registration={registration}
        termsAndConditions={termsAndConditions}
      />
    );
  }

  return (
    <RootLayout showNavigation={false}>
      <ContentContainer>
        <StyledSectionPage>
          <Heading level={2}>{getContent('onboarding.heading.main')}</Heading>
          <ErrorHandler keys={errorKeys} />
          {contentToRender}
        </StyledSectionPage>
      </ContentContainer>
    </RootLayout>
  );
};

export default Onboarding;
