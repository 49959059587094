import { FormGroup } from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import styled, { css } from 'styled-components';

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${spacing.md};
  gap: ${spacing.md};
`;

export const FormDateGroup = styled(FormGroup)<{
  hasError: boolean;
}>`
  label {
    display: none;
  }

  fieldset {
    label {
      display: block;
    }

    h3 {
      font-size: 110%;
      font-weight: 500;
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      input,
      select {
        border-color: ${colours.borders.error};
      }
    `}
`;
