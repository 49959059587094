import {
  FormGroup,
  IconEdit,
  TableCell,
  TableContainer,
  TableHeader,
} from '@snsw/react-component-library';
import { colours, spacing } from 'ams-common';
import styled, { css } from 'styled-components';

export const StyledTableContainer = styled(TableContainer)`
  div:first-of-type {
    overflow-x: hidden;
  }
  margin-top: 35px;
  h3 {
    font-size: 1rem;
    font-weight: 300;
  }
  a {
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  display: flex;

  justify-content: flex-end;
`;

export const StyledTableCellB = styled(TableCell)`
  vertical-align: top;
`;

export const StyledTableHeader = styled(TableHeader)`
  text-align: center;
`;

export const StyledLinkContainer = styled.p`
  padding-top: 4px;

  a {
    text-decoration: underline;
    margin: 8px;
  }
`;

export const StyledNoDueDateContainer = styled.span`
  color: ${colours.text.error};
  font-size: 1rem;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledEditIcon = styled(IconEdit)`
  margin-left: ${spacing.sm};
  margin-right: 5px;
`;

export const NoStyleButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  color: ${colours.text.link};
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  text-decoration-thickness: 1px;
`;

export const FormDateGroup = styled(FormGroup)<{
  hasError: boolean;
}>`
  label {
    display: none;
  }

  fieldset {
    label {
      display: block;
    }

    h3 {
      font-size: 110%;
      font-weight: 500;
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      input,
      select {
        border-color: ${colours.borders.error};
      }
    `}
`;
