import React, { useEffect, useMemo, useState } from 'react';
import {
  AMSUserContext,
  AMSUserContextType,
  OktaAuth,
} from 'src/components/users';
import { AMSAuth } from 'src/components/users/amsAuth';
import { SESSION_STORAGE_KEY_USER_INFO } from 'src/constants';
import { MatterContextProvider } from 'src/context/MatterContext';
import { MessageContextProvider } from 'src/context/MessageContext';
import environments from 'src/environments';
import { UserLoginType } from 'src/types';

import { globalNavigation } from './globalNavigation';

interface AppWithRouterProps {
  isAuthenticated: boolean;
  isStaffLogin: boolean;
  isCustomerLogin: boolean;
}

const AppWithRouter: React.FC<AppWithRouterProps> = ({
  isAuthenticated,
  isStaffLogin,
  isCustomerLogin,
}) => (
  <MatterContextProvider>
    <MessageContextProvider>
      {isStaffLogin ? (
        <OktaAuth>{globalNavigation(isStaffLogin, isCustomerLogin)}</OktaAuth>
      ) : (
        <AMSAuth isAuthenticated={isAuthenticated}>
          {globalNavigation(isStaffLogin, isCustomerLogin)}
        </AMSAuth>
      )}
    </MessageContextProvider>
  </MatterContextProvider>
);

const handleStorageChange = (
  setAmsUserContextValue: React.Dispatch<
    React.SetStateAction<AMSUserContextType | null | undefined>
  >,
) => {
  return () => {
    const savedValue = window.sessionStorage.getItem(
      SESSION_STORAGE_KEY_USER_INFO,
    );

    if (savedValue) {
      const parsedValue = JSON.parse(savedValue) as AMSUserContextType;
      setAmsUserContextValue(parsedValue);
    } else {
      setAmsUserContextValue(null);
    }
  };
};

const useAmsUserContext = () => {
  const [amsUserContextValue, setAmsUserContextValue] = useState<
    AMSUserContextType | null | undefined
  >(undefined);

  useEffect(() => {
    const storageChangeHandler = handleStorageChange(setAmsUserContextValue);

    storageChangeHandler();

    window.addEventListener('storage', storageChangeHandler);

    return () => window.removeEventListener('storage', storageChangeHandler);
  }, []);

  return amsUserContextValue;
};

const determineAuthenticationType = (
  isAuthenticated: boolean,
  userType: UserLoginType | undefined,
  query: URLSearchParams,
) => {
  return isAuthenticated
    ? userType === UserLoginType.Staff
    : query.get('loginType') === UserLoginType.Staff ||
        window.location.pathname === `${environments.baseName}/login/callback`;
};

const determineIsCustomerLoggedIn = (
  isAuthenticated: boolean,
  userType: UserLoginType | undefined,
) => {
  return isAuthenticated ? userType === UserLoginType.Customer : false;
};

const Authentication: React.FC = () => {
  const amsUserContextValue = useAmsUserContext();

  const isAuthenticated = useMemo(
    () => !!amsUserContextValue,
    [amsUserContextValue],
  );
  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const isStaffLogin = useMemo(
    () =>
      determineAuthenticationType(
        isAuthenticated,
        amsUserContextValue?.userType,
        query,
      ),
    [isAuthenticated, amsUserContextValue, query],
  );

  const isCustomerLogin = useMemo(
    () =>
      determineIsCustomerLoggedIn(
        isAuthenticated,
        amsUserContextValue?.userType,
      ),
    [isAuthenticated, amsUserContextValue],
  );

  if (typeof amsUserContextValue === 'undefined') {
    return null;
  }

  return (
    <AMSUserContext.Provider value={amsUserContextValue}>
      <AppWithRouter
        isAuthenticated={isAuthenticated}
        isStaffLogin={isStaffLogin}
        isCustomerLogin={isCustomerLogin}
      />
    </AMSUserContext.Provider>
  );
};

export default Authentication;
