import {
  Col,
  ComponentLoader,
  Heading,
  Row,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@snsw/react-component-library';
import { StyledTableHeader } from 'ams-common';

import { StyledTable, StyledTableContainer } from '../styles';
import { HistoryLogValues } from '../types';

import { columns } from './columns';
import { HistoryLogRow } from './historyLogRow';

interface HistoryLogProps {
  historyLogValues?: HistoryLogValues;
  isLoading: boolean;
}

export const HistoryLog = ({
  historyLogValues,
  isLoading,
}: HistoryLogProps) => {
  const tableColumns = columns();
  const sortedLogs = historyLogValues
    ? historyLogValues.sort(
        (a, b) => Date.parse(b.modifiedDate) - Date.parse(a.modifiedDate),
      )
    : [];

  return (
    <>
      <ComponentLoader active={isLoading} fullPage />
      <Row>
        <Col>
          <Heading level={4}>History Log</Heading>
        </Col>
      </Row>
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            {tableColumns?.map(({ header }) => (
              <StyledTableHeader key={header}>{header}</StyledTableHeader>
            ))}
          </TableHead>
          <TableBody>
            {(!historyLogValues || historyLogValues?.length === 0) && (
              <TableRow>
                <TableCell>No logs to display</TableCell>
              </TableRow>
            )}
            {sortedLogs?.map((historyLog) => (
              <HistoryLogRow
                key={historyLog.id}
                rowData={historyLog}
                tableColumns={tableColumns}
              />
            ))}
          </TableBody>{' '}
        </StyledTable>
      </StyledTableContainer>
    </>
  );
};
