import { EllipsisText, formatDate } from 'ams-common';
import { ButtonWithPopup } from 'src/components/buttonWithPopup';

import { MainStatus } from '../constants';
import { CenteredText, StyledDescriptionPopup } from '../styles';
import { ColumnType } from '../types';

import { HistoryLogFieldLabel, HistoryLogFieldNames } from './constants';

export const columns = (): ColumnType[] => [
  {
    header: HistoryLogFieldLabel.originalStatus,
    dataIndex: HistoryLogFieldNames.originalStatus,
    render: ({ columnData }) => {
      return (
        columnData &&
        (MainStatus[columnData] && columnData !== MainStatus.Review ? (
          <CenteredText>{` ${MainStatus[columnData]} - ${columnData}`}</CenteredText>
        ) : (
          <CenteredText>{columnData}</CenteredText>
        ))
      );
    },
  },
  {
    header: HistoryLogFieldLabel.revisedStatus,
    dataIndex: HistoryLogFieldNames.revisedStatus,
    render: ({ columnData }) => {
      return (
        columnData &&
        (MainStatus[columnData] && columnData !== MainStatus.Review ? (
          <CenteredText>{` ${MainStatus[columnData]} - ${columnData}`}</CenteredText>
        ) : (
          <CenteredText>{columnData}</CenteredText>
        ))
      );
    },
  },
  {
    header: HistoryLogFieldLabel.comment,
    dataIndex: HistoryLogFieldNames.comment,
    render: ({ columnData }) => {
      return columnData && columnData.toString().length > 8 ? (
        <ButtonWithPopup
          width={150}
          renderActionItems={() => (
            <StyledDescriptionPopup>{columnData}</StyledDescriptionPopup>
          )}
          title={columnData}
        />
      ) : (
        <EllipsisText>{columnData}</EllipsisText>
      );
    },
  },

  {
    header: HistoryLogFieldLabel.modifiedDate,
    dataIndex: HistoryLogFieldNames.modifiedDate,
    render: ({ columnData }) => {
      return (
        columnData && (
          <CenteredText>
            {formatDate(columnData.toString(), 'DD MMM YYYY HH:mm')}
          </CenteredText>
        )
      );
    },
  },
  {
    header: HistoryLogFieldLabel.modifiedBy,
    dataIndex: HistoryLogFieldNames.modifiedBy,
  },
];
