import { ClientOptions, Clients, MatterClient } from './types';

export const getClientOptions = (clients?: Clients): ClientOptions => {
  if (!clients) {
    return null;
  }

  return clients?.map(({ id, name }) => {
    return {
      text: name,
      value: String(id),
    };
  });
};

export const isDocumentClientsMatchedWithAllClients = (
  clients: MatterClient[],
  customerIds: number[],
): boolean =>
  clients.length === customerIds.length &&
  clients.every(({ id }) => customerIds.includes(id));
