import { MatterAlert } from 'ams-common';
import { getContentWithReplacements } from 'src/utils/contentUtils';

import { CustomerOnboardingEmailSentData } from './types';

type CustomerOnboardingEmailSentProps = {
  data: CustomerOnboardingEmailSentData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (matterIdOrThreadId: string) => void;
  alertId: string;
};

export const CustomerOnboardingEmailSent = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: CustomerOnboardingEmailSentProps) => {
  const { matterId, contactEmail, contactName, clientId, clientName } = data;

  const body = getContentWithReplacements(
    'matterAlert.alert.customer.onboarding.email.sent.message',
    {
      contactName,
      contactEmail,
      customerId: `${clientId}`,
      clientName,
    },
  );

  return (
    <MatterAlert
      title="Customer onboarding email sent"
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`/matters/${matterId}/contacts`}
      linkText="View contacts"
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (matterId) updateMatterContext?.(matterId);
      }}
    />
  );
};
