import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

import { getRequestBody } from '../helpers';
import {
  ModalType,
  OnConfirmType,
  RequestType,
  SubmitAuditCommencementDocumentsInitValues,
} from '../types';

export const DraftModal = ({
  onClose,
  auditCommencementValues,
  matterUId,
  correspondenceId,
  threadId,
  onConfirm,
}: {
  onClose: () => void;
  auditCommencementValues: SubmitAuditCommencementDocumentsInitValues;
  matterUId?: string | null;
  correspondenceId: string | null;
  threadId: string | null;
  onConfirm: OnConfirmType;
}) => {
  const handleConfirm = async () => {
    try {
      const requestBody = getRequestBody(
        auditCommencementValues,
        ModalType.Draft,
      );

      if (matterUId) {
        if (correspondenceId && threadId) {
          await onConfirm({
            matterUId,
            payload: requestBody,
            requestType: RequestType.Update,
            threadId,
            correspondenceId,
            saveAs: 'draft',
            isCustomer: undefined,
            isReply: undefined,
          });
        } else {
          await onConfirm({
            matterUId,
            payload: requestBody,
            requestType: RequestType.Add,
            threadId: undefined,
            correspondenceId: undefined,
            saveAs: 'draft',
            isCustomer: undefined,
            isReply: undefined,
          });
        }
      } else {
        throw new Error('Missing matterUId');
      }
    } catch (e) {
      console.error('Error during creating a draft:', e);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      buttons={[
        { text: 'Save draft', onClick: handleConfirm },
        { text: 'Cancel', onClick: onClose },
      ]}
      title={getContent(
        'matters.correspondence.submitAuditCommencementDocuments.modal.draft.title',
      )}
    >
      <p>
        {getContent(
          'matters.correspondence.submitAuditCommencementDocuments.modal.draft.content',
        )}
      </p>
    </Modal>
  );
};
