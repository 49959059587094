import * as t from 'io-ts';
import { tOptional } from 'src/types';

// Start - Document Details

const ClientCodec = t.type({
  id: t.number,
});

export const DocumentDetailsCodec = t.type({
  id: t.string,
  status: t.string,
  uploadedBy: t.string,
  uploadedDate: t.string,
  clients: t.array(ClientCodec),
  name: t.string,
  classification: t.string,
  description: t.string,
  contentLength: t.number,
  s3Key: tOptional(t.string),
});

export type DocumentDetails = t.TypeOf<typeof DocumentDetailsCodec>;

// End - Document Details

// Start - Download Document

export const DocumentDownloadCodec = t.type({
  url: t.string,
});

export type DocumentDownload = t.TypeOf<typeof DocumentDownloadCodec>;

// End - Download Document

// Start - Upload Document

export const PresignedUrlCodec = t.type({
  s3Key: t.string,
  url: t.string,
});

export type UploadDocument = {
  documentId?: string;
  id?: string;
  name: string;
  matterId?: string;
  s3Key: string;
  clients?: {
    id: string;
  }[];
  classification: string;
  description: string;
};

// End - Upload Document

export enum RequestType {
  Add,
  Update,
}

export type ClassificationOptions = {
  text: string;
  value: string;
  apiSectionName: string;
  sectionId: string;
};

// start - matter documents

const ClientsCodec = t.intersection([
  t.type({
    id: t.number,
  }),
  t.partial({
    read: tOptional(t.boolean),
  }),
]);

const MatterDocumentCodec = t.type({
  id: t.string,
  status: t.string,
  displayStatus: t.string,
  s3Key: tOptional(t.string),
  uploadedBy: t.string,
  uploadedDate: t.string,
  clients: tOptional(t.array(ClientsCodec)),
  name: t.string,
  classification: t.string,
  description: t.string,
});

export type MatterDocument = t.TypeOf<typeof MatterDocumentCodec>;

export const MatterDocumentsCodec = t.array(MatterDocumentCodec);

export type MatterDocuments = t.TypeOf<typeof MatterDocumentsCodec>;

// end - matter documents

// start - matter trim documents
export const MatterTrimDocumentFolderCodec = t.type({
  trimRecordNumber: t.string,
});

export type MatterTrimDocumentFolder = t.TypeOf<
  typeof MatterTrimDocumentFolderCodec
>;

const MatterTrimDocumentCodec = t.intersection([
  t.type({
    id: t.string,
    status: t.string,
    s3Key: tOptional(t.string),
    uploadedBy: t.string,
    uploadedDate: t.string,
    clients: tOptional(t.array(ClientsCodec)),
    name: t.string,
    classification: t.string,
    displayStatus: t.string,
  }),
  t.partial({
    trimTitle: tOptional(t.string),
    financialYear: tOptional(t.string),
    trimRecordNumber: tOptional(t.string),
    trimDescription: tOptional(t.string),
    contentLength: tOptional(t.number),
    description: tOptional(t.string),
  }),
]);

export type MatterTrimDocument = t.TypeOf<typeof MatterTrimDocumentCodec>;

export const MatterTrimDocumentsCodec = t.array(MatterTrimDocumentCodec);

export type MatterTrimDocuments = t.TypeOf<typeof MatterTrimDocumentsCodec>;

const TrimUploadDocumentCodec = t.type({
  documentId: t.string,
  clientId: t.string,
  financialYear: tOptional(t.string),
});

export type TrimUploadDocument = t.TypeOf<typeof TrimUploadDocumentCodec>;
export const TrimUploadDocumentsCodec = t.array(TrimUploadDocumentCodec);
export type TrimUploadDocuments = t.TypeOf<typeof TrimUploadDocumentsCodec>;

// end - matter trim documents
