import { useNavigate } from 'react-router-dom';
import { Button, Col, FormInput, Row } from '@snsw/react-component-library';
import { StyledAMSContentContainer } from 'ams-common';
import { Formik } from 'formik';
import {
  SESSION_STORAGE_CUSTOMER_ACCESS_TOKEN,
  SESSION_STORAGE_KEY_USER_INFO,
} from 'src/constants';
import { UserLoginType } from 'src/types';
import getContent from 'src/utils/contentUtils';
import * as Yup from 'yup';

import { StyledButtonWrapper } from './styles';

const amsSignInSchema = Yup.object().shape({
  userName: Yup.string()
    .matches(/^[a-zA-Z][\w_-]{3,20}$/, 'Please enter a valid user name')
    .required('user name is required'),
  password: Yup.string().required('password is required'),
});

export const AMSSignIn = () => {
  const navigate = useNavigate();

  const onSubmit = (_values, { setSubmitting }) => {
    setSubmitting(false);
    window.sessionStorage.setItem(
      SESSION_STORAGE_KEY_USER_INFO,
      JSON.stringify({
        userType: UserLoginType.Customer,
      }),
    );
    window.sessionStorage.setItem(
      SESSION_STORAGE_CUSTOMER_ACCESS_TOKEN,
      'todo_get_access_token_from_BE',
    );
    window.dispatchEvent(new Event('storage'));
    setTimeout(() => {
      navigate('/');
    }, 600);
  };

  return (
    <>
      {/* <PageTitle title={getContent('signIn.heading.title')} /> */}
      <StyledAMSContentContainer>
        <Formik
          initialValues={{ userName: 'ams-user', password: '' }}
          validationSchema={amsSignInSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={8}>
                  <FormInput
                    id="userName"
                    label={getContent('signIn.userName.label')}
                    value={values.userName}
                    onChange={handleChange}
                    placeholder={getContent('signIn.userName.placeholder')}
                    aria-required
                    errorMessage={errors.userName}
                    hasError={!!errors.userName}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <FormInput
                    id="password"
                    label={getContent('signIn.password.label')}
                    value={values.password}
                    onChange={handleChange}
                    placeholder={getContent('signIn.password.placeholder')}
                    aria-required
                    errorMessage={errors.password}
                    hasError={!!errors.password}
                  />
                </Col>
              </Row>
              <StyledButtonWrapper>
                <Button type="submit">
                  {getContent('signIn.button.text')}
                </Button>
              </StyledButtonWrapper>
            </form>
          )}
        </Formik>
      </StyledAMSContentContainer>
    </>
  );
};
