export enum OperationType {
  Add = 'add',
  Edit = 'edit',
}

export type HandleEditDocument = {
  documentName: string;
  documentId: string;
  clientId: string;
  classification: string;
  description: string;
};

export type DocumentStatus = { name: string; status: string };
