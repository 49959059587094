import { useState } from 'react';
import {
  Button,
  Col,
  FormCheckbox,
  Heading,
  Row,
} from '@snsw/react-component-library';
import getContent, { getContentWithHtml } from 'src/utils/contentUtils';

import { ButtonGroup, NoStyleButton } from '../styles';

import { PrivacyCollectionModal } from './privacyCollectionModal';
import TermsOfUseModal from './termsOfUseModal';

type TermsOfUseTypes = {
  isAgreed: boolean;
  setIsAgreed: (agree: boolean) => void;
  handleAcceptAndContinue: () => void;
};

const TermsOfUse = ({
  isAgreed,
  setIsAgreed,
  handleAcceptAndContinue,
}: TermsOfUseTypes) => {
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
  };
  const [termsOfUseModalOpen, setTermsOfUseModalOpen] = useState(false);
  const [privacyCollectionModalOpen, setprivacyCollectionModalOpen] =
    useState(false);

  return (
    <>
      <Heading level={3}>
        {getContent('onboarding.termsOfUse.heading.first.paragraph.heading')}
      </Heading>
      <span>
        {getContent('onboarding.termsOfUse.content.first.paragraph.content1')}
      </span>
      <span>
        <NoStyleButton
          onClick={() => {
            setTermsOfUseModalOpen(true);
          }}
        >
          <p>
            {getContent(
              'onboarding.termsOfUse.content.first.paragraph.content2',
            )}
          </p>
        </NoStyleButton>
      </span>
      <Heading level={3}>
        {getContent('onboarding.termsOfUse.heading.second.paragraph.heading')}
      </Heading>
      <span>
        {getContent('onboarding.termsOfUse.content.first.paragraph.content1')}
      </span>
      <span>
        <NoStyleButton
          onClick={() => {
            setprivacyCollectionModalOpen(true);
          }}
        >
          <p>
            {getContent(
              'onboarding.termsOfUse.content.second.paragraph.content2',
            )}
          </p>
        </NoStyleButton>
      </span>
      <Row>
        <Col span={8}>
          <FormCheckbox
            id="agree"
            name="agree"
            label={getContentWithHtml(
              'onboarding.termsOfUse.agreeToTerms.checkbox.label',
            )}
            onChange={handleCheckboxChange}
          />
        </Col>
      </Row>
      <ButtonGroup>
        <Button disabled={!isAgreed} onClick={handleAcceptAndContinue}>
          {getContent('onboarding.termsOfUse.primary.button.text')}
        </Button>
      </ButtonGroup>

      {termsOfUseModalOpen && (
        <TermsOfUseModal
          openModal={termsOfUseModalOpen}
          onClose={() => setTermsOfUseModalOpen(false)}
        />
      )}
      {privacyCollectionModalOpen && (
        <PrivacyCollectionModal
          openModal={privacyCollectionModalOpen}
          onClose={() => setprivacyCollectionModalOpen(false)}
        />
      )}
    </>
  );
};

export default TermsOfUse;
