import { Heading } from '@snsw/react-component-library';
import { spacing } from 'ams-common';
import styled from 'styled-components';

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${spacing.md};
  gap: ${spacing.md};
`;

export const StyledErrorContainer = styled.div<{
  showError: boolean;
}>`
  div.error-content {
    ${({ showError }) => !showError && `display: none`}
  }
`;

export const StyledHeading = styled(Heading)`
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
`;
