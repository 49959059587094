import { isRight, Left } from 'fp-ts/lib/Either';
import { Errors, getFunctionName, Type, ValidationError } from 'io-ts';
import { flatten, get, initial, last } from 'lodash';

function stringify(v: any): string {
  if (typeof v === 'function') {
    return getFunctionName(v);
  }
  if (typeof v === 'number' && !Number.isFinite(v)) {
    if (Number.isNaN(v)) {
      return 'NaN';
    }
    return v > 0 ? 'Infinity' : '-Infinity';
  }
  return JSON.stringify(v, null, 2);
}

function process(error: ValidationError) {
  const { context } = error;

  const path = flatten([
    initial(context).map((entry) => entry.key),
    `${get(last(context), 'key')}:${get(last(context), 'type.name')}` || 'NONE',
  ]).join('/');

  return {
    value: stringify(error.value),
    path,
  };
}

function report(result: Left<Errors>) {
  return result.left.map((error) => {
    return {
      ...(error.message ? { message: error.message } : process(error)),
      object: (get(error.context[0], 'actual') as { [k: string]: any }) || {},
    };
  });
}

export function withDecoded<A, O, OUT>(
  codec: Type<A, O, any>,
  data: any,
  transform: (decoded: A) => OUT,
): OUT {
  const decoded = codec.decode(data);

  if (isRight(decoded)) {
    const r = decoded.right;

    return transform(r);
  }
  const r = report(decoded);
  throw new Error(
    `Unable to parse model using ${codec.name}, there were ${decoded.left.length} errors\n${r}`,
  );
}
