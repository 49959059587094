import { Heading, Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

import { StyledContainer, StyledLink } from '../styles';

const TermsOfUseModal = ({
  openModal,
  onClose,
}: {
  openModal: boolean;
  onClose: () => void;
}) => {
  return (
    <>
      <p />
      {openModal && (
        <Modal
          title={getContent(
            'onboarding.termsOfUse.heading.first.modal.heading',
          )}
          onClose={onClose}
          buttons={[
            {
              text: 'OK',
              onClick: () => {
                onClose();
              },
            },
          ]}
        >
          <StyledContainer>
            This page and related links are used to explain the payroll tax
            compliance portal terms of use.
            <StyledContainer>
              <ol>
                <Heading level={4}>Introduction</Heading>
                <li>
                  <StyledContainer>
                    Using the payroll tax compliance portal
                  </StyledContainer>
                  You agree to use the payroll tax compliance portal
                  (“compliance portal”) only for lawful purposes. You must use
                  it in a way that does not infringe on the rights of, or
                  restrict or inhibit the use of, this site by anyone else.
                </li>
                <li>
                  <StyledContainer>About these terms of use</StyledContainer>
                  <StyledContainer>
                    By creating a compliance portal account, you agree to be
                    bound by these terms of use and have read the compliance
                    portal - Privacy Collection Notice.
                  </StyledContainer>
                  <StyledContainer>
                    Importantly, this means making sure you know and follow your
                    responsibilities (see below)
                  </StyledContainer>
                  <StyledContainer>
                    Your use of the compliance portal and having an account does
                    not affect or reduce any legal obligation:
                  </StyledContainer>
                  <ul>
                    <li>Revenue NSW owes you,or</li>
                    <li> you owe to Revenue NSW.</li>
                  </ul>
                </li>
                <li>
                  Updates to terms of use
                  <StyledContainer>
                    Revenue NSW may change, add, or remove any functionality of
                    the compliance portal, or make changes to these terms of use
                    at any time. If those changes affect your rights or
                    responsibilities, Revenue NSW will notify you via email.
                  </StyledContainer>
                  By logging in or staying logged in after that notification is
                  sent, you accept the new terms of use.
                </li>
                <li>
                  Who can have a compliance portal account?
                  <StyledContainer>
                    To have a compliance portal account you must:
                  </StyledContainer>
                  <ul>
                    <li>be a natural person (not a corporate entity)</li>
                    <li>
                      have your own email address (because an email address can
                      only be used once to create a compliance portal account)
                    </li>
                    <li>not share your account with others </li>
                    <li>not share your account login details with others</li>
                  </ul>
                </li>
                <li>
                  <StyledContainer>Privacy and security</StyledContainer> By
                  having a compliance portal account, you agree to Revenue NSW’s
                  collection, storage, use and disclosure of your personal
                  information as set out in the compliance portal – Privacy
                  Collection Notice.
                </li>
                <StyledContainer>
                  Revenue NSW gives priority to protecting the privacy of your
                  personal information. We do this by handling personal
                  information in a responsible manner and in accordance with the
                  Privacy and Personal Information Protection Act 1998 and the
                  Taxation Administration Act 1996.
                </StyledContainer>
                <StyledContainer>
                  Revenue NSW will take reasonable security measures to protect
                  your personal information from loss, unauthorised access, use,
                  modification, disclosure, or other misuse. Physical measures,
                  such as building and equipment security, are used in
                  conjunction with digital technology, such as data encryption
                  and firewalls, to minimise unauthorised access to information.
                </StyledContainer>
                <StyledContainer>
                  For further details about how Revenue NSW collects and manages
                  personal information, how you can access and correct it, or to
                  make a privacy complaint, please visit
                  <StyledLink href="https://www.revenue.nsw.gov.au/privacy.">
                    https://www.revenue.nsw.gov.au/privacy.
                  </StyledLink>
                </StyledContainer>
                <Heading level={4}>Your Responsibilities</Heading>
                <li>
                  <StyledContainer>
                    Importance of your responsibilities
                  </StyledContainer>
                  <StyledContainer>
                    It is important to understand your responsibilities when
                    using the compliance portal.{' '}
                    <strong>
                      If you do not comply with these responsibilities, we may
                      lock or suspend your compliance portal account.
                    </strong>
                  </StyledContainer>
                  If you do not keep your personal information secure, it is
                  possible someone could pretend to be you to obtain services or
                  personal information.
                </li>
                <li>
                  <StyledContainer>Access</StyledContainer>You must only create
                  a compliance portal account with your own email address. You
                  must not use an email address that is shared or accessible by
                  others.
                  <StyledContainer>
                    You must not allow someone else to log in to your account,
                    and you are not allowed to access another person&apos;s
                    account.
                  </StyledContainer>
                  <StyledContainer>
                    Keep your compliance portal account, email, and password
                    secure at all times — do not share your password with anyone
                    else.
                  </StyledContainer>
                  <StyledContainer>
                    If you require additional users to access your matter in the
                    compliance portal, please contact us.
                  </StyledContainer>
                  <StyledContainer>
                    You acknowledge and agree that:
                    <ol>
                      <li>
                        each user is authorised to access and use the compliance
                        portal
                      </li>
                      <li>
                        all information included during the compliance portal
                        registration process provided by you and each user is
                        true and accurate
                      </li>
                      <li>
                        all users approved by you are able to access, view and
                        update any content within the compliance portal for the
                        specified matter
                      </li>
                      <li>
                        you will not share your login details with other
                        individuals
                      </li>
                      <li>
                        all information uploaded in the compliance portal is
                        true and accurate. Providing false or misleading
                        information is a serious offence and penalties may
                        apply.
                      </li>
                    </ol>
                  </StyledContainer>
                </li>
                <li>
                  <StyledContainer>Banned uses </StyledContainer> You are not
                  allowed to use the compliance portal:
                  <ul>
                    <li>
                      to participate in any illegal or fraudulent activity
                    </li>
                    <li>
                      to cause disruption to the compliance portal in a way that
                      infringes a person&apos;s rights or prevents them from
                      using the compliance portal in any way that may harass,
                      cause distress or inconvenience others
                    </li>
                    <li>
                      to post or send unlawful, defamatory, offensive, or
                      scandalous material, or
                    </li>
                    <li>
                      to post or send material that breaks or encourages
                      breaking the law.
                    </li>
                    <StyledContainer>
                      If Revenue NSW suspects that your account is being used in
                      a manner that is not permitted, including for any illegal
                      or fraudulent activity, you may be prevented from using
                      your account. Where illegal or fraudulent activity is
                      suspected, Revenue NSW may also notify NSW Police and
                      other authorities as permitted by law.
                    </StyledContainer>
                  </ul>
                </li>
                <li>
                  <StyledContainer>Notifications and messages</StyledContainer>
                  Revenue NSW will send you notifications about your compliance
                  portal account and important information via:
                  <ul>
                    <li>email and/or</li>
                    <li>SMS.</li>
                  </ul>
                  <StyledContainer>
                    You need to keep your notification contact details up to
                    date, so you know when you have messages from Revenue NSW.
                  </StyledContainer>
                  <StyledContainer>
                    You are responsible for reading all your notifications in a
                    timely manner.
                  </StyledContainer>
                  If you receive information not intended for you or believe
                  access to your account has been compromised, you must contact
                  us.
                </li>
                <Heading level={4}>Our Responsibilities</Heading>
                <li>
                  <StyledContainer>General </StyledContainer> Revenue NSW will
                  take reasonable care in providing information and services
                  through the compliance portal. Revenue NSW does not warrant
                  that:
                  <ul>
                    <li>the compliance portal is error free </li>
                    <li>
                      any defects in the compliance portal will be rectified, or
                    </li>
                    <li>
                      you will have continuous access to your compliance portal
                      account.
                    </li>
                  </ul>
                  Revenue NSW does not accept liability for
                  <ul>
                    <li>
                      any loss, damage, cost, or expense (to any person or
                      property) including consequential or indirect loss or any
                      loss of profits, data or revenue that could arise as a
                      result of your use of the compliance portal, or
                    </li>
                    <li>
                      your inability to access your compliance portal account.
                    </li>
                  </ul>
                </li>
                <li>
                  <StyledContainer>
                    Security of your personal information stored within your
                    account
                  </StyledContainer>
                  Revenue NSW has responsibilities relating to the security of
                  information, within your compliance portal account and will
                  use all reasonable endeavours to meet these responsibilities.
                  <StyledContainer>
                    Your compliance portal account information can only be
                    accessed by Revenue NSW staff who are authorised to access
                    this information. All access to the compliance portal
                    accounts is monitored by Revenue NSW and Revenue NSW staff
                    are trained about their privacy obligations.
                  </StyledContainer>
                  <StyledContainer>
                    Revenue NSW will use all reasonable endeavours to ensure
                    that your information is accurately recorded and not
                    corrupted or changed.
                  </StyledContainer>
                  <StyledContainer>
                    Revenue NSW is responsible for the security of information
                    while it is collected by, stored on, or passing through the
                    Revenue NSW systems.
                  </StyledContainer>
                </li>
                <li>
                  <StyledContainer>Notifications and messages </StyledContainer>
                  All correspondence about changes to your compliance portal
                  account will be sent to the email address you use to log in to
                  your compliance portal account.
                  <StyledContainer>
                    For this reason, you must regularly check and update your
                    email address and notification preferences as you will be
                    deemed to have received it unless you notify Revenue NSW
                    otherwise in writing.
                  </StyledContainer>
                </li>
              </ol>
            </StyledContainer>
          </StyledContainer>
        </Modal>
      )}
    </>
  );
};

export default TermsOfUseModal;
