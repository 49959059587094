import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

import { AuditorAuditMatterTransferredData } from './types';

type AuditorAuditMatterTransferredFromProps = {
  data: AuditorAuditMatterTransferredData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  updateMatterContext?: (matterIdOrThreadId: string) => void;
  alertId: string;
};

export const AuditorAuditMatterTransferredFrom = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
  updateMatterContext,
}: AuditorAuditMatterTransferredFromProps) => {
  const { auditorName, leadClientName, matterId } = data;

  const title = getContent('matterAlert.alert.audit.matter.transferred.title');
  const body = getContentWithReplacements(
    `matterAlert.alert.auditor.audit.matter.transferred.from.body`,
    {
      auditorName,
      leadClientName,
      matterId,
    },
  );

  const linkText = getContentWithReplacements(
    `matterAlert.alert.auditor.audit.matter.transferred.from.linkText`,
    {
      auditorName,
      leadClientName,
      matterId,
    },
  );

  const linkUrl = `${PATHS.matters}`;

  return (
    <MatterAlert
      title={title}
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={linkUrl}
      linkText={linkText}
      onClick={() => {
        if (!read) updateStatus(alertId);
        if (matterId) updateMatterContext?.(matterId);
      }}
    />
  );
};
