import { ReactNode, useCallback } from 'react';
import { useOktaLogout } from 'src/components/users';
import environments from 'src/environments';

import { BaseAutoLogout } from './BaseAutoLogout';
import {
  CONTINUE_KEY_PREFIX,
  LAST_ACTIVITY_KEY_PREFIX,
  LOGOUT_KEY_PREFIX,
  USER_TYPES,
} from './constants';

interface StaffAutoLogoutProps {
  children: ReactNode;
}

export const StaffAutoLogout = ({ children }: StaffAutoLogoutProps) => {
  const handleLogout = useOktaLogout();

  const onLogout = useCallback(async () => {
    await handleLogout?.();
    localStorage.removeItem(
      `${environments.appEnvironment}_${LAST_ACTIVITY_KEY_PREFIX}${USER_TYPES.STAFF}`,
    );
    localStorage.removeItem(
      `${environments.appEnvironment}_${CONTINUE_KEY_PREFIX}${USER_TYPES.STAFF}`,
    );
    localStorage.removeItem(
      `${environments.appEnvironment}_${LOGOUT_KEY_PREFIX}${USER_TYPES.STAFF}`,
    );
  }, [handleLogout]);

  return (
    <BaseAutoLogout onLogout={onLogout} userType={USER_TYPES.STAFF}>
      {children}
    </BaseAutoLogout>
  );
};
