import { MatterAlert } from 'ams-common';
import { PATHS } from 'src/routes/constants';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

import { AuditorToCustomerMessageReceivedData } from './types';

type ExtendedAuditorToCustomerMessageReceivedData =
  AuditorToCustomerMessageReceivedData & {
    matterId: string;
  };

type AuditorToCustomerMessageReceivedProps = {
  data: ExtendedAuditorToCustomerMessageReceivedData;
  createdDate: string;
  read: boolean;
  updateStatus: (alertId: string) => void;
  alertId: string;
};

export const AuditorToCustomerMessageReceived = ({
  data,
  createdDate,
  read,
  alertId,
  updateStatus,
}: AuditorToCustomerMessageReceivedProps) => {
  const { auditorName, matterId, threadId } = data;

  const title = getContent('matterAlert.alert.message.received.title');

  const body = getContentWithReplacements(
    'matterAlert.alert.customer.message.received.body',
    {
      auditorName,
    },
  );

  return (
    <MatterAlert
      title={title}
      timestamp={createdDate}
      body={body}
      isRead={read}
      linkUrl={`${PATHS.matters}/${matterId}${PATHS.messages}/${threadId}${PATHS.messageDetails}`}
      linkText="View message"
      onClick={() => {
        if (!read) updateStatus(alertId);
      }}
    />
  );
};
