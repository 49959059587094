export enum HistoryLogFieldNames {
  id = 'id',
  originalStatus = 'originalStatus',
  revisedStatus = 'revisedStatus',
  comment = 'comment',
  modifiedDate = 'modifiedDate',
  modifiedBy = 'modifiedBy',
}

export enum HistoryLogFieldLabel {
  originalStatus = 'Original status',
  revisedStatus = 'Revised status',
  comment = 'Comments',
  modifiedDate = 'Timestamp',
  modifiedBy = 'Changed by',
}

export const HistoryLogHeaderLabels = [
  'Original status',
  'Revised status',
  'Comments',
  'Timestamp',
  'Changed by',
];
