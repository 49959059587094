import * as t from 'io-ts';
import { tOptional } from 'src/types';

export type SortConfig = {
  key: string | null;
  direction: boolean | 'desc' | 'asc';
};

export type SortDirection = boolean | 'desc' | 'asc';

export const MatterCodec = t.type({
  portalMatterId: t.string,
  matterId: t.string,
  leadClientName: t.string,
  age: t.number,
  groupMember: t.number,
  status: t.string,
  lastActionDate: t.string,
  lastActionBy: t.string,
  lastAction: tOptional(t.string),
  closed: t.boolean,
  terminated: t.boolean,
  completionDate: tOptional(t.string),
});

export const MattersContentCodec = t.array(MatterCodec);

export const MattersDataCodec = t.type({
  totalElements: t.number,
  content: tOptional(MattersContentCodec),
});

export type MattersContent = t.TypeOf<typeof MattersContentCodec>;

export type MattersData = t.TypeOf<typeof MattersDataCodec>;

export type Matter = t.TypeOf<typeof MatterCodec>;

export type NavigationTitle =
  | 'summary'
  | 'threads'
  | 'documents'
  | 'contacts'
  | 'matterNotifications';

export type SubNavigationTitle =
  | 'threads.auditCommencementDocuments'
  | 'threads.requestForInformation'
  | 'threads.preliminaryFindings'
  | 'threads.finalisationAdvice'
  | 'threads.submitAuditCommencementDocuments'
  | 'threads.submitResponseToAuditCommencementDocuments'
  | 'threads.auditCommencement';
