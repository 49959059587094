/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum DocumentFieldLabels {
  CorrespondenceType = 'Correspondence type',
  InitiatedBy = 'Initiated by',
  Status = 'Status',
  SentDate = 'Sent date',
  DueDate = 'Due date',
}

export enum DocumentFieldNames {
  CorrespondenceId = 'id',
  CorrespondenceType = 'type',
  InitiatedBy = 'initiatedBy',
  Status = 'status',
  SentDate = 'sentDate',
  DueDate = 'dueDate',
  Action = 'action',
}

export enum CorrespondenceFieldNames {
  CorrespondenceId = 'id',
  PortalContactsId = 'id',
  CorrespondenceType = 'corroType',
  Status = 'status',
  DueDate = 'dueDate',
  InitiatedDate = 'sentDate',
  InitiatedBy = 'initiatedBy',
  Subject = 'subject',
  PortalContacts = 'portalContacts',
  LastAction = 'lastAction',
  ClientId = 'clientId',
  ClientName = 'clientName',
  ContactName = 'contactName',
  ResponseIndicator = 'response',
  MessageBody = 'messageBody',
  Documents = 'documents',
  ReplyFrom = 'replyFrom',
  SentBy = 'sentBy',
  DraftCorrespondenceId = 'draftCorrespondenceId',
  ContactId = 'contactId',
}

export enum CorrespondenceLabels {
  CorrespondenceId = 'id',
  CorrespondenceType = 'Correspondence type',
  Status = 'Status',
  DueDate = 'Due date',
  InitiatedDate = 'Initiated date',
  InitiatedBy = 'Initiated by',
  Subject = 'Subject',
  EntityName = 'Entity name(s)',
  SentDate = 'Sent date',
  lastAction = 'Last action',
}

export enum CorroTypes {
  AuditCommencement = 'AUDIT COMMENCEMENT DOCUMENTS',
  RequestInfo = 'REQUEST FOR INFORMATION',
  PreliminaryFindings = 'PRELIMINARY FINDINGS',
  FinalisationAdvice = 'FINALISATION ADVICE',
}

enum ActionValues {
  AuditCommencement = 'audit_commencement',
  RequestInfo = 'request_info',
  PreliminaryFindings = 'preliminary_findings',
  FinalisationAdvice = 'finalisation_adice',
}

export enum CorrespondenceContentKeys {
  AuditCommencement = 'auditCommencementDocuments',
  RequestInfo = 'requestForInformation',
  PreliminaryFindings = 'preliminaryFindings',
  FinalisationAdvice = 'finalisationAdvice',
}

export const CORRESPONDENCE_TYPE_MAPPER = {
  [CorroTypes.AuditCommencement]: 'Audit commencement documents',
  [CorroTypes.RequestInfo]: 'Request for information',
  [CorroTypes.PreliminaryFindings]: 'Preliminary findings',
  [CorroTypes.FinalisationAdvice]: 'Finalisation advice',
};

enum PathToCorrespondenceMapper {
  AuditCommencement = 'submit-response-to-audit-commencement-documents',
  RequestInfo = 'request-information',
  PreliminaryFindings = 'preliminary-findings',
  FinalisationAdvice = 'finalisation-advice',
}

export const CORRESPONDENCE_TYPES = [
  {
    action: ActionValues.AuditCommencement,
    value: CorroTypes.AuditCommencement,
    contentKey: CorrespondenceContentKeys.AuditCommencement,
    title: CORRESPONDENCE_TYPE_MAPPER[CorroTypes.AuditCommencement],
    path: PathToCorrespondenceMapper.AuditCommencement,
  },
  {
    action: ActionValues.RequestInfo,
    value: CorroTypes.RequestInfo,
    contentKey: CorrespondenceContentKeys.RequestInfo,
    title: CORRESPONDENCE_TYPE_MAPPER[CorroTypes.RequestInfo],
    path: PathToCorrespondenceMapper.RequestInfo,
  },
  {
    action: ActionValues.PreliminaryFindings,
    value: CorroTypes.PreliminaryFindings,
    contentKey: CorrespondenceContentKeys.PreliminaryFindings,
    title: CORRESPONDENCE_TYPE_MAPPER[CorroTypes.PreliminaryFindings],
    path: PathToCorrespondenceMapper.PreliminaryFindings,
  },
  {
    action: ActionValues.FinalisationAdvice,
    value: CorroTypes.FinalisationAdvice,
    contentKey: CorrespondenceContentKeys.FinalisationAdvice,
    title: CORRESPONDENCE_TYPE_MAPPER[CorroTypes.FinalisationAdvice],
    path: PathToCorrespondenceMapper.FinalisationAdvice,
  },
];

export const CLOSE_CORRESPONDENCE_STATUS = 'Closed';

export enum MessageType {
  Success = 'success',
  Error = 'error',
}

export const PDF_EXT = '.pdf';
