import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

export const ConfirmMarkReadModal = ({
  close,
  onConfirmMarkAsRead,
}: {
  close: () => void;
  onConfirmMarkAsRead?: () => void;
}) => {
  return (
    <Modal
      title={getContent('notificationGroup.modal.heading')}
      buttons={[
        {
          text: getContent('notificationGroup.modal.primary.button.title'),
          onClick: () => {
            onConfirmMarkAsRead?.();
            close();
          },
        },
        {
          text: getContent('notificationGroup.modal.secondary.button.title'),
          onClick: close,
        },
      ]}
      description={getContent('notificationGroup.modal.text')}
    />
  );
};
