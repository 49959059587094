import {
  AMS_PORTAL_EXTERNAL_BFF,
  APIResource,
  MethodTypes,
  requestApi,
} from 'src/api';

import { EditDocumentRequestPayload } from './types';

const editDocumentRequest = (
  matterUId: string,
  documentId: string,
  editDocumentParams: EditDocumentRequestPayload,
  isCustomer: boolean = false,
) => {
  return requestApi(
    {
      ...(isCustomer && { baseUrl: AMS_PORTAL_EXTERNAL_BFF }),
      resourcePath: APIResource.documents,
      suffix: ['matters', `${matterUId}`, 'documents', `${documentId}`],
    },
    MethodTypes.PUT,
    editDocumentParams,
    undefined,
    undefined,
    // passing headers
    {
      'content-type': 'application/json',
    },
  );
};

const editDocument = (
  matterUId: string,
  documentId: string,
  editDocumentParams: EditDocumentRequestPayload,
  isCustomer: boolean = false,
) => editDocumentRequest(matterUId, documentId, editDocumentParams, isCustomer);

export const useSingleDocumentModal = () => {
  return {
    editDocument,
  };
};
