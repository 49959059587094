const contents = {
  'myprofile.heading': 'My Profile',
  'myprofile.first.subheading': 'Details',
  'myprofile.second.subheading': 'Out of office',
  'myprofile.onsubmit.alert.success': 'Out of office update successful',
  'myprofile.onsubmit.alert.error': 'Failed to update. Please try again.',
  'myprofile.details.tooltip.text':
    'Your name, position and phone details are from the MARS system. Any changes to them are to be updated in MARS',
  'myprofile.outOfOffice.message.helpMessage':
    ' Maximum 300 characters including spaces.  {remMessageCharCount} character(s) remaining',
  'myprofile.customer.first.subheading': 'Contact details',
  'myprofile.breadcrumbs.text': 'My profile',
  'myprofile.contactDetails.button.text': 'Back to matter overview',
  'myprofile.updateContactDetails.breadcrumbs.text': 'Update contact details',
  'myprofile.updateContactDetails.emailAlert.modal.heading.text':
    'Updating your existing email address',
  'myprofile.updateContactDetails.emailAlert.modal.description.text':
    'By updating the e-mail address, you will have to undergo the new registration process with your updated e-mail address, and the access with the existing e-mail address will be lost. Are you sure you want to proceed?',
  'myprofile.updateContactDetails.principalContactError.modal.heading.text':
    'Principal contact already exists',
  'myprofile.updateContactDetails.principalContactError.modal.description.text':
    'Please contact the Auditor to update you as a Principal compliance contact as there is another principal user exist already',
};

export default contents;
