import { useEffect, useState } from 'react';
import { PageTitle, StyledAMSContentContainer } from 'ams-common';
import { updateNotificationContentWithMatterId } from 'src/components/alerts/helpers';
import NotificationSection from 'src/components/alerts/notificationsSection';
import { MatterNotificationContent } from 'src/components/alerts/types';
import { useMatter } from 'src/context/MatterContext';
import { useMattersData } from 'src/screens/common/matters/hooks';
import getContent from 'src/utils/contentUtils';

import { useNotifications, useUpdateAlertStatus } from './hooks';

export const Notifications = () => {
  const { isLoading, isError, data: notifications } = useNotifications();
  const { mutate: updateAlertStatus } = useUpdateAlertStatus();
  const { data: mattersData } = useMattersData();
  const { updateMatterUId, updateMatterStatus, updateMatterCorrespondenceId } =
    useMatter();
  const [notificationContent, setNotificationContent] = useState<
    MatterNotificationContent[] | null
  >(null);

  useEffect(() => {
    if (notifications && mattersData?.content) {
      const updatedNotificationsContent = updateNotificationContentWithMatterId(
        notifications,
        ['CUSTOMER_CORRESPONDENCE_READ', 'CUSTOMER_DOCUMENT_READ'],
        undefined,
        mattersData?.content,
      );

      setNotificationContent(updatedNotificationsContent);
    }
  }, [notifications, mattersData]);

  if (isLoading || isError || !notifications) {
    return <PageTitle title={getContent('notifications.heading.title')} />;
  }
  const updateMatterContext = (matterId: string, correspondenceId?: string) => {
    if (mattersData && mattersData.content) {
      const { portalMatterId: matterUId, status } =
        mattersData.content.find(
          ({ matterId: matterIdFromResponse }) =>
            matterIdFromResponse === matterId,
        ) || {};

      if (matterUId) {
        updateMatterUId(matterUId);
      }

      if (status) {
        updateMatterStatus(status);
      }

      if (correspondenceId) {
        updateMatterCorrespondenceId(correspondenceId);
      }
    }
  };

  const updateStatus = async (id: string) => {
    await updateAlertStatus(id);
  };

  return (
    <>
      <PageTitle title={getContent('notifications.heading.title')} />
      <StyledAMSContentContainer>
        {notificationContent && notificationContent.length > 0 && (
          <NotificationSection
            notifications={notificationContent}
            updateStatus={updateStatus}
            updateMatterContext={updateMatterContext}
          />
        )}
      </StyledAMSContentContainer>
    </>
  );
};
