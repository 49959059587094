import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import environments from 'src/environments';
import { PATHS } from 'src/routes/constants';

import { useCustomerLogout, useOktaLogout } from '../hook';

import { AuthButton } from './styles';

interface AuthButtonsProps {
  isStaffLogin: boolean;
  isCustomerLogin: boolean;
}

export const AuthButtons: React.FC<AuthButtonsProps> = ({
  isStaffLogin,
  isCustomerLogin,
}) => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth() || {};
  const handleLogout = useOktaLogout();
  const handleCustomerLogout = useCustomerLogout();

  const logoutCustomer = async () => {
    if (!handleCustomerLogout) return;

    try {
      await handleCustomerLogout();
      navigate(PATHS.logoutSuccess, {
        state: { [`${environments.appEnvironment}_logout_customer`]: true },
      });
      localStorage.setItem(
        `${environments.appEnvironment}_logout_customer`,
        'true',
      );
    } catch (err) {
      console.error('Error logging out customer:', err);
    }
  };

  const handleStaffLogout = async () => {
    if (handleLogout) {
      await handleLogout();
      localStorage.setItem(
        `${environments.appEnvironment}_logout_staff`,
        'true',
      );
    }
  };

  if (isStaffLogin && authState?.isAuthenticated) {
    return <AuthButton onClick={handleStaffLogout}>Logout</AuthButton>;
  }

  if (isCustomerLogin) {
    return <AuthButton onClick={logoutCustomer}>Logout</AuthButton>;
  }

  return null;
};
