import { spacing } from 'ams-common';
import styled from 'styled-components';

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: left;
  margin-top: ${spacing.xxl};
  gap: ${spacing.md};
  flex-direction: column;
  width: 20%;
`;

export const StyledErrorContainer = styled.div<{
  showError: boolean;
}>`
  div.error-content {
    ${({ showError }) => !showError && `display: none`}
  }
`;
