import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomerLogout } from 'src/components/users';
import { SESSION_STORAGE_KEY_USER_INFO } from 'src/constants';
import environments from 'src/environments';
import { PATHS } from 'src/routes/constants';

import { BaseAutoLogout } from './BaseAutoLogout';
import {
  CONTINUE_KEY_PREFIX,
  LAST_ACTIVITY_KEY_PREFIX,
  LOGOUT_KEY_PREFIX,
  USER_TYPES,
} from './constants';

interface CustomerAutoLogoutProps {
  children: ReactNode;
}

export const CustomerAutoLogout = ({ children }: CustomerAutoLogoutProps) => {
  const navigate = useNavigate();
  const handleCustomerLogout = useCustomerLogout();

  const onLogout = useCallback(async () => {
    await handleCustomerLogout();
    window.sessionStorage.removeItem(SESSION_STORAGE_KEY_USER_INFO);
    localStorage.removeItem(
      `${environments.appEnvironment}_${LAST_ACTIVITY_KEY_PREFIX}${USER_TYPES.CUSTOMER}`,
    );
    localStorage.removeItem(
      `${environments.appEnvironment}_${CONTINUE_KEY_PREFIX}${USER_TYPES.CUSTOMER}`,
    );
    localStorage.removeItem(
      `${environments.appEnvironment}_${LOGOUT_KEY_PREFIX}${USER_TYPES.CUSTOMER}`,
    );
    navigate(PATHS.logoutSuccess, {
      state: {
        [`${environments.appEnvironment}_${LOGOUT_KEY_PREFIX}${USER_TYPES.CUSTOMER}`]:
          true,
      },
    });
  }, [handleCustomerLogout, navigate]);

  return (
    <BaseAutoLogout onLogout={onLogout} userType={USER_TYPES.CUSTOMER}>
      {children}
    </BaseAutoLogout>
  );
};
