import { useState } from 'react';
import {
  FormDate,
  FormGroup,
  InPageAlert,
  Modal,
  Textarea,
} from '@snsw/react-component-library';
import { ErrorSummary, formatDate, Tooltip } from 'ams-common';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { MethodTypes } from 'src/api';
import { useUpdateDueDate } from 'src/hooks';
import getContent, {
  combineContent,
  getContentWithReplacements,
} from 'src/utils/contentUtils';
import * as Yup from 'yup';

import {
  AUDIT_COMMENCEMENT_DOCUMENTS,
  DUE_DATE_EXTENSION_LIMIT,
} from './constants';
import { FormDateGroup } from './styles';

export const DueDateModal = ({
  date,
  theadId,
  matterUId,
  onClose,
  corroType,
}: {
  date: string;
  theadId: string;
  matterUId: string;
  onClose: () => void;
  corroType: string;
}) => {
  const [showModal, setShowModal] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [internalServerError, setInternalServerError] = useState(false);
  const [reasonCharCount, setReasonCharCount] = useState(0);

  const navigateError = () => {
    setShowModal(true);
    setInternalServerError(true);
  };

  const navigateSuccess = () => {
    onClose();
  };

  const { mutate: updateDueDate } = useUpdateDueDate(
    matterUId,
    theadId,
    navigateError,
    navigateSuccess,
  );

  const validationSchema = Yup.object({
    dueDate: Yup.string()
      .required('Due date is required')
      .test('date format validation', 'Invalid due date', (val) => {
        if (!val) return true;
        return dayjs(val, 'YYYY-MM-DD', true).isValid();
      })
      .test('due-date-not-past', 'Due date cannot be a past date', (val) => {
        if (!val) return true;
        const today = dayjs().startOf('day');
        return dayjs(val).isAfter(today) || dayjs(val).isSame(today);
      })
      .test(
        'due-date-within-extension-limit',
        `Due date cannot exceed ${DUE_DATE_EXTENSION_LIMIT[corroType]} weeks`,
        (val) => {
          if (!val) return true;
          const limitDate = dayjs().add(
            DUE_DATE_EXTENSION_LIMIT[corroType] * 7,
            'days',
          );
          return dayjs(val).isBefore(limitDate) || dayjs(val).isSame(limitDate); // Check if dueDate is before or on the limit date
        },
      ),
  });
  const { values, handleChange, handleSubmit, errors, setValues } = useFormik({
    initialValues: {
      dueDate: formatDate(date, 'YYYY-MM-DD'),
      ...(corroType === AUDIT_COMMENCEMENT_DOCUMENTS && { reason: '' }),
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,

    onSubmit: () => {
      setShowConfirmModal(true);
      setShowModal(false);
    },
  });

  const handleDueDateChange = ({ value }: { value: string }) => {
    setValues({
      ...values,
      ...{ dueDate: value },
    });
  };

  const handleDueDateChangeSubmit = async () => {
    setShowConfirmModal(false);
    await updateDueDate([values, MethodTypes.PUT]);
  };
  const corroTypeKey =
    corroType === AUDIT_COMMENCEMENT_DOCUMENTS
      ? 'auditCommencementDocuments'
      : 'others';

  const dueDateTooltipText = getContent(
    `correspondence.${corroTypeKey}.dueDate.modal.dueDate.tooltip.text` as keyof typeof combineContent,
  );

  return (
    <form>
      {showModal && (
        <Modal
          title={getContent(`correspondence.dueDate.modal.title`)}
          buttons={[
            {
              text: getContent(`correspondence.dueDate.modal.secondary.button`),
              onClick: handleSubmit,
            },
            {
              text: getContent('correspondence.dueDate.modal.primary.button'),
              onClick: () => {
                setShowModal(false);
                onClose();
              },
            },
          ]}
          description={getContent(`correspondence.dueDate.modal.text`)}
        >
          <>
            {internalServerError ? (
              <InPageAlert variant="error" title="Error." compact>
                <p>Internal server error. Please try again after sometime.</p>
              </InPageAlert>
            ) : null}
            <ErrorSummary errors={errors} />
            <FormDateGroup
              id="dueDate"
              label={<Tooltip label="Due date" text={dueDateTooltipText} />}
              hasError={!!errors.dueDate}
            >
              <FormDate
                name="dueDate"
                value={values.dueDate}
                onChange={handleDueDateChange}
              />
            </FormDateGroup>
            {corroType !== AUDIT_COMMENCEMENT_DOCUMENTS ? null : (
              <FormGroup
                label="Reason"
                id="reason"
                helpMessage={getContentWithReplacements(
                  'correspondence.dueDate.reason.helpMessage.text',
                  {
                    remChar: (500 - reasonCharCount).toString(),
                  },
                )}
              >
                <Textarea
                  name="reason"
                  value={values.reason}
                  onChange={(e) => {
                    setReasonCharCount(e.target.value.length);
                    handleChange(e);
                  }}
                  placeholder="Please enter a reason"
                  maxLength={500}
                />
              </FormGroup>
            )}
          </>
        </Modal>
      )}{' '}
      {showConfirmModal && (
        <Modal
          title="Confirm new due date"
          buttons={[
            {
              text: getContent(
                `correspondence.dueDate.confirmModal.secondary.button`,
              ),
              onClick: handleDueDateChangeSubmit,
            },
            {
              text: getContent('correspondence.dueDate.modal.primary.button'),
              onClick: () => {
                setShowModal(true);
              },
            },
          ]}
          description={getContentWithReplacements(
            `correspondence.dueDate.confirmModal.text`,
            {
              oldDate: formatDate(date, 'DD MMM YYYY'),
              newDate: formatDate(values.dueDate, 'DD MMM YYYY'),
            },
          )}
        />
      )}
    </form>
  );
};
