import { Modal } from '@snsw/react-component-library';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

interface SaveAsDraftModalProps {
  open: boolean;
  close: () => void;
  handleSaveAsDraft: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isCustomer?: boolean;
  corroType: string;
}

export const SaveAsDraft = ({
  open,
  close,
  handleSaveAsDraft,
  isCustomer = false,
  corroType,
}: SaveAsDraftModalProps) => {
  if (!open) {
    return null;
  }

  const userType = isCustomer ? 'customer' : 'auditor';

  return (
    <Modal
      title={getContent(
        'correspondence.correspondence.saveAsDraft.modal.title',
      )}
      buttons={[
        {
          name: 'save as draft',
          text: 'Save draft',
          onClick: handleSaveAsDraft,
        },
        {
          text: 'Cancel',
          onClick: close,
        },
      ]}
      description={getContentWithReplacements(
        `correspondence.correspondence.${userType}.saveAsDraft.modal.description`,
        { corroType: corroType.toLowerCase() },
      )}
    />
  );
};
