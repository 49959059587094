import { ContentContainer } from '@snsw/react-component-library';
import { Breadcrumbs, StyledAMSContentContainer } from 'ams-common';
import { useUserContext } from 'src/hooks';
import { getMattersBreadcrumbs } from 'src/screens/common/matters/utils';
import { UserLoginType } from 'src/types';

import { InternalMatterNotifications } from './auditor';
import { ExternalMatterNotifications } from './customer';

export const MatterNotifications = () => {
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;

  return (
    <ContentContainer>
      <Breadcrumbs
        paths={getMattersBreadcrumbs('matterNotifications')}
        isAccessible={!isCustomer}
      />

      <StyledAMSContentContainer>
        {isCustomer ? (
          <ExternalMatterNotifications />
        ) : (
          <InternalMatterNotifications />
        )}
      </StyledAMSContentContainer>
    </ContentContainer>
  );
};
