import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  ComponentLoader,
  Heading,
  InPageAlert,
  Row,
} from '@snsw/react-component-library';
import { LabelDescriptionList, ListItemType } from 'ams-common';
import { useCurrentContactDetails, useUserContext } from 'src/hooks';
import { PATHS } from 'src/routes/constants';
import { useMatterSummaryData } from 'src/screens/common/matters/tabs/common/matterSummary/hook';
import { ButtonRow } from 'src/screens/common/matters/tabs/common/matterSummary/styles';
import { UserLoginType } from 'src/types';
import getContent from 'src/utils/contentUtils';

import {
  getClientLabelDescriptionList,
  getCustomerProfileLabelDescriptionList,
} from '../utils';

import { StyledInPageAlert, StyledLink, StyledMessages } from './styles';

export const CustomerContactDetails = () => {
  const { data, isLoading, isError } = useCurrentContactDetails(true);
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const {
    data: currentMatterDetails,
    isLoading: isLoadingCurrentMatterDetails,
    isError: isErrorCurrentMatterDetails,
  } = useMatterSummaryData(null, isCustomer, true);

  const clientDetails =
    currentMatterDetails?.leadClient?.id === data?.clientId
      ? currentMatterDetails?.leadClient
      : currentMatterDetails?.clients.find(
          (client) => data?.clientId === client.id,
        );

  return (
    <>
      {isErrorCurrentMatterDetails ? (
        <InPageAlert variant="error" title="Error." compact>
          <p>Unable to load customer details.</p>
        </InPageAlert>
      ) : null}
      <Row>
        <ComponentLoader active={isLoadingCurrentMatterDetails} fullPage />

        <Col span={8}>
          <Heading />
          {clientDetails && (
            <LabelDescriptionList
              list={
                getClientLabelDescriptionList(clientDetails) as ListItemType[]
              }
            />
          )}
          <Heading level={3}>
            {getContent('myprofile.customer.first.subheading')}
          </Heading>
          <ComponentLoader active={isLoading} fullPage />
          {isError ? (
            <InPageAlert variant="error" title="Error." compact>
              <p>Unable to load customer details.</p>
            </InPageAlert>
          ) : null}

          {data && data.complianceType === 'Principal' ? (
            <StyledInPageAlert
              variant="success"
              title="Principal compliance contact"
            />
          ) : (
            ''
          )}

          {data && (
            <LabelDescriptionList
              list={
                getCustomerProfileLabelDescriptionList(data) as ListItemType[]
              }
            />
          )}
        </Col>
      </Row>
      <ButtonRow>
        <Col span={6}>
          <Link to={`/matters/${currentMatterDetails?.matterId}/overview`}>
            <Button variant="secondary">
              {getContent('myprofile.contactDetails.button.text')}
            </Button>
          </Link>
        </Col>
      </ButtonRow>
      <InPageAlert variant="info" title="" compact>
        <p>
          Should you require an update on e-mail address or the contact number,
          please navigate to{' '}
          <StyledLink
            to={`${PATHS.matters}/${currentMatterDetails?.matterId}${PATHS.messages}`}
          >
            <StyledMessages>Messages</StyledMessages>
          </StyledLink>{' '}
          section to send a request for change
        </p>
      </InPageAlert>
    </>
  );
};
