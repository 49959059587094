const content = {
  'matters.breadcrumbs.text': 'Matters',
  'matters.summary.breadcrumbs.text': 'Overview',
  'matters.contacts.breadcrumbs.text': 'Contacts',
  'matters.documents.breadcrumbs.text': 'Documents',
  'matters.threads.breadcrumbs.text': 'Correspondence',
  'matters.threads.submitAuditCommencementDocuments.breadcrumbs.text':
    'Submit Audit Commencement Documents',
  'matters.threads.submitResponseToAuditCommencementDocuments.breadcrumbs.text':
    'Submit response to audit commencement documents',
  'matters.threads.auditCommencementDocuments.breadcrumbs.text':
    'Audit commencement documents',
  'matters.threads.requestForInformation.breadcrumbs.text':
    'Request for information',
  'matters.threads.preliminaryFindings.breadcrumbs.text':
    'Preliminary Findings',
  'matters.threads.finalisationAdvice.breadcrumbs.text': 'Finalisation Advice',
  'matters.threads.auditCommencement.breadcrumbs.text':
    'Audit commencement documents',
  'matters.threads.auditCommencementCorrespondence.breadcrumbs.text':
    'Audit commencement documents',
  'matters.matterNotifications.breadcrumbs.text': 'Matter Notifications',
};

export default content;
