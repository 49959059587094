import { useQueryClient } from 'react-query';
import { Modal } from '@snsw/react-component-library';
import { QueryKey } from 'src/api/constants';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

import { useDeleteDocument } from './hook';

type Props = {
  matterUId: string | null;
  close: () => void;
  handleAfterDocumentDelete?: (documentId?: string) => void;
  isOpen: boolean;
  documentId: string;
  documentName: string;
  isCustomer?: boolean;
  handleDeleteError?: () => void;
};

const DeleteDocument = ({
  matterUId,
  close,
  handleAfterDocumentDelete,
  documentId,
  documentName,
  isOpen,
  isCustomer,
  handleDeleteError,
}: Props) => {
  const { deleteDocument } = useDeleteDocument();
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    if (!isOpen) {
      return null;
    }

    try {
      if (matterUId) {
        await deleteDocument(matterUId, documentId, isCustomer);
        queryClient.invalidateQueries([QueryKey.MATTER_DOCUMENTS, matterUId]);
        handleAfterDocumentDelete?.(documentId);
        close();
      }
    } catch (error) {
      if (error) handleDeleteError?.();
      close();
    }

    return null;
  };

  return (
    <form>
      <Modal
        id={getContent(`matter.correspondence.deleteDocument.modal.id`)}
        title={getContent(`matter.correspondence.deleteDocument.modal.title`)}
        buttons={[
          {
            text: getContent(
              `matter.correspondence.deleteDocument.modal.secondary.button`,
            ),
            onClick: handleDelete,
          },
          {
            text: getContent(
              'matter.correspondence.deleteDocument.modal.primary.button',
            ),
            onClick: close,
          },
        ]}
        description={getContentWithReplacements(
          `matter.correspondence.deleteDocument.modal.help.text`,
          { documentName },
        )}
      />
    </form>
  );
};

export default DeleteDocument;
