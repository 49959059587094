import { Modal } from '@snsw/react-component-library';
import { pxToRem, spacing } from 'ams-common';
import styled from 'styled-components';

export const FileName = styled.p`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
  margin-top: ${spacing.xs};
  margin-bottom: ${spacing.xs};
`;

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${spacing.lg} 0;
  gap: ${spacing.md};
  float: right;
`;

export const StyledModal = styled(Modal)`
  [class^='Footer'] {
    display: none;
  }
`;

export const StyledIcon = styled.div`
  margin-top: ${pxToRem(10)};
  align-content: center;
`;
